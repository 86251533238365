import React, { FC, useState, useEffect, useLayoutEffect } from "react";
import Main from "components/templates/Main";
import { RouteComponentProps } from "react-router-dom";
import { Box, Button, Flex, Select, SimpleGrid, Text } from "@chakra-ui/react";
import { IMaintenance } from "models";
import { Card } from "components/atoms";
import { CheckBoxElement, Form, InputElement } from "components/atoms/HookForm";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import { useToast } from "hooks/toast";
import Moment from "moment";

type PageStates = {
  maintenance: IMaintenance;
};

const MaintenanceEdit: FC<RouteComponentProps<{}, {}, PageStates>> = ({
  location: {
    state: { maintenance },
  },
  history
}) => {
  const { project } = useAuth();
  const toast = useToast();
  const [getP, statusP, dataPeriod] = Hooks.ListPeriod();
  const [onEdit, status, dataEdit] = Hooks.EditMaintenance();

  const [maintenanceType, setMaintenanceType] = useState<string>(
    maintenance.maintenanceTypeID.toString()
  );
  const [periodType, setPeriodType] = useState<string>(
    maintenance.maintenancePeriodID.toString()
  );

  function handleValid(values: any) {
    onEdit({
      id: maintenance.id,
      beforeDay: maintenance.beforeDay,
      controlFormPhotoIdRequired: values.controlFormPhotoIdRequired,
      inventoryPhotoIdRequired: values.inventoryPhotoIdRequired,
      maintenanceContractIsRequired: values.maintenanceContractIsRequired,
      userGudiePathIsRequired: values.userGudiePathIsRequired,
      riskAnalysisIsRequired: values.riskAnalysisIsRequired,
      userInstructionsIsRequired: values.userInstructionsIsRequired,
      inventoryID: maintenance.inventoryID,
      isActive: maintenance.isActive,

    });
  }

  useEffect(() => {
    if (dataEdit?.data.success) {
      toast({ title: "İşlem Başarıyla gerçekleşti!", status: "success" });
      history.push(`/maintenance-list/${maintenance.inventoryID}`);
    } else if (dataEdit?.data.success === false) {
      toast({
        title: "Hata Oluştu!",
        description: dataEdit?.data.message,
        status: "error",
      });
    }
  }, [dataEdit]);

  useLayoutEffect(() => {
    getP(project.id);
  }, []);

  return (
    <Main headerProps={{ title: "Bakım Düzenle" }}>
      <Card mt="4">
        <Form onValid={handleValid}>
          <SimpleGrid columns={[1, 2]} spacing="5" mt="3">
            <CheckBoxElement
              label="Kullanma talimatı zorunlu mu?"
              name="userInstructionsIsRequired"
              defaultValue={maintenance.userInstructionsIsRequired}
            />
            <CheckBoxElement
              label="Kullanım klavuzu zorunlu mu?"
              name="userGudiePathIsRequired"
              defaultValue={maintenance.userGudiePathIsRequired}
            />
            <CheckBoxElement
              label="ISG risk analizi zorunlu mu?"
              name="riskAnalysisIsRequired"
              defaultValue={maintenance.riskAnalysisIsRequired}
            />
            <CheckBoxElement
              label="Ekipman fotoğrafı zorunlu mu?"
              name="inventoryPhotoIdRequired"
              defaultValue={maintenance.inventoryPhotoIdRequired}
            />
            <CheckBoxElement
              label="İş izin / bakım formu zorunlu mu?"
              name="controlFormPhotoIdRequired"
              defaultValue={maintenance.controlFormPhotoIdRequired}
            />
            <CheckBoxElement
              label="Bakım sözleşmesi zorunlu mu?"
              name="maintenanceContractIsRequired"
              defaultValue={maintenance.maintenanceContractIsRequired}
            />
          </SimpleGrid>
          <Flex mt="5" justifyContent="flex-end">
            <Button colorScheme="teal" type="submit">
              Bakım Düzenle
            </Button>
          </Flex>
        </Form>
      </Card>
    </Main>
  );
};

export default MaintenanceEdit;
