import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  SimpleGrid,
  Spinner,
  Text,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";
import { Card } from "components/atoms";
import Dropzone from "components/atoms/Dropzone";
import Main from "components/templates/Main";
import { api, __mode__ } from "consts";
import { useAuth } from "hooks/redux-hooks";
import { useToast } from "hooks/toast";
import { TimelineItem, TimelineItemDetail } from "models";
import { FC, Fragment, useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaCheck, FaHandPointer } from "react-icons/fa";
import { RouteComponentProps } from "react-router-dom";
import { Hooks } from "services";

interface DoMaintenancePageProps {}

const DoMaintenancePage: FC<
  DoMaintenancePageProps &
    RouteComponentProps<{ id: string }, {}, { timelineItem: TimelineItem }>
> = (props) => {
  const { control, handleSubmit } = useForm();
  const { user, project } = useAuth();
  const [getDetail, statDetail, dataDetail] = Hooks.MaintenanceTimelineDetail();
  const [getQustions, statQuestions, dataQuestions] = Hooks.ListQuestion();
  const [doMaintenance, statDoMain, dataDoMain] = Hooks.DoMaintenance();
  const [loading, setLoading] = useState<boolean>(false);
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const [datas, setDatas] = useState({
    isg: false,
    contract: false,
    instruction: false,
    guide: false,
    photo: null as File[] | null,
    request: null as File[] | null,
    firm: "",
    user: "",
    explain: "",
  });

  const [questions, setQuestions] = useState<any[]>([]);
  const toast = useToast();

  function _onOk(_key: keyof typeof datas) {
    setDatas((_datas) => ({ ...datas, [_key]: true }));
  }

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  function getBase64Photos(arr: any[]) {
    return new Promise(async (resolve, reject) => {
      let arrData: any[] = [];
      for (var index = 0; index < arr.length; index++) {
        let base64str = await toBase64(arr[index]);
        let ext = arr[index].name.split(".").pop();
        arrData.push({ base64: base64str, extension: ext });
      }

      return resolve(arrData);
    });
  }

  useEffect(() => {
    if (statDoMain) {
      if (dataDoMain?.data.success) {
        toast({ title: "Bakım işlemi başarıyla gerçekleşti!" });
        props.history.push("/");
      }
    }
  }, [statDoMain]);

  async function handleClickComplete() {
    setLoading(true);
    let base64List = await getBase64Photos(datas.photo || []);
    let base64ListForms = await getBase64Photos(datas.request || []);
    await doMaintenance({
      createUserID: user.id,
      projectID: project.id,
      projectName: project.name,
      inventoryBarcode: (dataDetail?.data.data as TimelineItemDetail).barcode,
      inventoryID: (dataDetail?.data.data as TimelineItemDetail).inventoryID,
      inventoryMaintenanceID: (dataDetail?.data.data as TimelineItemDetail)
        .maintenanceId,
      maintenanceStartDate: (dataDetail?.data.data as TimelineItemDetail)
        .startDate,
      maintenanceEndDate: (dataDetail?.data.data as TimelineItemDetail).endDate,
      images: [
        ...(base64List as any[]).map((base64Str) => ({
          photo: base64Str.base64,
          extension: base64Str.extension,
          type: "inventoryPhoto",
        })),
        ...(base64ListForms as any[]).map((base64Str) => ({
          photo: base64Str.base64,
          extension: base64Str.extension,
          type: "formPhoto",
        })),
      ],
      inventoryName: (dataDetail?.data.data as TimelineItemDetail).name,
      maintenanceTypeName: (dataDetail?.data.data as TimelineItemDetail)
        .maintenanceTypeName,
      periodName: (dataDetail?.data.data as TimelineItemDetail)
        .maintenancePeriodName,
      personelName: datas.firm,
      maintenanceFirm: datas.firm,
      isAfterCompleted:
        (dataDetail?.data.data as TimelineItemDetail).maintenanceTypeId !== 3,
      maintenanceExplain: datas.explain,
      maintenanceQuestion: !!dataQuestions?.data.data
        ? JSON.stringify(questions)
        : undefined,
      isMaintenanceIncomplete:
        (dataDetail?.data.data as TimelineItemDetail).maintenanceTypeId === 3,
      //TODO: STATE DON SORUYA GORE
      state: !questions
        .map((question: any) => {
          if (question.QuestionType === 1) {
            if (
              question.SelectedItem <= question.EndValue &&
              question.SelectedItem >= question.InitialValue
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            if (question.trueFalseAnswer) {
              return question.SelectedItem === "Yes";
            } else {
              return question.SelectedItem === "No";
            }
          }
        })
        .includes(false),
    });
    setLoading(false);
  }

  useEffect(() => {
    if (!!dataDetail && !!dataDetail?.data?.data?.inventoryMaintenanceTypeID) {
      getQustions(dataDetail.data.data.inventoryMaintenanceTypeID);
    }
  }, [dataDetail]);

  useEffect(() => {
    if (dataQuestions) {
      let data: any[] = [];
      dataQuestions.data.data
        .sort((a: any, b: any) => b.order - a.order)
        .forEach((quest: any, index: number) => {
          data.push({
            ...quest,
            Id: quest.id,
            Question: quest.question,
            SelectedItem: null,
            isDone: true,
            QuestionType: quest.maintenanceQuestionTypeID,
            InitialValue: quest.initialValue,
            EndValue: quest.endValue,
            trueFalseAnswer: quest.trueFalseAnswer,
          });
        });
      setQuestions(data);
    }
  }, [dataQuestions]);

  useLayoutEffect(() => {
    getDetail(parseInt(props.match.params.id));
  }, []);

  return (
    <Main headerProps={{ title: "Bakım İşlemleri" }}>
      <Card mb="10">
        {statDetail !== "fulfilled" && (
          <Center h="300px">
            <Spinner />
          </Center>
        )}
        {statDetail === "fulfilled" && !!dataDetail && (
          <Box>
            {[
              (dataDetail.data.data as TimelineItemDetail)
                .userGudiePathIsRequired,
              (dataDetail.data.data as TimelineItemDetail)
                .userInstructionsIsRequired,
              (dataDetail.data.data as TimelineItemDetail)
                .riskAnalysisIsRequired,
              (dataDetail.data.data as TimelineItemDetail)
                .maintenanceContractIsRequired,
            ].includes(true) && (
              <Fragment>
                <Heading fontSize="large">
                  Zorunlu Dokümanlar{" "}
                  <Text d="inline" color="red.500">
                    *
                  </Text>
                </Heading>
                <Divider my="2" />
                <SimpleGrid columns={[1, 2]} spacing="5">
                  <DocumentField
                    required={
                      (dataDetail.data.data as TimelineItemDetail)
                        .maintenanceContractIsRequired
                    }
                    title="Bakım Sözleşmesi"
                    value={datas.contract}
                    onOk={() => {
                      _onOk("contract");
                    }}
                    url={
                      (dataDetail.data.data as TimelineItemDetail)
                        .maintenanceContractPath
                    }
                  />
                  <DocumentField
                    required={
                      (dataDetail.data.data as TimelineItemDetail)
                        .riskAnalysisIsRequired
                    }
                    title="ISG Risk Analizi"
                    value={datas.isg}
                    onOk={() => {
                      _onOk("isg");
                    }}
                    url={
                      (dataDetail.data.data as TimelineItemDetail)
                        .riskAnalysisPath
                    }
                  />
                  <DocumentField
                    required={
                      (dataDetail.data.data as TimelineItemDetail)
                        .userInstructionsIsRequired
                    }
                    title="Kullanma Talimatı"
                    value={datas.instruction}
                    onOk={() => {
                      _onOk("instruction");
                    }}
                    url={
                      (dataDetail.data.data as TimelineItemDetail)
                        .userInstructionsPath
                    }
                  />
                  <DocumentField
                    required={
                      (dataDetail.data.data as TimelineItemDetail)
                        .userGudiePathIsRequired
                    }
                    title="Kullanım Klavuzu"
                    value={datas.guide}
                    onOk={() => {
                      _onOk("guide");
                    }}
                    url={
                      (dataDetail.data.data as TimelineItemDetail).userGudiePath
                    }
                  />
                </SimpleGrid>
              </Fragment>
            )}

            {(dataDetail.data.data as TimelineItemDetail)
              .isInventoryPhotoRequired && (
              <Fragment>
                <Heading fontSize="large" mt="8">
                  Ekipman Fotoğrafı{" "}
                  <Text d="inline" color="red.500">
                    *
                  </Text>
                </Heading>
                <Divider my="2" />
                <Box>
                  <Dropzone
                    multiple
                    onDrop={(files) => {
                      setDatas((_data) => ({ ..._data, photo: files }));
                    }}
                  />
                </Box>
              </Fragment>
            )}

            {(dataDetail.data.data as TimelineItemDetail).maintenanceTypeId !==
              3 &&
              (dataDetail.data.data as TimelineItemDetail)
                .controlFormPhotoIsRequired && (
                <Fragment>
                  <Heading fontSize="large" mt="8">
                    İş İzin / Bakım Formu{" "}
                    <Text d="inline" color="red.500">
                      *
                    </Text>
                  </Heading>
                  <Divider my="2" />
                  <Box>
                    <Dropzone
                      multiple
                      onDrop={(files) => {
                        setDatas((_data) => ({ ..._data, request: files }));
                      }}
                    />
                  </Box>
                </Fragment>
              )}

            {!!dataQuestions?.data.data &&
              Array.isArray(dataQuestions.data.data) &&
              dataQuestions.data.data!.length > 0 && (
                <Fragment>
                  <Heading fontSize="large" mt="8">
                    Sorular{" "}
                    <Text d="inline" color="red.500">
                      *
                    </Text>
                  </Heading>
                  <Divider my="2" />
                  <Box bgColor={bgColor} p="5">
                    {questions
                      .sort((a, b) => a.orders - b.orders)
                      .map((question, index) => (
                        <Box mt="2">
                          <Text>{question.Question}</Text>
                          {question.QuestionType === 2 && (
                            <Flex mt="1">
                              <Button
                                mr="0.5"
                                rightIcon={
                                  question.SelectedItem === "Yes" ? (
                                    <FaCheck />
                                  ) : undefined
                                }
                                variant={
                                  question.SelectedItem === "Yes"
                                    ? "solid"
                                    : "outline"
                                }
                                colorScheme="green"
                                onClick={() => {
                                  const tmpQuestion = [...questions];
                                  questions[index].SelectedItem = "Yes";
                                  setQuestions(tmpQuestion);
                                }}
                              >
                                Evet
                              </Button>
                              <Button
                                rightIcon={
                                  question.SelectedItem === "No" ? (
                                    <FaCheck />
                                  ) : undefined
                                }
                                variant={
                                  question.SelectedItem === "No"
                                    ? "solid"
                                    : "outline"
                                }
                                colorScheme="red"
                                onClick={() => {
                                  const tmpQuestion = [...questions];
                                  questions[index].SelectedItem = "No";
                                  setQuestions(tmpQuestion);
                                }}
                              >
                                Hayır
                              </Button>
                            </Flex>
                          )}
                          {question.QuestionType === 1 && (
                            <Flex mt="1" flexDir="column">
                              <Text
                                fontSize="sm"
                                mb="0.5"
                              >{`Uygun değer aralığı: ${question.InitialValue} - ${question.EndValue}`}</Text>
                              <Input
                                type="number"
                                onChange={(e) => {
                                  const tmpQuestion = [...questions];
                                  questions[index].SelectedItem =
                                    e.currentTarget.value;
                                  setQuestions(tmpQuestion);
                                }}
                              />
                            </Flex>
                          )}
                          <Divider my="2" />
                        </Box>
                      ))}
                  </Box>
                </Fragment>
              )}

            <Heading fontSize="large" mt="8">
              Bilgiler
            </Heading>
            <Divider my="2" />
            <Box>
              <FormControl mb="2">
                <FormLabel>
                  Bakım Firması{" "}
                  {__mode__ !== api.otk && (
                    <Text d="inline" color="red.500">
                      *
                    </Text>
                  )}
                </FormLabel>
                <Input
                  type="text"
                  value={datas.firm}
                  onChange={(e) =>
                    setDatas((_datas) => ({
                      ..._datas,
                      firm: e.currentTarget.value,
                    }))
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Bakım Notları</FormLabel>
                <Textarea
                  type="text"
                  value={datas.explain}
                  onChange={(e) =>
                    setDatas((_datas) => ({
                      ..._datas,
                      explain: e.currentTarget.value,
                    }))
                  }
                />
              </FormControl>
            </Box>

            <Flex justifyContent="flex-end" mt="10">
              <Button
                colorScheme="teal"
                onClick={handleClickComplete}
                isLoading={loading}
                isDisabled={[
                  questions
                    ?.filter(
                      (question) =>
                        question.maintenanceQuestionTypeID === 2 &&
                        question.isNegativeAnswerBlockMaintenance
                    )
                    .some((question) => {
                      if (question.trueFalseAnswer) {
                        return question.SelectedItem === "No";
                      } else {
                        return question.SelectedItem === "Yes";
                      }
                      return false;
                    })
                    ? false
                    : true,
                  (dataDetail?.data.data as TimelineItemDetail)
                    .isMaintenanceFirmRequired
                    ? !!datas.firm
                    : true,
                  (dataDetail?.data.data as TimelineItemDetail)
                    .controlFormPhotoIsRequired
                    ? Array.isArray(datas.request) && datas.request?.length > 0
                    : true,
                  (dataDetail?.data.data as TimelineItemDetail)
                    .isInventoryPhotoRequired
                    ? Array.isArray(datas.photo) && datas.photo?.length > 0
                    : true,
                  (dataDetail?.data.data as TimelineItemDetail)
                    .userGudiePathIsRequired
                    ? datas.guide
                    : true,
                  (dataDetail?.data.data as TimelineItemDetail)
                    .userInstructionsIsRequired
                    ? datas.instruction
                    : true,
                  (dataDetail?.data.data as TimelineItemDetail)
                    .riskAnalysisIsRequired
                    ? datas.isg
                    : true,
                  (dataDetail?.data.data as TimelineItemDetail)
                    .maintenanceContractIsRequired
                    ? datas.contract
                    : true,
                ].includes(false)}
              >
                Bakımı Tamamla
              </Button>
            </Flex>
          </Box>
        )}
      </Card>
    </Main>
  );
};

export const DocumentField = (props: {
  url: string;
  required: boolean;
  value: boolean;
  onOk: () => void;
  title: string;
}) => {
  if (!props.required) {
    return null;
  }
  return (
    <Button
      colorScheme={props.value ? "green" : "orange"}
      rightIcon={props.value ? <FaCheck /> : undefined}
      size="lg"
      variant={props.value ? "solid" : "outline"}
      leftIcon={<FaHandPointer />}
      onClick={() => {
        props.onOk();
        window.open(props.url, "_new");
      }}
    >
      <Flex flexDir="column">
        <Text fontSize="md">
          {props.title} {props.value ? "Onaylandı" : "Onayla"}
        </Text>
        <Text fontSize="xs">(Onaylamak İçin Buraya Dokunun)</Text>
      </Flex>
    </Button>
  );
};

DoMaintenancePage.defaultProps = {};

export default DoMaintenancePage;
