import { Button, Spinner, VStack, Text } from "@chakra-ui/react";
import { Card, DataTable } from "components/atoms";
import Main from "components/templates/Main";
import { useAuth } from "hooks/redux-hooks";
import moment from "moment";
import React, { useEffect } from "react";
import { FaFile } from "react-icons/fa";
import { Hooks } from "services";

const CounterReport = () => {
  const { project } = useAuth();
  const [get, status, data] = Hooks.CounterTimeline();

  useEffect(() => {
    get(project?.id);
  }, []);

  const counterList = data?.data?.data.filter((x: any) => x.isCompleted);

  return (
    <Main headerProps={{ title: `Sayaç Raporları` }}>
      <Card>
        {status === "pending" && (
          <VStack>
            <Spinner />
            <Text>Raporlar Yükleniyor</Text>
          </VStack>
        )}
        {status === "fulfilled" && (
          <DataTable
            data={counterList ?? []}
            columns={[
              {
                name: "Kod",
                selector: "barcode",
              },
              {
                name: "Rapor",
                cell: (row: any) => (
                  <Button
                    size="xs"
                    colorScheme="blue"
                    onClick={() => {
                      localStorage.removeItem("counter-report");
                      localStorage.setItem(
                        "counter-report",
                        JSON.stringify(row)
                      );
                      window.open("/doc-report-counter", "_blank");
                    }}
                    leftIcon={<FaFile />}
                  >
                    Raporu Görüntüle
                  </Button>
                ),
              },
              {
                name: "Tüketim Bölgesi",
                selector: "location",
              },
              {
                name: "Periyot",
                selector: "periodName",
              },
              {
                name: "Sayaç Türü",
                selector: "counterTypeName",
              },
              {
                name: "Gerçekleştiren Kullanıcı",
                selector: "controlledUserName",
              },
              {
                name: "Gerçekleştirme Tarihi",
                selector: "completedDate",
                specialData: (cell) => {
                  return moment(cell, moment.ISO_8601).format("DD/MM/YYYY HH:mm");
                },
                cell: (row: any) => {
                  return moment(row.completedDate).format("DD/MM/YYYY");
                },
              },
              {
                name: "Sayaç Değeri",
                selector: "value",
              },
            ]}
          />
        )}
      </Card>
    </Main>
  );
};

CounterReport.defaultProps = {};

export default CounterReport;
