import {
  Button, Flex, SimpleGrid, Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs, Text
} from "@chakra-ui/react";
import { Card } from "components/atoms";
import {
  InputElement,
  SelectElement
} from "components/atoms/FormElements";
import { ModalProps } from "components/atoms/Modal";
import Main from "components/templates/Main";
import { useAuth } from "hooks/redux-hooks";
import { useToast } from "hooks/toast";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Hooks } from "services";

function InventoryCreate() {
  const { project } = useAuth();
  const { control, handleSubmit, watch, setValue, register, formState } =
    useForm();
  const [addControl, statusControl, dataControl] = Hooks.CreateControlTask();
  const [getTypes, , userTypes] = Hooks.UserTypeList();
  const toast = useToast();
  const history = useHistory();

  const [getCapacities, statCapacities, dataCapacities] = Hooks.CapacityList();

  const [getCampuses, statCampuses, dataCampuses] = Hooks.CampusList();
  const [getBuilds, statBuilds, dataBuilds] = Hooks.BuildList();
  const [getFloors, statFloors, dataFloors] = Hooks.FloorList();
  const [getRooms, statRooms, dataRooms] = Hooks.RoomList();
  const [getGroups, statGroups, dataGroups] = Hooks.GroupList();
  const [getBrands, statBrands, dataBrands] = Hooks.BrandList();
  const [getModels, statModels, dataModels] = Hooks.ModelList();

  const [onAddGroup, statusAddGroup] = Hooks.AddGroup();
  const [onAddBrand, statusAddBrand] = Hooks.AddBrand();
  const [onAddModel, statusAddModel] = Hooks.AddModel();

  const groupModal = useRef<ModalProps>(null);
  const brandModal = useRef<ModalProps>(null);
  const modelModal = useRef<ModalProps>(null);

  useLayoutEffect(() => {
    if (!!!dataCampuses) {
      getCampuses(project.id);
    }

    getTypes();
  }, []);

  useEffect(() => {
    if (!isNaN(parseInt(watch("CampusID")))) {
      getBuilds(parseInt(watch("CampusID")));
      setValue("BuildID", undefined);
    }
  }, [watch("CampusID")]);

  useEffect(() => {
    if (!isNaN(parseInt(watch("BuildID")))) {
      getFloors(parseInt(watch("BuildID")));
      setValue("FloorID", undefined);
    }
  }, [watch("BuildID")]);

  useEffect(() => {
    if (!isNaN(parseInt(watch("FloorID")))) {
      getRooms(parseInt(watch("FloorID")));
      setValue("RoomID", undefined);
    }
  }, [watch("FloorID")]);

  function onValid(values: any) {
    function checkField(value: string) {
      return !!value ? value : undefined;
    }
    addControl({
      name: values.Name,
      campusID: parseInt(values.CampusID),
      buildID: parseInt(values.BuildID),
      floorID: parseInt(values.FloorID),
      roomID: parseInt(values.RoomID),
      userTypeID: parseInt(values.UserTypeID),
      explain: values.Explain,
      projectID: project.id,
    });
  }

  function onInvalid() {
    toast({ status: "error", title: "Zorunlu alanlar doldurulmalı!" });
  }

  useEffect(() => {
    if (!!dataControl) {
      if (dataControl.data.success) {
        toast({ status: "success", title: "Denetim başarıyla eklendi!" });
        history.push(`/control`);
      } else {
        toast({
          status: "error",
          title: "Denetim Eklenemedi",
          description: dataControl.data.message,
        });
      }
    }
  }, [dataControl]);

  return (
    <Main headerProps={{ title: "Denetim Oluştur" }}>
      {formState.isSubmitted && !formState.isSubmitSuccessful && (
        <Card mb="3">
          <SimpleGrid columns={[2, 3]}>
            {Object.entries(formState.errors).map((item) => {
              return <Text color="red.500">* {item?.[1]?.message}</Text>;
            })}
          </SimpleGrid>
        </Card>
      )}
      <Card>
        <form onSubmit={handleSubmit(onValid, onInvalid)}>
          <Tabs isFitted>
            <TabList>
              <Tab>Lokasyon Bilgileri</Tab>
              <Tab>Teknik Bilgiler Ve Kullanıcı Tipi</Tab>
              <Tab>Genel Bilgiler</Tab>
            </TabList>
            <TabPanels>
              <TabPanel pt="10">
                <SimpleGrid columns={[1, 2]} gap="5">
                  <SelectElement
                    name="CampusID"
                    label="Tesis"
                    control={control}
                    isRequired
                    data={dataCampuses?.data.data.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                  <SelectElement
                    name="BuildID"
                    label="Bina"
                    control={control}
                    isRequired
                    data={dataBuilds?.data.data.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                  <SelectElement
                    name="FloorID"
                    label="Kat"
                    isRequired
                    control={control}
                    data={dataFloors?.data.data.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                  <SelectElement
                    name="RoomID"
                    label="Oda"
                    isRequired
                    control={control}
                    data={dataRooms?.data.data.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                </SimpleGrid>
              </TabPanel>
              <TabPanel pt="10">
                <SimpleGrid columns={[1, 2]} gap="5">
                  <SelectElement
                    name="UserTypeID"
                    label="Kullanıcı Tipi"
                    isRequired
                    control={control}
                    data={userTypes?.data.data.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                </SimpleGrid>
              </TabPanel>
              <TabPanel pt="10">
                <SimpleGrid columns={[1, 2]} gap="5">
                  <InputElement
                    name="Name"
                    label="Denetim Adı"
                    isRequired
                    control={control}
                  />
                  <InputElement
                    name="Explain"
                    label="Açıklama"
                    control={control}
                  />
                </SimpleGrid>
                <Flex justifyContent="flex-end" mt="5">
                  <Button colorScheme="teal" type="submit">
                    Oluştur
                  </Button>
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </form>
      </Card>
    </Main>
  );
}

export default InventoryCreate;
