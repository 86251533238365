import {
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  UseDisclosureReturn,
  VStack,
} from "@chakra-ui/react";
import ColorModeButton from "components/atoms/ColorModeButton";
import { UserMenu } from "components/molecules";
import { FeatureEnum, getFeatures } from "consts/features";
import { useAuth } from "hooks/redux-hooks";
import { useResponsive } from "hooks/responsive";
import { UserRoles } from "models/user";
import { ReactNodeArray } from "react";
import { FaArrowLeft } from "react-icons/fa";
import {
  IoChevronDown,
  IoChevronForward,
  IoMenu,
  IoSearch,
} from "react-icons/io5";
import { useHistory, useLocation } from "react-router-dom";
import Notifications from "./Notifications";

export interface HeaderProps {
  title: string;
  breadcrumbs?: Array<{ link: string; title: string }>;
}

function Header({
  title,
  breadcrumbs,
  drawerDisc,
  searchDisc,
}: HeaderProps & {
  drawerDisc: UseDisclosureReturn;
  searchDisc: UseDisclosureReturn;
}) {
  const { isBase, isSm } = useResponsive();
  const { role } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const renderFeatures = () => {
    const features = getFeatures();
    const featuresRows: any[] = [];

    features.forEach((feat) => {
      switch (feat) {
        case FeatureEnum.maintenance:
          featuresRows.push(
            <MenuItem
            key={feat}
              onClick={() => {
                history.push("/inventory/create");
              }}
            >
              Ekipman Tanımla
            </MenuItem>
          );
          break;
        case FeatureEnum.demand:
          featuresRows.push(
            <MenuItem
            key={feat}
              onClick={() => {
                history.push("/demand/create");
              }}
            >
              Talep Tanımla
            </MenuItem>
          );
          break;
        case FeatureEnum.document:
          featuresRows.push(
            <MenuItem
            key={feat}
              onClick={() => {
                history.push("/control-create");
              }}
            >
              Denetim Tanımla
            </MenuItem>
          );
          break;
        case FeatureEnum.follow:
          featuresRows.push(
            <MenuItem
            key={feat}
              onClick={() => {
                history.push("/document-create");
              }}
            >
              Doküman Tanımla
            </MenuItem>
          );
          break;
        default:
          break;
      }
    });

    return featuresRows;
  };

  return (
    <Flex
      bgColor={useColorModeValue("gray.50", "gray.900")}
      h="65px"
      px="5"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex flex="1">
        {(isBase || isSm) && (
          <IconButton
            w="40px"
            h="40px"
            aria-label="menu"
            icon={<Icon as={IoMenu} />}
            bgColor="gray.200"
            borderRadius="full"
            onClick={drawerDisc.onToggle}
          />
        )}
        {location.pathname !== "/" && !isBase && !isSm && (
          <Button
            leftIcon={<FaArrowLeft />}
            onClick={() => {
              history.goBack();
            }}
            colorScheme="gray"
            mr="2"
          >
            Geri
          </Button>
        )}
        {role !== UserRoles.basic &&
          role !== UserRoles.view &&
          !(isSm || isBase) && (
            <Menu>
              <MenuButton as={Button} rightIcon={<IoChevronDown />}>
                Tanımla
              </MenuButton>
              <MenuList>{renderFeatures()}</MenuList>
            </Menu>
          )}
      </Flex>
      <Center flex="3">
        <VStack>
          <Heading m="0" p="0" fontSize={["12px", "14px", "24px"]}>
            {title}
          </Heading>
          {!isBase && !!breadcrumbs && (
            <Flex
              justifyContent="center"
              alignItems="center"
              mt="0 !important"
            >
              <Link fontSize="sm">Dashboard</Link>
              <Icon fontSize="sm" as={IoChevronForward} />
              <Link fontSize="sm">Dashboard Dashboard</Link>
              <Icon fontSize="sm" as={IoChevronForward} />
              <Text fontSize="sm">{title}</Text>
            </Flex>
          )}
        </VStack>
      </Center>
      <Flex flex="1" justifyContent="flex-end">
        <IconButton
          onClick={searchDisc.onToggle}
          aria-label="Search"
          rounded="full"
          mr="2"
          icon={<IoSearch />}
        />
        <ColorModeButton />
        <Notifications />
        <UserMenu />
      </Flex>
    </Flex>
  );
}

export default Header;
