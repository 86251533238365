import React, { FC, useEffect } from "react";
import { Box, Button, Heading, HStack, Text, Textarea } from "@chakra-ui/react";
import { Card } from "components/atoms";
import { Controller, useForm } from "react-hook-form";
import MdField from "components/molecules/Form/MdField";
import moment from "moment";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import { useToast } from "hooks/toast";

type DailyNoteFormProps = {
  onCreate: () => void;
};

const DailyNoteForm: FC<DailyNoteFormProps> = (props) => {
  const { project, user } = useAuth();
  const [postNote, statusNote] = Hooks.NoteCreate();
  const _form = useForm();
  const dateMoment = moment();
  const toast = useToast();

  const onValid = (values: any) => {
    postNote({
      body: values.body,
      date: dateMoment.format("YYYY-MM-DD"),
      isActive: true,
      projectID: project.id,
      title: "",
      userID: user.id,
    });
  };

  useEffect(() => {
    if (statusNote === "fulfilled") {
      toast({ title: "Not Eklendi", status: "success" });
      _form.reset();
      _form.setValue("body", "");
      props.onCreate();
    } else if (statusNote === "rejected") {
      toast({ title: "Not Eklenemedi", status: "error" });
    }
  }, [statusNote]);

  return (
    <form onSubmit={_form.handleSubmit(onValid)}>
      <Card>
        <Heading mb="3" size="md">
          Not Ekle
        </Heading>
        <Controller
          control={_form.control}
          rules={{ required: true }}
          name="body"
          render={({ field }) => (
            <Textarea {...field} placeholder="Not Giriniz..." bgColor="white" />
          )}
        />
        <HStack justifyContent="flex-end" mt="4">
          <Button type="submit">Ekle</Button>
        </HStack>
      </Card>
    </form>
  );
};

export default DailyNoteForm;
