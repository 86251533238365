import { api, __mode__ } from "consts";

export enum FeatureEnum {
  maintenance,
  legal,
  demand,
  follow,
  document,
  counter,
}

export function getFeatures() {
  switch (__mode__) {
    case api.siemens:
      return [
        FeatureEnum.document,
        FeatureEnum.demand,
        FeatureEnum.follow,
        FeatureEnum.maintenance,
        FeatureEnum.legal,
        FeatureEnum.counter,
      ];
    case api.otk:
      return [
        FeatureEnum.maintenance,
        FeatureEnum.legal,
      ];
    case api.apl:
      return [
        FeatureEnum.maintenance,
        FeatureEnum.legal,
        FeatureEnum.demand,
        FeatureEnum.follow,
        FeatureEnum.counter,
      ];
    case api.test:
      return [
        FeatureEnum.maintenance,
        FeatureEnum.legal,
        FeatureEnum.demand,
        FeatureEnum.follow,
        FeatureEnum.counter,
      ];
    default:
      throw new Error("Have not declare app mode");
  }
}
