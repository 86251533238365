import React, {
  FC,
  ReactNodeArray,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import {
  Flex,
  IconButton,
  Button,
  Select,
  Box,
  Badge,
  useColorModeValue,
  HStack,
} from "@chakra-ui/react";
import TimelineMaintenance from "./TimelineWidget";
import TimelineDemand from "./TimelineDemand";
import TimelineLegal, { TimelineStatus as LegalStatus } from "./TimelineLegal";
import TimelineDocument, {
  TimelineStatus as DocStatus,
} from "./TimelineDocument";
import { Tooltip } from "@chakra-ui/react";
import { BsCalendar, BsCardChecklist } from "react-icons/bs";
import { BiCalendarCheck } from "react-icons/bi";
import {
  IoCalendar,
  IoCheckbox,
  IoContract,
  IoCheckboxOutline,
  IoList,
  IoTime,
} from "react-icons/io5";
import TimelineControl, {
  TimelineStatus as ControlStatus,
} from "./TimelineControl";
import { api, __mode__ } from "consts";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import { isSieOrNo } from "helper/project-mode";
import TimelineCounter from "./TimelineCounter";
import { useHistory } from "react-router-dom";
import { FeatureEnum, getFeatures } from "consts/features";

interface TimelineWrapperProps {}

enum TimelineTypes {
  maintenance = "Bakımlar",
  document = "Talep İşlemleri",
  legal = "Periyodik Kontroller",
  documents = "Belge Takip İşlemleri",
  control = "Denetim İşlemleri",
  counter = "Sayaç İşlemleri",
}

const TimelineWrapper: FC<TimelineWrapperProps> = (props) => {
  const { project, user } = useAuth();
  const [hidden, setHidden] = useState<boolean>(false);
  const [hiddenFuture, setHiddenFuture] = useState<boolean>(false);
  const [selectedCampus, setSelectedCampus] = useState<number>(0);
  const [getTime] = Hooks.ControlTaskTimeline();
  const [getTimelineDoc] = Hooks.TimelineDocument();
  const [getTimelineDemand] = Hooks.DemandTimeline();
  const [getTimelineLegal] = Hooks.TimelineLegal();
  const badgeBg = useColorModeValue("blue", "red");
  const badgeColor = useColorModeValue("blue.700", "red.300");
  const history = useHistory();

  const [counts, setCounts] = useState({
    maintenance: 0,
    legal: 0,
    demand: 0,
    document: 0,
    control: 0,
    counter: 0,
  });
  const [timelineType, setTimelineType] = useState<TimelineTypes>(
    __mode__ === api.siemens ? TimelineTypes.control : TimelineTypes.maintenance
  );
  const [getCampuses, statCampuses, dataCampuses] = Hooks.CampusList();

  useLayoutEffect(() => {
    let params = new URLSearchParams(history.location.search);
    const initialParam = params.get("initial");
    if (initialParam === "counter") {
      setTimelineType(TimelineTypes.counter);
    }
    getCampuses(project.id);
    const _hidden = localStorage.getItem("filter-hidden");
    const _hiddenFuture = localStorage.getItem("filter-hidden-future");
    const _campus = localStorage.getItem("filter-campus");
    if (!!_hidden) {
      setHidden(_hidden === "on");
    }
    if (!!_hiddenFuture) {
      setHiddenFuture(_hiddenFuture === "on");
    }
    if (!!_campus) {
      setSelectedCampus(parseInt(_campus));
    }
  }, []);

  async function getControlTaskCount() {
    const timeData = await getTime(project.id);
    if (timeData) {
      setCounts((prev) => ({
        ...prev,
        control: timeData.data.data!.filter((item) => {
          let filterArr = [];
          if (hidden) {
            if (
              [ControlStatus.done, ControlStatus.lateDone].includes(
                item.statusCode!
              )
            ) {
              filterArr.push(false);
            }
          }

          if (hiddenFuture) {
            if ([ControlStatus.wait].includes(item.statusCode!)) {
              filterArr.push(false);
            }
          }

          filterArr.push(item.userID === user.id);

          if (selectedCampus !== 0) {
            filterArr.push(item.campusID === selectedCampus);
          }

          return !filterArr.includes(false);
        }).length,
      }));
    }
  }

  async function getDocumentCount() {
    const data = await getTimelineDoc(project.id);
    if (data) {
      setCounts((prev) => ({
        ...prev,
        document: data.data.data!.filter((item) => {
          let filterArr = [];
          if (hidden) {
            if (
              [DocStatus.completed, DocStatus.lateDone].includes(item.status!)
            ) {
              filterArr.push(false);
            }
          }

          if (hiddenFuture) {
            if ([DocStatus.notTime].includes(item.status!)) {
              filterArr.push(false);
            }
          }

          filterArr.push(item.assignUserID === user.id);

          if (selectedCampus !== 0) {
            filterArr.push(item.campusID === selectedCampus);
          }

          return !filterArr.includes(false);
        }).length,
      }));
    }
  }

  async function getDemandCount() {
    const data = await getTimelineDemand(project.id);
    if (data) {
      setCounts((prev) => ({
        ...prev,
        demand: (data as any).data.data.filter((item: any) => {
          let filterArr = [];
          const isRequiredDoc = item.documents
            .map((_item: any) => _item.isCompleted)
            .includes(false);

          if (hidden) {
            filterArr.push(!!isRequiredDoc);
          }

          const indexOf = item.documents.findIndex(
            (doc: any) => doc.assignUserID === user.id
          );
          filterArr.push(indexOf !== -1);
          // filterArr.push(item.assignUserID === user.id);

          if (selectedCampus !== 0) {
            filterArr.push(item.campusID === selectedCampus);
          }

          return !filterArr.includes(false);
        }).length,
      }));
    }
  }

  async function getLegalCount() {
    const data = await getTimelineLegal(project.id);
    if (data) {
      setCounts((prev) => ({
        ...prev,
        legal: (data as any).data.data.filter((item: any) => {
          let filterArr = [];
          if (hidden) {
            if (
              [LegalStatus.completed, LegalStatus.lateDone].includes(
                item.status
              )
            ) {
              filterArr.push(false);
            }
          }
          if (hiddenFuture) {
            if ([LegalStatus.notTime].includes(item.status)) {
              filterArr.push(false);
            }
          }

          if (selectedCampus !== 0) {
            filterArr.push(selectedCampus === (item as any).campusID);
          }

          return !filterArr.includes(false);
        }).length,
      }));
    }
  }

  useEffect(() => {
    getControlTaskCount();
    getDocumentCount();
    getDemandCount();
    getLegalCount();
  }, [selectedCampus]);

  const renderFeatures = () => {
    const features = getFeatures();

    const featuresRows: any[] = [];

    features.forEach((feat) => {
      switch (feat) {
        case FeatureEnum.maintenance:
          featuresRows.push(
            <Tooltip label="Bakımlar" aria-label="A tooltip" key={feat}>
              <Button
                size="sm"
                colorScheme={
                  timelineType === TimelineTypes.maintenance ? "blue" : "gray"
                }
                aria-label="Bakımlar"
                leftIcon={<IoCalendar />}
                onClick={() => {
                  setTimelineType(TimelineTypes.maintenance);
                }}
              >
                Bakım{" "}
                <Badge
                  colorScheme={badgeBg}
                  color={badgeColor}
                  ml="3"
                  rounded="full"
                >
                  {counts.maintenance}
                </Badge>
              </Button>
            </Tooltip>
          );
          break;
        case FeatureEnum.legal:
          featuresRows.push(
            <Tooltip
              key={feat}
              label={`Periyodik ${isSieOrNo("Kontroller", "Muayeneler")}`}
              aria-label="A tooltip"
            >
              <Button
                size="sm"
                aria-label={`Periyodik ${isSieOrNo(
                  "Kontroller",
                  "Muayeneler"
                )}`}
                colorScheme={
                  timelineType === TimelineTypes.legal ? "blue" : "gray"
                }
                leftIcon={<BsCalendar />}
                onClick={() => {
                  setTimelineType(TimelineTypes.legal);
                }}
              >
                Periyodik {isSieOrNo("Kontrol", "Muayene")}{" "}
                <Badge
                  colorScheme={badgeBg}
                  color={badgeColor}
                  ml="3"
                  rounded="full"
                >
                  {counts.legal}
                </Badge>
              </Button>
            </Tooltip>
          );
          break;
        case FeatureEnum.demand:
          featuresRows.push(
            <Tooltip key={feat} label="Talep İşlemleri" aria-label="A tooltip">
              <Button
                size="sm"
                colorScheme={
                  timelineType === TimelineTypes.document ? "blue" : "gray"
                }
                aria-label="Talep İşlemleri"
                leftIcon={<BiCalendarCheck />}
                onClick={() => {
                  setTimelineType(TimelineTypes.document);
                }}
              >
                Talep
                <Badge
                  colorScheme={badgeBg}
                  color={badgeColor}
                  ml="3"
                  rounded="full"
                >
                  {counts.demand}
                </Badge>
              </Button>
            </Tooltip>
          );
          break;
        case FeatureEnum.follow:
          featuresRows.push(
            <Tooltip
              key={feat}
              label="Belge Takip İşlemleri"
              aria-label="A tooltip"
            >
              <Button
                size="sm"
                colorScheme={
                  timelineType === TimelineTypes.documents ? "blue" : "gray"
                }
                aria-label="Belge Takip İşlemleri"
                leftIcon={<BsCardChecklist />}
                onClick={() => {
                  setTimelineType(TimelineTypes.documents);
                }}
              >
                Belge Takip{" "}
                <Badge
                  colorScheme={badgeBg}
                  color={badgeColor}
                  ml="3"
                  rounded="full"
                >
                  {counts.document}
                </Badge>
              </Button>
            </Tooltip>
          );
          break;
        case FeatureEnum.document:
          featuresRows.push(
            <Tooltip
              key={feat}
              label="Denetim İşlemleri"
              aria-label="A tooltip"
            >
              <Button
                size="sm"
                colorScheme={
                  timelineType === TimelineTypes.control ? "blue" : "gray"
                }
                aria-label="Denetim İşlemleri"
                leftIcon={<IoList />}
                onClick={() => {
                  setTimelineType(TimelineTypes.control);
                }}
              >
                Denetim{" "}
                <Badge
                  colorScheme={badgeBg}
                  color={badgeColor}
                  ml="3"
                  rounded="full"
                >
                  {counts.control}
                </Badge>
              </Button>
            </Tooltip>
          );
          break;
        case FeatureEnum.counter:
          featuresRows.push(
            <Tooltip
              key={feat}
              label="Denetim İşlemleri"
              aria-label="A tooltip"
            >
              <Button
                size="sm"
                colorScheme={
                  timelineType === TimelineTypes.counter ? "blue" : "gray"
                }
                aria-label="Denetim İşlemleri"
                leftIcon={<IoTime />}
                onClick={() => {
                  setTimelineType(TimelineTypes.counter);
                }}
              >
                Sayaç{" "}
              </Button>
            </Tooltip>
          );
          break;
        default:
          break;
      }
    });

    return <HStack spacing="2">{featuresRows}</HStack>;
  };

  const renderChangeDrodown = () => (
    <Flex flexDir="column">
      <Flex py="3" spaceX="2">
        <Box>
          <Tooltip label="Tamamlananlar" aria-label="completed">
            <IconButton
              aria-label="completed"
              icon={<IoCheckboxOutline />}
              size="sm"
              colorScheme="blue"
              variant={hidden ? "outline" : "solid"}
              onClick={() => {
                setHidden((prev) => {
                  localStorage.setItem("filter-hidden", !prev ? "on" : "off");
                  return !prev;
                });
              }}
            />
          </Tooltip>
        </Box>
        <Box>
          <Tooltip label="Beklenenler" aria-label="future">
            <IconButton
              aria-label="future"
              icon={<IoTime />}
              size="sm"
              colorScheme="green"
              variant={hiddenFuture ? "outline" : "solid"}
              onClick={() => {
                setHiddenFuture((prev) => {
                  localStorage.setItem(
                    "filter-hidden-future",
                    !prev ? "on" : "off"
                  );
                  return !prev;
                });
              }}
            />
          </Tooltip>
        </Box>
        <Select
          size="sm"
          value={selectedCampus.toString()}
          onChange={(e) => {
            localStorage.setItem("filter-campus", e.target.value);
            setSelectedCampus(parseInt(e.target.value));
          }}
        >
          <option value="0">Tüm Tesisler</option>
          {dataCampuses?.data.data.map((campus) => (
            <option value={campus.id} key={campus.id}>
              {campus.name}
            </option>
          ))}
        </Select>
      </Flex>
      {renderFeatures()}
    </Flex>
  );

  if (timelineType === TimelineTypes.maintenance) {
    return (
      <TimelineMaintenance
        renderChangeDropdown={renderChangeDrodown}
        hidden={hidden}
        hiddenFuture={hiddenFuture}
        selectedCampus={selectedCampus}
        onChangeCount={(count) =>
          setCounts((prev) => ({ ...prev, maintenance: count }))
        }
      />
    );
  } else if (timelineType === TimelineTypes.document) {
    return (
      <TimelineDemand
        renderChangeDropdown={renderChangeDrodown}
        hidden={hidden}
        hiddenFuture={hiddenFuture}
        onChangeCount={(count) =>
          setCounts((prev) => ({ ...prev, demand: count }))
        }
        selectedCampus={selectedCampus}
      />
    );
  } else if (timelineType === TimelineTypes.legal) {
    return (
      <TimelineLegal
        renderChangeDropdown={renderChangeDrodown}
        hidden={hidden}
        hiddenFuture={hiddenFuture}
        selectedCampus={selectedCampus}
        onChangeCount={(count) =>
          setCounts((prev) => ({ ...prev, legal: count }))
        }
      />
    );
  } else if (timelineType === TimelineTypes.documents) {
    return (
      <TimelineDocument
        renderChangeDropdown={renderChangeDrodown}
        hidden={hidden}
        hiddenFuture={hiddenFuture}
        onChangeCount={(count) =>
          setCounts((prev) => ({ ...prev, document: count }))
        }
        selectedCampus={selectedCampus}
      />
    );
  } else if (timelineType === TimelineTypes.control) {
    return (
      <TimelineControl
        renderChangeDropdown={renderChangeDrodown}
        hidden={hidden}
        onChangeCount={(count) =>
          setCounts((prev) => ({ ...prev, control: count }))
        }
        hiddenFuture={hiddenFuture}
        selectedCampus={selectedCampus}
      />
    );
  } else if (timelineType === TimelineTypes.counter) {
    return (
      <TimelineCounter
        renderChangeDropdown={renderChangeDrodown}
        hidden={hidden}
        onChangeCount={(count) =>
          setCounts((prev) => ({ ...prev, control: count }))
        }
        hiddenFuture={hiddenFuture}
        // hidden={hidden}
        // onChangeCount={(count) =>
        //   setCounts((prev) => ({ ...prev, control: count }))
        // }
        // selectedCampus={selectedCampus}
      />
    );
  } else {
    return null;
  }
};

TimelineWrapper.defaultProps = {};

export default TimelineWrapper;
