/* tslint:disable */
/* eslint-disable */
/**
 * ETS WEB API
 * Here is all endpoints
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddDemandDTO
 */
export interface AddDemandDTO {
    /**
     * 
     * @type {number}
     * @memberof AddDemandDTO
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddDemandDTO
     */
    demandGroupID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddDemandDTO
     */
    userTypeID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddDemandDTO
     */
    createUserId?: number;
    /**
     * 
     * @type {number}
     * @memberof AddDemandDTO
     */
    campusID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddDemandDTO
     */
    buildID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddDemandDTO
     */
    floorID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddDemandDTO
     */
    roomID?: number;
    /**
     * 
     * @type {string}
     * @memberof AddDemandDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemandDTO
     */
    explain?: string | null;
}
/**
 * 
 * @export
 * @interface AddDemandDocumentDTO
 */
export interface AddDemandDocumentDTO {
    /**
     * 
     * @type {number}
     * @memberof AddDemandDocumentDTO
     */
    demandID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddDemandDocumentDTO
     */
    assignUserID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemandDocumentDTO
     */
    documentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddDemandDocumentDTO
     */
    lastDate?: string;
}
/**
 * 
 * @export
 * @interface AddInventoryMaintenanceDTO
 */
export interface AddInventoryMaintenanceDTO {
    /**
     * 
     * @type {number}
     * @memberof AddInventoryMaintenanceDTO
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddInventoryMaintenanceDTO
     */
    inventoryMaintenanceID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddInventoryMaintenanceDTO
     */
    inventoryID?: number;
    /**
     * 
     * @type {string}
     * @memberof AddInventoryMaintenanceDTO
     */
    personelName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddInventoryMaintenanceDTO
     */
    maintenanceFirm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddInventoryMaintenanceDTO
     */
    maintenanceExplain?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddInventoryMaintenanceDTO
     */
    createUserID?: number;
    /**
     * 
     * @type {string}
     * @memberof AddInventoryMaintenanceDTO
     */
    maintenanceQuestion?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddInventoryMaintenanceDTO
     */
    isMaintenanceIncomplete?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddInventoryMaintenanceDTO
     */
    isAfterCompleted?: boolean;
    /**
     * 
     * @type {Array<AddInventoryMaintenancePhotoDTO>}
     * @memberof AddInventoryMaintenanceDTO
     */
    images?: Array<AddInventoryMaintenancePhotoDTO> | null;
    /**
     * 
     * @type {string}
     * @memberof AddInventoryMaintenanceDTO
     */
    inventoryName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddInventoryMaintenanceDTO
     */
    inventoryBarcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddInventoryMaintenanceDTO
     */
    projectName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddInventoryMaintenanceDTO
     */
    maintenanceTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddInventoryMaintenanceDTO
     */
    periodName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddInventoryMaintenanceDTO
     */
    maintenanceStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AddInventoryMaintenanceDTO
     */
    maintenanceEndDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddInventoryMaintenanceDTO
     */
    state?: boolean;
}
/**
 * 
 * @export
 * @interface AddInventoryMaintenancePhotoDTO
 */
export interface AddInventoryMaintenancePhotoDTO {
    /**
     * 
     * @type {string}
     * @memberof AddInventoryMaintenancePhotoDTO
     */
    extension?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddInventoryMaintenancePhotoDTO
     */
    photo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddInventoryMaintenancePhotoDTO
     */
    type?: string | null;
}
/**
 * 
 * @export
 * @interface AddMobilLogDTO
 */
export interface AddMobilLogDTO {
    /**
     * 
     * @type {string}
     * @memberof AddMobilLogDTO
     */
    notificationToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddMobilLogDTO
     */
    platform?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddMobilLogDTO
     */
    userID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddMobilLogDTO
     */
    projectID?: number;
    /**
     * 
     * @type {string}
     * @memberof AddMobilLogDTO
     */
    phoneBrand?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddMobilLogDTO
     */
    phoneModel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddMobilLogDTO
     */
    appVersionName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddMobilLogDTO
     */
    appVersionCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddMobilLogDTO
     */
    appVersionCodeInt?: number;
    /**
     * 
     * @type {string}
     * @memberof AddMobilLogDTO
     */
    androidVersionCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddMobilLogDTO
     */
    androidVersionName?: string | null;
}
/**
 * 
 * @export
 * @interface AddUserDTO
 */
export interface AddUserDTO {
    /**
     * 
     * @type {number}
     * @memberof AddUserDTO
     */
    userRoleID?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof AddUserDTO
     */
    userTypes?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof AddUserDTO
     */
    companyID?: number;
    /**
     * 
     * @type {number}
     * @memberof AddUserDTO
     */
    projectID?: number;
    /**
     * 
     * @type {string}
     * @memberof AddUserDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddUserDTO
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddUserDTO
     */
    username?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddUserDTO
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddUserDTO
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddUserDTO
     */
    passwordHash?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddUserDTO
     */
    isNotificationEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddUserDTO
     */
    isEmailEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface Brand
 */
export interface Brand {
    /**
     * 
     * @type {number}
     * @memberof Brand
     */
    groupID?: number;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Brand
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Brand
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface Build
 */
export interface Build {
    /**
     * 
     * @type {number}
     * @memberof Build
     */
    campusID?: number;
    /**
     * 
     * @type {string}
     * @memberof Build
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Build
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Build
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Build
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface Campus
 */
export interface Campus {
    /**
     * 
     * @type {number}
     * @memberof Campus
     */
    projectID?: number;
    /**
     * 
     * @type {string}
     * @memberof Campus
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Campus
     */
    address?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Campus
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Campus
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Campus
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface CancelControlMaintenanceDTO
 */
export interface CancelControlMaintenanceDTO {
    /**
     * 
     * @type {number}
     * @memberof CancelControlMaintenanceDTO
     */
    controlMaintenanceId?: number;
}
/**
 * 
 * @export
 * @interface CancelCounterMaintenanceDTO
 */
export interface CancelCounterMaintenanceDTO {
    /**
     * 
     * @type {number}
     * @memberof CancelCounterMaintenanceDTO
     */
    counterTaskId?: number;
}
/**
 * 
 * @export
 * @interface CancelDocumentMaintenanceDTO
 */
export interface CancelDocumentMaintenanceDTO {
    /**
     * 
     * @type {number}
     * @memberof CancelDocumentMaintenanceDTO
     */
    documentMaintenanceId?: number;
}
/**
 * 
 * @export
 * @interface CancelMaintenanceProcessDTO
 */
export interface CancelMaintenanceProcessDTO {
    /**
     * 
     * @type {number}
     * @memberof CancelMaintenanceProcessDTO
     */
    inventoryID?: number;
    /**
     * 
     * @type {number}
     * @memberof CancelMaintenanceProcessDTO
     */
    inventoryMaintenanceID?: number;
}
/**
 * 
 * @export
 * @interface CompletedMaintenanceDTO
 */
export interface CompletedMaintenanceDTO {
    /**
     * 
     * @type {number}
     * @memberof CompletedMaintenanceDTO
     */
    userTypeId?: number;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    userTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    barcode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompletedMaintenanceDTO
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    unit?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompletedMaintenanceDTO
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    maintenanceDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    maintenanceCreateDate?: string;
    /**
     * 
     * @type {number}
     * @memberof CompletedMaintenanceDTO
     */
    inventoryMaintenanceDetailID?: number;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    maintenancePeriodName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    maintenanceFirm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    maintenanceTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    maintenanceExplain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    serialNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    productionYear?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    inventoryPhoto?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    campusName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    buildName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    floorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    roomName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    groupName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    brandName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    modelName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    inventoryCapacityName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    assetNo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    maintenanceQuestion?: string | null;
    /**
     * 
     * @type {Array<InventoryMaintenanceDetailPhotos>}
     * @memberof CompletedMaintenanceDTO
     */
    photos?: Array<InventoryMaintenanceDetailPhotos> | null;
    /**
     * 
     * @type {number}
     * @memberof CompletedMaintenanceDTO
     */
    maintenanceTypeID?: number;
    /**
     * 
     * @type {number}
     * @memberof CompletedMaintenanceDTO
     */
    maintenancePeriodID?: number;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    personelName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    riskAnalysisExpriDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    riskAnalysisPath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    userInstructions?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    userGudiePath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    maintenanceContract?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompletedMaintenanceDTO
     */
    riskAnalysisIsRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompletedMaintenanceDTO
     */
    userInstructionsIsRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompletedMaintenanceDTO
     */
    userGudiePathIsRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompletedMaintenanceDTO
     */
    maintenanceContractIsRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompletedMaintenanceDTO
     */
    inventoryPhotoIdRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompletedMaintenanceDTO
     */
    controlFormPhotoIdRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompletedMaintenanceDTO
     */
    isAfterCompleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompletedMaintenanceDTO
     */
    isMaintenanceComplete?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CompletedMaintenanceDTO
     */
    maintenanceStatus?: number;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    maintenanceStatusDesc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTO
     */
    maintenanceStatusColor?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompletedMaintenanceDTO
     */
    isPositiveAnswer?: boolean;
}
/**
 * 
 * @export
 * @interface CompletedMaintenanceDTOListDataResult
 */
export interface CompletedMaintenanceDTOListDataResult {
    /**
     * 
     * @type {Array<CompletedMaintenanceDTO>}
     * @memberof CompletedMaintenanceDTOListDataResult
     */
    data?: Array<CompletedMaintenanceDTO> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompletedMaintenanceDTOListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompletedMaintenanceDTOListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface Control
 */
export interface Control {
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    campusID?: number;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    buildID?: number;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    floorID?: number;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    roomID?: number;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    userTypeID?: number;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    explain?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Control
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface ControlAndTypeListDTO
 */
export interface ControlAndTypeListDTO {
    /**
     * 
     * @type {number}
     * @memberof ControlAndTypeListDTO
     */
    controlID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlAndTypeListDTO
     */
    controlName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlAndTypeListDTO
     */
    controlDescription?: string | null;
    /**
     * 
     * @type {Array<ControlTaskDTO>}
     * @memberof ControlAndTypeListDTO
     */
    controlTaskList?: Array<ControlTaskDTO> | null;
}
/**
 * 
 * @export
 * @interface ControlAndTypeListDTOListDataResult
 */
export interface ControlAndTypeListDTOListDataResult {
    /**
     * 
     * @type {Array<ControlAndTypeListDTO>}
     * @memberof ControlAndTypeListDTOListDataResult
     */
    data?: Array<ControlAndTypeListDTO> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlAndTypeListDTOListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlAndTypeListDTOListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface ControlDTO
 */
export interface ControlDTO {
    /**
     * 
     * @type {number}
     * @memberof ControlDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlDTO
     */
    campusID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlDTO
     */
    campusName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlDTO
     */
    buildID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlDTO
     */
    buildName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlDTO
     */
    floorID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlDTO
     */
    floorName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlDTO
     */
    roomID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlDTO
     */
    roomName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlDTO
     */
    userTypeID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlDTO
     */
    userTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlDTO
     */
    explain?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlDTO
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlDTO
     */
    createDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlDTO
     */
    hasControl?: boolean;
}
/**
 * 
 * @export
 * @interface ControlDTOListDataResult
 */
export interface ControlDTOListDataResult {
    /**
     * 
     * @type {Array<ControlDTO>}
     * @memberof ControlDTOListDataResult
     */
    data?: Array<ControlDTO> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlDTOListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlDTOListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface ControlPeriod
 */
export interface ControlPeriod {
    /**
     * 
     * @type {string}
     * @memberof ControlPeriod
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlPeriod
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ControlPeriod
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlPeriod
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface ControlPeriodListDataResult
 */
export interface ControlPeriodListDataResult {
    /**
     * 
     * @type {Array<ControlPeriod>}
     * @memberof ControlPeriodListDataResult
     */
    data?: Array<ControlPeriod> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlPeriodListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlPeriodListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface ControlQuestion
 */
export interface ControlQuestion {
    /**
     * 
     * @type {number}
     * @memberof ControlQuestion
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlQuestion
     */
    controlQuestionGroupID?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlQuestion
     */
    controlQuestionTypeID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlQuestion
     */
    question?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlQuestion
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ControlQuestion
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlQuestion
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface ControlQuestionGroup
 */
export interface ControlQuestionGroup {
    /**
     * 
     * @type {number}
     * @memberof ControlQuestionGroup
     */
    projectID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlQuestionGroup
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlQuestionGroup
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ControlQuestionGroup
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlQuestionGroup
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface ControlQuestionGroupListDataResult
 */
export interface ControlQuestionGroupListDataResult {
    /**
     * 
     * @type {Array<ControlQuestionGroup>}
     * @memberof ControlQuestionGroupListDataResult
     */
    data?: Array<ControlQuestionGroup> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlQuestionGroupListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlQuestionGroupListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface ControlQuestionListDataResult
 */
export interface ControlQuestionListDataResult {
    /**
     * 
     * @type {Array<ControlQuestion>}
     * @memberof ControlQuestionListDataResult
     */
    data?: Array<ControlQuestion> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlQuestionListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlQuestionListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface ControlQuestionType
 */
export interface ControlQuestionType {
    /**
     * 
     * @type {string}
     * @memberof ControlQuestionType
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlQuestionType
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ControlQuestionType
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlQuestionType
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface ControlQuestionTypeListDataResult
 */
export interface ControlQuestionTypeListDataResult {
    /**
     * 
     * @type {Array<ControlQuestionType>}
     * @memberof ControlQuestionTypeListDataResult
     */
    data?: Array<ControlQuestionType> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlQuestionTypeListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlQuestionTypeListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface ControlReportDTO
 */
export interface ControlReportDTO {
    /**
     * 
     * @type {number}
     * @memberof ControlReportDTO
     */
    controlId?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlReportDTO
     */
    userTypeID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlReportDTO
     */
    userTypeName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlReportDTO
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlReportDTO
     */
    campusID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlReportDTO
     */
    campusName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlReportDTO
     */
    buildID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlReportDTO
     */
    buildName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlReportDTO
     */
    floorID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlReportDTO
     */
    floorName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlReportDTO
     */
    roomID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlReportDTO
     */
    roomName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlReportDTO
     */
    controlName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlReportDTO
     */
    controlCreateDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ControlReportDTO
     */
    controlTypeID?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlReportDTO
     */
    userID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlReportDTO
     */
    userName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlReportDTO
     */
    controlPeriodID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlReportDTO
     */
    controlPeriodName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlReportDTO
     */
    controlQuestionGroupID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlReportDTO
     */
    controlQuestionGroupName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlReportDTO
     */
    controlTypeExplain?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlReportDTO
     */
    beforeDay?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlReportDTO
     */
    controlTaskMaintenanceID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlReportDTO
     */
    beforeDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlReportDTO
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlReportDTO
     */
    endDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlReportDTO
     */
    isCompleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlReportDTO
     */
    answer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlReportDTO
     */
    controlExplain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlReportDTO
     */
    completedUserName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlReportDTO
     */
    completedUserID?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ControlReportDTO
     */
    state?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlReportDTO
     */
    complatedDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ControlReportDTO
     */
    statusCode?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlReportDTO
     */
    statusColor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlReportDTO
     */
    statusDesc?: string | null;
    /**
     * 
     * @type {Array<ControlTaskMaintenanceDetailDocument>}
     * @memberof ControlReportDTO
     */
    documents?: Array<ControlTaskMaintenanceDetailDocument> | null;
    /**
     * 
     * @type {number}
     * @memberof ControlReportDTO
     */
    controlTaskMaintenanceDetailID?: number | null;
}
/**
 * 
 * @export
 * @interface ControlReportDTOListDataResult
 */
export interface ControlReportDTOListDataResult {
    /**
     * 
     * @type {Array<ControlReportDTO>}
     * @memberof ControlReportDTOListDataResult
     */
    data?: Array<ControlReportDTO> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlReportDTOListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlReportDTOListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface ControlTask
 */
export interface ControlTask {
    /**
     * 
     * @type {number}
     * @memberof ControlTask
     */
    controlID?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlTask
     */
    userID?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlTask
     */
    controlPeriodID?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlTask
     */
    controlQuestionGroupID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlTask
     */
    explain?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlTask
     */
    beforeDay?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ControlTask
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlTask
     */
    startDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ControlTask
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlTask
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface ControlTaskDTO
 */
export interface ControlTaskDTO {
    /**
     * 
     * @type {number}
     * @memberof ControlTaskDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlTaskDTO
     */
    controlID?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlTaskDTO
     */
    userID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlTaskDTO
     */
    userName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlTaskDTO
     */
    controlPeriodID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlTaskDTO
     */
    controlPeriodName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlTaskDTO
     */
    controlQuestionGroupID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlTaskDTO
     */
    controlQuestionGroupName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlTaskDTO
     */
    explain?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlTaskDTO
     */
    beforeDay?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ControlTaskDTO
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlTaskDTO
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlTaskDTO
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface ControlTaskDTOListDataResult
 */
export interface ControlTaskDTOListDataResult {
    /**
     * 
     * @type {Array<ControlTaskDTO>}
     * @memberof ControlTaskDTOListDataResult
     */
    data?: Array<ControlTaskDTO> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlTaskDTOListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlTaskDTOListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface ControlTaskMaintenanceAddDTO
 */
export interface ControlTaskMaintenanceAddDTO {
    /**
     * 
     * @type {number}
     * @memberof ControlTaskMaintenanceAddDTO
     */
    controlTaskMaintenanceID?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlTaskMaintenanceAddDTO
     */
    controlID?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlTaskMaintenanceAddDTO
     */
    projectID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlTaskMaintenanceAddDTO
     */
    answer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlTaskMaintenanceAddDTO
     */
    explain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlTaskMaintenanceAddDTO
     */
    completedUserName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlTaskMaintenanceAddDTO
     */
    completedUserID?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ControlTaskMaintenanceAddDTO
     */
    state?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlTaskMaintenanceAddDTO
     */
    createDate?: string;
    /**
     * 
     * @type {Array<ControlTaskMaintenanceDetailDocument>}
     * @memberof ControlTaskMaintenanceAddDTO
     */
    documents?: Array<ControlTaskMaintenanceDetailDocument> | null;
}
/**
 * 
 * @export
 * @interface ControlTaskMaintenanceDetailDocument
 */
export interface ControlTaskMaintenanceDetailDocument {
    /**
     * 
     * @type {number}
     * @memberof ControlTaskMaintenanceDetailDocument
     */
    controlTaskMaintenanceDetailID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlTaskMaintenanceDetailDocument
     */
    extension?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlTaskMaintenanceDetailDocument
     */
    path?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlTaskMaintenanceDetailDocument
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlTaskMaintenanceDetailDocument
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlTaskMaintenanceDetailDocument
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface ControlTimelineDTO
 */
export interface ControlTimelineDTO {
    /**
     * 
     * @type {number}
     * @memberof ControlTimelineDTO
     */
    controlId?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlTimelineDTO
     */
    userTypeID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlTimelineDTO
     */
    userTypeName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlTimelineDTO
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlTimelineDTO
     */
    campusID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlTimelineDTO
     */
    campusName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlTimelineDTO
     */
    buildID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlTimelineDTO
     */
    buildName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlTimelineDTO
     */
    floorID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlTimelineDTO
     */
    floorName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlTimelineDTO
     */
    roomID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlTimelineDTO
     */
    roomName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlTimelineDTO
     */
    controlName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlTimelineDTO
     */
    controlExplain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlTimelineDTO
     */
    controlCreateDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ControlTimelineDTO
     */
    controlTypeID?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlTimelineDTO
     */
    userID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlTimelineDTO
     */
    userName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlTimelineDTO
     */
    controlPeriodID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlTimelineDTO
     */
    controlPeriodName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlTimelineDTO
     */
    controlQuestionGroupID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlTimelineDTO
     */
    controlQuestionGroupName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlTimelineDTO
     */
    controlTypeExplain?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlTimelineDTO
     */
    beforeDay?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlTimelineDTO
     */
    controlTaskMaintenanceID?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlTimelineDTO
     */
    beforeDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlTimelineDTO
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlTimelineDTO
     */
    endDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlTimelineDTO
     */
    isCompleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlTimelineDTO
     */
    complatedDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ControlTimelineDTO
     */
    statusCode?: number;
    /**
     * 
     * @type {string}
     * @memberof ControlTimelineDTO
     */
    statusColor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlTimelineDTO
     */
    statusDesc?: string | null;
}
/**
 * 
 * @export
 * @interface ControlTimelineDTOListDataResult
 */
export interface ControlTimelineDTOListDataResult {
    /**
     * 
     * @type {Array<ControlTimelineDTO>}
     * @memberof ControlTimelineDTOListDataResult
     */
    data?: Array<ControlTimelineDTO> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ControlTimelineDTOListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlTimelineDTOListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface Counter
 */
export interface Counter {
    /**
     * 
     * @type {number}
     * @memberof Counter
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof Counter
     */
    counterTypeID?: number;
    /**
     * 
     * @type {string}
     * @memberof Counter
     */
    barcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Counter
     */
    location?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Counter
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Counter
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Counter
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface CounterMaintenanceTypeAddDTO
 */
export interface CounterMaintenanceTypeAddDTO {
    /**
     * 
     * @type {number}
     * @memberof CounterMaintenanceTypeAddDTO
     */
    counterID?: number;
    /**
     * 
     * @type {number}
     * @memberof CounterMaintenanceTypeAddDTO
     */
    counterPeriodID?: number;
    /**
     * 
     * @type {string}
     * @memberof CounterMaintenanceTypeAddDTO
     */
    startDate?: string;
    /**
     * 
     * @type {number}
     * @memberof CounterMaintenanceTypeAddDTO
     */
    beforeDay?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CounterMaintenanceTypeAddDTO
     */
    isTRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterMaintenanceTypeAddDTO
     */
    isT1Required?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterMaintenanceTypeAddDTO
     */
    isT2Required?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterMaintenanceTypeAddDTO
     */
    isT3Required?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterMaintenanceTypeAddDTO
     */
    isKapasitifRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterMaintenanceTypeAddDTO
     */
    isReaktifRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterMaintenanceTypeAddDTO
     */
    isDemantRequired?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CounterMaintenanceTypeAddDTO
     */
    addedUserID?: number;
    /**
     * 
     * @type {number}
     * @memberof CounterMaintenanceTypeAddDTO
     */
    multiplierValue?: number;
    /**
     * 
     * @type {number}
     * @memberof CounterMaintenanceTypeAddDTO
     */
    consumptionDifferencePercentage?: number;
}
/**
 * 
 * @export
 * @interface CounterMaintenanceTypeDTO
 */
export interface CounterMaintenanceTypeDTO {
    /**
     * 
     * @type {number}
     * @memberof CounterMaintenanceTypeDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CounterMaintenanceTypeDTO
     */
    counterID?: number;
    /**
     * 
     * @type {string}
     * @memberof CounterMaintenanceTypeDTO
     */
    counterBarcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CounterMaintenanceTypeDTO
     */
    counterLocation?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CounterMaintenanceTypeDTO
     */
    counterPeriodID?: number;
    /**
     * 
     * @type {string}
     * @memberof CounterMaintenanceTypeDTO
     */
    counterPeriodName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CounterMaintenanceTypeDTO
     */
    startDate?: string;
    /**
     * 
     * @type {number}
     * @memberof CounterMaintenanceTypeDTO
     */
    beforeDay?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CounterMaintenanceTypeDTO
     */
    isTRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterMaintenanceTypeDTO
     */
    isT1Required?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterMaintenanceTypeDTO
     */
    isT2Required?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterMaintenanceTypeDTO
     */
    isT3Required?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterMaintenanceTypeDTO
     */
    isKapasitifRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterMaintenanceTypeDTO
     */
    isReaktifRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterMaintenanceTypeDTO
     */
    isDemantRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterMaintenanceTypeDTO
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CounterMaintenanceTypeDTO
     */
    addedUserID?: number;
    /**
     * 
     * @type {string}
     * @memberof CounterMaintenanceTypeDTO
     */
    addedUserIName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CounterMaintenanceTypeDTO
     */
    createDate?: string;
    /**
     * 
     * @type {number}
     * @memberof CounterMaintenanceTypeDTO
     */
    multiplierValue?: number;
    /**
     * 
     * @type {number}
     * @memberof CounterMaintenanceTypeDTO
     */
    consumptionDifferencePercentage?: number;
}
/**
 * 
 * @export
 * @interface CounterMaintenanceTypeDTOListDataResult
 */
export interface CounterMaintenanceTypeDTOListDataResult {
    /**
     * 
     * @type {Array<CounterMaintenanceTypeDTO>}
     * @memberof CounterMaintenanceTypeDTOListDataResult
     */
    data?: Array<CounterMaintenanceTypeDTO> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CounterMaintenanceTypeDTOListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CounterMaintenanceTypeDTOListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface CounterPeriod
 */
export interface CounterPeriod {
    /**
     * 
     * @type {string}
     * @memberof CounterPeriod
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CounterPeriod
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CounterPeriod
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CounterPeriod
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface CounterPeriodListDataResult
 */
export interface CounterPeriodListDataResult {
    /**
     * 
     * @type {Array<CounterPeriod>}
     * @memberof CounterPeriodListDataResult
     */
    data?: Array<CounterPeriod> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CounterPeriodListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CounterPeriodListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface CounterTimeLine
 */
export interface CounterTimeLine {
    /**
     * 
     * @type {number}
     * @memberof CounterTimeLine
     */
    counterTaskID?: number;
    /**
     * 
     * @type {number}
     * @memberof CounterTimeLine
     */
    counterID?: number;
    /**
     * 
     * @type {string}
     * @memberof CounterTimeLine
     */
    barcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CounterTimeLine
     */
    location?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CounterTimeLine
     */
    isTRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterTimeLine
     */
    isT1Required?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterTimeLine
     */
    isT2Required?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterTimeLine
     */
    isT3Required?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterTimeLine
     */
    isKapasitifRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterTimeLine
     */
    isReaktifRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterTimeLine
     */
    isDemantRequired?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CounterTimeLine
     */
    periodId?: number;
    /**
     * 
     * @type {string}
     * @memberof CounterTimeLine
     */
    periodName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CounterTimeLine
     */
    counterTypeId?: number;
    /**
     * 
     * @type {string}
     * @memberof CounterTimeLine
     */
    counterTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CounterTimeLine
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CounterTimeLine
     */
    beforeDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CounterTimeLine
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CounterTimeLine
     */
    t?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CounterTimeLine
     */
    t1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CounterTimeLine
     */
    t2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CounterTimeLine
     */
    t3?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CounterTimeLine
     */
    kapasitif?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CounterTimeLine
     */
    reaktif?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CounterTimeLine
     */
    demant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CounterTimeLine
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CounterTimeLine
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CounterTimeLine
     */
    photoPath?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CounterTimeLine
     */
    controlledUserID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CounterTimeLine
     */
    controlledUserName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CounterTimeLine
     */
    isCompleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CounterTimeLine
     */
    completedDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CounterTimeLine
     */
    statusCode?: number;
    /**
     * 
     * @type {string}
     * @memberof CounterTimeLine
     */
    statusColor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CounterTimeLine
     */
    statusDesc?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CounterTimeLine
     */
    multiplierValue?: number;
    /**
     * 
     * @type {number}
     * @memberof CounterTimeLine
     */
    consumptionDifferencePercentage?: number;
}
/**
 * 
 * @export
 * @interface CounterTimeLineListDataResult
 */
export interface CounterTimeLineListDataResult {
    /**
     * 
     * @type {Array<CounterTimeLine>}
     * @memberof CounterTimeLineListDataResult
     */
    data?: Array<CounterTimeLine> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CounterTimeLineListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CounterTimeLineListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface CounterType
 */
export interface CounterType {
    /**
     * 
     * @type {string}
     * @memberof CounterType
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CounterType
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CounterType
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CounterType
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface CounterTypeListDataResult
 */
export interface CounterTypeListDataResult {
    /**
     * 
     * @type {Array<CounterType>}
     * @memberof CounterTypeListDataResult
     */
    data?: Array<CounterType> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CounterTypeListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CounterTypeListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface Demand
 */
export interface Demand {
    /**
     * 
     * @type {number}
     * @memberof Demand
     */
    demandGroupID?: number;
    /**
     * 
     * @type {number}
     * @memberof Demand
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof Demand
     */
    userTypeID?: number;
    /**
     * 
     * @type {number}
     * @memberof Demand
     */
    campusID?: number;
    /**
     * 
     * @type {number}
     * @memberof Demand
     */
    createUserID?: number;
    /**
     * 
     * @type {number}
     * @memberof Demand
     */
    buildID?: number;
    /**
     * 
     * @type {number}
     * @memberof Demand
     */
    floorID?: number;
    /**
     * 
     * @type {number}
     * @memberof Demand
     */
    roomID?: number;
    /**
     * 
     * @type {string}
     * @memberof Demand
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Demand
     */
    explain?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Demand
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Demand
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Demand
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface DemandAndAllDocumentDTO
 */
export interface DemandAndAllDocumentDTO {
    /**
     * 
     * @type {number}
     * @memberof DemandAndAllDocumentDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DemandAndAllDocumentDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandAndAllDocumentDTO
     */
    explain?: string | null;
    /**
     * 
     * @type {Array<DemandAndAllDocumentItemDTO>}
     * @memberof DemandAndAllDocumentDTO
     */
    demandDocuments?: Array<DemandAndAllDocumentItemDTO> | null;
}
/**
 * 
 * @export
 * @interface DemandAndAllDocumentDTOListDataResult
 */
export interface DemandAndAllDocumentDTOListDataResult {
    /**
     * 
     * @type {Array<DemandAndAllDocumentDTO>}
     * @memberof DemandAndAllDocumentDTOListDataResult
     */
    data?: Array<DemandAndAllDocumentDTO> | null;
    /**
     * 
     * @type {boolean}
     * @memberof DemandAndAllDocumentDTOListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DemandAndAllDocumentDTOListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface DemandAndAllDocumentItemDTO
 */
export interface DemandAndAllDocumentItemDTO {
    /**
     * 
     * @type {number}
     * @memberof DemandAndAllDocumentItemDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DemandAndAllDocumentItemDTO
     */
    assignUserID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DemandAndAllDocumentItemDTO
     */
    assignUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandAndAllDocumentItemDTO
     */
    documentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandAndAllDocumentItemDTO
     */
    lastDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DemandAndAllDocumentItemDTO
     */
    isCompleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DemandAndAllDocumentItemDTO
     */
    createDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DemandAndAllDocumentItemDTO
     */
    path?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemandAndAllDocumentItemDTO
     */
    completedUserID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DemandAndAllDocumentItemDTO
     */
    completedUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandAndAllDocumentItemDTO
     */
    completedDate?: string | null;
}
/**
 * 
 * @export
 * @interface DemandDocument
 */
export interface DemandDocument {
    /**
     * 
     * @type {number}
     * @memberof DemandDocument
     */
    demandID?: number;
    /**
     * 
     * @type {number}
     * @memberof DemandDocument
     */
    assignUserID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DemandDocument
     */
    documentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandDocument
     */
    lastDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DemandDocument
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DemandDocument
     */
    isCompleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DemandDocument
     */
    path?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemandDocument
     */
    completedUserID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DemandDocument
     */
    completedDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemandDocument
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DemandDocument
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface DemandGroup
 */
export interface DemandGroup {
    /**
     * 
     * @type {number}
     * @memberof DemandGroup
     */
    projectID?: number;
    /**
     * 
     * @type {string}
     * @memberof DemandGroup
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemandGroup
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DemandGroup
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface DemandGroupListDataResult
 */
export interface DemandGroupListDataResult {
    /**
     * 
     * @type {Array<DemandGroup>}
     * @memberof DemandGroupListDataResult
     */
    data?: Array<DemandGroup> | null;
    /**
     * 
     * @type {boolean}
     * @memberof DemandGroupListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DemandGroupListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface DemandReportDTO
 */
export interface DemandReportDTO {
    /**
     * 
     * @type {number}
     * @memberof DemandReportDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DemandReportDTO
     */
    demandGroupID?: number;
    /**
     * 
     * @type {string}
     * @memberof DemandReportDTO
     */
    demandGroupName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemandReportDTO
     */
    userTypeID?: number;
    /**
     * 
     * @type {string}
     * @memberof DemandReportDTO
     */
    userTypeName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemandReportDTO
     */
    campusID?: number;
    /**
     * 
     * @type {string}
     * @memberof DemandReportDTO
     */
    campusName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemandReportDTO
     */
    buildID?: number;
    /**
     * 
     * @type {string}
     * @memberof DemandReportDTO
     */
    buildName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemandReportDTO
     */
    floorID?: number;
    /**
     * 
     * @type {string}
     * @memberof DemandReportDTO
     */
    floorName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemandReportDTO
     */
    roomID?: number;
    /**
     * 
     * @type {string}
     * @memberof DemandReportDTO
     */
    roomName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandReportDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandReportDTO
     */
    explain?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DemandReportDTO
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DemandReportDTO
     */
    createDate?: string;
    /**
     * 
     * @type {number}
     * @memberof DemandReportDTO
     */
    createUserID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DemandReportDTO
     */
    createUserName?: string | null;
    /**
     * 
     * @type {Array<DemandReportDocumentDTO>}
     * @memberof DemandReportDTO
     */
    documents?: Array<DemandReportDocumentDTO> | null;
}
/**
 * 
 * @export
 * @interface DemandReportDTOListDataResult
 */
export interface DemandReportDTOListDataResult {
    /**
     * 
     * @type {Array<DemandReportDTO>}
     * @memberof DemandReportDTOListDataResult
     */
    data?: Array<DemandReportDTO> | null;
    /**
     * 
     * @type {boolean}
     * @memberof DemandReportDTOListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DemandReportDTOListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface DemandReportDocumentDTO
 */
export interface DemandReportDocumentDTO {
    /**
     * 
     * @type {number}
     * @memberof DemandReportDocumentDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DemandReportDocumentDTO
     */
    demandID?: number;
    /**
     * 
     * @type {number}
     * @memberof DemandReportDocumentDTO
     */
    assignUserID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DemandReportDocumentDTO
     */
    assignUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandReportDocumentDTO
     */
    documentName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DemandReportDocumentDTO
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DemandReportDocumentDTO
     */
    isCompleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DemandReportDocumentDTO
     */
    path?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemandReportDocumentDTO
     */
    completedUserID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DemandReportDocumentDTO
     */
    completedUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandReportDocumentDTO
     */
    lastDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DemandReportDocumentDTO
     */
    completedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandReportDocumentDTO
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface DoCounterMaintenanceModel
 */
export interface DoCounterMaintenanceModel {
    /**
     * 
     * @type {number}
     * @memberof DoCounterMaintenanceModel
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DoCounterMaintenanceModel
     */
    controlledUserID?: number;
    /**
     * 
     * @type {string}
     * @memberof DoCounterMaintenanceModel
     */
    t?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoCounterMaintenanceModel
     */
    t1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoCounterMaintenanceModel
     */
    t2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoCounterMaintenanceModel
     */
    t3?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoCounterMaintenanceModel
     */
    kapasitif?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoCounterMaintenanceModel
     */
    reaktif?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoCounterMaintenanceModel
     */
    demant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoCounterMaintenanceModel
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoCounterMaintenanceModel
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoCounterMaintenanceModel
     */
    photo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoCounterMaintenanceModel
     */
    photoExtension?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DoCounterMaintenanceModel
     */
    multiplierValue?: number;
    /**
     * 
     * @type {number}
     * @memberof DoCounterMaintenanceModel
     */
    consumptionDifferencePercentage?: number;
}
/**
 * 
 * @export
 * @interface DoDocumentMaintenanceDTO
 */
export interface DoDocumentMaintenanceDTO {
    /**
     * 
     * @type {number}
     * @memberof DoDocumentMaintenanceDTO
     */
    documentMaintenanceID?: number;
    /**
     * 
     * @type {string}
     * @memberof DoDocumentMaintenanceDTO
     */
    questionAnswer?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DoDocumentMaintenanceDTO
     */
    status?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DoDocumentMaintenanceDTO
     */
    explain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoDocumentMaintenanceDTO
     */
    firstControlUserName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DoDocumentMaintenanceDTO
     */
    firstControlUserID?: number;
    /**
     * 
     * @type {string}
     * @memberof DoDocumentMaintenanceDTO
     */
    secondDate?: string;
    /**
     * 
     * @type {Array<DoDocumentMaintenancePhotoDTO>}
     * @memberof DoDocumentMaintenanceDTO
     */
    documents?: Array<DoDocumentMaintenancePhotoDTO> | null;
}
/**
 * 
 * @export
 * @interface DoDocumentMaintenancePhotoDTO
 */
export interface DoDocumentMaintenancePhotoDTO {
    /**
     * 
     * @type {string}
     * @memberof DoDocumentMaintenancePhotoDTO
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoDocumentMaintenancePhotoDTO
     */
    document?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoDocumentMaintenancePhotoDTO
     */
    extension?: string | null;
}
/**
 * 
 * @export
 * @interface DoUpdateDocumentMaintenanceDTO
 */
export interface DoUpdateDocumentMaintenanceDTO {
    /**
     * 
     * @type {number}
     * @memberof DoUpdateDocumentMaintenanceDTO
     */
    documentMaintenanceDetailID?: number;
    /**
     * 
     * @type {number}
     * @memberof DoUpdateDocumentMaintenanceDTO
     */
    documentMaintenanceID?: number;
    /**
     * 
     * @type {string}
     * @memberof DoUpdateDocumentMaintenanceDTO
     */
    explain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoUpdateDocumentMaintenanceDTO
     */
    secondControlUserName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DoUpdateDocumentMaintenanceDTO
     */
    secondControlUserID?: number;
    /**
     * 
     * @type {Array<DoDocumentMaintenancePhotoDTO>}
     * @memberof DoUpdateDocumentMaintenanceDTO
     */
    documents?: Array<DoDocumentMaintenancePhotoDTO> | null;
}
/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    userTypeID?: number;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    assignUserID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    campusID?: number;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    buildID?: number;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    floorID?: number;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    roomID?: number;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    groupID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    brandID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    modelID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    inventoryCapacityID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    documentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    productionYear?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    explain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    unit?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Document
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    createUserID?: number;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface DocumentAndTypeDTO
 */
export interface DocumentAndTypeDTO {
    /**
     * 
     * @type {number}
     * @memberof DocumentAndTypeDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentAndTypeDTO
     */
    documentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentAndTypeDTO
     */
    explain?: string | null;
    /**
     * 
     * @type {Array<DocumentMaintenanceTypeDTO>}
     * @memberof DocumentAndTypeDTO
     */
    typeList?: Array<DocumentMaintenanceTypeDTO> | null;
}
/**
 * 
 * @export
 * @interface DocumentAndTypeDTOListDataResult
 */
export interface DocumentAndTypeDTOListDataResult {
    /**
     * 
     * @type {Array<DocumentAndTypeDTO>}
     * @memberof DocumentAndTypeDTOListDataResult
     */
    data?: Array<DocumentAndTypeDTO> | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentAndTypeDTOListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentAndTypeDTOListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentDTO
 */
export interface DocumentDTO {
    /**
     * 
     * @type {number}
     * @memberof DocumentDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentDTO
     */
    userTypeID?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentDTO
     */
    assignUserID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    assignUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    userTypeName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentDTO
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentDTO
     */
    campusID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    campusName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentDTO
     */
    buildID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    buildName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentDTO
     */
    floorID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    floorName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentDTO
     */
    roomID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    roomName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentDTO
     */
    groupID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    groupName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentDTO
     */
    brandID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    brandName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentDTO
     */
    modelID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    modelName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentDTO
     */
    inventoryCapacityID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    inventoryCapacityName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    documentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    productionYear?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    explain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    unit?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDTO
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    createDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDTO
     */
    hasMaintenance?: boolean;
}
/**
 * 
 * @export
 * @interface DocumentDTOListDataResult
 */
export interface DocumentDTOListDataResult {
    /**
     * 
     * @type {Array<DocumentDTO>}
     * @memberof DocumentDTOListDataResult
     */
    data?: Array<DocumentDTO> | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDTOListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTOListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentMaintenanceDetailDocument
 */
export interface DocumentMaintenanceDetailDocument {
    /**
     * 
     * @type {number}
     * @memberof DocumentMaintenanceDetailDocument
     */
    documentMaintenanceDetailID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentMaintenanceDetailDocument
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentMaintenanceDetailDocument
     */
    path?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentMaintenanceDetailDocument
     */
    extension?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentMaintenanceDetailDocument
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentMaintenanceDetailDocument
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface DocumentMaintenanceType
 */
export interface DocumentMaintenanceType {
    /**
     * 
     * @type {number}
     * @memberof DocumentMaintenanceType
     */
    documentID?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentMaintenanceType
     */
    documentPeriodID?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentMaintenanceType
     */
    beforeDay?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentMaintenanceType
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentMaintenanceType
     */
    question?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentMaintenanceType
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DocumentMaintenanceType
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentMaintenanceType
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface DocumentMaintenanceTypeDTO
 */
export interface DocumentMaintenanceTypeDTO {
    /**
     * 
     * @type {number}
     * @memberof DocumentMaintenanceTypeDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentMaintenanceTypeDTO
     */
    documentID?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentMaintenanceTypeDTO
     */
    documentPeriodID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentMaintenanceTypeDTO
     */
    documentPeriodName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentMaintenanceTypeDTO
     */
    beforeDay?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentMaintenanceTypeDTO
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentMaintenanceTypeDTO
     */
    question?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentMaintenanceTypeDTO
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentMaintenanceTypeDTO
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface DocumentMaintenanceTypeDTOListDataResult
 */
export interface DocumentMaintenanceTypeDTOListDataResult {
    /**
     * 
     * @type {Array<DocumentMaintenanceTypeDTO>}
     * @memberof DocumentMaintenanceTypeDTOListDataResult
     */
    data?: Array<DocumentMaintenanceTypeDTO> | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentMaintenanceTypeDTOListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentMaintenanceTypeDTOListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentPeriod
 */
export interface DocumentPeriod {
    /**
     * 
     * @type {string}
     * @memberof DocumentPeriod
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPeriod
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DocumentPeriod
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentPeriod
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface DocumentPeriodListDataResult
 */
export interface DocumentPeriodListDataResult {
    /**
     * 
     * @type {Array<DocumentPeriod>}
     * @memberof DocumentPeriodListDataResult
     */
    data?: Array<DocumentPeriod> | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPeriodListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentPeriodListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentReportDTO
 */
export interface DocumentReportDTO {
    /**
     * 
     * @type {number}
     * @memberof DocumentReportDTO
     */
    assignUserID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    assignUserName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentReportDTO
     */
    documentID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    documentExplain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    documentName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentReportDTO
     */
    userTypeID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    userTypeName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentReportDTO
     */
    campusID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    campusName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentReportDTO
     */
    buildID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    buildName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentReportDTO
     */
    floorID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    floorName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentReportDTO
     */
    roomID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    roomName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentReportDTO
     */
    groupID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    groupName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentReportDTO
     */
    brandID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    brandName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentReportDTO
     */
    modelID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    modelName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentReportDTO
     */
    documentCapacityID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    documentCapacityName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    documentUnit?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    documentProductionYear?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentReportDTO
     */
    documentMaintenanceDetailID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentReportDTO
     */
    documentMaintenanceID?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentReportDTO
     */
    documentMaintenanceTypeID?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentReportDTO
     */
    documentMaintenancePeriodID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    documentMaintenancePeriodName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    question?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentReportDTO
     */
    beforeDay?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    beforeDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    secondDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentReportDTO
     */
    isCompleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    documentMaintenanceCreateDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    questionAnswer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    answerExplain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    updateExplain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    firstControlUserName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentReportDTO
     */
    firstControlUserID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    secondControlUserName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentReportDTO
     */
    secondControlUserID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    updateDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    completedDate?: string | null;
    /**
     * 
     * @type {Array<DocumentMaintenanceDetailDocument>}
     * @memberof DocumentReportDTO
     */
    documents?: Array<DocumentMaintenanceDetailDocument> | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentReportDTO
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    statusDesc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTO
     */
    statusColor?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentReportDTOListDataResult
 */
export interface DocumentReportDTOListDataResult {
    /**
     * 
     * @type {Array<DocumentReportDTO>}
     * @memberof DocumentReportDTOListDataResult
     */
    data?: Array<DocumentReportDTO> | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentReportDTOListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportDTOListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentTimelineDTO
 */
export interface DocumentTimelineDTO {
    /**
     * 
     * @type {number}
     * @memberof DocumentTimelineDTO
     */
    documentMaintenanceID?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentTimelineDTO
     */
    documentMaintenanceTypeID?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentTimelineDTO
     */
    documentID?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentTimelineDTO
     */
    assignUserID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTO
     */
    assignUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTO
     */
    documentName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentTimelineDTO
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentTimelineDTO
     */
    documentPeriodID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTO
     */
    documentPeriodName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentTimelineDTO
     */
    userTypeID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTO
     */
    userTypeName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentTimelineDTO
     */
    campusID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTO
     */
    campusName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentTimelineDTO
     */
    buildID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTO
     */
    buildName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentTimelineDTO
     */
    floorID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTO
     */
    floorName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentTimelineDTO
     */
    roomID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTO
     */
    roomName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentTimelineDTO
     */
    beforeDay?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTO
     */
    question?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTO
     */
    beforeDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTO
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTO
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTO
     */
    secondDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTimelineDTO
     */
    isCompleted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DocumentTimelineDTO
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTO
     */
    statusDesc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTO
     */
    statusColor?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentTimelineDTO
     */
    groupID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTO
     */
    groupName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentTimelineDTO
     */
    brandID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTO
     */
    brandName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentTimelineDTO
     */
    modelID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTO
     */
    modelName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTO
     */
    completedDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentTimelineDTO
     */
    documentMaintenanceDetailID?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTO
     */
    questionAnswer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTO
     */
    explain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTO
     */
    updateExplain?: string | null;
    /**
     * 
     * @type {Array<DocumentMaintenanceDetailDocument>}
     * @memberof DocumentTimelineDTO
     */
    documents?: Array<DocumentMaintenanceDetailDocument> | null;
}
/**
 * 
 * @export
 * @interface DocumentTimelineDTOListDataResult
 */
export interface DocumentTimelineDTOListDataResult {
    /**
     * 
     * @type {Array<DocumentTimelineDTO>}
     * @memberof DocumentTimelineDTOListDataResult
     */
    data?: Array<DocumentTimelineDTO> | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTimelineDTOListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentTimelineDTOListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface Floor
 */
export interface Floor {
    /**
     * 
     * @type {number}
     * @memberof Floor
     */
    buildID?: number;
    /**
     * 
     * @type {string}
     * @memberof Floor
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Floor
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Floor
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Floor
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {number}
     * @memberof Group
     */
    projectID?: number;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Group
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Group
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface InventoryCapacity
 */
export interface InventoryCapacity {
    /**
     * 
     * @type {string}
     * @memberof InventoryCapacity
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryCapacity
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InventoryCapacity
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryCapacity
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface InventoryDTO
 */
export interface InventoryDTO {
    /**
     * 
     * @type {number}
     * @memberof InventoryDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryDTO
     */
    userTypeID?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    userTypeName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryDTO
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryDTO
     */
    campusID?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    campusName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryDTO
     */
    buildID?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    buildName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryDTO
     */
    floorID?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    floorName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryDTO
     */
    roomID?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    roomName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryDTO
     */
    groupID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    groupName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryDTO
     */
    brandID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    brandName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryDTO
     */
    modelID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    modelName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryDTO
     */
    inventoryaCapacityID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    inventoryaCapacityName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    barcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    assetNo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    serialNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    productionYear?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    inventoryPhoto?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    explain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    unit?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryDTO
     */
    count?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryDTO
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    riskAnalysisExpriDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    riskAnalysisPath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    userInstructions?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    userGudiePath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    maintenanceContract?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryDTO
     */
    createUserID?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    createUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTO
     */
    createDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryDTO
     */
    hasMaintenance?: boolean;
}
/**
 * 
 * @export
 * @interface InventoryDTOListDataResult
 */
export interface InventoryDTOListDataResult {
    /**
     * 
     * @type {Array<InventoryDTO>}
     * @memberof InventoryDTOListDataResult
     */
    data?: Array<InventoryDTO> | null;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryDTOListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InventoryDTOListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface InventoryLegalInspectionDoMakeDTO
 */
export interface InventoryLegalInspectionDoMakeDTO {
    /**
     * 
     * @type {number}
     * @memberof InventoryLegalInspectionDoMakeDTO
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryLegalInspectionDoMakeDTO
     */
    answer?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InventoryLegalInspectionDoMakeDTO
     */
    explain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryLegalInspectionDoMakeDTO
     */
    maintenanceFirm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryLegalInspectionDoMakeDTO
     */
    completedPersonelName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryLegalInspectionDoMakeDTO
     */
    completedUserID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryLegalInspectionDoMakeDTO
     */
    secondDate?: string | null;
    /**
     * 
     * @type {Array<LegalDocument>}
     * @memberof InventoryLegalInspectionDoMakeDTO
     */
    documents?: Array<LegalDocument> | null;
}
/**
 * 
 * @export
 * @interface InventoryLegalInspectionType
 */
export interface InventoryLegalInspectionType {
    /**
     * 
     * @type {number}
     * @memberof InventoryLegalInspectionType
     */
    inventoryID?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryLegalInspectionType
     */
    projectID?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryLegalInspectionType
     */
    riskAnalysisIsRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryLegalInspectionType
     */
    userInstructionsIsRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryLegalInspectionType
     */
    userGudiePathIsRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryLegalInspectionType
     */
    maintenanceContractIsRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryLegalInspectionType
     */
    inventoryPhotoIdRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryLegalInspectionType
     */
    controlFormPhotoIdRequired?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InventoryLegalInspectionType
     */
    question?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryLegalInspectionType
     */
    beforeDay?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryLegalInspectionType
     */
    startDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryLegalInspectionType
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InventoryLegalInspectionType
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryLegalInspectionType
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface InventoryLegalInspectionTypeAddDTO
 */
export interface InventoryLegalInspectionTypeAddDTO {
    /**
     * 
     * @type {number}
     * @memberof InventoryLegalInspectionTypeAddDTO
     */
    inventoryID?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryLegalInspectionTypeAddDTO
     */
    projectID?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryLegalInspectionTypeAddDTO
     */
    riskAnalysisIsRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryLegalInspectionTypeAddDTO
     */
    userInstructionsIsRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryLegalInspectionTypeAddDTO
     */
    userGudiePathIsRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryLegalInspectionTypeAddDTO
     */
    maintenanceContractIsRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryLegalInspectionTypeAddDTO
     */
    inventoryPhotoIdRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryLegalInspectionTypeAddDTO
     */
    controlFormPhotoIdRequired?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InventoryLegalInspectionTypeAddDTO
     */
    question?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryLegalInspectionTypeAddDTO
     */
    beforeDay?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryLegalInspectionTypeAddDTO
     */
    startDate?: string;
}
/**
 * 
 * @export
 * @interface InventoryLegalInspectionTypeListDataResult
 */
export interface InventoryLegalInspectionTypeListDataResult {
    /**
     * 
     * @type {Array<InventoryLegalInspectionType>}
     * @memberof InventoryLegalInspectionTypeListDataResult
     */
    data?: Array<InventoryLegalInspectionType> | null;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryLegalInspectionTypeListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InventoryLegalInspectionTypeListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface InventoryMaintenanceDetailPhotos
 */
export interface InventoryMaintenanceDetailPhotos {
    /**
     * 
     * @type {number}
     * @memberof InventoryMaintenanceDetailPhotos
     */
    inventoryMaintenanceDetailID?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryMaintenanceDetailPhotos
     */
    photoPath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryMaintenanceDetailPhotos
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryMaintenanceDetailPhotos
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryMaintenanceDetailPhotos
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface InventoryMaintenanceType
 */
export interface InventoryMaintenanceType {
    /**
     * 
     * @type {number}
     * @memberof InventoryMaintenanceType
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryMaintenanceType
     */
    inventoryID?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryMaintenanceType
     */
    maintenanceTypeID?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryMaintenanceType
     */
    maintenancePeriodID?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryMaintenanceType
     */
    riskAnalysisIsRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryMaintenanceType
     */
    userInstructionsIsRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryMaintenanceType
     */
    userGudiePathIsRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryMaintenanceType
     */
    maintenanceContractIsRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryMaintenanceType
     */
    inventoryPhotoIdRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryMaintenanceType
     */
    controlFormPhotoIdRequired?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InventoryMaintenanceType
     */
    beforeDay?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryMaintenanceType
     */
    startDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryMaintenanceType
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryMaintenanceType
     */
    isMaintenanceFirmRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryMaintenanceType
     */
    isNegativeQuestionsBlocked?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InventoryMaintenanceType
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryMaintenanceType
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface LegalDocument
 */
export interface LegalDocument {
    /**
     * 
     * @type {string}
     * @memberof LegalDocument
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LegalDocument
     */
    extension?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LegalDocument
     */
    document?: string | null;
}
/**
 * 
 * @export
 * @interface LegalInspectionMaintenanceEndDateDTO
 */
export interface LegalInspectionMaintenanceEndDateDTO {
    /**
     * 
     * @type {number}
     * @memberof LegalInspectionMaintenanceEndDateDTO
     */
    legalInspectionID?: number;
    /**
     * 
     * @type {string}
     * @memberof LegalInspectionMaintenanceEndDateDTO
     */
    endDate?: string;
}
/**
 * 
 * @export
 * @interface MaintenanceQuestion
 */
export interface MaintenanceQuestion {
    /**
     * 
     * @type {number}
     * @memberof MaintenanceQuestion
     */
    orders?: number;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceQuestion
     */
    maintenanceQuestionTypeID?: number;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceQuestion
     */
    inventoryMaintenanceTypeID?: number;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceQuestion
     */
    question?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceQuestion
     */
    initialValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceQuestion
     */
    endValue?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceQuestion
     */
    trueFalseAnswer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceQuestion
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceQuestion
     */
    isNegativeAnswerBlockMaintenance?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceQuestion
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceQuestion
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface MaintenanceQuestionDTO
 */
export interface MaintenanceQuestionDTO {
    /**
     * 
     * @type {number}
     * @memberof MaintenanceQuestionDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceQuestionDTO
     */
    orders?: number;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceQuestionDTO
     */
    inventoryMaintenanceTypeID?: number;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceQuestionDTO
     */
    maintenanceQuestionTypeID?: number;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceQuestionDTO
     */
    maintenanceQuestionTypeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceQuestionDTO
     */
    question?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceQuestionDTO
     */
    initialValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceQuestionDTO
     */
    endValue?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceQuestionDTO
     */
    trueFalseAnswer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceQuestionDTO
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceQuestionDTO
     */
    isNegativeAnswerBlockMaintenance?: boolean;
}
/**
 * 
 * @export
 * @interface MaintenanceQuestionDTOListDataResult
 */
export interface MaintenanceQuestionDTOListDataResult {
    /**
     * 
     * @type {Array<MaintenanceQuestionDTO>}
     * @memberof MaintenanceQuestionDTOListDataResult
     */
    data?: Array<MaintenanceQuestionDTO> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceQuestionDTOListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceQuestionDTOListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface MaintenanceQuestionType
 */
export interface MaintenanceQuestionType {
    /**
     * 
     * @type {string}
     * @memberof MaintenanceQuestionType
     */
    questionTypeName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceQuestionType
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceQuestionType
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface MaintenanceQuestionTypeListDataResult
 */
export interface MaintenanceQuestionTypeListDataResult {
    /**
     * 
     * @type {Array<MaintenanceQuestionType>}
     * @memberof MaintenanceQuestionTypeListDataResult
     */
    data?: Array<MaintenanceQuestionType> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceQuestionTypeListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceQuestionTypeListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface MaintenanceTimeLineDateRangeDto
 */
export interface MaintenanceTimeLineDateRangeDto {
    /**
     * 
     * @type {number}
     * @memberof MaintenanceTimeLineDateRangeDto
     */
    startMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceTimeLineDateRangeDto
     */
    endMonth?: number;
}
/**
 * 
 * @export
 * @interface MaintenanceType
 */
export interface MaintenanceType {
    /**
     * 
     * @type {string}
     * @memberof MaintenanceType
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceType
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceType
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceType
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface Model
 */
export interface Model {
    /**
     * 
     * @type {number}
     * @memberof Model
     */
    brandID?: number;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Model
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Model
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface Note
 */
export interface Note {
    /**
     * 
     * @type {number}
     * @memberof Note
     */
    projectID?: number;
    /**
     * 
     * @type {number}
     * @memberof Note
     */
    userID?: number;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    body?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Note
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    date?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Note
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    companyID?: number;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface Result
 */
export interface Result {
    /**
     * 
     * @type {boolean}
     * @memberof Result
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface Room
 */
export interface Room {
    /**
     * 
     * @type {number}
     * @memberof Room
     */
    floorID?: number;
    /**
     * 
     * @type {string}
     * @memberof Room
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Room
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Room
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Room
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface TimelineStatusCodeDTO
 */
export interface TimelineStatusCodeDTO {
    /**
     * 
     * @type {number}
     * @memberof TimelineStatusCodeDTO
     */
    statusCode?: number;
    /**
     * 
     * @type {string}
     * @memberof TimelineStatusCodeDTO
     */
    statusColor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TimelineStatusCodeDTO
     */
    statusDesc?: string | null;
}
/**
 * 
 * @export
 * @interface TimelineStatusCodeDTOListDataResult
 */
export interface TimelineStatusCodeDTOListDataResult {
    /**
     * 
     * @type {Array<TimelineStatusCodeDTO>}
     * @memberof TimelineStatusCodeDTOListDataResult
     */
    data?: Array<TimelineStatusCodeDTO> | null;
    /**
     * 
     * @type {boolean}
     * @memberof TimelineStatusCodeDTOListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TimelineStatusCodeDTOListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface UploadDemandDocumentDTO
 */
export interface UploadDemandDocumentDTO {
    /**
     * 
     * @type {number}
     * @memberof UploadDemandDocumentDTO
     */
    demandDocumentID?: number;
    /**
     * 
     * @type {number}
     * @memberof UploadDemandDocumentDTO
     */
    completedUserID?: number;
    /**
     * 
     * @type {string}
     * @memberof UploadDemandDocumentDTO
     */
    document?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadDemandDocumentDTO
     */
    extension?: string | null;
}
/**
 * 
 * @export
 * @interface UserProject
 */
export interface UserProject {
    /**
     * 
     * @type {number}
     * @memberof UserProject
     */
    userID?: number;
    /**
     * 
     * @type {number}
     * @memberof UserProject
     */
    projectID?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserProject
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserProject
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserProject
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface UserProjectDTO
 */
export interface UserProjectDTO {
    /**
     * 
     * @type {number}
     * @memberof UserProjectDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UserProjectDTO
     */
    companyID?: number;
    /**
     * 
     * @type {string}
     * @memberof UserProjectDTO
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectDTO
     */
    companyLogo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectDTO
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserProjectDTO
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProjectDTO
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface UserProjectDTOListDataResult
 */
export interface UserProjectDTOListDataResult {
    /**
     * 
     * @type {Array<UserProjectDTO>}
     * @memberof UserProjectDTOListDataResult
     */
    data?: Array<UserProjectDTO> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserProjectDTOListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProjectDTOListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface UserType
 */
export interface UserType {
    /**
     * 
     * @type {string}
     * @memberof UserType
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserType
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserType
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserType
     */
    createDate?: string;
}
/**
 * 
 * @export
 * @interface UserTypeAddDTO
 */
export interface UserTypeAddDTO {
    /**
     * 
     * @type {string}
     * @memberof UserTypeAddDTO
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface UserTypeDTO
 */
export interface UserTypeDTO {
    /**
     * 
     * @type {number}
     * @memberof UserTypeDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserTypeDTO
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface UserTypeDTOListDataResult
 */
export interface UserTypeDTOListDataResult {
    /**
     * 
     * @type {Array<UserTypeDTO>}
     * @memberof UserTypeDTOListDataResult
     */
    data?: Array<UserTypeDTO> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserTypeDTOListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserTypeDTOListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface UserTypeListDataResult
 */
export interface UserTypeListDataResult {
    /**
     * 
     * @type {Array<UserType>}
     * @memberof UserTypeListDataResult
     */
    data?: Array<UserType> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserTypeListDataResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserTypeListDataResult
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface UserTypeUpdateDTO
 */
export interface UserTypeUpdateDTO {
    /**
     * 
     * @type {number}
     * @memberof UserTypeUpdateDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserTypeUpdateDTO
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserTypeUpdateDTO
     */
    isActive?: boolean;
}
/**
 * 
 * @export
 * @interface UserUpdateDTO
 */
export interface UserUpdateDTO {
    /**
     * 
     * @type {number}
     * @memberof UserUpdateDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UserUpdateDTO
     */
    userRoleID?: number;
    /**
     * 
     * @type {number}
     * @memberof UserUpdateDTO
     */
    companyID?: number;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDTO
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDTO
     */
    username?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDTO
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDTO
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDTO
     */
    passwordSalt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDTO
     */
    passwordHash?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateDTO
     */
    isNotificationEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateDTO
     */
    isEmailEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDTO
     */
    notificationToken?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateDTO
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserUpdateDTO
     */
    userTypes?: Array<number> | null;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [username] 
         * @param {string} [pass] 
         * @param {string} [deviceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthLoginGet: async (username?: string, pass?: string, deviceToken?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (pass !== undefined) {
                localVarQueryParameter['pass'] = pass;
            }

            if (deviceToken !== undefined) {
                localVarQueryParameter['deviceToken'] = deviceToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [username] 
         * @param {string} [pass] 
         * @param {string} [deviceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthLoginGet(username?: string, pass?: string, deviceToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthLoginGet(username, pass, deviceToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [username] 
         * @param {string} [pass] 
         * @param {string} [deviceToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthLoginGet(username?: string, pass?: string, deviceToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthLoginGet(username, pass, deviceToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {string} [username] 
     * @param {string} [pass] 
     * @param {string} [deviceToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthLoginGet(username?: string, pass?: string, deviceToken?: string, options?: any) {
        return AuthApiFp(this.configuration).apiAuthLoginGet(username, pass, deviceToken, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BuildApi - axios parameter creator
 * @export
 */
export const BuildApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Bina ekler
         * @param {Build} [build] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBuildAddBuildPost: async (build?: Build, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Build/AddBuild`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(build, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Id ye göre binanın bilgilerini getirir.
         * @param {number} [buildId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBuildGetBuildByIdGet: async (buildId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Build/GetBuildById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (buildId !== undefined) {
                localVarQueryParameter['buildId'] = buildId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Campüse ait bütün binaları listeler
         * @param {number} [campusId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBuildGetBuildListByCampusIDGet: async (campusId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Build/GetBuildListByCampusID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (campusId !== undefined) {
                localVarQueryParameter['campusId'] = campusId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bina günceller
         * @param {Build} [build] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBuildUpdateBuildPost: async (build?: Build, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Build/UpdateBuild`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(build, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuildApi - functional programming interface
 * @export
 */
export const BuildApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BuildApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Bina ekler
         * @param {Build} [build] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBuildAddBuildPost(build?: Build, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBuildAddBuildPost(build, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Id ye göre binanın bilgilerini getirir.
         * @param {number} [buildId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBuildGetBuildByIdGet(buildId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBuildGetBuildByIdGet(buildId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Campüse ait bütün binaları listeler
         * @param {number} [campusId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBuildGetBuildListByCampusIDGet(campusId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBuildGetBuildListByCampusIDGet(campusId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Bina günceller
         * @param {Build} [build] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBuildUpdateBuildPost(build?: Build, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBuildUpdateBuildPost(build, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BuildApi - factory interface
 * @export
 */
export const BuildApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BuildApiFp(configuration)
    return {
        /**
         * 
         * @summary Bina ekler
         * @param {Build} [build] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBuildAddBuildPost(build?: Build, options?: any): AxiosPromise<void> {
            return localVarFp.apiBuildAddBuildPost(build, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Id ye göre binanın bilgilerini getirir.
         * @param {number} [buildId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBuildGetBuildByIdGet(buildId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiBuildGetBuildByIdGet(buildId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Campüse ait bütün binaları listeler
         * @param {number} [campusId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBuildGetBuildListByCampusIDGet(campusId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiBuildGetBuildListByCampusIDGet(campusId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Bina günceller
         * @param {Build} [build] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBuildUpdateBuildPost(build?: Build, options?: any): AxiosPromise<void> {
            return localVarFp.apiBuildUpdateBuildPost(build, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BuildApi - object-oriented interface
 * @export
 * @class BuildApi
 * @extends {BaseAPI}
 */
export class BuildApi extends BaseAPI {
    /**
     * 
     * @summary Bina ekler
     * @param {Build} [build] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildApi
     */
    public apiBuildAddBuildPost(build?: Build, options?: any) {
        return BuildApiFp(this.configuration).apiBuildAddBuildPost(build, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Id ye göre binanın bilgilerini getirir.
     * @param {number} [buildId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildApi
     */
    public apiBuildGetBuildByIdGet(buildId?: number, options?: any) {
        return BuildApiFp(this.configuration).apiBuildGetBuildByIdGet(buildId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Campüse ait bütün binaları listeler
     * @param {number} [campusId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildApi
     */
    public apiBuildGetBuildListByCampusIDGet(campusId?: number, options?: any) {
        return BuildApiFp(this.configuration).apiBuildGetBuildListByCampusIDGet(campusId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Bina günceller
     * @param {Build} [build] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildApi
     */
    public apiBuildUpdateBuildPost(build?: Build, options?: any) {
        return BuildApiFp(this.configuration).apiBuildUpdateBuildPost(build, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CampusApi - axios parameter creator
 * @export
 */
export const CampusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Kampüs ekler
         * @param {Campus} [campus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampusAddCampusPost: async (campus?: Campus, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Campus/AddCampus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Id ye göre kampüsün bilgilerini getirir
         * @param {number} [campusId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampusGetCampusByIdGet: async (campusId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Campus/GetCampusById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (campusId !== undefined) {
                localVarQueryParameter['campusId'] = campusId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Projeye ait bütün kampüsleri listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampusGetCampusListByProjectIDGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Campus/GetCampusListByProjectID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kampüs günceller
         * @param {Campus} [campus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampusUpdateCampusPost: async (campus?: Campus, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Campus/UpdateCampus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampusApi - functional programming interface
 * @export
 */
export const CampusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Kampüs ekler
         * @param {Campus} [campus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCampusAddCampusPost(campus?: Campus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCampusAddCampusPost(campus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Id ye göre kampüsün bilgilerini getirir
         * @param {number} [campusId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCampusGetCampusByIdGet(campusId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCampusGetCampusByIdGet(campusId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Projeye ait bütün kampüsleri listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCampusGetCampusListByProjectIDGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCampusGetCampusListByProjectIDGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kampüs günceller
         * @param {Campus} [campus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCampusUpdateCampusPost(campus?: Campus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCampusUpdateCampusPost(campus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampusApi - factory interface
 * @export
 */
export const CampusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampusApiFp(configuration)
    return {
        /**
         * 
         * @summary Kampüs ekler
         * @param {Campus} [campus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampusAddCampusPost(campus?: Campus, options?: any): AxiosPromise<void> {
            return localVarFp.apiCampusAddCampusPost(campus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Id ye göre kampüsün bilgilerini getirir
         * @param {number} [campusId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampusGetCampusByIdGet(campusId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCampusGetCampusByIdGet(campusId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Projeye ait bütün kampüsleri listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampusGetCampusListByProjectIDGet(projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCampusGetCampusListByProjectIDGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kampüs günceller
         * @param {Campus} [campus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCampusUpdateCampusPost(campus?: Campus, options?: any): AxiosPromise<void> {
            return localVarFp.apiCampusUpdateCampusPost(campus, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampusApi - object-oriented interface
 * @export
 * @class CampusApi
 * @extends {BaseAPI}
 */
export class CampusApi extends BaseAPI {
    /**
     * 
     * @summary Kampüs ekler
     * @param {Campus} [campus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampusApi
     */
    public apiCampusAddCampusPost(campus?: Campus, options?: any) {
        return CampusApiFp(this.configuration).apiCampusAddCampusPost(campus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Id ye göre kampüsün bilgilerini getirir
     * @param {number} [campusId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampusApi
     */
    public apiCampusGetCampusByIdGet(campusId?: number, options?: any) {
        return CampusApiFp(this.configuration).apiCampusGetCampusByIdGet(campusId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Projeye ait bütün kampüsleri listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampusApi
     */
    public apiCampusGetCampusListByProjectIDGet(projectId?: number, options?: any) {
        return CampusApiFp(this.configuration).apiCampusGetCampusListByProjectIDGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kampüs günceller
     * @param {Campus} [campus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampusApi
     */
    public apiCampusUpdateCampusPost(campus?: Campus, options?: any) {
        return CampusApiFp(this.configuration).apiCampusUpdateCampusPost(campus, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Şirket Ekler, Id alanı 0 gönderilebilir.
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {any} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyAddCompanyPost: async (id?: number, name?: string, photo?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Company/AddCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (photo !== undefined) { 
                localVarFormParams.append('Photo', photo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Id ye göre şirketi getirir
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyGetCompanyByIdGet: async (companyId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Company/GetCompanyById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bütün şirketleri listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyGetCompanyListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Company/GetCompanyList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Şirket günceller, IsActive parametresi zorunlu olarak gönderilmelidir.
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {any} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyUpdateCompanyPost: async (id?: number, name?: string, photo?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Company/UpdateCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (photo !== undefined) { 
                localVarFormParams.append('Photo', photo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Şirket Ekler, Id alanı 0 gönderilebilir.
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {any} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyAddCompanyPost(id?: number, name?: string, photo?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyAddCompanyPost(id, name, photo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Id ye göre şirketi getirir
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyGetCompanyByIdGet(companyId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyGetCompanyByIdGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Bütün şirketleri listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyGetCompanyListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyGetCompanyListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Şirket günceller, IsActive parametresi zorunlu olarak gönderilmelidir.
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {any} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyUpdateCompanyPost(id?: number, name?: string, photo?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyUpdateCompanyPost(id, name, photo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @summary Şirket Ekler, Id alanı 0 gönderilebilir.
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {any} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyAddCompanyPost(id?: number, name?: string, photo?: any, options?: any): AxiosPromise<void> {
            return localVarFp.apiCompanyAddCompanyPost(id, name, photo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Id ye göre şirketi getirir
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyGetCompanyByIdGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCompanyGetCompanyByIdGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Bütün şirketleri listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyGetCompanyListGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCompanyGetCompanyListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Şirket günceller, IsActive parametresi zorunlu olarak gönderilmelidir.
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {any} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyUpdateCompanyPost(id?: number, name?: string, photo?: any, options?: any): AxiosPromise<void> {
            return localVarFp.apiCompanyUpdateCompanyPost(id, name, photo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @summary Şirket Ekler, Id alanı 0 gönderilebilir.
     * @param {number} [id] 
     * @param {string} [name] 
     * @param {any} [photo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public apiCompanyAddCompanyPost(id?: number, name?: string, photo?: any, options?: any) {
        return CompanyApiFp(this.configuration).apiCompanyAddCompanyPost(id, name, photo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Id ye göre şirketi getirir
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public apiCompanyGetCompanyByIdGet(companyId?: number, options?: any) {
        return CompanyApiFp(this.configuration).apiCompanyGetCompanyByIdGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Bütün şirketleri listeler
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public apiCompanyGetCompanyListGet(options?: any) {
        return CompanyApiFp(this.configuration).apiCompanyGetCompanyListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Şirket günceller, IsActive parametresi zorunlu olarak gönderilmelidir.
     * @param {number} [id] 
     * @param {string} [name] 
     * @param {any} [photo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public apiCompanyUpdateCompanyPost(id?: number, name?: string, photo?: any, options?: any) {
        return CompanyApiFp(this.configuration).apiCompanyUpdateCompanyPost(id, name, photo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ControlTaskApi - axios parameter creator
 * @export
 */
export const ControlTaskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary denetim için; denetim ekler
         * @param {Control} [control] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskAddControlPost: async (control?: Control, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlTask/AddControl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(control, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary denetim için; kontrol soru grup u ekler
         * @param {ControlQuestionGroup} [controlQuestionGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskAddControlQuestionGroupPost: async (controlQuestionGroup?: ControlQuestionGroup, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlTask/AddControlQuestionGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlQuestionGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary denetim için; kontrol sorusu ekler
         * @param {ControlQuestion} [controlQuestion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskAddControlQuestionPost: async (controlQuestion?: ControlQuestion, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlTask/AddControlQuestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlQuestion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary denetim için; kontrole görev tanımlar , periodid 9 ise StartDate ve Beforedate yollamana gerek yok
         * @param {ControlTask} [controlTask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskAddControlTaskPost: async (controlTask?: ControlTask, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlTask/AddControlTask`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlTask, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gerçekleştirilen denetim işlemini geri alır.
         * @param {CancelControlMaintenanceDTO} [cancelControlMaintenanceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskCancelControlMaintenancePost: async (cancelControlMaintenanceDTO?: CancelControlMaintenanceDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlTask/CancelControlMaintenance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelControlMaintenanceDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary denetim için; kontrol sorularını listeler, grubuna göre
         * @param {number} [controlQuestionGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskControlQuestionListGet: async (controlQuestionGroupId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlTask/ControlQuestionList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (controlQuestionGroupId !== undefined) {
                localVarQueryParameter['controlQuestionGroupId'] = controlQuestionGroupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary denetim için; denetim işlemini gerçekleştirir
         * @param {ControlTaskMaintenanceAddDTO} [controlTaskMaintenanceAddDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskDoControlMaintenancePost: async (controlTaskMaintenanceAddDTO?: ControlTaskMaintenanceAddDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlTask/DoControlMaintenance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlTaskMaintenanceAddDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary denetim için; görevleri generate eder, web kullanmayacak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskGenerateControlTaskGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlTask/GenerateControlTask`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary denetimleri ve o denetime ait bakım tiplerini listerler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskGetControlAndTypeListDGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlTask/GetControlAndTypeListD`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary denetim için;denetimleri listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskGetControlListGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlTask/GetControlList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary denetim için; kontrol soru gruplarını listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskGetControlQuestionGroupListGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlTask/GetControlQuestionGroupList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary denetim için; kontrol soru tiplerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskGetControlQuestionTypeListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlTask/GetControlQuestionTypeList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary denetim için; raporlar
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskGetControlReportGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlTask/GetControlReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary denetim için; kontrole ait görevleri listeler
         * @param {number} [controlId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskGetControlTaskListGet: async (controlId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlTask/GetControlTaskList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (controlId !== undefined) {
                localVarQueryParameter['controlId'] = controlId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary denetim için; kontrol görev periodlarını listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskGetControlTaskPeriodListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlTask/GetControlTaskPeriodList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary denetim için;timeline
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskGetControlTimelineGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlTask/GetControlTimeline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary denetim için;timeline
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskGetControlTimelineStatusDescGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlTask/GetControlTimelineStatusDesc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskNotCompletedControlTaskForEmailGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlTask/NotCompletedControlTaskForEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary denetim için; denetim günceller
         * @param {Control} [control] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskUpdateControlPost: async (control?: Control, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlTask/UpdateControl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(control, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary denetim için; kontrol sorusunu günceller - soru ve durumu
         * @param {number} [questionId] 
         * @param {string} [question] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskUpdateControlQuestionGet: async (questionId?: number, question?: string, isActive?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlTask/UpdateControlQuestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (questionId !== undefined) {
                localVarQueryParameter['questionId'] = questionId;
            }

            if (question !== undefined) {
                localVarQueryParameter['question'] = question;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary denetim için; kontrol soru grup u günceller - adı ve durumu
         * @param {number} [questionGroupId] 
         * @param {string} [name] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskUpdateControlQuestionGroupGet: async (questionGroupId?: number, name?: string, isActive?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlTask/UpdateControlQuestionGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (questionGroupId !== undefined) {
                localVarQueryParameter['questionGroupId'] = questionGroupId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary denetim için; kontrole ait görevi günceller
         * @param {ControlTask} [controlTask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskUpdateControlTaskPost: async (controlTask?: ControlTask, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ControlTask/UpdateControlTask`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlTask, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ControlTaskApi - functional programming interface
 * @export
 */
export const ControlTaskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ControlTaskApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary denetim için; denetim ekler
         * @param {Control} [control] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlTaskAddControlPost(control?: Control, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlTaskAddControlPost(control, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary denetim için; kontrol soru grup u ekler
         * @param {ControlQuestionGroup} [controlQuestionGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlTaskAddControlQuestionGroupPost(controlQuestionGroup?: ControlQuestionGroup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlTaskAddControlQuestionGroupPost(controlQuestionGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary denetim için; kontrol sorusu ekler
         * @param {ControlQuestion} [controlQuestion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlTaskAddControlQuestionPost(controlQuestion?: ControlQuestion, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlTaskAddControlQuestionPost(controlQuestion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary denetim için; kontrole görev tanımlar , periodid 9 ise StartDate ve Beforedate yollamana gerek yok
         * @param {ControlTask} [controlTask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlTaskAddControlTaskPost(controlTask?: ControlTask, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlTaskAddControlTaskPost(controlTask, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gerçekleştirilen denetim işlemini geri alır.
         * @param {CancelControlMaintenanceDTO} [cancelControlMaintenanceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlTaskCancelControlMaintenancePost(cancelControlMaintenanceDTO?: CancelControlMaintenanceDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlTaskCancelControlMaintenancePost(cancelControlMaintenanceDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary denetim için; kontrol sorularını listeler, grubuna göre
         * @param {number} [controlQuestionGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlTaskControlQuestionListGet(controlQuestionGroupId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlQuestionListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlTaskControlQuestionListGet(controlQuestionGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary denetim için; denetim işlemini gerçekleştirir
         * @param {ControlTaskMaintenanceAddDTO} [controlTaskMaintenanceAddDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlTaskDoControlMaintenancePost(controlTaskMaintenanceAddDTO?: ControlTaskMaintenanceAddDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlTaskDoControlMaintenancePost(controlTaskMaintenanceAddDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary denetim için; görevleri generate eder, web kullanmayacak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlTaskGenerateControlTaskGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlTaskGenerateControlTaskGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary denetimleri ve o denetime ait bakım tiplerini listerler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlTaskGetControlAndTypeListDGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlAndTypeListDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlTaskGetControlAndTypeListDGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary denetim için;denetimleri listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlTaskGetControlListGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlTaskGetControlListGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary denetim için; kontrol soru gruplarını listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlTaskGetControlQuestionGroupListGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlQuestionGroupListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlTaskGetControlQuestionGroupListGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary denetim için; kontrol soru tiplerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlTaskGetControlQuestionTypeListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlQuestionTypeListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlTaskGetControlQuestionTypeListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary denetim için; raporlar
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlTaskGetControlReportGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReportDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlTaskGetControlReportGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary denetim için; kontrole ait görevleri listeler
         * @param {number} [controlId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlTaskGetControlTaskListGet(controlId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlTaskDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlTaskGetControlTaskListGet(controlId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary denetim için; kontrol görev periodlarını listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlTaskGetControlTaskPeriodListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlPeriodListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlTaskGetControlTaskPeriodListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary denetim için;timeline
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlTaskGetControlTimelineGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlTimelineDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlTaskGetControlTimelineGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary denetim için;timeline
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlTaskGetControlTimelineStatusDescGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimelineStatusCodeDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlTaskGetControlTimelineStatusDescGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlTaskNotCompletedControlTaskForEmailGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlTaskNotCompletedControlTaskForEmailGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary denetim için; denetim günceller
         * @param {Control} [control] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlTaskUpdateControlPost(control?: Control, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlTaskUpdateControlPost(control, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary denetim için; kontrol sorusunu günceller - soru ve durumu
         * @param {number} [questionId] 
         * @param {string} [question] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlTaskUpdateControlQuestionGet(questionId?: number, question?: string, isActive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlTaskUpdateControlQuestionGet(questionId, question, isActive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary denetim için; kontrol soru grup u günceller - adı ve durumu
         * @param {number} [questionGroupId] 
         * @param {string} [name] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlTaskUpdateControlQuestionGroupGet(questionGroupId?: number, name?: string, isActive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlTaskUpdateControlQuestionGroupGet(questionGroupId, name, isActive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary denetim için; kontrole ait görevi günceller
         * @param {ControlTask} [controlTask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiControlTaskUpdateControlTaskPost(controlTask?: ControlTask, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiControlTaskUpdateControlTaskPost(controlTask, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ControlTaskApi - factory interface
 * @export
 */
export const ControlTaskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ControlTaskApiFp(configuration)
    return {
        /**
         * 
         * @summary denetim için; denetim ekler
         * @param {Control} [control] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskAddControlPost(control?: Control, options?: any): AxiosPromise<Result> {
            return localVarFp.apiControlTaskAddControlPost(control, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary denetim için; kontrol soru grup u ekler
         * @param {ControlQuestionGroup} [controlQuestionGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskAddControlQuestionGroupPost(controlQuestionGroup?: ControlQuestionGroup, options?: any): AxiosPromise<Result> {
            return localVarFp.apiControlTaskAddControlQuestionGroupPost(controlQuestionGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary denetim için; kontrol sorusu ekler
         * @param {ControlQuestion} [controlQuestion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskAddControlQuestionPost(controlQuestion?: ControlQuestion, options?: any): AxiosPromise<Result> {
            return localVarFp.apiControlTaskAddControlQuestionPost(controlQuestion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary denetim için; kontrole görev tanımlar , periodid 9 ise StartDate ve Beforedate yollamana gerek yok
         * @param {ControlTask} [controlTask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskAddControlTaskPost(controlTask?: ControlTask, options?: any): AxiosPromise<Result> {
            return localVarFp.apiControlTaskAddControlTaskPost(controlTask, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gerçekleştirilen denetim işlemini geri alır.
         * @param {CancelControlMaintenanceDTO} [cancelControlMaintenanceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskCancelControlMaintenancePost(cancelControlMaintenanceDTO?: CancelControlMaintenanceDTO, options?: any): AxiosPromise<Result> {
            return localVarFp.apiControlTaskCancelControlMaintenancePost(cancelControlMaintenanceDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary denetim için; kontrol sorularını listeler, grubuna göre
         * @param {number} [controlQuestionGroupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskControlQuestionListGet(controlQuestionGroupId?: number, options?: any): AxiosPromise<ControlQuestionListDataResult> {
            return localVarFp.apiControlTaskControlQuestionListGet(controlQuestionGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary denetim için; denetim işlemini gerçekleştirir
         * @param {ControlTaskMaintenanceAddDTO} [controlTaskMaintenanceAddDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskDoControlMaintenancePost(controlTaskMaintenanceAddDTO?: ControlTaskMaintenanceAddDTO, options?: any): AxiosPromise<Result> {
            return localVarFp.apiControlTaskDoControlMaintenancePost(controlTaskMaintenanceAddDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary denetim için; görevleri generate eder, web kullanmayacak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskGenerateControlTaskGet(options?: any): AxiosPromise<Result> {
            return localVarFp.apiControlTaskGenerateControlTaskGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary denetimleri ve o denetime ait bakım tiplerini listerler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskGetControlAndTypeListDGet(projectId?: number, options?: any): AxiosPromise<ControlAndTypeListDTOListDataResult> {
            return localVarFp.apiControlTaskGetControlAndTypeListDGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary denetim için;denetimleri listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskGetControlListGet(projectId?: number, options?: any): AxiosPromise<ControlDTOListDataResult> {
            return localVarFp.apiControlTaskGetControlListGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary denetim için; kontrol soru gruplarını listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskGetControlQuestionGroupListGet(projectId?: number, options?: any): AxiosPromise<ControlQuestionGroupListDataResult> {
            return localVarFp.apiControlTaskGetControlQuestionGroupListGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary denetim için; kontrol soru tiplerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskGetControlQuestionTypeListGet(options?: any): AxiosPromise<ControlQuestionTypeListDataResult> {
            return localVarFp.apiControlTaskGetControlQuestionTypeListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary denetim için; raporlar
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskGetControlReportGet(projectId?: number, options?: any): AxiosPromise<ControlReportDTOListDataResult> {
            return localVarFp.apiControlTaskGetControlReportGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary denetim için; kontrole ait görevleri listeler
         * @param {number} [controlId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskGetControlTaskListGet(controlId?: number, options?: any): AxiosPromise<ControlTaskDTOListDataResult> {
            return localVarFp.apiControlTaskGetControlTaskListGet(controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary denetim için; kontrol görev periodlarını listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskGetControlTaskPeriodListGet(options?: any): AxiosPromise<ControlPeriodListDataResult> {
            return localVarFp.apiControlTaskGetControlTaskPeriodListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary denetim için;timeline
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskGetControlTimelineGet(projectId?: number, options?: any): AxiosPromise<ControlTimelineDTOListDataResult> {
            return localVarFp.apiControlTaskGetControlTimelineGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary denetim için;timeline
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskGetControlTimelineStatusDescGet(options?: any): AxiosPromise<TimelineStatusCodeDTOListDataResult> {
            return localVarFp.apiControlTaskGetControlTimelineStatusDescGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskNotCompletedControlTaskForEmailGet(options?: any): AxiosPromise<Result> {
            return localVarFp.apiControlTaskNotCompletedControlTaskForEmailGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary denetim için; denetim günceller
         * @param {Control} [control] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskUpdateControlPost(control?: Control, options?: any): AxiosPromise<Result> {
            return localVarFp.apiControlTaskUpdateControlPost(control, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary denetim için; kontrol sorusunu günceller - soru ve durumu
         * @param {number} [questionId] 
         * @param {string} [question] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskUpdateControlQuestionGet(questionId?: number, question?: string, isActive?: boolean, options?: any): AxiosPromise<Result> {
            return localVarFp.apiControlTaskUpdateControlQuestionGet(questionId, question, isActive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary denetim için; kontrol soru grup u günceller - adı ve durumu
         * @param {number} [questionGroupId] 
         * @param {string} [name] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskUpdateControlQuestionGroupGet(questionGroupId?: number, name?: string, isActive?: boolean, options?: any): AxiosPromise<Result> {
            return localVarFp.apiControlTaskUpdateControlQuestionGroupGet(questionGroupId, name, isActive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary denetim için; kontrole ait görevi günceller
         * @param {ControlTask} [controlTask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiControlTaskUpdateControlTaskPost(controlTask?: ControlTask, options?: any): AxiosPromise<Result> {
            return localVarFp.apiControlTaskUpdateControlTaskPost(controlTask, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ControlTaskApi - object-oriented interface
 * @export
 * @class ControlTaskApi
 * @extends {BaseAPI}
 */
export class ControlTaskApi extends BaseAPI {
    /**
     * 
     * @summary denetim için; denetim ekler
     * @param {Control} [control] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlTaskApi
     */
    public apiControlTaskAddControlPost(control?: Control, options?: any) {
        return ControlTaskApiFp(this.configuration).apiControlTaskAddControlPost(control, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary denetim için; kontrol soru grup u ekler
     * @param {ControlQuestionGroup} [controlQuestionGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlTaskApi
     */
    public apiControlTaskAddControlQuestionGroupPost(controlQuestionGroup?: ControlQuestionGroup, options?: any) {
        return ControlTaskApiFp(this.configuration).apiControlTaskAddControlQuestionGroupPost(controlQuestionGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary denetim için; kontrol sorusu ekler
     * @param {ControlQuestion} [controlQuestion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlTaskApi
     */
    public apiControlTaskAddControlQuestionPost(controlQuestion?: ControlQuestion, options?: any) {
        return ControlTaskApiFp(this.configuration).apiControlTaskAddControlQuestionPost(controlQuestion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary denetim için; kontrole görev tanımlar , periodid 9 ise StartDate ve Beforedate yollamana gerek yok
     * @param {ControlTask} [controlTask] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlTaskApi
     */
    public apiControlTaskAddControlTaskPost(controlTask?: ControlTask, options?: any) {
        return ControlTaskApiFp(this.configuration).apiControlTaskAddControlTaskPost(controlTask, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gerçekleştirilen denetim işlemini geri alır.
     * @param {CancelControlMaintenanceDTO} [cancelControlMaintenanceDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlTaskApi
     */
    public apiControlTaskCancelControlMaintenancePost(cancelControlMaintenanceDTO?: CancelControlMaintenanceDTO, options?: any) {
        return ControlTaskApiFp(this.configuration).apiControlTaskCancelControlMaintenancePost(cancelControlMaintenanceDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary denetim için; kontrol sorularını listeler, grubuna göre
     * @param {number} [controlQuestionGroupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlTaskApi
     */
    public apiControlTaskControlQuestionListGet(controlQuestionGroupId?: number, options?: any) {
        return ControlTaskApiFp(this.configuration).apiControlTaskControlQuestionListGet(controlQuestionGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary denetim için; denetim işlemini gerçekleştirir
     * @param {ControlTaskMaintenanceAddDTO} [controlTaskMaintenanceAddDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlTaskApi
     */
    public apiControlTaskDoControlMaintenancePost(controlTaskMaintenanceAddDTO?: ControlTaskMaintenanceAddDTO, options?: any) {
        return ControlTaskApiFp(this.configuration).apiControlTaskDoControlMaintenancePost(controlTaskMaintenanceAddDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary denetim için; görevleri generate eder, web kullanmayacak
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlTaskApi
     */
    public apiControlTaskGenerateControlTaskGet(options?: any) {
        return ControlTaskApiFp(this.configuration).apiControlTaskGenerateControlTaskGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary denetimleri ve o denetime ait bakım tiplerini listerler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlTaskApi
     */
    public apiControlTaskGetControlAndTypeListDGet(projectId?: number, options?: any) {
        return ControlTaskApiFp(this.configuration).apiControlTaskGetControlAndTypeListDGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary denetim için;denetimleri listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlTaskApi
     */
    public apiControlTaskGetControlListGet(projectId?: number, options?: any) {
        return ControlTaskApiFp(this.configuration).apiControlTaskGetControlListGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary denetim için; kontrol soru gruplarını listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlTaskApi
     */
    public apiControlTaskGetControlQuestionGroupListGet(projectId?: number, options?: any) {
        return ControlTaskApiFp(this.configuration).apiControlTaskGetControlQuestionGroupListGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary denetim için; kontrol soru tiplerini listeler
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlTaskApi
     */
    public apiControlTaskGetControlQuestionTypeListGet(options?: any) {
        return ControlTaskApiFp(this.configuration).apiControlTaskGetControlQuestionTypeListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary denetim için; raporlar
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlTaskApi
     */
    public apiControlTaskGetControlReportGet(projectId?: number, options?: any) {
        return ControlTaskApiFp(this.configuration).apiControlTaskGetControlReportGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary denetim için; kontrole ait görevleri listeler
     * @param {number} [controlId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlTaskApi
     */
    public apiControlTaskGetControlTaskListGet(controlId?: number, options?: any) {
        return ControlTaskApiFp(this.configuration).apiControlTaskGetControlTaskListGet(controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary denetim için; kontrol görev periodlarını listeler
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlTaskApi
     */
    public apiControlTaskGetControlTaskPeriodListGet(options?: any) {
        return ControlTaskApiFp(this.configuration).apiControlTaskGetControlTaskPeriodListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary denetim için;timeline
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlTaskApi
     */
    public apiControlTaskGetControlTimelineGet(projectId?: number, options?: any) {
        return ControlTaskApiFp(this.configuration).apiControlTaskGetControlTimelineGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary denetim için;timeline
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlTaskApi
     */
    public apiControlTaskGetControlTimelineStatusDescGet(options?: any) {
        return ControlTaskApiFp(this.configuration).apiControlTaskGetControlTimelineStatusDescGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlTaskApi
     */
    public apiControlTaskNotCompletedControlTaskForEmailGet(options?: any) {
        return ControlTaskApiFp(this.configuration).apiControlTaskNotCompletedControlTaskForEmailGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary denetim için; denetim günceller
     * @param {Control} [control] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlTaskApi
     */
    public apiControlTaskUpdateControlPost(control?: Control, options?: any) {
        return ControlTaskApiFp(this.configuration).apiControlTaskUpdateControlPost(control, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary denetim için; kontrol sorusunu günceller - soru ve durumu
     * @param {number} [questionId] 
     * @param {string} [question] 
     * @param {boolean} [isActive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlTaskApi
     */
    public apiControlTaskUpdateControlQuestionGet(questionId?: number, question?: string, isActive?: boolean, options?: any) {
        return ControlTaskApiFp(this.configuration).apiControlTaskUpdateControlQuestionGet(questionId, question, isActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary denetim için; kontrol soru grup u günceller - adı ve durumu
     * @param {number} [questionGroupId] 
     * @param {string} [name] 
     * @param {boolean} [isActive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlTaskApi
     */
    public apiControlTaskUpdateControlQuestionGroupGet(questionGroupId?: number, name?: string, isActive?: boolean, options?: any) {
        return ControlTaskApiFp(this.configuration).apiControlTaskUpdateControlQuestionGroupGet(questionGroupId, name, isActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary denetim için; kontrole ait görevi günceller
     * @param {ControlTask} [controlTask] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlTaskApi
     */
    public apiControlTaskUpdateControlTaskPost(controlTask?: ControlTask, options?: any) {
        return ControlTaskApiFp(this.configuration).apiControlTaskUpdateControlTaskPost(controlTask, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CounterApi - axios parameter creator
 * @export
 */
export const CounterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary sayaça bakım ekler
         * @param {CounterMaintenanceTypeAddDTO} [counterMaintenanceTypeAddDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterAddCounterMaintenanceTypePost: async (counterMaintenanceTypeAddDTO?: CounterMaintenanceTypeAddDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Counter/AddCounterMaintenanceType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(counterMaintenanceTypeAddDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sayaç ekler
         * @param {Counter} [counter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterAddCounterPost: async (counter?: Counter, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Counter/AddCounter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(counter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sayaç okuma işlemini iptal eder.
         * @param {CancelCounterMaintenanceDTO} [cancelCounterMaintenanceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterCancelCounterMaintenancePost: async (cancelCounterMaintenanceDTO?: CancelCounterMaintenanceDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Counter/CancelCounterMaintenance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelCounterMaintenanceDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary projedeki sayaçları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterCounterListByProjectIdGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Counter/CounterListByProjectId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sayaça bakılarını üretir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterCounterMaintenanceTypeGenerateGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Counter/CounterMaintenanceTypeGenerate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sayaç timeline
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterCounterTimelineGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Counter/CounterTimeline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sayaç timeline durumlarını verir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterCounterTimelineStatusGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Counter/CounterTimelineStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sayaç okuma işlemi yapılır.
         * @param {DoCounterMaintenanceModel} [doCounterMaintenanceModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterDoCounterMaintenancePost: async (doCounterMaintenanceModel?: DoCounterMaintenanceModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Counter/DoCounterMaintenance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doCounterMaintenanceModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sayaç bakılarını listeler
         * @param {number} [counterId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterGetCounterMaintenanceTypeListGet: async (counterId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Counter/GetCounterMaintenanceTypeList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (counterId !== undefined) {
                localVarQueryParameter['counterId'] = counterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sayaç periyoflarını listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterGetCounterPeriodListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Counter/GetCounterPeriodList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sayaç bakım tiplerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterGetCounterTypeListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Counter/GetCounterTypeList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterNotCompletedCounterTaskDTOGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Counter/NotCompletedCounterTaskDTO`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CounterApi - functional programming interface
 * @export
 */
export const CounterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CounterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary sayaça bakım ekler
         * @param {CounterMaintenanceTypeAddDTO} [counterMaintenanceTypeAddDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCounterAddCounterMaintenanceTypePost(counterMaintenanceTypeAddDTO?: CounterMaintenanceTypeAddDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCounterAddCounterMaintenanceTypePost(counterMaintenanceTypeAddDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sayaç ekler
         * @param {Counter} [counter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCounterAddCounterPost(counter?: Counter, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCounterAddCounterPost(counter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sayaç okuma işlemini iptal eder.
         * @param {CancelCounterMaintenanceDTO} [cancelCounterMaintenanceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCounterCancelCounterMaintenancePost(cancelCounterMaintenanceDTO?: CancelCounterMaintenanceDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCounterCancelCounterMaintenancePost(cancelCounterMaintenanceDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary projedeki sayaçları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCounterCounterListByProjectIdGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCounterCounterListByProjectIdGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sayaça bakılarını üretir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCounterCounterMaintenanceTypeGenerateGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCounterCounterMaintenanceTypeGenerateGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sayaç timeline
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCounterCounterTimelineGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CounterTimeLineListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCounterCounterTimelineGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sayaç timeline durumlarını verir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCounterCounterTimelineStatusGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimelineStatusCodeDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCounterCounterTimelineStatusGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sayaç okuma işlemi yapılır.
         * @param {DoCounterMaintenanceModel} [doCounterMaintenanceModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCounterDoCounterMaintenancePost(doCounterMaintenanceModel?: DoCounterMaintenanceModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCounterDoCounterMaintenancePost(doCounterMaintenanceModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sayaç bakılarını listeler
         * @param {number} [counterId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCounterGetCounterMaintenanceTypeListGet(counterId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CounterMaintenanceTypeDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCounterGetCounterMaintenanceTypeListGet(counterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sayaç periyoflarını listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCounterGetCounterPeriodListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CounterPeriodListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCounterGetCounterPeriodListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sayaç bakım tiplerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCounterGetCounterTypeListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CounterTypeListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCounterGetCounterTypeListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCounterNotCompletedCounterTaskDTOGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCounterNotCompletedCounterTaskDTOGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CounterApi - factory interface
 * @export
 */
export const CounterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CounterApiFp(configuration)
    return {
        /**
         * 
         * @summary sayaça bakım ekler
         * @param {CounterMaintenanceTypeAddDTO} [counterMaintenanceTypeAddDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterAddCounterMaintenanceTypePost(counterMaintenanceTypeAddDTO?: CounterMaintenanceTypeAddDTO, options?: any): AxiosPromise<Result> {
            return localVarFp.apiCounterAddCounterMaintenanceTypePost(counterMaintenanceTypeAddDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sayaç ekler
         * @param {Counter} [counter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterAddCounterPost(counter?: Counter, options?: any): AxiosPromise<Result> {
            return localVarFp.apiCounterAddCounterPost(counter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sayaç okuma işlemini iptal eder.
         * @param {CancelCounterMaintenanceDTO} [cancelCounterMaintenanceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterCancelCounterMaintenancePost(cancelCounterMaintenanceDTO?: CancelCounterMaintenanceDTO, options?: any): AxiosPromise<Result> {
            return localVarFp.apiCounterCancelCounterMaintenancePost(cancelCounterMaintenanceDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary projedeki sayaçları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterCounterListByProjectIdGet(projectId?: number, options?: any): AxiosPromise<Result> {
            return localVarFp.apiCounterCounterListByProjectIdGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sayaça bakılarını üretir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterCounterMaintenanceTypeGenerateGet(options?: any): AxiosPromise<Result> {
            return localVarFp.apiCounterCounterMaintenanceTypeGenerateGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sayaç timeline
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterCounterTimelineGet(projectId?: number, options?: any): AxiosPromise<CounterTimeLineListDataResult> {
            return localVarFp.apiCounterCounterTimelineGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sayaç timeline durumlarını verir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterCounterTimelineStatusGet(options?: any): AxiosPromise<TimelineStatusCodeDTOListDataResult> {
            return localVarFp.apiCounterCounterTimelineStatusGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sayaç okuma işlemi yapılır.
         * @param {DoCounterMaintenanceModel} [doCounterMaintenanceModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterDoCounterMaintenancePost(doCounterMaintenanceModel?: DoCounterMaintenanceModel, options?: any): AxiosPromise<Result> {
            return localVarFp.apiCounterDoCounterMaintenancePost(doCounterMaintenanceModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sayaç bakılarını listeler
         * @param {number} [counterId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterGetCounterMaintenanceTypeListGet(counterId?: number, options?: any): AxiosPromise<CounterMaintenanceTypeDTOListDataResult> {
            return localVarFp.apiCounterGetCounterMaintenanceTypeListGet(counterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sayaç periyoflarını listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterGetCounterPeriodListGet(options?: any): AxiosPromise<CounterPeriodListDataResult> {
            return localVarFp.apiCounterGetCounterPeriodListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sayaç bakım tiplerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterGetCounterTypeListGet(options?: any): AxiosPromise<CounterTypeListDataResult> {
            return localVarFp.apiCounterGetCounterTypeListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCounterNotCompletedCounterTaskDTOGet(options?: any): AxiosPromise<Result> {
            return localVarFp.apiCounterNotCompletedCounterTaskDTOGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CounterApi - object-oriented interface
 * @export
 * @class CounterApi
 * @extends {BaseAPI}
 */
export class CounterApi extends BaseAPI {
    /**
     * 
     * @summary sayaça bakım ekler
     * @param {CounterMaintenanceTypeAddDTO} [counterMaintenanceTypeAddDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounterApi
     */
    public apiCounterAddCounterMaintenanceTypePost(counterMaintenanceTypeAddDTO?: CounterMaintenanceTypeAddDTO, options?: any) {
        return CounterApiFp(this.configuration).apiCounterAddCounterMaintenanceTypePost(counterMaintenanceTypeAddDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sayaç ekler
     * @param {Counter} [counter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounterApi
     */
    public apiCounterAddCounterPost(counter?: Counter, options?: any) {
        return CounterApiFp(this.configuration).apiCounterAddCounterPost(counter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sayaç okuma işlemini iptal eder.
     * @param {CancelCounterMaintenanceDTO} [cancelCounterMaintenanceDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounterApi
     */
    public apiCounterCancelCounterMaintenancePost(cancelCounterMaintenanceDTO?: CancelCounterMaintenanceDTO, options?: any) {
        return CounterApiFp(this.configuration).apiCounterCancelCounterMaintenancePost(cancelCounterMaintenanceDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary projedeki sayaçları listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounterApi
     */
    public apiCounterCounterListByProjectIdGet(projectId?: number, options?: any) {
        return CounterApiFp(this.configuration).apiCounterCounterListByProjectIdGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sayaça bakılarını üretir
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounterApi
     */
    public apiCounterCounterMaintenanceTypeGenerateGet(options?: any) {
        return CounterApiFp(this.configuration).apiCounterCounterMaintenanceTypeGenerateGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sayaç timeline
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounterApi
     */
    public apiCounterCounterTimelineGet(projectId?: number, options?: any) {
        return CounterApiFp(this.configuration).apiCounterCounterTimelineGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sayaç timeline durumlarını verir
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounterApi
     */
    public apiCounterCounterTimelineStatusGet(options?: any) {
        return CounterApiFp(this.configuration).apiCounterCounterTimelineStatusGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sayaç okuma işlemi yapılır.
     * @param {DoCounterMaintenanceModel} [doCounterMaintenanceModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounterApi
     */
    public apiCounterDoCounterMaintenancePost(doCounterMaintenanceModel?: DoCounterMaintenanceModel, options?: any) {
        return CounterApiFp(this.configuration).apiCounterDoCounterMaintenancePost(doCounterMaintenanceModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sayaç bakılarını listeler
     * @param {number} [counterId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounterApi
     */
    public apiCounterGetCounterMaintenanceTypeListGet(counterId?: number, options?: any) {
        return CounterApiFp(this.configuration).apiCounterGetCounterMaintenanceTypeListGet(counterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sayaç periyoflarını listeler
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounterApi
     */
    public apiCounterGetCounterPeriodListGet(options?: any) {
        return CounterApiFp(this.configuration).apiCounterGetCounterPeriodListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sayaç bakım tiplerini listeler
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounterApi
     */
    public apiCounterGetCounterTypeListGet(options?: any) {
        return CounterApiFp(this.configuration).apiCounterGetCounterTypeListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounterApi
     */
    public apiCounterNotCompletedCounterTaskDTOGet(options?: any) {
        return CounterApiFp(this.configuration).apiCounterNotCompletedCounterTaskDTOGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemandApi - axios parameter creator
 * @export
 */
export const DemandApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Talep e yeni bir belge ekler
         * @param {AddDemandDocumentDTO} [addDemandDocumentDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandAddDemandDocumentPost: async (addDemandDocumentDTO?: AddDemandDocumentDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Demand/AddDemandDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDemandDocumentDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Talep grubu ekler
         * @param {DemandGroup} [demandGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandAddDemandGroupPost: async (demandGroup?: DemandGroup, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Demand/AddDemandGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(demandGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Yeni talep ekler
         * @param {AddDemandDTO} [addDemandDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandAddDemandPost: async (addDemandDTO?: AddDemandDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Demand/AddDemand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDemandDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary talepleri ve taleplere ait dokumanları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandGetDemandAndDocumentListGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Demand/GetDemandAndDocumentList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Talep e ait belgeleri listeler
         * @param {number} [demandId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandGetDemandDocumentListGet: async (demandId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Demand/GetDemandDocumentList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (demandId !== undefined) {
                localVarQueryParameter['demandId'] = demandId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Talep gruplarını listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandGetDemandGroupListGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Demand/GetDemandGroupList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Talep listesini verir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandGetDemandListByProjectIDGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Demand/GetDemandListByProjectID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandGetDemandListPastLastDateForDailyEmailGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Demand/GetDemandListPastLastDateForDailyEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Son tarihi geçmiş belgeleri listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandGetDemandListPastLastDateGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Demand/GetDemandListPastLastDate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Talep raporlarını listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandGetDemandReportListGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Demand/GetDemandReportList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary talep timeline listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandGetDemandTimeLineGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Demand/GetDemandTimeLine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary belge günceller\"
         * @param {DemandDocument} [demandDocument] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandUpdateDemandDocumentPost: async (demandDocument?: DemandDocument, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Demand/UpdateDemandDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(demandDocument, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Talep grubu günceller
         * @param {DemandGroup} [demandGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandUpdateDemandGroupPost: async (demandGroup?: DemandGroup, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Demand/UpdateDemandGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(demandGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Talep günceller
         * @param {Demand} [demand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandUpdateDemandPost: async (demand?: Demand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Demand/UpdateDemand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(demand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary talep dokumanını yükler, Document yazan kısım base64 olacak
         * @param {UploadDemandDocumentDTO} [uploadDemandDocumentDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandUploadDemandDocumentPost: async (uploadDemandDocumentDTO?: UploadDemandDocumentDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Demand/UploadDemandDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadDemandDocumentDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemandApi - functional programming interface
 * @export
 */
export const DemandApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemandApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Talep e yeni bir belge ekler
         * @param {AddDemandDocumentDTO} [addDemandDocumentDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDemandAddDemandDocumentPost(addDemandDocumentDTO?: AddDemandDocumentDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDemandAddDemandDocumentPost(addDemandDocumentDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Talep grubu ekler
         * @param {DemandGroup} [demandGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDemandAddDemandGroupPost(demandGroup?: DemandGroup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDemandAddDemandGroupPost(demandGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Yeni talep ekler
         * @param {AddDemandDTO} [addDemandDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDemandAddDemandPost(addDemandDTO?: AddDemandDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDemandAddDemandPost(addDemandDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary talepleri ve taleplere ait dokumanları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDemandGetDemandAndDocumentListGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemandAndAllDocumentDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDemandGetDemandAndDocumentListGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Talep e ait belgeleri listeler
         * @param {number} [demandId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDemandGetDemandDocumentListGet(demandId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDemandGetDemandDocumentListGet(demandId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Talep gruplarını listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDemandGetDemandGroupListGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemandGroupListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDemandGetDemandGroupListGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Talep listesini verir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDemandGetDemandListByProjectIDGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDemandGetDemandListByProjectIDGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDemandGetDemandListPastLastDateForDailyEmailGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDemandGetDemandListPastLastDateForDailyEmailGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Son tarihi geçmiş belgeleri listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDemandGetDemandListPastLastDateGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDemandGetDemandListPastLastDateGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Talep raporlarını listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDemandGetDemandReportListGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemandReportDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDemandGetDemandReportListGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary talep timeline listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDemandGetDemandTimeLineGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDemandGetDemandTimeLineGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary belge günceller\"
         * @param {DemandDocument} [demandDocument] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDemandUpdateDemandDocumentPost(demandDocument?: DemandDocument, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDemandUpdateDemandDocumentPost(demandDocument, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Talep grubu günceller
         * @param {DemandGroup} [demandGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDemandUpdateDemandGroupPost(demandGroup?: DemandGroup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDemandUpdateDemandGroupPost(demandGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Talep günceller
         * @param {Demand} [demand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDemandUpdateDemandPost(demand?: Demand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDemandUpdateDemandPost(demand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary talep dokumanını yükler, Document yazan kısım base64 olacak
         * @param {UploadDemandDocumentDTO} [uploadDemandDocumentDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDemandUploadDemandDocumentPost(uploadDemandDocumentDTO?: UploadDemandDocumentDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDemandUploadDemandDocumentPost(uploadDemandDocumentDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemandApi - factory interface
 * @export
 */
export const DemandApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemandApiFp(configuration)
    return {
        /**
         * 
         * @summary Talep e yeni bir belge ekler
         * @param {AddDemandDocumentDTO} [addDemandDocumentDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandAddDemandDocumentPost(addDemandDocumentDTO?: AddDemandDocumentDTO, options?: any): AxiosPromise<void> {
            return localVarFp.apiDemandAddDemandDocumentPost(addDemandDocumentDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Talep grubu ekler
         * @param {DemandGroup} [demandGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandAddDemandGroupPost(demandGroup?: DemandGroup, options?: any): AxiosPromise<Result> {
            return localVarFp.apiDemandAddDemandGroupPost(demandGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Yeni talep ekler
         * @param {AddDemandDTO} [addDemandDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandAddDemandPost(addDemandDTO?: AddDemandDTO, options?: any): AxiosPromise<void> {
            return localVarFp.apiDemandAddDemandPost(addDemandDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary talepleri ve taleplere ait dokumanları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandGetDemandAndDocumentListGet(projectId?: number, options?: any): AxiosPromise<DemandAndAllDocumentDTOListDataResult> {
            return localVarFp.apiDemandGetDemandAndDocumentListGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Talep e ait belgeleri listeler
         * @param {number} [demandId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandGetDemandDocumentListGet(demandId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiDemandGetDemandDocumentListGet(demandId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Talep gruplarını listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandGetDemandGroupListGet(projectId?: number, options?: any): AxiosPromise<DemandGroupListDataResult> {
            return localVarFp.apiDemandGetDemandGroupListGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Talep listesini verir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandGetDemandListByProjectIDGet(projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiDemandGetDemandListByProjectIDGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandGetDemandListPastLastDateForDailyEmailGet(options?: any): AxiosPromise<Result> {
            return localVarFp.apiDemandGetDemandListPastLastDateForDailyEmailGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Son tarihi geçmiş belgeleri listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandGetDemandListPastLastDateGet(projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiDemandGetDemandListPastLastDateGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Talep raporlarını listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandGetDemandReportListGet(projectId?: number, options?: any): AxiosPromise<DemandReportDTOListDataResult> {
            return localVarFp.apiDemandGetDemandReportListGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary talep timeline listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandGetDemandTimeLineGet(projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiDemandGetDemandTimeLineGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary belge günceller\"
         * @param {DemandDocument} [demandDocument] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandUpdateDemandDocumentPost(demandDocument?: DemandDocument, options?: any): AxiosPromise<void> {
            return localVarFp.apiDemandUpdateDemandDocumentPost(demandDocument, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Talep grubu günceller
         * @param {DemandGroup} [demandGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandUpdateDemandGroupPost(demandGroup?: DemandGroup, options?: any): AxiosPromise<Result> {
            return localVarFp.apiDemandUpdateDemandGroupPost(demandGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Talep günceller
         * @param {Demand} [demand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandUpdateDemandPost(demand?: Demand, options?: any): AxiosPromise<void> {
            return localVarFp.apiDemandUpdateDemandPost(demand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary talep dokumanını yükler, Document yazan kısım base64 olacak
         * @param {UploadDemandDocumentDTO} [uploadDemandDocumentDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDemandUploadDemandDocumentPost(uploadDemandDocumentDTO?: UploadDemandDocumentDTO, options?: any): AxiosPromise<void> {
            return localVarFp.apiDemandUploadDemandDocumentPost(uploadDemandDocumentDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemandApi - object-oriented interface
 * @export
 * @class DemandApi
 * @extends {BaseAPI}
 */
export class DemandApi extends BaseAPI {
    /**
     * 
     * @summary Talep e yeni bir belge ekler
     * @param {AddDemandDocumentDTO} [addDemandDocumentDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemandApi
     */
    public apiDemandAddDemandDocumentPost(addDemandDocumentDTO?: AddDemandDocumentDTO, options?: any) {
        return DemandApiFp(this.configuration).apiDemandAddDemandDocumentPost(addDemandDocumentDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Talep grubu ekler
     * @param {DemandGroup} [demandGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemandApi
     */
    public apiDemandAddDemandGroupPost(demandGroup?: DemandGroup, options?: any) {
        return DemandApiFp(this.configuration).apiDemandAddDemandGroupPost(demandGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Yeni talep ekler
     * @param {AddDemandDTO} [addDemandDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemandApi
     */
    public apiDemandAddDemandPost(addDemandDTO?: AddDemandDTO, options?: any) {
        return DemandApiFp(this.configuration).apiDemandAddDemandPost(addDemandDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary talepleri ve taleplere ait dokumanları listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemandApi
     */
    public apiDemandGetDemandAndDocumentListGet(projectId?: number, options?: any) {
        return DemandApiFp(this.configuration).apiDemandGetDemandAndDocumentListGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Talep e ait belgeleri listeler
     * @param {number} [demandId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemandApi
     */
    public apiDemandGetDemandDocumentListGet(demandId?: number, options?: any) {
        return DemandApiFp(this.configuration).apiDemandGetDemandDocumentListGet(demandId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Talep gruplarını listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemandApi
     */
    public apiDemandGetDemandGroupListGet(projectId?: number, options?: any) {
        return DemandApiFp(this.configuration).apiDemandGetDemandGroupListGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Talep listesini verir
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemandApi
     */
    public apiDemandGetDemandListByProjectIDGet(projectId?: number, options?: any) {
        return DemandApiFp(this.configuration).apiDemandGetDemandListByProjectIDGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemandApi
     */
    public apiDemandGetDemandListPastLastDateForDailyEmailGet(options?: any) {
        return DemandApiFp(this.configuration).apiDemandGetDemandListPastLastDateForDailyEmailGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Son tarihi geçmiş belgeleri listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemandApi
     */
    public apiDemandGetDemandListPastLastDateGet(projectId?: number, options?: any) {
        return DemandApiFp(this.configuration).apiDemandGetDemandListPastLastDateGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Talep raporlarını listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemandApi
     */
    public apiDemandGetDemandReportListGet(projectId?: number, options?: any) {
        return DemandApiFp(this.configuration).apiDemandGetDemandReportListGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary talep timeline listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemandApi
     */
    public apiDemandGetDemandTimeLineGet(projectId?: number, options?: any) {
        return DemandApiFp(this.configuration).apiDemandGetDemandTimeLineGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary belge günceller\"
     * @param {DemandDocument} [demandDocument] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemandApi
     */
    public apiDemandUpdateDemandDocumentPost(demandDocument?: DemandDocument, options?: any) {
        return DemandApiFp(this.configuration).apiDemandUpdateDemandDocumentPost(demandDocument, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Talep grubu günceller
     * @param {DemandGroup} [demandGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemandApi
     */
    public apiDemandUpdateDemandGroupPost(demandGroup?: DemandGroup, options?: any) {
        return DemandApiFp(this.configuration).apiDemandUpdateDemandGroupPost(demandGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Talep günceller
     * @param {Demand} [demand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemandApi
     */
    public apiDemandUpdateDemandPost(demand?: Demand, options?: any) {
        return DemandApiFp(this.configuration).apiDemandUpdateDemandPost(demand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary talep dokumanını yükler, Document yazan kısım base64 olacak
     * @param {UploadDemandDocumentDTO} [uploadDemandDocumentDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemandApi
     */
    public apiDemandUploadDemandDocumentPost(uploadDemandDocumentDTO?: UploadDemandDocumentDTO, options?: any) {
        return DemandApiFp(this.configuration).apiDemandUploadDemandDocumentPost(uploadDemandDocumentDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Dokumana yeni bakım ekler
         * @param {DocumentMaintenanceType} [documentMaintenanceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentAddDocumentMaintenanceTypePost: async (documentMaintenanceType?: DocumentMaintenanceType, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Document/AddDocumentMaintenanceType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentMaintenanceType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dokuman ekler
         * @param {Document} [document] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentAddDocumentPost: async (document?: Document, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Document/AddDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(document, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Belge Takip İşlemini Geri Alır.
         * @param {CancelDocumentMaintenanceDTO} [cancelDocumentMaintenanceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentCancelDocumentMaintenancePost: async (cancelDocumentMaintenanceDTO?: CancelDocumentMaintenanceDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Document/CancelDocumentMaintenance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelDocumentMaintenanceDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dokumanlar için bakımları generate eder. web bunu kullanmayacak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentCreateDocumentMaintenanceGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Document/CreateDocumentMaintenance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dokumanlar Bakımını yapar, bakım ilk kez yapıalcaksa bu kullanlır, bakıma belge eklenecekse diğer endpointi kullanmak gerekir
         * @param {DoDocumentMaintenanceDTO} [doDocumentMaintenanceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentDoDocumentMaintenancePost: async (doDocumentMaintenanceDTO?: DoDocumentMaintenanceDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Document/DoDocumentMaintenance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doDocumentMaintenanceDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dokumanlar için ikinci belge ekleme apisi
         * @param {DoUpdateDocumentMaintenanceDTO} [doUpdateDocumentMaintenanceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentDoUpdateDocumentMaintenancePost: async (doUpdateDocumentMaintenanceDTO?: DoUpdateDocumentMaintenanceDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Document/DoUpdateDocumentMaintenance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doUpdateDocumentMaintenanceDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dokumanları ve dokumana ait bakımları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentGetDocumentAndTypeListGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Document/GetDocumentAndTypeList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dokumanları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentGetDocumentListGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Document/GetDocumentList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dokumana ait bakımları listeler
         * @param {number} [documentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentGetDocumentMaintenanceTypeGet: async (documentId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Document/GetDocumentMaintenanceType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (documentId !== undefined) {
                localVarQueryParameter['documentId'] = documentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dokuman için period listesi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentGetDocumentPeriodListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Document/GetDocumentPeriodList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dokuman raporlarını verir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentGetDocumentReportListGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Document/GetDocumentReportList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dokumanlar timeline
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentGetDocumentTimelineGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Document/GetDocumentTimeline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dokumanlar timeline status bilgilerini verir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentGetDocumentTimelineStatusDescGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Document/GetDocumentTimelineStatusDesc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentGetNotCompletedDocumentForDailyEmailGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Document/GetNotCompletedDocumentForDailyEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dokumana ait bakımı günceller, sadece Question ve IsActive parametresini güncelliyor
         * @param {DocumentMaintenanceType} [documentMaintenanceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentUpdateDocumentMaintenanceTypePost: async (documentMaintenanceType?: DocumentMaintenanceType, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Document/UpdateDocumentMaintenanceType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentMaintenanceType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dokuman günceller
         * @param {Document} [document] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentUpdateDocumentPost: async (document?: Document, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Document/UpdateDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(document, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Dokumana yeni bakım ekler
         * @param {DocumentMaintenanceType} [documentMaintenanceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentAddDocumentMaintenanceTypePost(documentMaintenanceType?: DocumentMaintenanceType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentAddDocumentMaintenanceTypePost(documentMaintenanceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dokuman ekler
         * @param {Document} [document] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentAddDocumentPost(document?: Document, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentAddDocumentPost(document, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Belge Takip İşlemini Geri Alır.
         * @param {CancelDocumentMaintenanceDTO} [cancelDocumentMaintenanceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentCancelDocumentMaintenancePost(cancelDocumentMaintenanceDTO?: CancelDocumentMaintenanceDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentCancelDocumentMaintenancePost(cancelDocumentMaintenanceDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dokumanlar için bakımları generate eder. web bunu kullanmayacak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentCreateDocumentMaintenanceGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentCreateDocumentMaintenanceGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dokumanlar Bakımını yapar, bakım ilk kez yapıalcaksa bu kullanlır, bakıma belge eklenecekse diğer endpointi kullanmak gerekir
         * @param {DoDocumentMaintenanceDTO} [doDocumentMaintenanceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentDoDocumentMaintenancePost(doDocumentMaintenanceDTO?: DoDocumentMaintenanceDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentDoDocumentMaintenancePost(doDocumentMaintenanceDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dokumanlar için ikinci belge ekleme apisi
         * @param {DoUpdateDocumentMaintenanceDTO} [doUpdateDocumentMaintenanceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentDoUpdateDocumentMaintenancePost(doUpdateDocumentMaintenanceDTO?: DoUpdateDocumentMaintenanceDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentDoUpdateDocumentMaintenancePost(doUpdateDocumentMaintenanceDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dokumanları ve dokumana ait bakımları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentGetDocumentAndTypeListGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentAndTypeDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentGetDocumentAndTypeListGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dokumanları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentGetDocumentListGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentGetDocumentListGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dokumana ait bakımları listeler
         * @param {number} [documentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentGetDocumentMaintenanceTypeGet(documentId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentMaintenanceTypeDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentGetDocumentMaintenanceTypeGet(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dokuman için period listesi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentGetDocumentPeriodListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentPeriodListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentGetDocumentPeriodListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dokuman raporlarını verir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentGetDocumentReportListGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentReportDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentGetDocumentReportListGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dokumanlar timeline
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentGetDocumentTimelineGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentTimelineDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentGetDocumentTimelineGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dokumanlar timeline status bilgilerini verir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentGetDocumentTimelineStatusDescGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimelineStatusCodeDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentGetDocumentTimelineStatusDescGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentGetNotCompletedDocumentForDailyEmailGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentGetNotCompletedDocumentForDailyEmailGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dokumana ait bakımı günceller, sadece Question ve IsActive parametresini güncelliyor
         * @param {DocumentMaintenanceType} [documentMaintenanceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentUpdateDocumentMaintenanceTypePost(documentMaintenanceType?: DocumentMaintenanceType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentUpdateDocumentMaintenanceTypePost(documentMaintenanceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dokuman günceller
         * @param {Document} [document] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentUpdateDocumentPost(document?: Document, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentUpdateDocumentPost(document, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentApiFp(configuration)
    return {
        /**
         * 
         * @summary Dokumana yeni bakım ekler
         * @param {DocumentMaintenanceType} [documentMaintenanceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentAddDocumentMaintenanceTypePost(documentMaintenanceType?: DocumentMaintenanceType, options?: any): AxiosPromise<Result> {
            return localVarFp.apiDocumentAddDocumentMaintenanceTypePost(documentMaintenanceType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dokuman ekler
         * @param {Document} [document] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentAddDocumentPost(document?: Document, options?: any): AxiosPromise<Result> {
            return localVarFp.apiDocumentAddDocumentPost(document, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Belge Takip İşlemini Geri Alır.
         * @param {CancelDocumentMaintenanceDTO} [cancelDocumentMaintenanceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentCancelDocumentMaintenancePost(cancelDocumentMaintenanceDTO?: CancelDocumentMaintenanceDTO, options?: any): AxiosPromise<Result> {
            return localVarFp.apiDocumentCancelDocumentMaintenancePost(cancelDocumentMaintenanceDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dokumanlar için bakımları generate eder. web bunu kullanmayacak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentCreateDocumentMaintenanceGet(options?: any): AxiosPromise<Result> {
            return localVarFp.apiDocumentCreateDocumentMaintenanceGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dokumanlar Bakımını yapar, bakım ilk kez yapıalcaksa bu kullanlır, bakıma belge eklenecekse diğer endpointi kullanmak gerekir
         * @param {DoDocumentMaintenanceDTO} [doDocumentMaintenanceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentDoDocumentMaintenancePost(doDocumentMaintenanceDTO?: DoDocumentMaintenanceDTO, options?: any): AxiosPromise<Result> {
            return localVarFp.apiDocumentDoDocumentMaintenancePost(doDocumentMaintenanceDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dokumanlar için ikinci belge ekleme apisi
         * @param {DoUpdateDocumentMaintenanceDTO} [doUpdateDocumentMaintenanceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentDoUpdateDocumentMaintenancePost(doUpdateDocumentMaintenanceDTO?: DoUpdateDocumentMaintenanceDTO, options?: any): AxiosPromise<Result> {
            return localVarFp.apiDocumentDoUpdateDocumentMaintenancePost(doUpdateDocumentMaintenanceDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dokumanları ve dokumana ait bakımları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentGetDocumentAndTypeListGet(projectId?: number, options?: any): AxiosPromise<DocumentAndTypeDTOListDataResult> {
            return localVarFp.apiDocumentGetDocumentAndTypeListGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dokumanları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentGetDocumentListGet(projectId?: number, options?: any): AxiosPromise<DocumentDTOListDataResult> {
            return localVarFp.apiDocumentGetDocumentListGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dokumana ait bakımları listeler
         * @param {number} [documentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentGetDocumentMaintenanceTypeGet(documentId?: number, options?: any): AxiosPromise<DocumentMaintenanceTypeDTOListDataResult> {
            return localVarFp.apiDocumentGetDocumentMaintenanceTypeGet(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dokuman için period listesi
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentGetDocumentPeriodListGet(options?: any): AxiosPromise<DocumentPeriodListDataResult> {
            return localVarFp.apiDocumentGetDocumentPeriodListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dokuman raporlarını verir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentGetDocumentReportListGet(projectId?: number, options?: any): AxiosPromise<DocumentReportDTOListDataResult> {
            return localVarFp.apiDocumentGetDocumentReportListGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dokumanlar timeline
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentGetDocumentTimelineGet(projectId?: number, options?: any): AxiosPromise<DocumentTimelineDTOListDataResult> {
            return localVarFp.apiDocumentGetDocumentTimelineGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dokumanlar timeline status bilgilerini verir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentGetDocumentTimelineStatusDescGet(options?: any): AxiosPromise<TimelineStatusCodeDTOListDataResult> {
            return localVarFp.apiDocumentGetDocumentTimelineStatusDescGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentGetNotCompletedDocumentForDailyEmailGet(options?: any): AxiosPromise<Result> {
            return localVarFp.apiDocumentGetNotCompletedDocumentForDailyEmailGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dokumana ait bakımı günceller, sadece Question ve IsActive parametresini güncelliyor
         * @param {DocumentMaintenanceType} [documentMaintenanceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentUpdateDocumentMaintenanceTypePost(documentMaintenanceType?: DocumentMaintenanceType, options?: any): AxiosPromise<Result> {
            return localVarFp.apiDocumentUpdateDocumentMaintenanceTypePost(documentMaintenanceType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dokuman günceller
         * @param {Document} [document] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentUpdateDocumentPost(document?: Document, options?: any): AxiosPromise<Result> {
            return localVarFp.apiDocumentUpdateDocumentPost(document, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI {
    /**
     * 
     * @summary Dokumana yeni bakım ekler
     * @param {DocumentMaintenanceType} [documentMaintenanceType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentAddDocumentMaintenanceTypePost(documentMaintenanceType?: DocumentMaintenanceType, options?: any) {
        return DocumentApiFp(this.configuration).apiDocumentAddDocumentMaintenanceTypePost(documentMaintenanceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dokuman ekler
     * @param {Document} [document] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentAddDocumentPost(document?: Document, options?: any) {
        return DocumentApiFp(this.configuration).apiDocumentAddDocumentPost(document, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Belge Takip İşlemini Geri Alır.
     * @param {CancelDocumentMaintenanceDTO} [cancelDocumentMaintenanceDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentCancelDocumentMaintenancePost(cancelDocumentMaintenanceDTO?: CancelDocumentMaintenanceDTO, options?: any) {
        return DocumentApiFp(this.configuration).apiDocumentCancelDocumentMaintenancePost(cancelDocumentMaintenanceDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dokumanlar için bakımları generate eder. web bunu kullanmayacak
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentCreateDocumentMaintenanceGet(options?: any) {
        return DocumentApiFp(this.configuration).apiDocumentCreateDocumentMaintenanceGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dokumanlar Bakımını yapar, bakım ilk kez yapıalcaksa bu kullanlır, bakıma belge eklenecekse diğer endpointi kullanmak gerekir
     * @param {DoDocumentMaintenanceDTO} [doDocumentMaintenanceDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentDoDocumentMaintenancePost(doDocumentMaintenanceDTO?: DoDocumentMaintenanceDTO, options?: any) {
        return DocumentApiFp(this.configuration).apiDocumentDoDocumentMaintenancePost(doDocumentMaintenanceDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dokumanlar için ikinci belge ekleme apisi
     * @param {DoUpdateDocumentMaintenanceDTO} [doUpdateDocumentMaintenanceDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentDoUpdateDocumentMaintenancePost(doUpdateDocumentMaintenanceDTO?: DoUpdateDocumentMaintenanceDTO, options?: any) {
        return DocumentApiFp(this.configuration).apiDocumentDoUpdateDocumentMaintenancePost(doUpdateDocumentMaintenanceDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dokumanları ve dokumana ait bakımları listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentGetDocumentAndTypeListGet(projectId?: number, options?: any) {
        return DocumentApiFp(this.configuration).apiDocumentGetDocumentAndTypeListGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dokumanları listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentGetDocumentListGet(projectId?: number, options?: any) {
        return DocumentApiFp(this.configuration).apiDocumentGetDocumentListGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dokumana ait bakımları listeler
     * @param {number} [documentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentGetDocumentMaintenanceTypeGet(documentId?: number, options?: any) {
        return DocumentApiFp(this.configuration).apiDocumentGetDocumentMaintenanceTypeGet(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dokuman için period listesi
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentGetDocumentPeriodListGet(options?: any) {
        return DocumentApiFp(this.configuration).apiDocumentGetDocumentPeriodListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dokuman raporlarını verir
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentGetDocumentReportListGet(projectId?: number, options?: any) {
        return DocumentApiFp(this.configuration).apiDocumentGetDocumentReportListGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dokumanlar timeline
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentGetDocumentTimelineGet(projectId?: number, options?: any) {
        return DocumentApiFp(this.configuration).apiDocumentGetDocumentTimelineGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dokumanlar timeline status bilgilerini verir
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentGetDocumentTimelineStatusDescGet(options?: any) {
        return DocumentApiFp(this.configuration).apiDocumentGetDocumentTimelineStatusDescGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentGetNotCompletedDocumentForDailyEmailGet(options?: any) {
        return DocumentApiFp(this.configuration).apiDocumentGetNotCompletedDocumentForDailyEmailGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dokumana ait bakımı günceller, sadece Question ve IsActive parametresini güncelliyor
     * @param {DocumentMaintenanceType} [documentMaintenanceType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentUpdateDocumentMaintenanceTypePost(documentMaintenanceType?: DocumentMaintenanceType, options?: any) {
        return DocumentApiFp(this.configuration).apiDocumentUpdateDocumentMaintenanceTypePost(documentMaintenanceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dokuman günceller
     * @param {Document} [document] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public apiDocumentUpdateDocumentPost(document?: Document, options?: any) {
        return DocumentApiFp(this.configuration).apiDocumentUpdateDocumentPost(document, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FloorApi - axios parameter creator
 * @export
 */
export const FloorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Kat ekler
         * @param {Floor} [floor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFloorAddFloorPost: async (floor?: Floor, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Floor/AddFloor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(floor, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Idye göre katın bilgilerini getirir.
         * @param {number} [floorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFloorGetFloorByIdGet: async (floorId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Floor/GetFloorById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (floorId !== undefined) {
                localVarQueryParameter['floorId'] = floorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Binanın bütün katlarını listeler
         * @param {number} [buildId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFloorGetFloorListByBuildIDGet: async (buildId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Floor/GetFloorListByBuildID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (buildId !== undefined) {
                localVarQueryParameter['buildId'] = buildId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kat günceller
         * @param {Floor} [floor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFloorUpdateFloorPost: async (floor?: Floor, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Floor/UpdateFloor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(floor, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FloorApi - functional programming interface
 * @export
 */
export const FloorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FloorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Kat ekler
         * @param {Floor} [floor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFloorAddFloorPost(floor?: Floor, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFloorAddFloorPost(floor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Idye göre katın bilgilerini getirir.
         * @param {number} [floorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFloorGetFloorByIdGet(floorId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFloorGetFloorByIdGet(floorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Binanın bütün katlarını listeler
         * @param {number} [buildId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFloorGetFloorListByBuildIDGet(buildId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFloorGetFloorListByBuildIDGet(buildId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kat günceller
         * @param {Floor} [floor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFloorUpdateFloorPost(floor?: Floor, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFloorUpdateFloorPost(floor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FloorApi - factory interface
 * @export
 */
export const FloorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FloorApiFp(configuration)
    return {
        /**
         * 
         * @summary Kat ekler
         * @param {Floor} [floor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFloorAddFloorPost(floor?: Floor, options?: any): AxiosPromise<void> {
            return localVarFp.apiFloorAddFloorPost(floor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Idye göre katın bilgilerini getirir.
         * @param {number} [floorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFloorGetFloorByIdGet(floorId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiFloorGetFloorByIdGet(floorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Binanın bütün katlarını listeler
         * @param {number} [buildId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFloorGetFloorListByBuildIDGet(buildId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiFloorGetFloorListByBuildIDGet(buildId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kat günceller
         * @param {Floor} [floor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFloorUpdateFloorPost(floor?: Floor, options?: any): AxiosPromise<void> {
            return localVarFp.apiFloorUpdateFloorPost(floor, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FloorApi - object-oriented interface
 * @export
 * @class FloorApi
 * @extends {BaseAPI}
 */
export class FloorApi extends BaseAPI {
    /**
     * 
     * @summary Kat ekler
     * @param {Floor} [floor] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorApi
     */
    public apiFloorAddFloorPost(floor?: Floor, options?: any) {
        return FloorApiFp(this.configuration).apiFloorAddFloorPost(floor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Idye göre katın bilgilerini getirir.
     * @param {number} [floorId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorApi
     */
    public apiFloorGetFloorByIdGet(floorId?: number, options?: any) {
        return FloorApiFp(this.configuration).apiFloorGetFloorByIdGet(floorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Binanın bütün katlarını listeler
     * @param {number} [buildId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorApi
     */
    public apiFloorGetFloorListByBuildIDGet(buildId?: number, options?: any) {
        return FloorApiFp(this.configuration).apiFloorGetFloorListByBuildIDGet(buildId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kat günceller
     * @param {Floor} [floor] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorApi
     */
    public apiFloorUpdateFloorPost(floor?: Floor, options?: any) {
        return FloorApiFp(this.configuration).apiFloorUpdateFloorPost(floor, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupApi - axios parameter creator
 * @export
 */
export const GroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Marka ekler
         * @param {Brand} [brand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupAddBrandPost: async (brand?: Brand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Group/AddBrand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Grup ekler
         * @param {Group} [group] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupAddGroupPost: async (group?: Group, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Group/AddGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(group, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Model Ekler
         * @param {Model} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupAddModelPost: async (model?: Model, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Group/AddModel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(model, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary idye göre marka bilgilerini getirir ekler
         * @param {number} [brandId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupGetBrandByIdGet: async (brandId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Group/GetBrandById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (brandId !== undefined) {
                localVarQueryParameter['brandId'] = brandId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Grup a göre bütün markaları listeler
         * @param {number} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupGetBrandListByGroupIDGet: async (groupId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Group/GetBrandListByGroupID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Idye göre grubun bilgilerini getirir
         * @param {number} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupGetGroupByIdGet: async (groupId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Group/GetGroupById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Projeye ait bütün grupları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupGetGroupListByProjectIDGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Group/GetGroupListByProjectID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ID ye göre modelin bilgilerini getirir
         * @param {number} [modelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupGetModelByIdGet: async (modelId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Group/GetModelById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (modelId !== undefined) {
                localVarQueryParameter['modelId'] = modelId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Markanın bütün modellerini listeler
         * @param {number} [brandId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupGetModelListByBrandIDGet: async (brandId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Group/GetModelListByBrandID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (brandId !== undefined) {
                localVarQueryParameter['brandId'] = brandId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Marka günceller
         * @param {Brand} [brand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupUpdateBrandPost: async (brand?: Brand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Group/UpdateBrand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Grup günceller
         * @param {Group} [group] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupUpdateGroupPost: async (group?: Group, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Group/UpdateGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(group, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Model Günceller
         * @param {Model} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupUpdateModelPost: async (model?: Model, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Group/UpdateModel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(model, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupApi - functional programming interface
 * @export
 */
export const GroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Marka ekler
         * @param {Brand} [brand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupAddBrandPost(brand?: Brand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupAddBrandPost(brand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Grup ekler
         * @param {Group} [group] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupAddGroupPost(group?: Group, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupAddGroupPost(group, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Model Ekler
         * @param {Model} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupAddModelPost(model?: Model, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupAddModelPost(model, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary idye göre marka bilgilerini getirir ekler
         * @param {number} [brandId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupGetBrandByIdGet(brandId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupGetBrandByIdGet(brandId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Grup a göre bütün markaları listeler
         * @param {number} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupGetBrandListByGroupIDGet(groupId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupGetBrandListByGroupIDGet(groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Idye göre grubun bilgilerini getirir
         * @param {number} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupGetGroupByIdGet(groupId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupGetGroupByIdGet(groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Projeye ait bütün grupları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupGetGroupListByProjectIDGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupGetGroupListByProjectIDGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ID ye göre modelin bilgilerini getirir
         * @param {number} [modelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupGetModelByIdGet(modelId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupGetModelByIdGet(modelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Markanın bütün modellerini listeler
         * @param {number} [brandId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupGetModelListByBrandIDGet(brandId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupGetModelListByBrandIDGet(brandId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Marka günceller
         * @param {Brand} [brand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupUpdateBrandPost(brand?: Brand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupUpdateBrandPost(brand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Grup günceller
         * @param {Group} [group] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupUpdateGroupPost(group?: Group, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupUpdateGroupPost(group, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Model Günceller
         * @param {Model} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupUpdateModelPost(model?: Model, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupUpdateModelPost(model, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupApi - factory interface
 * @export
 */
export const GroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupApiFp(configuration)
    return {
        /**
         * 
         * @summary Marka ekler
         * @param {Brand} [brand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupAddBrandPost(brand?: Brand, options?: any): AxiosPromise<void> {
            return localVarFp.apiGroupAddBrandPost(brand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Grup ekler
         * @param {Group} [group] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupAddGroupPost(group?: Group, options?: any): AxiosPromise<void> {
            return localVarFp.apiGroupAddGroupPost(group, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Model Ekler
         * @param {Model} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupAddModelPost(model?: Model, options?: any): AxiosPromise<void> {
            return localVarFp.apiGroupAddModelPost(model, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary idye göre marka bilgilerini getirir ekler
         * @param {number} [brandId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupGetBrandByIdGet(brandId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiGroupGetBrandByIdGet(brandId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Grup a göre bütün markaları listeler
         * @param {number} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupGetBrandListByGroupIDGet(groupId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiGroupGetBrandListByGroupIDGet(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Idye göre grubun bilgilerini getirir
         * @param {number} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupGetGroupByIdGet(groupId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiGroupGetGroupByIdGet(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Projeye ait bütün grupları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupGetGroupListByProjectIDGet(projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiGroupGetGroupListByProjectIDGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ID ye göre modelin bilgilerini getirir
         * @param {number} [modelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupGetModelByIdGet(modelId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiGroupGetModelByIdGet(modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Markanın bütün modellerini listeler
         * @param {number} [brandId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupGetModelListByBrandIDGet(brandId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiGroupGetModelListByBrandIDGet(brandId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Marka günceller
         * @param {Brand} [brand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupUpdateBrandPost(brand?: Brand, options?: any): AxiosPromise<void> {
            return localVarFp.apiGroupUpdateBrandPost(brand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Grup günceller
         * @param {Group} [group] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupUpdateGroupPost(group?: Group, options?: any): AxiosPromise<void> {
            return localVarFp.apiGroupUpdateGroupPost(group, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Model Günceller
         * @param {Model} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupUpdateModelPost(model?: Model, options?: any): AxiosPromise<void> {
            return localVarFp.apiGroupUpdateModelPost(model, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupApi - object-oriented interface
 * @export
 * @class GroupApi
 * @extends {BaseAPI}
 */
export class GroupApi extends BaseAPI {
    /**
     * 
     * @summary Marka ekler
     * @param {Brand} [brand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiGroupAddBrandPost(brand?: Brand, options?: any) {
        return GroupApiFp(this.configuration).apiGroupAddBrandPost(brand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Grup ekler
     * @param {Group} [group] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiGroupAddGroupPost(group?: Group, options?: any) {
        return GroupApiFp(this.configuration).apiGroupAddGroupPost(group, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Model Ekler
     * @param {Model} [model] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiGroupAddModelPost(model?: Model, options?: any) {
        return GroupApiFp(this.configuration).apiGroupAddModelPost(model, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary idye göre marka bilgilerini getirir ekler
     * @param {number} [brandId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiGroupGetBrandByIdGet(brandId?: number, options?: any) {
        return GroupApiFp(this.configuration).apiGroupGetBrandByIdGet(brandId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Grup a göre bütün markaları listeler
     * @param {number} [groupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiGroupGetBrandListByGroupIDGet(groupId?: number, options?: any) {
        return GroupApiFp(this.configuration).apiGroupGetBrandListByGroupIDGet(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Idye göre grubun bilgilerini getirir
     * @param {number} [groupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiGroupGetGroupByIdGet(groupId?: number, options?: any) {
        return GroupApiFp(this.configuration).apiGroupGetGroupByIdGet(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Projeye ait bütün grupları listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiGroupGetGroupListByProjectIDGet(projectId?: number, options?: any) {
        return GroupApiFp(this.configuration).apiGroupGetGroupListByProjectIDGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ID ye göre modelin bilgilerini getirir
     * @param {number} [modelId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiGroupGetModelByIdGet(modelId?: number, options?: any) {
        return GroupApiFp(this.configuration).apiGroupGetModelByIdGet(modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Markanın bütün modellerini listeler
     * @param {number} [brandId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiGroupGetModelListByBrandIDGet(brandId?: number, options?: any) {
        return GroupApiFp(this.configuration).apiGroupGetModelListByBrandIDGet(brandId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Marka günceller
     * @param {Brand} [brand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiGroupUpdateBrandPost(brand?: Brand, options?: any) {
        return GroupApiFp(this.configuration).apiGroupUpdateBrandPost(brand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Grup günceller
     * @param {Group} [group] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiGroupUpdateGroupPost(group?: Group, options?: any) {
        return GroupApiFp(this.configuration).apiGroupUpdateGroupPost(group, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Model Günceller
     * @param {Model} [model] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public apiGroupUpdateModelPost(model?: Model, options?: any) {
        return GroupApiFp(this.configuration).apiGroupUpdateModelPost(model, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InventoryApi - axios parameter creator
 * @export
 */
export const InventoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Inventere ait belgeleri günceller  Type alanı için :    2 : RiskAnalysisPath  3 : UserInstructions  4 : UserGudiePath  5 : MaintenanceContract
         * @param {number} [projectID] 
         * @param {number} [inventoryID] 
         * @param {number} [type] 
         * @param {any} [path] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryAddInventoryDocumentPost: async (projectID?: number, inventoryID?: number, type?: number, path?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Inventory/AddInventoryDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (projectID !== undefined) { 
                localVarFormParams.append('ProjectID', projectID as any);
            }
    
            if (inventoryID !== undefined) { 
                localVarFormParams.append('InventoryID', inventoryID as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('Type', type as any);
            }
    
            if (path !== undefined) { 
                localVarFormParams.append('Path', path as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [userTypeID] 
         * @param {number} [projectID] 
         * @param {number} [campusID] 
         * @param {number} [buildID] 
         * @param {number} [floorID] 
         * @param {number} [roomID] 
         * @param {number} [groupID] 
         * @param {number} [brandID] 
         * @param {number} [modelID] 
         * @param {number} [inventoryCapacityID] 
         * @param {string} [barcode] 
         * @param {string} [assetNo] 
         * @param {string} [name] 
         * @param {string} [serialNumber] 
         * @param {string} [productionYear] 
         * @param {any} [inventoryPhoto] 
         * @param {string} [explain] 
         * @param {string} [unit] 
         * @param {number} [count] 
         * @param {number} [createUserID] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryAddInventoryPost: async (userTypeID?: number, projectID?: number, campusID?: number, buildID?: number, floorID?: number, roomID?: number, groupID?: number, brandID?: number, modelID?: number, inventoryCapacityID?: number, barcode?: string, assetNo?: string, name?: string, serialNumber?: string, productionYear?: string, inventoryPhoto?: any, explain?: string, unit?: string, count?: number, createUserID?: number, isActive?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Inventory/AddInventory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (userTypeID !== undefined) { 
                localVarFormParams.append('UserTypeID', userTypeID as any);
            }
    
            if (projectID !== undefined) { 
                localVarFormParams.append('ProjectID', projectID as any);
            }
    
            if (campusID !== undefined) { 
                localVarFormParams.append('CampusID', campusID as any);
            }
    
            if (buildID !== undefined) { 
                localVarFormParams.append('BuildID', buildID as any);
            }
    
            if (floorID !== undefined) { 
                localVarFormParams.append('FloorID', floorID as any);
            }
    
            if (roomID !== undefined) { 
                localVarFormParams.append('RoomID', roomID as any);
            }
    
            if (groupID !== undefined) { 
                localVarFormParams.append('GroupID', groupID as any);
            }
    
            if (brandID !== undefined) { 
                localVarFormParams.append('BrandID', brandID as any);
            }
    
            if (modelID !== undefined) { 
                localVarFormParams.append('ModelID', modelID as any);
            }
    
            if (inventoryCapacityID !== undefined) { 
                localVarFormParams.append('InventoryCapacityID', inventoryCapacityID as any);
            }
    
            if (barcode !== undefined) { 
                localVarFormParams.append('Barcode', barcode as any);
            }
    
            if (assetNo !== undefined) { 
                localVarFormParams.append('AssetNo', assetNo as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (serialNumber !== undefined) { 
                localVarFormParams.append('SerialNumber', serialNumber as any);
            }
    
            if (productionYear !== undefined) { 
                localVarFormParams.append('ProductionYear', productionYear as any);
            }
    
            if (inventoryPhoto !== undefined) { 
                localVarFormParams.append('InventoryPhoto', inventoryPhoto as any);
            }
    
            if (explain !== undefined) { 
                localVarFormParams.append('Explain', explain as any);
            }
    
            if (unit !== undefined) { 
                localVarFormParams.append('Unit', unit as any);
            }
    
            if (count !== undefined) { 
                localVarFormParams.append('Count', count as any);
            }
    
            if (createUserID !== undefined) { 
                localVarFormParams.append('CreateUserID', createUserID as any);
            }
    
            if (isActive !== undefined) { 
                localVarFormParams.append('IsActive', isActive as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [inventoryId] 
         * @param {number} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryDeleteInventoryDocumentDelete: async (inventoryId?: number, type?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Inventory/DeleteInventoryDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (inventoryId !== undefined) {
                localVarQueryParameter['inventoryId'] = inventoryId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary risk analizi tarihi geçen ekipmanları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryGetExpireRiskAnalysisInventoryListGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Inventory/GetExpireRiskAnalysisInventoryList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [barcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryGetInventoryByBarcodeGet: async (barcode?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Inventory/GetInventoryByBarcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (barcode !== undefined) {
                localVarQueryParameter['barcode'] = barcode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryGetInventoryListByProjectIDGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Inventory/GetInventoryListByProjectID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryGetInventoryMaintenanceTypeListGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Inventory/GetInventoryMaintenanceTypeList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bakım Sözleşmesi olmayan ekipmanları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryGetNonMaintenanceContractInventoryListGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Inventory/GetNonMaintenanceContractInventoryList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary risk analizi olmayan ekipmanları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryGetNonRiskAnalysisInventoryListGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Inventory/GetNonRiskAnalysisInventoryList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary kullanıcı kılavuzu olmayan ekipmanları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryGetNonUserGudieInventoryListGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Inventory/GetNonUserGudieInventoryList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary kullanıcı talimatları olmayan ekipmanları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryGetNonUserInstructionsInventoryListGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Inventory/GetNonUserInstructionsInventoryList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {number} [userTypeID] 
         * @param {number} [projectID] 
         * @param {number} [campusID] 
         * @param {number} [buildID] 
         * @param {number} [floorID] 
         * @param {number} [roomID] 
         * @param {number} [groupID] 
         * @param {number} [brandID] 
         * @param {number} [modelID] 
         * @param {number} [inventoryCapacityID] 
         * @param {string} [barcode] 
         * @param {string} [assetNo] 
         * @param {string} [name] 
         * @param {string} [serialNumber] 
         * @param {string} [productionYear] 
         * @param {any} [inventoryPhoto] 
         * @param {string} [explain] 
         * @param {string} [unit] 
         * @param {number} [count] 
         * @param {number} [createUserID] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryUpdateInventoryPost: async (id?: number, userTypeID?: number, projectID?: number, campusID?: number, buildID?: number, floorID?: number, roomID?: number, groupID?: number, brandID?: number, modelID?: number, inventoryCapacityID?: number, barcode?: string, assetNo?: string, name?: string, serialNumber?: string, productionYear?: string, inventoryPhoto?: any, explain?: string, unit?: string, count?: number, createUserID?: number, isActive?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Inventory/UpdateInventory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (userTypeID !== undefined) { 
                localVarFormParams.append('UserTypeID', userTypeID as any);
            }
    
            if (projectID !== undefined) { 
                localVarFormParams.append('ProjectID', projectID as any);
            }
    
            if (campusID !== undefined) { 
                localVarFormParams.append('CampusID', campusID as any);
            }
    
            if (buildID !== undefined) { 
                localVarFormParams.append('BuildID', buildID as any);
            }
    
            if (floorID !== undefined) { 
                localVarFormParams.append('FloorID', floorID as any);
            }
    
            if (roomID !== undefined) { 
                localVarFormParams.append('RoomID', roomID as any);
            }
    
            if (groupID !== undefined) { 
                localVarFormParams.append('GroupID', groupID as any);
            }
    
            if (brandID !== undefined) { 
                localVarFormParams.append('BrandID', brandID as any);
            }
    
            if (modelID !== undefined) { 
                localVarFormParams.append('ModelID', modelID as any);
            }
    
            if (inventoryCapacityID !== undefined) { 
                localVarFormParams.append('InventoryCapacityID', inventoryCapacityID as any);
            }
    
            if (barcode !== undefined) { 
                localVarFormParams.append('Barcode', barcode as any);
            }
    
            if (assetNo !== undefined) { 
                localVarFormParams.append('AssetNo', assetNo as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (serialNumber !== undefined) { 
                localVarFormParams.append('SerialNumber', serialNumber as any);
            }
    
            if (productionYear !== undefined) { 
                localVarFormParams.append('ProductionYear', productionYear as any);
            }
    
            if (inventoryPhoto !== undefined) { 
                localVarFormParams.append('InventoryPhoto', inventoryPhoto as any);
            }
    
            if (explain !== undefined) { 
                localVarFormParams.append('Explain', explain as any);
            }
    
            if (unit !== undefined) { 
                localVarFormParams.append('Unit', unit as any);
            }
    
            if (count !== undefined) { 
                localVarFormParams.append('Count', count as any);
            }
    
            if (createUserID !== undefined) { 
                localVarFormParams.append('CreateUserID', createUserID as any);
            }
    
            if (isActive !== undefined) { 
                localVarFormParams.append('IsActive', isActive as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Risk Analizi dokumanın bitiş tarihini günceller  Tarih Formatı : yyyy-MM-dd
         * @param {number} [inventoryId] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryUpdateRiskAnalysisExpireDateGet: async (inventoryId?: number, date?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Inventory/UpdateRiskAnalysisExpireDate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (inventoryId !== undefined) {
                localVarQueryParameter['inventoryId'] = inventoryId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryApi - functional programming interface
 * @export
 */
export const InventoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Inventere ait belgeleri günceller  Type alanı için :    2 : RiskAnalysisPath  3 : UserInstructions  4 : UserGudiePath  5 : MaintenanceContract
         * @param {number} [projectID] 
         * @param {number} [inventoryID] 
         * @param {number} [type] 
         * @param {any} [path] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryAddInventoryDocumentPost(projectID?: number, inventoryID?: number, type?: number, path?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryAddInventoryDocumentPost(projectID, inventoryID, type, path, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [userTypeID] 
         * @param {number} [projectID] 
         * @param {number} [campusID] 
         * @param {number} [buildID] 
         * @param {number} [floorID] 
         * @param {number} [roomID] 
         * @param {number} [groupID] 
         * @param {number} [brandID] 
         * @param {number} [modelID] 
         * @param {number} [inventoryCapacityID] 
         * @param {string} [barcode] 
         * @param {string} [assetNo] 
         * @param {string} [name] 
         * @param {string} [serialNumber] 
         * @param {string} [productionYear] 
         * @param {any} [inventoryPhoto] 
         * @param {string} [explain] 
         * @param {string} [unit] 
         * @param {number} [count] 
         * @param {number} [createUserID] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryAddInventoryPost(userTypeID?: number, projectID?: number, campusID?: number, buildID?: number, floorID?: number, roomID?: number, groupID?: number, brandID?: number, modelID?: number, inventoryCapacityID?: number, barcode?: string, assetNo?: string, name?: string, serialNumber?: string, productionYear?: string, inventoryPhoto?: any, explain?: string, unit?: string, count?: number, createUserID?: number, isActive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryAddInventoryPost(userTypeID, projectID, campusID, buildID, floorID, roomID, groupID, brandID, modelID, inventoryCapacityID, barcode, assetNo, name, serialNumber, productionYear, inventoryPhoto, explain, unit, count, createUserID, isActive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [inventoryId] 
         * @param {number} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryDeleteInventoryDocumentDelete(inventoryId?: number, type?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryDeleteInventoryDocumentDelete(inventoryId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary risk analizi tarihi geçen ekipmanları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryGetExpireRiskAnalysisInventoryListGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryGetExpireRiskAnalysisInventoryListGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [barcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryGetInventoryByBarcodeGet(barcode?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryGetInventoryByBarcodeGet(barcode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryGetInventoryListByProjectIDGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryGetInventoryListByProjectIDGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryGetInventoryMaintenanceTypeListGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryGetInventoryMaintenanceTypeListGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Bakım Sözleşmesi olmayan ekipmanları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryGetNonMaintenanceContractInventoryListGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryGetNonMaintenanceContractInventoryListGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary risk analizi olmayan ekipmanları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryGetNonRiskAnalysisInventoryListGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryGetNonRiskAnalysisInventoryListGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary kullanıcı kılavuzu olmayan ekipmanları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryGetNonUserGudieInventoryListGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryGetNonUserGudieInventoryListGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary kullanıcı talimatları olmayan ekipmanları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryGetNonUserInstructionsInventoryListGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryGetNonUserInstructionsInventoryListGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {number} [userTypeID] 
         * @param {number} [projectID] 
         * @param {number} [campusID] 
         * @param {number} [buildID] 
         * @param {number} [floorID] 
         * @param {number} [roomID] 
         * @param {number} [groupID] 
         * @param {number} [brandID] 
         * @param {number} [modelID] 
         * @param {number} [inventoryCapacityID] 
         * @param {string} [barcode] 
         * @param {string} [assetNo] 
         * @param {string} [name] 
         * @param {string} [serialNumber] 
         * @param {string} [productionYear] 
         * @param {any} [inventoryPhoto] 
         * @param {string} [explain] 
         * @param {string} [unit] 
         * @param {number} [count] 
         * @param {number} [createUserID] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryUpdateInventoryPost(id?: number, userTypeID?: number, projectID?: number, campusID?: number, buildID?: number, floorID?: number, roomID?: number, groupID?: number, brandID?: number, modelID?: number, inventoryCapacityID?: number, barcode?: string, assetNo?: string, name?: string, serialNumber?: string, productionYear?: string, inventoryPhoto?: any, explain?: string, unit?: string, count?: number, createUserID?: number, isActive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryUpdateInventoryPost(id, userTypeID, projectID, campusID, buildID, floorID, roomID, groupID, brandID, modelID, inventoryCapacityID, barcode, assetNo, name, serialNumber, productionYear, inventoryPhoto, explain, unit, count, createUserID, isActive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Risk Analizi dokumanın bitiş tarihini günceller  Tarih Formatı : yyyy-MM-dd
         * @param {number} [inventoryId] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryUpdateRiskAnalysisExpireDateGet(inventoryId?: number, date?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryUpdateRiskAnalysisExpireDateGet(inventoryId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InventoryApi - factory interface
 * @export
 */
export const InventoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventoryApiFp(configuration)
    return {
        /**
         * 
         * @summary Inventere ait belgeleri günceller  Type alanı için :    2 : RiskAnalysisPath  3 : UserInstructions  4 : UserGudiePath  5 : MaintenanceContract
         * @param {number} [projectID] 
         * @param {number} [inventoryID] 
         * @param {number} [type] 
         * @param {any} [path] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryAddInventoryDocumentPost(projectID?: number, inventoryID?: number, type?: number, path?: any, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryAddInventoryDocumentPost(projectID, inventoryID, type, path, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [userTypeID] 
         * @param {number} [projectID] 
         * @param {number} [campusID] 
         * @param {number} [buildID] 
         * @param {number} [floorID] 
         * @param {number} [roomID] 
         * @param {number} [groupID] 
         * @param {number} [brandID] 
         * @param {number} [modelID] 
         * @param {number} [inventoryCapacityID] 
         * @param {string} [barcode] 
         * @param {string} [assetNo] 
         * @param {string} [name] 
         * @param {string} [serialNumber] 
         * @param {string} [productionYear] 
         * @param {any} [inventoryPhoto] 
         * @param {string} [explain] 
         * @param {string} [unit] 
         * @param {number} [count] 
         * @param {number} [createUserID] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryAddInventoryPost(userTypeID?: number, projectID?: number, campusID?: number, buildID?: number, floorID?: number, roomID?: number, groupID?: number, brandID?: number, modelID?: number, inventoryCapacityID?: number, barcode?: string, assetNo?: string, name?: string, serialNumber?: string, productionYear?: string, inventoryPhoto?: any, explain?: string, unit?: string, count?: number, createUserID?: number, isActive?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryAddInventoryPost(userTypeID, projectID, campusID, buildID, floorID, roomID, groupID, brandID, modelID, inventoryCapacityID, barcode, assetNo, name, serialNumber, productionYear, inventoryPhoto, explain, unit, count, createUserID, isActive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [inventoryId] 
         * @param {number} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryDeleteInventoryDocumentDelete(inventoryId?: number, type?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryDeleteInventoryDocumentDelete(inventoryId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary risk analizi tarihi geçen ekipmanları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryGetExpireRiskAnalysisInventoryListGet(projectId?: number, options?: any): AxiosPromise<InventoryDTOListDataResult> {
            return localVarFp.apiInventoryGetExpireRiskAnalysisInventoryListGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [barcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryGetInventoryByBarcodeGet(barcode?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryGetInventoryByBarcodeGet(barcode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryGetInventoryListByProjectIDGet(projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryGetInventoryListByProjectIDGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryGetInventoryMaintenanceTypeListGet(projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryGetInventoryMaintenanceTypeListGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Bakım Sözleşmesi olmayan ekipmanları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryGetNonMaintenanceContractInventoryListGet(projectId?: number, options?: any): AxiosPromise<InventoryDTOListDataResult> {
            return localVarFp.apiInventoryGetNonMaintenanceContractInventoryListGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary risk analizi olmayan ekipmanları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryGetNonRiskAnalysisInventoryListGet(projectId?: number, options?: any): AxiosPromise<InventoryDTOListDataResult> {
            return localVarFp.apiInventoryGetNonRiskAnalysisInventoryListGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary kullanıcı kılavuzu olmayan ekipmanları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryGetNonUserGudieInventoryListGet(projectId?: number, options?: any): AxiosPromise<InventoryDTOListDataResult> {
            return localVarFp.apiInventoryGetNonUserGudieInventoryListGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary kullanıcı talimatları olmayan ekipmanları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryGetNonUserInstructionsInventoryListGet(projectId?: number, options?: any): AxiosPromise<InventoryDTOListDataResult> {
            return localVarFp.apiInventoryGetNonUserInstructionsInventoryListGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {number} [userTypeID] 
         * @param {number} [projectID] 
         * @param {number} [campusID] 
         * @param {number} [buildID] 
         * @param {number} [floorID] 
         * @param {number} [roomID] 
         * @param {number} [groupID] 
         * @param {number} [brandID] 
         * @param {number} [modelID] 
         * @param {number} [inventoryCapacityID] 
         * @param {string} [barcode] 
         * @param {string} [assetNo] 
         * @param {string} [name] 
         * @param {string} [serialNumber] 
         * @param {string} [productionYear] 
         * @param {any} [inventoryPhoto] 
         * @param {string} [explain] 
         * @param {string} [unit] 
         * @param {number} [count] 
         * @param {number} [createUserID] 
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryUpdateInventoryPost(id?: number, userTypeID?: number, projectID?: number, campusID?: number, buildID?: number, floorID?: number, roomID?: number, groupID?: number, brandID?: number, modelID?: number, inventoryCapacityID?: number, barcode?: string, assetNo?: string, name?: string, serialNumber?: string, productionYear?: string, inventoryPhoto?: any, explain?: string, unit?: string, count?: number, createUserID?: number, isActive?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryUpdateInventoryPost(id, userTypeID, projectID, campusID, buildID, floorID, roomID, groupID, brandID, modelID, inventoryCapacityID, barcode, assetNo, name, serialNumber, productionYear, inventoryPhoto, explain, unit, count, createUserID, isActive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Risk Analizi dokumanın bitiş tarihini günceller  Tarih Formatı : yyyy-MM-dd
         * @param {number} [inventoryId] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryUpdateRiskAnalysisExpireDateGet(inventoryId?: number, date?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryUpdateRiskAnalysisExpireDateGet(inventoryId, date, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventoryApi - object-oriented interface
 * @export
 * @class InventoryApi
 * @extends {BaseAPI}
 */
export class InventoryApi extends BaseAPI {
    /**
     * 
     * @summary Inventere ait belgeleri günceller  Type alanı için :    2 : RiskAnalysisPath  3 : UserInstructions  4 : UserGudiePath  5 : MaintenanceContract
     * @param {number} [projectID] 
     * @param {number} [inventoryID] 
     * @param {number} [type] 
     * @param {any} [path] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public apiInventoryAddInventoryDocumentPost(projectID?: number, inventoryID?: number, type?: number, path?: any, options?: any) {
        return InventoryApiFp(this.configuration).apiInventoryAddInventoryDocumentPost(projectID, inventoryID, type, path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [userTypeID] 
     * @param {number} [projectID] 
     * @param {number} [campusID] 
     * @param {number} [buildID] 
     * @param {number} [floorID] 
     * @param {number} [roomID] 
     * @param {number} [groupID] 
     * @param {number} [brandID] 
     * @param {number} [modelID] 
     * @param {number} [inventoryCapacityID] 
     * @param {string} [barcode] 
     * @param {string} [assetNo] 
     * @param {string} [name] 
     * @param {string} [serialNumber] 
     * @param {string} [productionYear] 
     * @param {any} [inventoryPhoto] 
     * @param {string} [explain] 
     * @param {string} [unit] 
     * @param {number} [count] 
     * @param {number} [createUserID] 
     * @param {boolean} [isActive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public apiInventoryAddInventoryPost(userTypeID?: number, projectID?: number, campusID?: number, buildID?: number, floorID?: number, roomID?: number, groupID?: number, brandID?: number, modelID?: number, inventoryCapacityID?: number, barcode?: string, assetNo?: string, name?: string, serialNumber?: string, productionYear?: string, inventoryPhoto?: any, explain?: string, unit?: string, count?: number, createUserID?: number, isActive?: boolean, options?: any) {
        return InventoryApiFp(this.configuration).apiInventoryAddInventoryPost(userTypeID, projectID, campusID, buildID, floorID, roomID, groupID, brandID, modelID, inventoryCapacityID, barcode, assetNo, name, serialNumber, productionYear, inventoryPhoto, explain, unit, count, createUserID, isActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [inventoryId] 
     * @param {number} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public apiInventoryDeleteInventoryDocumentDelete(inventoryId?: number, type?: number, options?: any) {
        return InventoryApiFp(this.configuration).apiInventoryDeleteInventoryDocumentDelete(inventoryId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary risk analizi tarihi geçen ekipmanları listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public apiInventoryGetExpireRiskAnalysisInventoryListGet(projectId?: number, options?: any) {
        return InventoryApiFp(this.configuration).apiInventoryGetExpireRiskAnalysisInventoryListGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [barcode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public apiInventoryGetInventoryByBarcodeGet(barcode?: string, options?: any) {
        return InventoryApiFp(this.configuration).apiInventoryGetInventoryByBarcodeGet(barcode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public apiInventoryGetInventoryListByProjectIDGet(projectId?: number, options?: any) {
        return InventoryApiFp(this.configuration).apiInventoryGetInventoryListByProjectIDGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public apiInventoryGetInventoryMaintenanceTypeListGet(projectId?: number, options?: any) {
        return InventoryApiFp(this.configuration).apiInventoryGetInventoryMaintenanceTypeListGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Bakım Sözleşmesi olmayan ekipmanları listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public apiInventoryGetNonMaintenanceContractInventoryListGet(projectId?: number, options?: any) {
        return InventoryApiFp(this.configuration).apiInventoryGetNonMaintenanceContractInventoryListGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary risk analizi olmayan ekipmanları listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public apiInventoryGetNonRiskAnalysisInventoryListGet(projectId?: number, options?: any) {
        return InventoryApiFp(this.configuration).apiInventoryGetNonRiskAnalysisInventoryListGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary kullanıcı kılavuzu olmayan ekipmanları listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public apiInventoryGetNonUserGudieInventoryListGet(projectId?: number, options?: any) {
        return InventoryApiFp(this.configuration).apiInventoryGetNonUserGudieInventoryListGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary kullanıcı talimatları olmayan ekipmanları listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public apiInventoryGetNonUserInstructionsInventoryListGet(projectId?: number, options?: any) {
        return InventoryApiFp(this.configuration).apiInventoryGetNonUserInstructionsInventoryListGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {number} [userTypeID] 
     * @param {number} [projectID] 
     * @param {number} [campusID] 
     * @param {number} [buildID] 
     * @param {number} [floorID] 
     * @param {number} [roomID] 
     * @param {number} [groupID] 
     * @param {number} [brandID] 
     * @param {number} [modelID] 
     * @param {number} [inventoryCapacityID] 
     * @param {string} [barcode] 
     * @param {string} [assetNo] 
     * @param {string} [name] 
     * @param {string} [serialNumber] 
     * @param {string} [productionYear] 
     * @param {any} [inventoryPhoto] 
     * @param {string} [explain] 
     * @param {string} [unit] 
     * @param {number} [count] 
     * @param {number} [createUserID] 
     * @param {boolean} [isActive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public apiInventoryUpdateInventoryPost(id?: number, userTypeID?: number, projectID?: number, campusID?: number, buildID?: number, floorID?: number, roomID?: number, groupID?: number, brandID?: number, modelID?: number, inventoryCapacityID?: number, barcode?: string, assetNo?: string, name?: string, serialNumber?: string, productionYear?: string, inventoryPhoto?: any, explain?: string, unit?: string, count?: number, createUserID?: number, isActive?: boolean, options?: any) {
        return InventoryApiFp(this.configuration).apiInventoryUpdateInventoryPost(id, userTypeID, projectID, campusID, buildID, floorID, roomID, groupID, brandID, modelID, inventoryCapacityID, barcode, assetNo, name, serialNumber, productionYear, inventoryPhoto, explain, unit, count, createUserID, isActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Risk Analizi dokumanın bitiş tarihini günceller  Tarih Formatı : yyyy-MM-dd
     * @param {number} [inventoryId] 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public apiInventoryUpdateRiskAnalysisExpireDateGet(inventoryId?: number, date?: string, options?: any) {
        return InventoryApiFp(this.configuration).apiInventoryUpdateRiskAnalysisExpireDateGet(inventoryId, date, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InventoryCapacityApi - axios parameter creator
 * @export
 */
export const InventoryCapacityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InventoryCapacity} [inventoryCapacity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryCapacityAddInventoryCapacityPost: async (inventoryCapacity?: InventoryCapacity, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InventoryCapacity/AddInventoryCapacity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inventoryCapacity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryCapacityGetInventoryCapacityListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InventoryCapacity/GetInventoryCapacityList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InventoryCapacity} [inventoryCapacity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryCapacityUpdateInventoryCapacityPost: async (inventoryCapacity?: InventoryCapacity, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InventoryCapacity/UpdateInventoryCapacity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inventoryCapacity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryCapacityApi - functional programming interface
 * @export
 */
export const InventoryCapacityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventoryCapacityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {InventoryCapacity} [inventoryCapacity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryCapacityAddInventoryCapacityPost(inventoryCapacity?: InventoryCapacity, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryCapacityAddInventoryCapacityPost(inventoryCapacity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryCapacityGetInventoryCapacityListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryCapacityGetInventoryCapacityListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InventoryCapacity} [inventoryCapacity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryCapacityUpdateInventoryCapacityPost(inventoryCapacity?: InventoryCapacity, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryCapacityUpdateInventoryCapacityPost(inventoryCapacity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InventoryCapacityApi - factory interface
 * @export
 */
export const InventoryCapacityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventoryCapacityApiFp(configuration)
    return {
        /**
         * 
         * @param {InventoryCapacity} [inventoryCapacity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryCapacityAddInventoryCapacityPost(inventoryCapacity?: InventoryCapacity, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryCapacityAddInventoryCapacityPost(inventoryCapacity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryCapacityGetInventoryCapacityListGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryCapacityGetInventoryCapacityListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InventoryCapacity} [inventoryCapacity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryCapacityUpdateInventoryCapacityPost(inventoryCapacity?: InventoryCapacity, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryCapacityUpdateInventoryCapacityPost(inventoryCapacity, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventoryCapacityApi - object-oriented interface
 * @export
 * @class InventoryCapacityApi
 * @extends {BaseAPI}
 */
export class InventoryCapacityApi extends BaseAPI {
    /**
     * 
     * @param {InventoryCapacity} [inventoryCapacity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryCapacityApi
     */
    public apiInventoryCapacityAddInventoryCapacityPost(inventoryCapacity?: InventoryCapacity, options?: any) {
        return InventoryCapacityApiFp(this.configuration).apiInventoryCapacityAddInventoryCapacityPost(inventoryCapacity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryCapacityApi
     */
    public apiInventoryCapacityGetInventoryCapacityListGet(options?: any) {
        return InventoryCapacityApiFp(this.configuration).apiInventoryCapacityGetInventoryCapacityListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InventoryCapacity} [inventoryCapacity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryCapacityApi
     */
    public apiInventoryCapacityUpdateInventoryCapacityPost(inventoryCapacity?: InventoryCapacity, options?: any) {
        return InventoryCapacityApiFp(this.configuration).apiInventoryCapacityUpdateInventoryCapacityPost(inventoryCapacity, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InventoryLegalInspectionApi - axios parameter creator
 * @export
 */
export const InventoryLegalInspectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Envantere Yasal Muayene Bakım Tipi Ekler
         * @param {InventoryLegalInspectionTypeAddDTO} [inventoryLegalInspectionTypeAddDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryLegalInspectionAddInventoryLegalInspectionTypePost: async (inventoryLegalInspectionTypeAddDTO?: InventoryLegalInspectionTypeAddDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InventoryLegalInspection/AddInventoryLegalInspectionType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inventoryLegalInspectionTypeAddDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Yasal Muayene Bakımı gerçekleştir
         * @param {InventoryLegalInspectionDoMakeDTO} [inventoryLegalInspectionDoMakeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryLegalInspectionDoInventoryLegalInspectionMaintenancePost: async (inventoryLegalInspectionDoMakeDTO?: InventoryLegalInspectionDoMakeDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InventoryLegalInspection/DoInventoryLegalInspectionMaintenance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inventoryLegalInspectionDoMakeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryLegalInspectionGetInventoryLegalInspectionForDailyMailGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InventoryLegalInspection/GetInventoryLegalInspectionForDailyMail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Yasal Muayene için Timeline bilgilerini verir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryLegalInspectionGetInventoryLegalInspectionTimelineGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InventoryLegalInspection/GetInventoryLegalInspectionTimeline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Yasal Muayene için Timeline durumlarının bilgilerini verir, açıklama amaçlıdır
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryLegalInspectionGetInventoryLegalInspectionTimelineStatusDescGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InventoryLegalInspection/GetInventoryLegalInspectionTimelineStatusDesc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Envantere ait Yasal Muayene Bakım Tiplerini listeler
         * @param {number} [inventoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryLegalInspectionGetInventoryLegalInspectionTypeListGet: async (inventoryId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InventoryLegalInspection/GetInventoryLegalInspectionTypeList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (inventoryId !== undefined) {
                localVarQueryParameter['inventoryId'] = inventoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Yasal Muayene Bakım Tipini Günceller
         * @param {InventoryLegalInspectionType} [inventoryLegalInspectionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryLegalInspectionUpdateInventoryLegalInspectionTypePost: async (inventoryLegalInspectionType?: InventoryLegalInspectionType, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InventoryLegalInspection/UpdateInventoryLegalInspectionType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inventoryLegalInspectionType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary yasal muayene nin bakım bitiş tarihini değiştirir
         * @param {LegalInspectionMaintenanceEndDateDTO} [legalInspectionMaintenanceEndDateDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryLegalInspectionUpdateLegalInspectionMaintenanceEndDatePost: async (legalInspectionMaintenanceEndDateDTO?: LegalInspectionMaintenanceEndDateDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InventoryLegalInspection/UpdateLegalInspectionMaintenanceEndDate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(legalInspectionMaintenanceEndDateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryLegalInspectionApi - functional programming interface
 * @export
 */
export const InventoryLegalInspectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventoryLegalInspectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Envantere Yasal Muayene Bakım Tipi Ekler
         * @param {InventoryLegalInspectionTypeAddDTO} [inventoryLegalInspectionTypeAddDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryLegalInspectionAddInventoryLegalInspectionTypePost(inventoryLegalInspectionTypeAddDTO?: InventoryLegalInspectionTypeAddDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryLegalInspectionAddInventoryLegalInspectionTypePost(inventoryLegalInspectionTypeAddDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Yasal Muayene Bakımı gerçekleştir
         * @param {InventoryLegalInspectionDoMakeDTO} [inventoryLegalInspectionDoMakeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryLegalInspectionDoInventoryLegalInspectionMaintenancePost(inventoryLegalInspectionDoMakeDTO?: InventoryLegalInspectionDoMakeDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryLegalInspectionDoInventoryLegalInspectionMaintenancePost(inventoryLegalInspectionDoMakeDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryLegalInspectionGetInventoryLegalInspectionForDailyMailGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryLegalInspectionGetInventoryLegalInspectionForDailyMailGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Yasal Muayene için Timeline bilgilerini verir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryLegalInspectionGetInventoryLegalInspectionTimelineGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryLegalInspectionGetInventoryLegalInspectionTimelineGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Yasal Muayene için Timeline durumlarının bilgilerini verir, açıklama amaçlıdır
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryLegalInspectionGetInventoryLegalInspectionTimelineStatusDescGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryLegalInspectionGetInventoryLegalInspectionTimelineStatusDescGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Envantere ait Yasal Muayene Bakım Tiplerini listeler
         * @param {number} [inventoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryLegalInspectionGetInventoryLegalInspectionTypeListGet(inventoryId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryLegalInspectionTypeListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryLegalInspectionGetInventoryLegalInspectionTypeListGet(inventoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Yasal Muayene Bakım Tipini Günceller
         * @param {InventoryLegalInspectionType} [inventoryLegalInspectionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryLegalInspectionUpdateInventoryLegalInspectionTypePost(inventoryLegalInspectionType?: InventoryLegalInspectionType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryLegalInspectionUpdateInventoryLegalInspectionTypePost(inventoryLegalInspectionType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary yasal muayene nin bakım bitiş tarihini değiştirir
         * @param {LegalInspectionMaintenanceEndDateDTO} [legalInspectionMaintenanceEndDateDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryLegalInspectionUpdateLegalInspectionMaintenanceEndDatePost(legalInspectionMaintenanceEndDateDTO?: LegalInspectionMaintenanceEndDateDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryLegalInspectionUpdateLegalInspectionMaintenanceEndDatePost(legalInspectionMaintenanceEndDateDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InventoryLegalInspectionApi - factory interface
 * @export
 */
export const InventoryLegalInspectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventoryLegalInspectionApiFp(configuration)
    return {
        /**
         * 
         * @summary Envantere Yasal Muayene Bakım Tipi Ekler
         * @param {InventoryLegalInspectionTypeAddDTO} [inventoryLegalInspectionTypeAddDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryLegalInspectionAddInventoryLegalInspectionTypePost(inventoryLegalInspectionTypeAddDTO?: InventoryLegalInspectionTypeAddDTO, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryLegalInspectionAddInventoryLegalInspectionTypePost(inventoryLegalInspectionTypeAddDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Yasal Muayene Bakımı gerçekleştir
         * @param {InventoryLegalInspectionDoMakeDTO} [inventoryLegalInspectionDoMakeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryLegalInspectionDoInventoryLegalInspectionMaintenancePost(inventoryLegalInspectionDoMakeDTO?: InventoryLegalInspectionDoMakeDTO, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryLegalInspectionDoInventoryLegalInspectionMaintenancePost(inventoryLegalInspectionDoMakeDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryLegalInspectionGetInventoryLegalInspectionForDailyMailGet(options?: any): AxiosPromise<Result> {
            return localVarFp.apiInventoryLegalInspectionGetInventoryLegalInspectionForDailyMailGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Yasal Muayene için Timeline bilgilerini verir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryLegalInspectionGetInventoryLegalInspectionTimelineGet(projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryLegalInspectionGetInventoryLegalInspectionTimelineGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Yasal Muayene için Timeline durumlarının bilgilerini verir, açıklama amaçlıdır
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryLegalInspectionGetInventoryLegalInspectionTimelineStatusDescGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryLegalInspectionGetInventoryLegalInspectionTimelineStatusDescGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Envantere ait Yasal Muayene Bakım Tiplerini listeler
         * @param {number} [inventoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryLegalInspectionGetInventoryLegalInspectionTypeListGet(inventoryId?: number, options?: any): AxiosPromise<InventoryLegalInspectionTypeListDataResult> {
            return localVarFp.apiInventoryLegalInspectionGetInventoryLegalInspectionTypeListGet(inventoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Yasal Muayene Bakım Tipini Günceller
         * @param {InventoryLegalInspectionType} [inventoryLegalInspectionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryLegalInspectionUpdateInventoryLegalInspectionTypePost(inventoryLegalInspectionType?: InventoryLegalInspectionType, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryLegalInspectionUpdateInventoryLegalInspectionTypePost(inventoryLegalInspectionType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary yasal muayene nin bakım bitiş tarihini değiştirir
         * @param {LegalInspectionMaintenanceEndDateDTO} [legalInspectionMaintenanceEndDateDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryLegalInspectionUpdateLegalInspectionMaintenanceEndDatePost(legalInspectionMaintenanceEndDateDTO?: LegalInspectionMaintenanceEndDateDTO, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryLegalInspectionUpdateLegalInspectionMaintenanceEndDatePost(legalInspectionMaintenanceEndDateDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventoryLegalInspectionApi - object-oriented interface
 * @export
 * @class InventoryLegalInspectionApi
 * @extends {BaseAPI}
 */
export class InventoryLegalInspectionApi extends BaseAPI {
    /**
     * 
     * @summary Envantere Yasal Muayene Bakım Tipi Ekler
     * @param {InventoryLegalInspectionTypeAddDTO} [inventoryLegalInspectionTypeAddDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryLegalInspectionApi
     */
    public apiInventoryLegalInspectionAddInventoryLegalInspectionTypePost(inventoryLegalInspectionTypeAddDTO?: InventoryLegalInspectionTypeAddDTO, options?: any) {
        return InventoryLegalInspectionApiFp(this.configuration).apiInventoryLegalInspectionAddInventoryLegalInspectionTypePost(inventoryLegalInspectionTypeAddDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Yasal Muayene Bakımı gerçekleştir
     * @param {InventoryLegalInspectionDoMakeDTO} [inventoryLegalInspectionDoMakeDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryLegalInspectionApi
     */
    public apiInventoryLegalInspectionDoInventoryLegalInspectionMaintenancePost(inventoryLegalInspectionDoMakeDTO?: InventoryLegalInspectionDoMakeDTO, options?: any) {
        return InventoryLegalInspectionApiFp(this.configuration).apiInventoryLegalInspectionDoInventoryLegalInspectionMaintenancePost(inventoryLegalInspectionDoMakeDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryLegalInspectionApi
     */
    public apiInventoryLegalInspectionGetInventoryLegalInspectionForDailyMailGet(options?: any) {
        return InventoryLegalInspectionApiFp(this.configuration).apiInventoryLegalInspectionGetInventoryLegalInspectionForDailyMailGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Yasal Muayene için Timeline bilgilerini verir
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryLegalInspectionApi
     */
    public apiInventoryLegalInspectionGetInventoryLegalInspectionTimelineGet(projectId?: number, options?: any) {
        return InventoryLegalInspectionApiFp(this.configuration).apiInventoryLegalInspectionGetInventoryLegalInspectionTimelineGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Yasal Muayene için Timeline durumlarının bilgilerini verir, açıklama amaçlıdır
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryLegalInspectionApi
     */
    public apiInventoryLegalInspectionGetInventoryLegalInspectionTimelineStatusDescGet(options?: any) {
        return InventoryLegalInspectionApiFp(this.configuration).apiInventoryLegalInspectionGetInventoryLegalInspectionTimelineStatusDescGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Envantere ait Yasal Muayene Bakım Tiplerini listeler
     * @param {number} [inventoryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryLegalInspectionApi
     */
    public apiInventoryLegalInspectionGetInventoryLegalInspectionTypeListGet(inventoryId?: number, options?: any) {
        return InventoryLegalInspectionApiFp(this.configuration).apiInventoryLegalInspectionGetInventoryLegalInspectionTypeListGet(inventoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Yasal Muayene Bakım Tipini Günceller
     * @param {InventoryLegalInspectionType} [inventoryLegalInspectionType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryLegalInspectionApi
     */
    public apiInventoryLegalInspectionUpdateInventoryLegalInspectionTypePost(inventoryLegalInspectionType?: InventoryLegalInspectionType, options?: any) {
        return InventoryLegalInspectionApiFp(this.configuration).apiInventoryLegalInspectionUpdateInventoryLegalInspectionTypePost(inventoryLegalInspectionType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary yasal muayene nin bakım bitiş tarihini değiştirir
     * @param {LegalInspectionMaintenanceEndDateDTO} [legalInspectionMaintenanceEndDateDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryLegalInspectionApi
     */
    public apiInventoryLegalInspectionUpdateLegalInspectionMaintenanceEndDatePost(legalInspectionMaintenanceEndDateDTO?: LegalInspectionMaintenanceEndDateDTO, options?: any) {
        return InventoryLegalInspectionApiFp(this.configuration).apiInventoryLegalInspectionUpdateLegalInspectionMaintenanceEndDatePost(legalInspectionMaintenanceEndDateDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InventoryMaintenanceDetailApi - axios parameter creator
 * @export
 */
export const InventoryMaintenanceDetailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Bakım işlemini geri almayı sağlar
         * @param {CancelMaintenanceProcessDTO} [cancelMaintenanceProcessDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryMaintenanceDetailCancelMaintenanceProcessPost: async (cancelMaintenanceProcessDTO?: CancelMaintenanceProcessDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InventoryMaintenanceDetail/CancelMaintenanceProcess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelMaintenanceProcessDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bakım yap
         * @param {AddInventoryMaintenanceDTO} [addInventoryMaintenanceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryMaintenanceDetailDoMaintenanceProcessPost: async (addInventoryMaintenanceDTO?: AddInventoryMaintenanceDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InventoryMaintenanceDetail/DoMaintenanceProcess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addInventoryMaintenanceDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Yapılan bakımın detayını getirir
         * @param {number} [maintenanceID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryMaintenanceDetailGetInventoryMaintenanceDetailGet: async (maintenanceID?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InventoryMaintenanceDetail/GetInventoryMaintenanceDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (maintenanceID !== undefined) {
                localVarQueryParameter['maintenanceID'] = maintenanceID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bakım notlarını listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryMaintenanceDetailGetMaintenanceNotesGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InventoryMaintenanceDetail/GetMaintenanceNotes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryMaintenanceDetailApi - functional programming interface
 * @export
 */
export const InventoryMaintenanceDetailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventoryMaintenanceDetailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Bakım işlemini geri almayı sağlar
         * @param {CancelMaintenanceProcessDTO} [cancelMaintenanceProcessDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryMaintenanceDetailCancelMaintenanceProcessPost(cancelMaintenanceProcessDTO?: CancelMaintenanceProcessDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryMaintenanceDetailCancelMaintenanceProcessPost(cancelMaintenanceProcessDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Bakım yap
         * @param {AddInventoryMaintenanceDTO} [addInventoryMaintenanceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryMaintenanceDetailDoMaintenanceProcessPost(addInventoryMaintenanceDTO?: AddInventoryMaintenanceDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryMaintenanceDetailDoMaintenanceProcessPost(addInventoryMaintenanceDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Yapılan bakımın detayını getirir
         * @param {number} [maintenanceID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryMaintenanceDetailGetInventoryMaintenanceDetailGet(maintenanceID?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryMaintenanceDetailGetInventoryMaintenanceDetailGet(maintenanceID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Bakım notlarını listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryMaintenanceDetailGetMaintenanceNotesGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryMaintenanceDetailGetMaintenanceNotesGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InventoryMaintenanceDetailApi - factory interface
 * @export
 */
export const InventoryMaintenanceDetailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventoryMaintenanceDetailApiFp(configuration)
    return {
        /**
         * 
         * @summary Bakım işlemini geri almayı sağlar
         * @param {CancelMaintenanceProcessDTO} [cancelMaintenanceProcessDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryMaintenanceDetailCancelMaintenanceProcessPost(cancelMaintenanceProcessDTO?: CancelMaintenanceProcessDTO, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryMaintenanceDetailCancelMaintenanceProcessPost(cancelMaintenanceProcessDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Bakım yap
         * @param {AddInventoryMaintenanceDTO} [addInventoryMaintenanceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryMaintenanceDetailDoMaintenanceProcessPost(addInventoryMaintenanceDTO?: AddInventoryMaintenanceDTO, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryMaintenanceDetailDoMaintenanceProcessPost(addInventoryMaintenanceDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Yapılan bakımın detayını getirir
         * @param {number} [maintenanceID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryMaintenanceDetailGetInventoryMaintenanceDetailGet(maintenanceID?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryMaintenanceDetailGetInventoryMaintenanceDetailGet(maintenanceID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Bakım notlarını listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryMaintenanceDetailGetMaintenanceNotesGet(projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryMaintenanceDetailGetMaintenanceNotesGet(projectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventoryMaintenanceDetailApi - object-oriented interface
 * @export
 * @class InventoryMaintenanceDetailApi
 * @extends {BaseAPI}
 */
export class InventoryMaintenanceDetailApi extends BaseAPI {
    /**
     * 
     * @summary Bakım işlemini geri almayı sağlar
     * @param {CancelMaintenanceProcessDTO} [cancelMaintenanceProcessDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryMaintenanceDetailApi
     */
    public apiInventoryMaintenanceDetailCancelMaintenanceProcessPost(cancelMaintenanceProcessDTO?: CancelMaintenanceProcessDTO, options?: any) {
        return InventoryMaintenanceDetailApiFp(this.configuration).apiInventoryMaintenanceDetailCancelMaintenanceProcessPost(cancelMaintenanceProcessDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Bakım yap
     * @param {AddInventoryMaintenanceDTO} [addInventoryMaintenanceDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryMaintenanceDetailApi
     */
    public apiInventoryMaintenanceDetailDoMaintenanceProcessPost(addInventoryMaintenanceDTO?: AddInventoryMaintenanceDTO, options?: any) {
        return InventoryMaintenanceDetailApiFp(this.configuration).apiInventoryMaintenanceDetailDoMaintenanceProcessPost(addInventoryMaintenanceDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Yapılan bakımın detayını getirir
     * @param {number} [maintenanceID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryMaintenanceDetailApi
     */
    public apiInventoryMaintenanceDetailGetInventoryMaintenanceDetailGet(maintenanceID?: number, options?: any) {
        return InventoryMaintenanceDetailApiFp(this.configuration).apiInventoryMaintenanceDetailGetInventoryMaintenanceDetailGet(maintenanceID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Bakım notlarını listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryMaintenanceDetailApi
     */
    public apiInventoryMaintenanceDetailGetMaintenanceNotesGet(projectId?: number, options?: any) {
        return InventoryMaintenanceDetailApiFp(this.configuration).apiInventoryMaintenanceDetailGetMaintenanceNotesGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InventoryMaintenanceTypeApi - axios parameter creator
 * @export
 */
export const InventoryMaintenanceTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Ekipmana bakım ekler.  Id alanını gönderme
         * @param {InventoryMaintenanceType} [inventoryMaintenanceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryMaintenanceTypeAddInventoryMaintenanceTypePost: async (inventoryMaintenanceType?: InventoryMaintenanceType, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InventoryMaintenanceType/AddInventoryMaintenanceType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inventoryMaintenanceType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ekipmanın sahip olduğu bakımları listeler
         * @param {number} [inventoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryMaintenanceTypeGetInventoryMaintenanteTypeListGet: async (inventoryId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InventoryMaintenanceType/GetInventoryMaintenanteTypeList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (inventoryId !== undefined) {
                localVarQueryParameter['inventoryId'] = inventoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ekipmana bakımını günceller.
         * @param {InventoryMaintenanceType} [inventoryMaintenanceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryMaintenanceTypeUpdateInventoryMaintenanceTypePost: async (inventoryMaintenanceType?: InventoryMaintenanceType, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InventoryMaintenanceType/UpdateInventoryMaintenanceType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inventoryMaintenanceType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryMaintenanceTypeApi - functional programming interface
 * @export
 */
export const InventoryMaintenanceTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventoryMaintenanceTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Ekipmana bakım ekler.  Id alanını gönderme
         * @param {InventoryMaintenanceType} [inventoryMaintenanceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryMaintenanceTypeAddInventoryMaintenanceTypePost(inventoryMaintenanceType?: InventoryMaintenanceType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryMaintenanceTypeAddInventoryMaintenanceTypePost(inventoryMaintenanceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ekipmanın sahip olduğu bakımları listeler
         * @param {number} [inventoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryMaintenanceTypeGetInventoryMaintenanteTypeListGet(inventoryId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryMaintenanceTypeGetInventoryMaintenanteTypeListGet(inventoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ekipmana bakımını günceller.
         * @param {InventoryMaintenanceType} [inventoryMaintenanceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInventoryMaintenanceTypeUpdateInventoryMaintenanceTypePost(inventoryMaintenanceType?: InventoryMaintenanceType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInventoryMaintenanceTypeUpdateInventoryMaintenanceTypePost(inventoryMaintenanceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InventoryMaintenanceTypeApi - factory interface
 * @export
 */
export const InventoryMaintenanceTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventoryMaintenanceTypeApiFp(configuration)
    return {
        /**
         * 
         * @summary Ekipmana bakım ekler.  Id alanını gönderme
         * @param {InventoryMaintenanceType} [inventoryMaintenanceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryMaintenanceTypeAddInventoryMaintenanceTypePost(inventoryMaintenanceType?: InventoryMaintenanceType, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryMaintenanceTypeAddInventoryMaintenanceTypePost(inventoryMaintenanceType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ekipmanın sahip olduğu bakımları listeler
         * @param {number} [inventoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryMaintenanceTypeGetInventoryMaintenanteTypeListGet(inventoryId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryMaintenanceTypeGetInventoryMaintenanteTypeListGet(inventoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ekipmana bakımını günceller.
         * @param {InventoryMaintenanceType} [inventoryMaintenanceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInventoryMaintenanceTypeUpdateInventoryMaintenanceTypePost(inventoryMaintenanceType?: InventoryMaintenanceType, options?: any): AxiosPromise<void> {
            return localVarFp.apiInventoryMaintenanceTypeUpdateInventoryMaintenanceTypePost(inventoryMaintenanceType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventoryMaintenanceTypeApi - object-oriented interface
 * @export
 * @class InventoryMaintenanceTypeApi
 * @extends {BaseAPI}
 */
export class InventoryMaintenanceTypeApi extends BaseAPI {
    /**
     * 
     * @summary Ekipmana bakım ekler.  Id alanını gönderme
     * @param {InventoryMaintenanceType} [inventoryMaintenanceType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryMaintenanceTypeApi
     */
    public apiInventoryMaintenanceTypeAddInventoryMaintenanceTypePost(inventoryMaintenanceType?: InventoryMaintenanceType, options?: any) {
        return InventoryMaintenanceTypeApiFp(this.configuration).apiInventoryMaintenanceTypeAddInventoryMaintenanceTypePost(inventoryMaintenanceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ekipmanın sahip olduğu bakımları listeler
     * @param {number} [inventoryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryMaintenanceTypeApi
     */
    public apiInventoryMaintenanceTypeGetInventoryMaintenanteTypeListGet(inventoryId?: number, options?: any) {
        return InventoryMaintenanceTypeApiFp(this.configuration).apiInventoryMaintenanceTypeGetInventoryMaintenanteTypeListGet(inventoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ekipmana bakımını günceller.
     * @param {InventoryMaintenanceType} [inventoryMaintenanceType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryMaintenanceTypeApi
     */
    public apiInventoryMaintenanceTypeUpdateInventoryMaintenanceTypePost(inventoryMaintenanceType?: InventoryMaintenanceType, options?: any) {
        return InventoryMaintenanceTypeApiFp(this.configuration).apiInventoryMaintenanceTypeUpdateInventoryMaintenanceTypePost(inventoryMaintenanceType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MaintenanceCommonApi - axios parameter creator
 * @export
 */
export const MaintenanceCommonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Bakım tipi ekler
         * @param {MaintenanceType} [maintenanceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceCommonAddMaintenanceTypePost: async (maintenanceType?: MaintenanceType, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceCommon/AddMaintenanceType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(maintenanceType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary bütün olan bakım periyotlarını listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceCommonGetMaintenancePeriodListByProjectIdGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceCommon/GetMaintenancePeriodListByProjectId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bakım Tipi sabitlerini listeler, bu sabitleri bakım tipi eklerken  kullanacağız.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceCommonGetMaintenanceTypeEnumsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceCommon/GetMaintenanceTypeEnums`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bütün Bakım tiplerini listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceCommonGetMaintenanceTypeListByProjectIdGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceCommon/GetMaintenanceTypeListByProjectId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Period sabitlerini listeler, bu sabitleri period eklerken  kullanacağız.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceCommonGetPeriodEnumsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceCommon/GetPeriodEnums`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceCommonMaintenanceCreateGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceCommon/MaintenanceCreate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bütün bakım listesinin start date, end date aralığının ayarlanmasını sağlar.   Örn: StartDate=-6 EndDate=24 ise 6 ay öncesi ve 24 ay sonrası aralığı.
         * @param {MaintenanceTimeLineDateRangeDto} [maintenanceTimeLineDateRangeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceCommonMaintenanceTimeLineDateRangePost: async (maintenanceTimeLineDateRangeDto?: MaintenanceTimeLineDateRangeDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceCommon/MaintenanceTimeLineDateRange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(maintenanceTimeLineDateRangeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Timeline detayını getirir.
         * @param {number} [timelineMaintenanceID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceCommonMaintenanceTimeLineDetailGet: async (timelineMaintenanceID?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceCommon/MaintenanceTimeLineDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (timelineMaintenanceID !== undefined) {
                localVarQueryParameter['timelineMaintenanceID'] = timelineMaintenanceID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bütün bakım listesini verir, Anasayfadaki Timeline ın kaynağı bu olacaktır.
         * @param {number} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceCommonMaintenanceTimeLineGet: async (projectID?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceCommon/MaintenanceTimeLine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Timelinedaki statusların açıklamalarını ve renk kodlarını verir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceCommonMaintenanceTimeLineStatusDescGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceCommon/MaintenanceTimeLineStatusDesc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bütün bakım listesinin paginationlı kullanılabilmesini sağlar.
         * @param {number} [projectId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [today] 
         * @param {boolean} [past] 
         * @param {string} [searchText] 
         * @param {Array<number>} [userTypes] 
         * @param {Array<number>} [maintenanceStatuses] 
         * @param {Array<number>} [periods] 
         * @param {Array<number>} [maintenanceTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceCommonMaintenanceTimeLineWithPaginationGet: async (projectId?: number, skip?: number, limit?: number, today?: boolean, past?: boolean, searchText?: string, userTypes?: Array<number>, maintenanceStatuses?: Array<number>, periods?: Array<number>, maintenanceTypes?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceCommon/MaintenanceTimeLineWithPagination`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (today !== undefined) {
                localVarQueryParameter['today'] = today;
            }

            if (past !== undefined) {
                localVarQueryParameter['past'] = past;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (userTypes) {
                localVarQueryParameter['UserTypes'] = userTypes;
            }

            if (maintenanceStatuses) {
                localVarQueryParameter['MaintenanceStatuses'] = maintenanceStatuses;
            }

            if (periods) {
                localVarQueryParameter['Periods'] = periods;
            }

            if (maintenanceTypes) {
                localVarQueryParameter['MaintenanceTypes'] = maintenanceTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaintenanceCommonApi - functional programming interface
 * @export
 */
export const MaintenanceCommonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MaintenanceCommonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Bakım tipi ekler
         * @param {MaintenanceType} [maintenanceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceCommonAddMaintenanceTypePost(maintenanceType?: MaintenanceType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceCommonAddMaintenanceTypePost(maintenanceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary bütün olan bakım periyotlarını listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceCommonGetMaintenancePeriodListByProjectIdGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceCommonGetMaintenancePeriodListByProjectIdGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Bakım Tipi sabitlerini listeler, bu sabitleri bakım tipi eklerken  kullanacağız.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceCommonGetMaintenanceTypeEnumsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceCommonGetMaintenanceTypeEnumsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Bütün Bakım tiplerini listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceCommonGetMaintenanceTypeListByProjectIdGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceCommonGetMaintenanceTypeListByProjectIdGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Period sabitlerini listeler, bu sabitleri period eklerken  kullanacağız.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceCommonGetPeriodEnumsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceCommonGetPeriodEnumsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceCommonMaintenanceCreateGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceCommonMaintenanceCreateGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Bütün bakım listesinin start date, end date aralığının ayarlanmasını sağlar.   Örn: StartDate=-6 EndDate=24 ise 6 ay öncesi ve 24 ay sonrası aralığı.
         * @param {MaintenanceTimeLineDateRangeDto} [maintenanceTimeLineDateRangeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceCommonMaintenanceTimeLineDateRangePost(maintenanceTimeLineDateRangeDto?: MaintenanceTimeLineDateRangeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceCommonMaintenanceTimeLineDateRangePost(maintenanceTimeLineDateRangeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Timeline detayını getirir.
         * @param {number} [timelineMaintenanceID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceCommonMaintenanceTimeLineDetailGet(timelineMaintenanceID?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceCommonMaintenanceTimeLineDetailGet(timelineMaintenanceID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Bütün bakım listesini verir, Anasayfadaki Timeline ın kaynağı bu olacaktır.
         * @param {number} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceCommonMaintenanceTimeLineGet(projectID?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceCommonMaintenanceTimeLineGet(projectID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Timelinedaki statusların açıklamalarını ve renk kodlarını verir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceCommonMaintenanceTimeLineStatusDescGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimelineStatusCodeDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceCommonMaintenanceTimeLineStatusDescGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Bütün bakım listesinin paginationlı kullanılabilmesini sağlar.
         * @param {number} [projectId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [today] 
         * @param {boolean} [past] 
         * @param {string} [searchText] 
         * @param {Array<number>} [userTypes] 
         * @param {Array<number>} [maintenanceStatuses] 
         * @param {Array<number>} [periods] 
         * @param {Array<number>} [maintenanceTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceCommonMaintenanceTimeLineWithPaginationGet(projectId?: number, skip?: number, limit?: number, today?: boolean, past?: boolean, searchText?: string, userTypes?: Array<number>, maintenanceStatuses?: Array<number>, periods?: Array<number>, maintenanceTypes?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceCommonMaintenanceTimeLineWithPaginationGet(projectId, skip, limit, today, past, searchText, userTypes, maintenanceStatuses, periods, maintenanceTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MaintenanceCommonApi - factory interface
 * @export
 */
export const MaintenanceCommonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MaintenanceCommonApiFp(configuration)
    return {
        /**
         * 
         * @summary Bakım tipi ekler
         * @param {MaintenanceType} [maintenanceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceCommonAddMaintenanceTypePost(maintenanceType?: MaintenanceType, options?: any): AxiosPromise<void> {
            return localVarFp.apiMaintenanceCommonAddMaintenanceTypePost(maintenanceType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary bütün olan bakım periyotlarını listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceCommonGetMaintenancePeriodListByProjectIdGet(projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMaintenanceCommonGetMaintenancePeriodListByProjectIdGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Bakım Tipi sabitlerini listeler, bu sabitleri bakım tipi eklerken  kullanacağız.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceCommonGetMaintenanceTypeEnumsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiMaintenanceCommonGetMaintenanceTypeEnumsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Bütün Bakım tiplerini listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceCommonGetMaintenanceTypeListByProjectIdGet(projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMaintenanceCommonGetMaintenanceTypeListByProjectIdGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Period sabitlerini listeler, bu sabitleri period eklerken  kullanacağız.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceCommonGetPeriodEnumsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiMaintenanceCommonGetPeriodEnumsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceCommonMaintenanceCreateGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiMaintenanceCommonMaintenanceCreateGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Bütün bakım listesinin start date, end date aralığının ayarlanmasını sağlar.   Örn: StartDate=-6 EndDate=24 ise 6 ay öncesi ve 24 ay sonrası aralığı.
         * @param {MaintenanceTimeLineDateRangeDto} [maintenanceTimeLineDateRangeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceCommonMaintenanceTimeLineDateRangePost(maintenanceTimeLineDateRangeDto?: MaintenanceTimeLineDateRangeDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiMaintenanceCommonMaintenanceTimeLineDateRangePost(maintenanceTimeLineDateRangeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Timeline detayını getirir.
         * @param {number} [timelineMaintenanceID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceCommonMaintenanceTimeLineDetailGet(timelineMaintenanceID?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMaintenanceCommonMaintenanceTimeLineDetailGet(timelineMaintenanceID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Bütün bakım listesini verir, Anasayfadaki Timeline ın kaynağı bu olacaktır.
         * @param {number} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceCommonMaintenanceTimeLineGet(projectID?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMaintenanceCommonMaintenanceTimeLineGet(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Timelinedaki statusların açıklamalarını ve renk kodlarını verir
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceCommonMaintenanceTimeLineStatusDescGet(options?: any): AxiosPromise<TimelineStatusCodeDTOListDataResult> {
            return localVarFp.apiMaintenanceCommonMaintenanceTimeLineStatusDescGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Bütün bakım listesinin paginationlı kullanılabilmesini sağlar.
         * @param {number} [projectId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [today] 
         * @param {boolean} [past] 
         * @param {string} [searchText] 
         * @param {Array<number>} [userTypes] 
         * @param {Array<number>} [maintenanceStatuses] 
         * @param {Array<number>} [periods] 
         * @param {Array<number>} [maintenanceTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceCommonMaintenanceTimeLineWithPaginationGet(projectId?: number, skip?: number, limit?: number, today?: boolean, past?: boolean, searchText?: string, userTypes?: Array<number>, maintenanceStatuses?: Array<number>, periods?: Array<number>, maintenanceTypes?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.apiMaintenanceCommonMaintenanceTimeLineWithPaginationGet(projectId, skip, limit, today, past, searchText, userTypes, maintenanceStatuses, periods, maintenanceTypes, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MaintenanceCommonApi - object-oriented interface
 * @export
 * @class MaintenanceCommonApi
 * @extends {BaseAPI}
 */
export class MaintenanceCommonApi extends BaseAPI {
    /**
     * 
     * @summary Bakım tipi ekler
     * @param {MaintenanceType} [maintenanceType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceCommonApi
     */
    public apiMaintenanceCommonAddMaintenanceTypePost(maintenanceType?: MaintenanceType, options?: any) {
        return MaintenanceCommonApiFp(this.configuration).apiMaintenanceCommonAddMaintenanceTypePost(maintenanceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary bütün olan bakım periyotlarını listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceCommonApi
     */
    public apiMaintenanceCommonGetMaintenancePeriodListByProjectIdGet(projectId?: number, options?: any) {
        return MaintenanceCommonApiFp(this.configuration).apiMaintenanceCommonGetMaintenancePeriodListByProjectIdGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Bakım Tipi sabitlerini listeler, bu sabitleri bakım tipi eklerken  kullanacağız.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceCommonApi
     */
    public apiMaintenanceCommonGetMaintenanceTypeEnumsGet(options?: any) {
        return MaintenanceCommonApiFp(this.configuration).apiMaintenanceCommonGetMaintenanceTypeEnumsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Bütün Bakım tiplerini listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceCommonApi
     */
    public apiMaintenanceCommonGetMaintenanceTypeListByProjectIdGet(projectId?: number, options?: any) {
        return MaintenanceCommonApiFp(this.configuration).apiMaintenanceCommonGetMaintenanceTypeListByProjectIdGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Period sabitlerini listeler, bu sabitleri period eklerken  kullanacağız.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceCommonApi
     */
    public apiMaintenanceCommonGetPeriodEnumsGet(options?: any) {
        return MaintenanceCommonApiFp(this.configuration).apiMaintenanceCommonGetPeriodEnumsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceCommonApi
     */
    public apiMaintenanceCommonMaintenanceCreateGet(options?: any) {
        return MaintenanceCommonApiFp(this.configuration).apiMaintenanceCommonMaintenanceCreateGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Bütün bakım listesinin start date, end date aralığının ayarlanmasını sağlar.   Örn: StartDate=-6 EndDate=24 ise 6 ay öncesi ve 24 ay sonrası aralığı.
     * @param {MaintenanceTimeLineDateRangeDto} [maintenanceTimeLineDateRangeDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceCommonApi
     */
    public apiMaintenanceCommonMaintenanceTimeLineDateRangePost(maintenanceTimeLineDateRangeDto?: MaintenanceTimeLineDateRangeDto, options?: any) {
        return MaintenanceCommonApiFp(this.configuration).apiMaintenanceCommonMaintenanceTimeLineDateRangePost(maintenanceTimeLineDateRangeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Timeline detayını getirir.
     * @param {number} [timelineMaintenanceID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceCommonApi
     */
    public apiMaintenanceCommonMaintenanceTimeLineDetailGet(timelineMaintenanceID?: number, options?: any) {
        return MaintenanceCommonApiFp(this.configuration).apiMaintenanceCommonMaintenanceTimeLineDetailGet(timelineMaintenanceID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Bütün bakım listesini verir, Anasayfadaki Timeline ın kaynağı bu olacaktır.
     * @param {number} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceCommonApi
     */
    public apiMaintenanceCommonMaintenanceTimeLineGet(projectID?: number, options?: any) {
        return MaintenanceCommonApiFp(this.configuration).apiMaintenanceCommonMaintenanceTimeLineGet(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Timelinedaki statusların açıklamalarını ve renk kodlarını verir
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceCommonApi
     */
    public apiMaintenanceCommonMaintenanceTimeLineStatusDescGet(options?: any) {
        return MaintenanceCommonApiFp(this.configuration).apiMaintenanceCommonMaintenanceTimeLineStatusDescGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Bütün bakım listesinin paginationlı kullanılabilmesini sağlar.
     * @param {number} [projectId] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {boolean} [today] 
     * @param {boolean} [past] 
     * @param {string} [searchText] 
     * @param {Array<number>} [userTypes] 
     * @param {Array<number>} [maintenanceStatuses] 
     * @param {Array<number>} [periods] 
     * @param {Array<number>} [maintenanceTypes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceCommonApi
     */
    public apiMaintenanceCommonMaintenanceTimeLineWithPaginationGet(projectId?: number, skip?: number, limit?: number, today?: boolean, past?: boolean, searchText?: string, userTypes?: Array<number>, maintenanceStatuses?: Array<number>, periods?: Array<number>, maintenanceTypes?: Array<number>, options?: any) {
        return MaintenanceCommonApiFp(this.configuration).apiMaintenanceCommonMaintenanceTimeLineWithPaginationGet(projectId, skip, limit, today, past, searchText, userTypes, maintenanceStatuses, periods, maintenanceTypes, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MaintenanceQuestionApi - axios parameter creator
 * @export
 */
export const MaintenanceQuestionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Bakıma soru ekler
         * @param {MaintenanceQuestion} [maintenanceQuestion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceQuestionAddMaintenanceQuestionPost: async (maintenanceQuestion?: MaintenanceQuestion, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceQuestion/AddMaintenanceQuestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(maintenanceQuestion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bakıma ait sorularını listeler
         * @param {number} [maintenanceID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceQuestionGetInventoryQuestionListGet: async (maintenanceID?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceQuestion/GetInventoryQuestionList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (maintenanceID !== undefined) {
                localVarQueryParameter['maintenanceID'] = maintenanceID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Soru tiplerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceQuestionGetMaintenanceQuestionTypeListPost: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceQuestion/GetMaintenanceQuestionTypeList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bakım sorusunu günceller
         * @param {MaintenanceQuestion} [maintenanceQuestion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceQuestionUpdateMaintenanceQuestionPost: async (maintenanceQuestion?: MaintenanceQuestion, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceQuestion/UpdateMaintenanceQuestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(maintenanceQuestion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaintenanceQuestionApi - functional programming interface
 * @export
 */
export const MaintenanceQuestionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MaintenanceQuestionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Bakıma soru ekler
         * @param {MaintenanceQuestion} [maintenanceQuestion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceQuestionAddMaintenanceQuestionPost(maintenanceQuestion?: MaintenanceQuestion, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceQuestionAddMaintenanceQuestionPost(maintenanceQuestion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Bakıma ait sorularını listeler
         * @param {number} [maintenanceID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceQuestionGetInventoryQuestionListGet(maintenanceID?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenanceQuestionDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceQuestionGetInventoryQuestionListGet(maintenanceID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Soru tiplerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceQuestionGetMaintenanceQuestionTypeListPost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenanceQuestionTypeListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceQuestionGetMaintenanceQuestionTypeListPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Bakım sorusunu günceller
         * @param {MaintenanceQuestion} [maintenanceQuestion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceQuestionUpdateMaintenanceQuestionPost(maintenanceQuestion?: MaintenanceQuestion, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceQuestionUpdateMaintenanceQuestionPost(maintenanceQuestion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MaintenanceQuestionApi - factory interface
 * @export
 */
export const MaintenanceQuestionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MaintenanceQuestionApiFp(configuration)
    return {
        /**
         * 
         * @summary Bakıma soru ekler
         * @param {MaintenanceQuestion} [maintenanceQuestion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceQuestionAddMaintenanceQuestionPost(maintenanceQuestion?: MaintenanceQuestion, options?: any): AxiosPromise<Result> {
            return localVarFp.apiMaintenanceQuestionAddMaintenanceQuestionPost(maintenanceQuestion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Bakıma ait sorularını listeler
         * @param {number} [maintenanceID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceQuestionGetInventoryQuestionListGet(maintenanceID?: number, options?: any): AxiosPromise<MaintenanceQuestionDTOListDataResult> {
            return localVarFp.apiMaintenanceQuestionGetInventoryQuestionListGet(maintenanceID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Soru tiplerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceQuestionGetMaintenanceQuestionTypeListPost(options?: any): AxiosPromise<MaintenanceQuestionTypeListDataResult> {
            return localVarFp.apiMaintenanceQuestionGetMaintenanceQuestionTypeListPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Bakım sorusunu günceller
         * @param {MaintenanceQuestion} [maintenanceQuestion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceQuestionUpdateMaintenanceQuestionPost(maintenanceQuestion?: MaintenanceQuestion, options?: any): AxiosPromise<Result> {
            return localVarFp.apiMaintenanceQuestionUpdateMaintenanceQuestionPost(maintenanceQuestion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MaintenanceQuestionApi - object-oriented interface
 * @export
 * @class MaintenanceQuestionApi
 * @extends {BaseAPI}
 */
export class MaintenanceQuestionApi extends BaseAPI {
    /**
     * 
     * @summary Bakıma soru ekler
     * @param {MaintenanceQuestion} [maintenanceQuestion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceQuestionApi
     */
    public apiMaintenanceQuestionAddMaintenanceQuestionPost(maintenanceQuestion?: MaintenanceQuestion, options?: any) {
        return MaintenanceQuestionApiFp(this.configuration).apiMaintenanceQuestionAddMaintenanceQuestionPost(maintenanceQuestion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Bakıma ait sorularını listeler
     * @param {number} [maintenanceID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceQuestionApi
     */
    public apiMaintenanceQuestionGetInventoryQuestionListGet(maintenanceID?: number, options?: any) {
        return MaintenanceQuestionApiFp(this.configuration).apiMaintenanceQuestionGetInventoryQuestionListGet(maintenanceID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Soru tiplerini listeler
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceQuestionApi
     */
    public apiMaintenanceQuestionGetMaintenanceQuestionTypeListPost(options?: any) {
        return MaintenanceQuestionApiFp(this.configuration).apiMaintenanceQuestionGetMaintenanceQuestionTypeListPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Bakım sorusunu günceller
     * @param {MaintenanceQuestion} [maintenanceQuestion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceQuestionApi
     */
    public apiMaintenanceQuestionUpdateMaintenanceQuestionPost(maintenanceQuestion?: MaintenanceQuestion, options?: any) {
        return MaintenanceQuestionApiFp(this.configuration).apiMaintenanceQuestionUpdateMaintenanceQuestionPost(maintenanceQuestion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MaintenanceReportApi - axios parameter creator
 * @export
 */
export const MaintenanceReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Barkod a göre envanterin bakım geçmişini listeler
         * @param {number} [projectId] 
         * @param {string} [barcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceReportGetAllMaintenanceReportByBarcodeGet: async (projectId?: number, barcode?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceReport/GetAllMaintenanceReportByBarcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (barcode !== undefined) {
                localVarQueryParameter['barcode'] = barcode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary yaklaşan bakımları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceReportGetComingMaintenanceGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceReport/GetComingMaintenance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gerçekleşen bakımı getirir
         * @param {number} [projectId] 
         * @param {number} [maintenanceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceReportGetCompletedMaintenanceByIdGet: async (projectId?: number, maintenanceId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceReport/GetCompletedMaintenanceById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (maintenanceId !== undefined) {
                localVarQueryParameter['maintenanceId'] = maintenanceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gerçekleşen bakımları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceReportGetCompletedMaintenanceGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceReport/GetCompletedMaintenance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Anasayfadaki özet istatistik kısmını verir,    TotalInventoryCount = Kayıtlı Ekipman Sayısı,                TotalInternalMaintenance = Kayıtlı İç Bakım Sayısı,                 TotalDetailedInternalMaintenance = Kayıtlı Detaylı İç Bakım Sayısı,                 TotalDetailedExternalMaintenance = Kayıtlı Detaylı Dış Bakım Sayısı,  TotalExternalMaintenance = Kayıtlı Dış Bakım Sayısı, <br />  TotalLegalMaintenance = Kayıtlı Yasal Muayene Bakım Sayısı,<br />   UnDoneInternalMaintenance = Gerçekleşmeyen İç Bakım Sayısı, <br />  UnDoneDetailedInternalMaintenance = Gerçekleşmeyen Detaylı İç Bakım Sayısı,   UnDoneDetailedExternalMaintenance = Gerçekleşmeyen Detaylı Dış Bakım Sayısı,    UnDoneExternalMaintenance = Gerçekleşmeyen Dış Bakım Sayısı,   UnDoneLegalMaintenance = Gerçekleşmeyen Yasal Muayene Bakım Sayısı,  UnCompletedMaintenance = Tamamlanmayan Yasal Muayene Bakım Sayısı
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceReportGetHomePageStatisticsGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceReport/GetHomePageStatistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sorugulama kısmında bakımın detayını verir, yapıldı ise
         * @param {number} [maintenanceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceReportGetMaintenanceReportDetailGet: async (maintenanceId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceReport/GetMaintenanceReportDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (maintenanceId !== undefined) {
                localVarQueryParameter['maintenanceId'] = maintenanceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary olumsuz yanıtlanan soruları listler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceReportGetNegativeAnswerMaintenanceGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceReport/GetNegativeAnswerMaintenance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gerçekleşmeyen bakımları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceReportGetNotCompletedMaintenanceGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MaintenanceReport/GetNotCompletedMaintenance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaintenanceReportApi - functional programming interface
 * @export
 */
export const MaintenanceReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MaintenanceReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Barkod a göre envanterin bakım geçmişini listeler
         * @param {number} [projectId] 
         * @param {string} [barcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceReportGetAllMaintenanceReportByBarcodeGet(projectId?: number, barcode?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceReportGetAllMaintenanceReportByBarcodeGet(projectId, barcode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary yaklaşan bakımları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceReportGetComingMaintenanceGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceReportGetComingMaintenanceGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gerçekleşen bakımı getirir
         * @param {number} [projectId] 
         * @param {number} [maintenanceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceReportGetCompletedMaintenanceByIdGet(projectId?: number, maintenanceId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceReportGetCompletedMaintenanceByIdGet(projectId, maintenanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gerçekleşen bakımları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceReportGetCompletedMaintenanceGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceReportGetCompletedMaintenanceGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Anasayfadaki özet istatistik kısmını verir,    TotalInventoryCount = Kayıtlı Ekipman Sayısı,                TotalInternalMaintenance = Kayıtlı İç Bakım Sayısı,                 TotalDetailedInternalMaintenance = Kayıtlı Detaylı İç Bakım Sayısı,                 TotalDetailedExternalMaintenance = Kayıtlı Detaylı Dış Bakım Sayısı,  TotalExternalMaintenance = Kayıtlı Dış Bakım Sayısı, <br />  TotalLegalMaintenance = Kayıtlı Yasal Muayene Bakım Sayısı,<br />   UnDoneInternalMaintenance = Gerçekleşmeyen İç Bakım Sayısı, <br />  UnDoneDetailedInternalMaintenance = Gerçekleşmeyen Detaylı İç Bakım Sayısı,   UnDoneDetailedExternalMaintenance = Gerçekleşmeyen Detaylı Dış Bakım Sayısı,    UnDoneExternalMaintenance = Gerçekleşmeyen Dış Bakım Sayısı,   UnDoneLegalMaintenance = Gerçekleşmeyen Yasal Muayene Bakım Sayısı,  UnCompletedMaintenance = Tamamlanmayan Yasal Muayene Bakım Sayısı
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceReportGetHomePageStatisticsGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceReportGetHomePageStatisticsGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sorugulama kısmında bakımın detayını verir, yapıldı ise
         * @param {number} [maintenanceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceReportGetMaintenanceReportDetailGet(maintenanceId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceReportGetMaintenanceReportDetailGet(maintenanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary olumsuz yanıtlanan soruları listler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceReportGetNegativeAnswerMaintenanceGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompletedMaintenanceDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceReportGetNegativeAnswerMaintenanceGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gerçekleşmeyen bakımları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMaintenanceReportGetNotCompletedMaintenanceGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMaintenanceReportGetNotCompletedMaintenanceGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MaintenanceReportApi - factory interface
 * @export
 */
export const MaintenanceReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MaintenanceReportApiFp(configuration)
    return {
        /**
         * 
         * @summary Barkod a göre envanterin bakım geçmişini listeler
         * @param {number} [projectId] 
         * @param {string} [barcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceReportGetAllMaintenanceReportByBarcodeGet(projectId?: number, barcode?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiMaintenanceReportGetAllMaintenanceReportByBarcodeGet(projectId, barcode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary yaklaşan bakımları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceReportGetComingMaintenanceGet(projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMaintenanceReportGetComingMaintenanceGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gerçekleşen bakımı getirir
         * @param {number} [projectId] 
         * @param {number} [maintenanceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceReportGetCompletedMaintenanceByIdGet(projectId?: number, maintenanceId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMaintenanceReportGetCompletedMaintenanceByIdGet(projectId, maintenanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gerçekleşen bakımları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceReportGetCompletedMaintenanceGet(projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMaintenanceReportGetCompletedMaintenanceGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Anasayfadaki özet istatistik kısmını verir,    TotalInventoryCount = Kayıtlı Ekipman Sayısı,                TotalInternalMaintenance = Kayıtlı İç Bakım Sayısı,                 TotalDetailedInternalMaintenance = Kayıtlı Detaylı İç Bakım Sayısı,                 TotalDetailedExternalMaintenance = Kayıtlı Detaylı Dış Bakım Sayısı,  TotalExternalMaintenance = Kayıtlı Dış Bakım Sayısı, <br />  TotalLegalMaintenance = Kayıtlı Yasal Muayene Bakım Sayısı,<br />   UnDoneInternalMaintenance = Gerçekleşmeyen İç Bakım Sayısı, <br />  UnDoneDetailedInternalMaintenance = Gerçekleşmeyen Detaylı İç Bakım Sayısı,   UnDoneDetailedExternalMaintenance = Gerçekleşmeyen Detaylı Dış Bakım Sayısı,    UnDoneExternalMaintenance = Gerçekleşmeyen Dış Bakım Sayısı,   UnDoneLegalMaintenance = Gerçekleşmeyen Yasal Muayene Bakım Sayısı,  UnCompletedMaintenance = Tamamlanmayan Yasal Muayene Bakım Sayısı
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceReportGetHomePageStatisticsGet(projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMaintenanceReportGetHomePageStatisticsGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sorugulama kısmında bakımın detayını verir, yapıldı ise
         * @param {number} [maintenanceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceReportGetMaintenanceReportDetailGet(maintenanceId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMaintenanceReportGetMaintenanceReportDetailGet(maintenanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary olumsuz yanıtlanan soruları listler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceReportGetNegativeAnswerMaintenanceGet(projectId?: number, options?: any): AxiosPromise<CompletedMaintenanceDTOListDataResult> {
            return localVarFp.apiMaintenanceReportGetNegativeAnswerMaintenanceGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gerçekleşmeyen bakımları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMaintenanceReportGetNotCompletedMaintenanceGet(projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMaintenanceReportGetNotCompletedMaintenanceGet(projectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MaintenanceReportApi - object-oriented interface
 * @export
 * @class MaintenanceReportApi
 * @extends {BaseAPI}
 */
export class MaintenanceReportApi extends BaseAPI {
    /**
     * 
     * @summary Barkod a göre envanterin bakım geçmişini listeler
     * @param {number} [projectId] 
     * @param {string} [barcode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceReportApi
     */
    public apiMaintenanceReportGetAllMaintenanceReportByBarcodeGet(projectId?: number, barcode?: string, options?: any) {
        return MaintenanceReportApiFp(this.configuration).apiMaintenanceReportGetAllMaintenanceReportByBarcodeGet(projectId, barcode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary yaklaşan bakımları listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceReportApi
     */
    public apiMaintenanceReportGetComingMaintenanceGet(projectId?: number, options?: any) {
        return MaintenanceReportApiFp(this.configuration).apiMaintenanceReportGetComingMaintenanceGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gerçekleşen bakımı getirir
     * @param {number} [projectId] 
     * @param {number} [maintenanceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceReportApi
     */
    public apiMaintenanceReportGetCompletedMaintenanceByIdGet(projectId?: number, maintenanceId?: number, options?: any) {
        return MaintenanceReportApiFp(this.configuration).apiMaintenanceReportGetCompletedMaintenanceByIdGet(projectId, maintenanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gerçekleşen bakımları listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceReportApi
     */
    public apiMaintenanceReportGetCompletedMaintenanceGet(projectId?: number, options?: any) {
        return MaintenanceReportApiFp(this.configuration).apiMaintenanceReportGetCompletedMaintenanceGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Anasayfadaki özet istatistik kısmını verir,    TotalInventoryCount = Kayıtlı Ekipman Sayısı,                TotalInternalMaintenance = Kayıtlı İç Bakım Sayısı,                 TotalDetailedInternalMaintenance = Kayıtlı Detaylı İç Bakım Sayısı,                 TotalDetailedExternalMaintenance = Kayıtlı Detaylı Dış Bakım Sayısı,  TotalExternalMaintenance = Kayıtlı Dış Bakım Sayısı, <br />  TotalLegalMaintenance = Kayıtlı Yasal Muayene Bakım Sayısı,<br />   UnDoneInternalMaintenance = Gerçekleşmeyen İç Bakım Sayısı, <br />  UnDoneDetailedInternalMaintenance = Gerçekleşmeyen Detaylı İç Bakım Sayısı,   UnDoneDetailedExternalMaintenance = Gerçekleşmeyen Detaylı Dış Bakım Sayısı,    UnDoneExternalMaintenance = Gerçekleşmeyen Dış Bakım Sayısı,   UnDoneLegalMaintenance = Gerçekleşmeyen Yasal Muayene Bakım Sayısı,  UnCompletedMaintenance = Tamamlanmayan Yasal Muayene Bakım Sayısı
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceReportApi
     */
    public apiMaintenanceReportGetHomePageStatisticsGet(projectId?: number, options?: any) {
        return MaintenanceReportApiFp(this.configuration).apiMaintenanceReportGetHomePageStatisticsGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sorugulama kısmında bakımın detayını verir, yapıldı ise
     * @param {number} [maintenanceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceReportApi
     */
    public apiMaintenanceReportGetMaintenanceReportDetailGet(maintenanceId?: number, options?: any) {
        return MaintenanceReportApiFp(this.configuration).apiMaintenanceReportGetMaintenanceReportDetailGet(maintenanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary olumsuz yanıtlanan soruları listler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceReportApi
     */
    public apiMaintenanceReportGetNegativeAnswerMaintenanceGet(projectId?: number, options?: any) {
        return MaintenanceReportApiFp(this.configuration).apiMaintenanceReportGetNegativeAnswerMaintenanceGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gerçekleşmeyen bakımları listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceReportApi
     */
    public apiMaintenanceReportGetNotCompletedMaintenanceGet(projectId?: number, options?: any) {
        return MaintenanceReportApiFp(this.configuration).apiMaintenanceReportGetNotCompletedMaintenanceGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NoteApi - axios parameter creator
 * @export
 */
export const NoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Not ekler
         * @param {Note} [note] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNoteAddNotePost: async (note?: Note, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Note/AddNote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(note, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tarihe göre notu getirir.
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNoteGetNoteByDateGet: async (date?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Note/GetNoteByDate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary idye göre notun bilgilerini getirir
         * @param {number} [noteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNoteGetNoteListByIDGet: async (noteId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Note/GetNoteListByID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (noteId !== undefined) {
                localVarQueryParameter['noteId'] = noteId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Projeye ait notları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNoteGetNoteListByProjectIDGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Note/GetNoteListByProjectID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Not günceller
         * @param {Note} [note] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNoteUpdateNotePost: async (note?: Note, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Note/UpdateNote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(note, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoteApi - functional programming interface
 * @export
 */
export const NoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Not ekler
         * @param {Note} [note] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNoteAddNotePost(note?: Note, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNoteAddNotePost(note, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tarihe göre notu getirir.
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNoteGetNoteByDateGet(date?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNoteGetNoteByDateGet(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary idye göre notun bilgilerini getirir
         * @param {number} [noteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNoteGetNoteListByIDGet(noteId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNoteGetNoteListByIDGet(noteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Projeye ait notları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNoteGetNoteListByProjectIDGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNoteGetNoteListByProjectIDGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Not günceller
         * @param {Note} [note] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNoteUpdateNotePost(note?: Note, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNoteUpdateNotePost(note, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NoteApi - factory interface
 * @export
 */
export const NoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoteApiFp(configuration)
    return {
        /**
         * 
         * @summary Not ekler
         * @param {Note} [note] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNoteAddNotePost(note?: Note, options?: any): AxiosPromise<void> {
            return localVarFp.apiNoteAddNotePost(note, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tarihe göre notu getirir.
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNoteGetNoteByDateGet(date?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiNoteGetNoteByDateGet(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary idye göre notun bilgilerini getirir
         * @param {number} [noteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNoteGetNoteListByIDGet(noteId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiNoteGetNoteListByIDGet(noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Projeye ait notları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNoteGetNoteListByProjectIDGet(projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiNoteGetNoteListByProjectIDGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Not günceller
         * @param {Note} [note] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNoteUpdateNotePost(note?: Note, options?: any): AxiosPromise<void> {
            return localVarFp.apiNoteUpdateNotePost(note, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoteApi - object-oriented interface
 * @export
 * @class NoteApi
 * @extends {BaseAPI}
 */
export class NoteApi extends BaseAPI {
    /**
     * 
     * @summary Not ekler
     * @param {Note} [note] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public apiNoteAddNotePost(note?: Note, options?: any) {
        return NoteApiFp(this.configuration).apiNoteAddNotePost(note, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tarihe göre notu getirir.
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public apiNoteGetNoteByDateGet(date?: string, options?: any) {
        return NoteApiFp(this.configuration).apiNoteGetNoteByDateGet(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary idye göre notun bilgilerini getirir
     * @param {number} [noteId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public apiNoteGetNoteListByIDGet(noteId?: number, options?: any) {
        return NoteApiFp(this.configuration).apiNoteGetNoteListByIDGet(noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Projeye ait notları listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public apiNoteGetNoteListByProjectIDGet(projectId?: number, options?: any) {
        return NoteApiFp(this.configuration).apiNoteGetNoteListByProjectIDGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Not günceller
     * @param {Note} [note] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public apiNoteUpdateNotePost(note?: Note, options?: any) {
        return NoteApiFp(this.configuration).apiNoteUpdateNotePost(note, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Proje ekler
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectAddProjectPost: async (project?: Project, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/AddProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(project, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Id ye göre  projeyi getirir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectGetProjectByIdGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Şirketin bütün projelerini listeler
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectGetProjectListByCompanyIDGet: async (companyId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectListByCompanyID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Proje günceller
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectUpdateProjectPost: async (project?: Project, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/UpdateProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(project, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Proje ekler
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectAddProjectPost(project?: Project, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectAddProjectPost(project, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Id ye göre  projeyi getirir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectGetProjectByIdGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectGetProjectByIdGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Şirketin bütün projelerini listeler
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectGetProjectListByCompanyIDGet(companyId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectGetProjectListByCompanyIDGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Proje günceller
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectUpdateProjectPost(project?: Project, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectUpdateProjectPost(project, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * 
         * @summary Proje ekler
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectAddProjectPost(project?: Project, options?: any): AxiosPromise<void> {
            return localVarFp.apiProjectAddProjectPost(project, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Id ye göre  projeyi getirir
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectGetProjectByIdGet(projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProjectGetProjectByIdGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Şirketin bütün projelerini listeler
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectGetProjectListByCompanyIDGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProjectGetProjectListByCompanyIDGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Proje günceller
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectUpdateProjectPost(project?: Project, options?: any): AxiosPromise<void> {
            return localVarFp.apiProjectUpdateProjectPost(project, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * 
     * @summary Proje ekler
     * @param {Project} [project] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiProjectAddProjectPost(project?: Project, options?: any) {
        return ProjectApiFp(this.configuration).apiProjectAddProjectPost(project, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Id ye göre  projeyi getirir
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiProjectGetProjectByIdGet(projectId?: number, options?: any) {
        return ProjectApiFp(this.configuration).apiProjectGetProjectByIdGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Şirketin bütün projelerini listeler
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiProjectGetProjectListByCompanyIDGet(companyId?: number, options?: any) {
        return ProjectApiFp(this.configuration).apiProjectGetProjectListByCompanyIDGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Proje günceller
     * @param {Project} [project] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public apiProjectUpdateProjectPost(project?: Project, options?: any) {
        return ProjectApiFp(this.configuration).apiProjectUpdateProjectPost(project, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoomApi - axios parameter creator
 * @export
 */
export const RoomApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Oda ekler.
         * @param {Room} [room] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoomAddRoomPost: async (room?: Room, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Room/AddRoom`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(room, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Idye göre odanın bütün bilgilerini getirir
         * @param {number} [floorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoomGetRoomByIdGet: async (floorId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Room/GetRoomById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (floorId !== undefined) {
                localVarQueryParameter['floorId'] = floorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Katın bütün odalarını listeler
         * @param {number} [floorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoomGetRoomListByFloorIDGet: async (floorId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Room/GetRoomListByFloorID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (floorId !== undefined) {
                localVarQueryParameter['floorId'] = floorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Oda günceller
         * @param {Room} [room] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoomUpdateRoomPost: async (room?: Room, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Room/UpdateRoom`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(room, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoomApi - functional programming interface
 * @export
 */
export const RoomApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoomApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Oda ekler.
         * @param {Room} [room] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRoomAddRoomPost(room?: Room, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRoomAddRoomPost(room, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Idye göre odanın bütün bilgilerini getirir
         * @param {number} [floorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRoomGetRoomByIdGet(floorId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRoomGetRoomByIdGet(floorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Katın bütün odalarını listeler
         * @param {number} [floorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRoomGetRoomListByFloorIDGet(floorId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRoomGetRoomListByFloorIDGet(floorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Oda günceller
         * @param {Room} [room] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRoomUpdateRoomPost(room?: Room, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRoomUpdateRoomPost(room, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoomApi - factory interface
 * @export
 */
export const RoomApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoomApiFp(configuration)
    return {
        /**
         * 
         * @summary Oda ekler.
         * @param {Room} [room] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoomAddRoomPost(room?: Room, options?: any): AxiosPromise<void> {
            return localVarFp.apiRoomAddRoomPost(room, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Idye göre odanın bütün bilgilerini getirir
         * @param {number} [floorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoomGetRoomByIdGet(floorId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiRoomGetRoomByIdGet(floorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Katın bütün odalarını listeler
         * @param {number} [floorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoomGetRoomListByFloorIDGet(floorId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiRoomGetRoomListByFloorIDGet(floorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Oda günceller
         * @param {Room} [room] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoomUpdateRoomPost(room?: Room, options?: any): AxiosPromise<void> {
            return localVarFp.apiRoomUpdateRoomPost(room, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoomApi - object-oriented interface
 * @export
 * @class RoomApi
 * @extends {BaseAPI}
 */
export class RoomApi extends BaseAPI {
    /**
     * 
     * @summary Oda ekler.
     * @param {Room} [room] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public apiRoomAddRoomPost(room?: Room, options?: any) {
        return RoomApiFp(this.configuration).apiRoomAddRoomPost(room, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Idye göre odanın bütün bilgilerini getirir
     * @param {number} [floorId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public apiRoomGetRoomByIdGet(floorId?: number, options?: any) {
        return RoomApiFp(this.configuration).apiRoomGetRoomByIdGet(floorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Katın bütün odalarını listeler
     * @param {number} [floorId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public apiRoomGetRoomListByFloorIDGet(floorId?: number, options?: any) {
        return RoomApiFp(this.configuration).apiRoomGetRoomListByFloorIDGet(floorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Oda günceller
     * @param {Room} [room] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public apiRoomUpdateRoomPost(room?: Room, options?: any) {
        return RoomApiFp(this.configuration).apiRoomUpdateRoomPost(room, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Timeline üzerinde global search sağlar
         * @param {number} [projectId] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchTimelineGlobalSearchGet: async (projectId?: number, searchText?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Search/TimelineGlobalSearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Timeline üzerinde global search sağlar
         * @param {number} [projectId] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSearchTimelineGlobalSearchGet(projectId?: number, searchText?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSearchTimelineGlobalSearchGet(projectId, searchText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * 
         * @summary Timeline üzerinde global search sağlar
         * @param {number} [projectId] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchTimelineGlobalSearchGet(projectId?: number, searchText?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiSearchTimelineGlobalSearchGet(projectId, searchText, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * 
     * @summary Timeline üzerinde global search sağlar
     * @param {number} [projectId] 
     * @param {string} [searchText] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public apiSearchTimelineGlobalSearchGet(projectId?: number, searchText?: string, options?: any) {
        return SearchApiFp(this.configuration).apiSearchTimelineGlobalSearchGet(projectId, searchText, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddMobilLogDTO} [addMobilLogDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersAddMobilLogPost: async (addMobilLogDTO?: AddMobilLogDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/AddMobilLog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addMobilLogDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kullanıcı ekler  isNotificationEnabled (treu/false)  isEmailEnabled (treu/false)
         * @param {AddUserDTO} [addUserDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersAddUserPost: async (addUserDTO?: AddUserDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/AddUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addUserDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary kullanıcıya proje atar
         * @param {UserProject} [userProject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersAddUserProjectPost: async (userProject?: UserProject, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/AddUserProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Yeni kullanıcı tipi ekler
         * @param {UserTypeAddDTO} [userTypeAddDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersAddUserTypePost: async (userTypeAddDTO?: UserTypeAddDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/AddUserType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userTypeAddDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Id ye göre kullanıcı bilgilerini getirir
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGetUserByIdGet: async (userId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/GetUserById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Projeye dahil olan bütün kullanıcıları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGetUserListByProjectIDGet: async (projectId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/GetUserListByProjectID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kullanıcının sahip olduğu projeleri listeler
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGetUserProjectByUserIDGet: async (userId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/getUserProjectByUserID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kullanıcı rollerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGetUserRoleListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/GetUserRoleList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kukullanıcının sahip olduğu bütün user tipleri listeler
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGetUserTypeByUserIDGet: async (userId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/GetUserTypeByUserID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kullanıcı tiplerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGetUserTypeListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/GetUserTypeList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary kullanıcı şifresini sıfırlar
         * @param {number} [userID] 
         * @param {string} [newPass] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersPasswordResetGet: async (userID?: number, newPass?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/PasswordReset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userID !== undefined) {
                localVarQueryParameter['userID'] = userID;
            }

            if (newPass !== undefined) {
                localVarQueryParameter['newPass'] = newPass;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary hangi platformdan bildirim alabileceğini düzenler.
         * @param {number} [userID] 
         * @param {boolean} [emailNotify] 
         * @param {boolean} [notificationNotify] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersUpdateUserNotifyChanceGet: async (userID?: number, emailNotify?: boolean, notificationNotify?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/UpdateUserNotifyChance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userID !== undefined) {
                localVarQueryParameter['userID'] = userID;
            }

            if (emailNotify !== undefined) {
                localVarQueryParameter['emailNotify'] = emailNotify;
            }

            if (notificationNotify !== undefined) {
                localVarQueryParameter['notificationNotify'] = notificationNotify;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary kullanıcı şifresini günceller
         * @param {number} [userID] 
         * @param {string} [oldPass] 
         * @param {string} [newPass] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersUpdateUserPasswordChangeGet: async (userID?: number, oldPass?: string, newPass?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/UpdateUserPasswordChange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userID !== undefined) {
                localVarQueryParameter['userID'] = userID;
            }

            if (oldPass !== undefined) {
                localVarQueryParameter['oldPass'] = oldPass;
            }

            if (newPass !== undefined) {
                localVarQueryParameter['newPass'] = newPass;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kullanıcı günceller  notificationToken alanı null olacak  passwordSalt alanı null olacak  id alanı zorunlu  isNotificationEnabled zorunlu  isEmailEnabled zorunlu  IsActive zorunlu
         * @param {UserUpdateDTO} [userUpdateDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersUpdateUserPost: async (userUpdateDTO?: UserUpdateDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/UpdateUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary kullanıcı tipi günceller
         * @param {UserTypeUpdateDTO} [userTypeUpdateDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersUpdateUserTypePost: async (userTypeUpdateDTO?: UserTypeUpdateDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/UpdateUserType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userTypeUpdateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [versionNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersVersionControlGet: async (versionNo?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/VersionControl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (versionNo !== undefined) {
                localVarQueryParameter['versionNo'] = versionNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddMobilLogDTO} [addMobilLogDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersAddMobilLogPost(addMobilLogDTO?: AddMobilLogDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersAddMobilLogPost(addMobilLogDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kullanıcı ekler  isNotificationEnabled (treu/false)  isEmailEnabled (treu/false)
         * @param {AddUserDTO} [addUserDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersAddUserPost(addUserDTO?: AddUserDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersAddUserPost(addUserDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary kullanıcıya proje atar
         * @param {UserProject} [userProject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersAddUserProjectPost(userProject?: UserProject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersAddUserProjectPost(userProject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Yeni kullanıcı tipi ekler
         * @param {UserTypeAddDTO} [userTypeAddDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersAddUserTypePost(userTypeAddDTO?: UserTypeAddDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersAddUserTypePost(userTypeAddDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Id ye göre kullanıcı bilgilerini getirir
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersGetUserByIdGet(userId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersGetUserByIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Projeye dahil olan bütün kullanıcıları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersGetUserListByProjectIDGet(projectId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersGetUserListByProjectIDGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kullanıcının sahip olduğu projeleri listeler
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersGetUserProjectByUserIDGet(userId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProjectDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersGetUserProjectByUserIDGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kullanıcı rollerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersGetUserRoleListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersGetUserRoleListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kukullanıcının sahip olduğu bütün user tipleri listeler
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersGetUserTypeByUserIDGet(userId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserTypeDTOListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersGetUserTypeByUserIDGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kullanıcı tiplerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersGetUserTypeListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserTypeListDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersGetUserTypeListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary kullanıcı şifresini sıfırlar
         * @param {number} [userID] 
         * @param {string} [newPass] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersPasswordResetGet(userID?: number, newPass?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersPasswordResetGet(userID, newPass, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary hangi platformdan bildirim alabileceğini düzenler.
         * @param {number} [userID] 
         * @param {boolean} [emailNotify] 
         * @param {boolean} [notificationNotify] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersUpdateUserNotifyChanceGet(userID?: number, emailNotify?: boolean, notificationNotify?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersUpdateUserNotifyChanceGet(userID, emailNotify, notificationNotify, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary kullanıcı şifresini günceller
         * @param {number} [userID] 
         * @param {string} [oldPass] 
         * @param {string} [newPass] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersUpdateUserPasswordChangeGet(userID?: number, oldPass?: string, newPass?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersUpdateUserPasswordChangeGet(userID, oldPass, newPass, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kullanıcı günceller  notificationToken alanı null olacak  passwordSalt alanı null olacak  id alanı zorunlu  isNotificationEnabled zorunlu  isEmailEnabled zorunlu  IsActive zorunlu
         * @param {UserUpdateDTO} [userUpdateDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersUpdateUserPost(userUpdateDTO?: UserUpdateDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersUpdateUserPost(userUpdateDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary kullanıcı tipi günceller
         * @param {UserTypeUpdateDTO} [userTypeUpdateDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersUpdateUserTypePost(userTypeUpdateDTO?: UserTypeUpdateDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersUpdateUserTypePost(userTypeUpdateDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [versionNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersVersionControlGet(versionNo?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersVersionControlGet(versionNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {AddMobilLogDTO} [addMobilLogDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersAddMobilLogPost(addMobilLogDTO?: AddMobilLogDTO, options?: any): AxiosPromise<void> {
            return localVarFp.apiUsersAddMobilLogPost(addMobilLogDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kullanıcı ekler  isNotificationEnabled (treu/false)  isEmailEnabled (treu/false)
         * @param {AddUserDTO} [addUserDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersAddUserPost(addUserDTO?: AddUserDTO, options?: any): AxiosPromise<void> {
            return localVarFp.apiUsersAddUserPost(addUserDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary kullanıcıya proje atar
         * @param {UserProject} [userProject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersAddUserProjectPost(userProject?: UserProject, options?: any): AxiosPromise<void> {
            return localVarFp.apiUsersAddUserProjectPost(userProject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Yeni kullanıcı tipi ekler
         * @param {UserTypeAddDTO} [userTypeAddDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersAddUserTypePost(userTypeAddDTO?: UserTypeAddDTO, options?: any): AxiosPromise<Result> {
            return localVarFp.apiUsersAddUserTypePost(userTypeAddDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Id ye göre kullanıcı bilgilerini getirir
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGetUserByIdGet(userId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUsersGetUserByIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Projeye dahil olan bütün kullanıcıları listeler
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGetUserListByProjectIDGet(projectId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUsersGetUserListByProjectIDGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kullanıcının sahip olduğu projeleri listeler
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGetUserProjectByUserIDGet(userId?: number, options?: any): AxiosPromise<UserProjectDTOListDataResult> {
            return localVarFp.apiUsersGetUserProjectByUserIDGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kullanıcı rollerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGetUserRoleListGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiUsersGetUserRoleListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kukullanıcının sahip olduğu bütün user tipleri listeler
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGetUserTypeByUserIDGet(userId?: number, options?: any): AxiosPromise<UserTypeDTOListDataResult> {
            return localVarFp.apiUsersGetUserTypeByUserIDGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kullanıcı tiplerini listeler
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGetUserTypeListGet(options?: any): AxiosPromise<UserTypeListDataResult> {
            return localVarFp.apiUsersGetUserTypeListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary kullanıcı şifresini sıfırlar
         * @param {number} [userID] 
         * @param {string} [newPass] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersPasswordResetGet(userID?: number, newPass?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUsersPasswordResetGet(userID, newPass, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary hangi platformdan bildirim alabileceğini düzenler.
         * @param {number} [userID] 
         * @param {boolean} [emailNotify] 
         * @param {boolean} [notificationNotify] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersUpdateUserNotifyChanceGet(userID?: number, emailNotify?: boolean, notificationNotify?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiUsersUpdateUserNotifyChanceGet(userID, emailNotify, notificationNotify, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary kullanıcı şifresini günceller
         * @param {number} [userID] 
         * @param {string} [oldPass] 
         * @param {string} [newPass] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersUpdateUserPasswordChangeGet(userID?: number, oldPass?: string, newPass?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUsersUpdateUserPasswordChangeGet(userID, oldPass, newPass, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kullanıcı günceller  notificationToken alanı null olacak  passwordSalt alanı null olacak  id alanı zorunlu  isNotificationEnabled zorunlu  isEmailEnabled zorunlu  IsActive zorunlu
         * @param {UserUpdateDTO} [userUpdateDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersUpdateUserPost(userUpdateDTO?: UserUpdateDTO, options?: any): AxiosPromise<void> {
            return localVarFp.apiUsersUpdateUserPost(userUpdateDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary kullanıcı tipi günceller
         * @param {UserTypeUpdateDTO} [userTypeUpdateDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersUpdateUserTypePost(userTypeUpdateDTO?: UserTypeUpdateDTO, options?: any): AxiosPromise<Result> {
            return localVarFp.apiUsersUpdateUserTypePost(userTypeUpdateDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [versionNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersVersionControlGet(versionNo?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUsersVersionControlGet(versionNo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {AddMobilLogDTO} [addMobilLogDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersAddMobilLogPost(addMobilLogDTO?: AddMobilLogDTO, options?: any) {
        return UsersApiFp(this.configuration).apiUsersAddMobilLogPost(addMobilLogDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kullanıcı ekler  isNotificationEnabled (treu/false)  isEmailEnabled (treu/false)
     * @param {AddUserDTO} [addUserDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersAddUserPost(addUserDTO?: AddUserDTO, options?: any) {
        return UsersApiFp(this.configuration).apiUsersAddUserPost(addUserDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary kullanıcıya proje atar
     * @param {UserProject} [userProject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersAddUserProjectPost(userProject?: UserProject, options?: any) {
        return UsersApiFp(this.configuration).apiUsersAddUserProjectPost(userProject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Yeni kullanıcı tipi ekler
     * @param {UserTypeAddDTO} [userTypeAddDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersAddUserTypePost(userTypeAddDTO?: UserTypeAddDTO, options?: any) {
        return UsersApiFp(this.configuration).apiUsersAddUserTypePost(userTypeAddDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Id ye göre kullanıcı bilgilerini getirir
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersGetUserByIdGet(userId?: number, options?: any) {
        return UsersApiFp(this.configuration).apiUsersGetUserByIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Projeye dahil olan bütün kullanıcıları listeler
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersGetUserListByProjectIDGet(projectId?: number, options?: any) {
        return UsersApiFp(this.configuration).apiUsersGetUserListByProjectIDGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kullanıcının sahip olduğu projeleri listeler
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersGetUserProjectByUserIDGet(userId?: number, options?: any) {
        return UsersApiFp(this.configuration).apiUsersGetUserProjectByUserIDGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kullanıcı rollerini listeler
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersGetUserRoleListGet(options?: any) {
        return UsersApiFp(this.configuration).apiUsersGetUserRoleListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kukullanıcının sahip olduğu bütün user tipleri listeler
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersGetUserTypeByUserIDGet(userId?: number, options?: any) {
        return UsersApiFp(this.configuration).apiUsersGetUserTypeByUserIDGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kullanıcı tiplerini listeler
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersGetUserTypeListGet(options?: any) {
        return UsersApiFp(this.configuration).apiUsersGetUserTypeListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary kullanıcı şifresini sıfırlar
     * @param {number} [userID] 
     * @param {string} [newPass] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersPasswordResetGet(userID?: number, newPass?: string, options?: any) {
        return UsersApiFp(this.configuration).apiUsersPasswordResetGet(userID, newPass, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary hangi platformdan bildirim alabileceğini düzenler.
     * @param {number} [userID] 
     * @param {boolean} [emailNotify] 
     * @param {boolean} [notificationNotify] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersUpdateUserNotifyChanceGet(userID?: number, emailNotify?: boolean, notificationNotify?: boolean, options?: any) {
        return UsersApiFp(this.configuration).apiUsersUpdateUserNotifyChanceGet(userID, emailNotify, notificationNotify, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary kullanıcı şifresini günceller
     * @param {number} [userID] 
     * @param {string} [oldPass] 
     * @param {string} [newPass] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersUpdateUserPasswordChangeGet(userID?: number, oldPass?: string, newPass?: string, options?: any) {
        return UsersApiFp(this.configuration).apiUsersUpdateUserPasswordChangeGet(userID, oldPass, newPass, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kullanıcı günceller  notificationToken alanı null olacak  passwordSalt alanı null olacak  id alanı zorunlu  isNotificationEnabled zorunlu  isEmailEnabled zorunlu  IsActive zorunlu
     * @param {UserUpdateDTO} [userUpdateDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersUpdateUserPost(userUpdateDTO?: UserUpdateDTO, options?: any) {
        return UsersApiFp(this.configuration).apiUsersUpdateUserPost(userUpdateDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary kullanıcı tipi günceller
     * @param {UserTypeUpdateDTO} [userTypeUpdateDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersUpdateUserTypePost(userTypeUpdateDTO?: UserTypeUpdateDTO, options?: any) {
        return UsersApiFp(this.configuration).apiUsersUpdateUserTypePost(userTypeUpdateDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [versionNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersVersionControlGet(versionNo?: number, options?: any) {
        return UsersApiFp(this.configuration).apiUsersVersionControlGet(versionNo, options).then((request) => request(this.axios, this.basePath));
    }
}


