import React, { useEffect } from "react";
import {
  Center,
  Button,
  Image,
  Text,
  Spacer,
  useToast,
  useColorModeValue
} from "@chakra-ui/react";
import { Card } from "components/atoms";
import { Form, InputElement } from "components/atoms/HookForm";
import { Hooks } from "services";
import { LoginForm } from "models/forms";
import { Authenticator } from "helper/authenticator";
import {__mode__, api} from 'consts';

function Login() {
  const [login, status, data] = Hooks.Login();
  const toast = useToast();

  const onValid = async (values: LoginForm) => {
    const data = (await login(
      values.username,
      values.password
    )) as unknown as any;
    if (data?.data?.success) {
      Authenticator.Login(data?.data.data.accessToken!, data?.data.data);
    }
  };

  return (
    <Center
      minH="100vh"
      backgroundImage={__mode__ !== api.siemens ? "url(https://i.pinimg.com/originals/92/d3/e2/92d3e202bee3df35b5a65278158677c1.jpg)" : "url(https://secure.aadcdn.microsoftonline-p.com/c1c6b6c8-ynmmni9vieierh5atlddxa2loq3ihganl084p3vc9m4/logintenantbranding/0/illustration?ts=636809774375247127)"}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
    >
      <Card
        p="10"
        mx="5"
        textAlign="center"
        borderWidth="1px"
        borderRadius="md"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        w="100%"
        maxW="460px"
      >
        <Text textAlign="center" fontSize="x-large" my="1" mb="5">
          Tesis Yönetim Sistemi
        </Text>
        <Form onValid={onValid}>
          <InputElement
            name="username"
            rules={{
              required: { value: true, message: "Bu alan boş bırakılamaz!" },
              minLength: {
                value: 3,
                message: "Bu alan 3 karakterden az olamaz",
              },
            }}
            label="Kullanıcı Adı"
          />
          <InputElement
            type="password"
            name="password"
            label="Şifre"
            containerProps={{ mt: "5" }}
          />
          <Button
            colorScheme="teal"
            type="submit"
            mt="5"
            w="100%"
            isLoading={status === "pending"}
          >
            Giriş Yap
          </Button>
        </Form>
        {(status === "rejected" || (data && !data.data.success)) && (
          <Text mt="3" fontSize="sm" color="red.500">
            {data?.data.message}
          </Text>
        )}
        <Button
          variant="link"
          mt="5"
          onClick={() => {
            toast({
              title: "Şifre işlemleri için yönetici ile iletişime geçiniz!",
              status: "warning",
              position: "top",
            });
          }}
        >
          Şifremi Unuttum!
        </Button>
      </Card>
    </Center>
  );
}

export default Login;
