import React, {
  FC,
  Fragment,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import {
  Box,
  Text,
  Center,
  Spinner,
  Flex,
  Button,
  Icon,
  SimpleGrid,
  Heading,
  Divider,
  Input,
  FormControl,
  FormLabel,
  Textarea,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { useForm } from "react-hook-form";
import { Hooks } from "services";
import { RouteComponentProps } from "react-router-dom";
import { TimelineItem, TimelineItemDetail, TimelineLegal } from "models";
import { FaCheck } from "react-icons/fa";
import { Card } from "components/atoms";
import Dropzone from "components/atoms/Dropzone";
import { useAuth } from "hooks/redux-hooks";
import { useToast } from "hooks/toast";
import Moment from "moment";
import { DocumentField } from "./DoMaintenance";
import { isSieOrNo } from "helper/project-mode";

interface DoMaintenancePageProps {}

const DoMaintenancePage: FC<
  DoMaintenancePageProps &
    RouteComponentProps<{ id: string }, {}, { timelineItem: TimelineLegal }>
> = (props) => {
  const { control, handleSubmit } = useForm();
  const { user, project } = useAuth();
  const [getQustions, statQuestions, dataQuestions] = Hooks.ListQuestion();
  const [doMaintenance, statDoMain, dataDoMain] = Hooks.DoLegalMaintenance();
  const [loading, setLoading] = useState<boolean>(false);
  const [datas, setDatas] = useState({
    isg: false,
    contract: false,
    instruction: false,
    guide: false,
    photo: null as File[] | null,
    request: null as File[] | null,
    filesRequests: null as File[] | null,
    firm: "",
    user: "",
    explain: "",
    quest: null as unknown as any,
    lastDate: "",
  });

  const [questions, setQuestions] = useState<any[]>([]);
  const toast = useToast();

  function _onOk(_key: keyof typeof datas) {
    setDatas((_datas) => ({ ...datas, [_key]: true }));
  }

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  function getBase64Photos(arr: any[]) {
    return new Promise(async (resolve, reject) => {
      let arrData: any[] = [];
      for (var index = 0; index < arr.length; index++) {
        let base64str = await toBase64(arr[index]);
        let ext = arr[index].name.split(".").pop();
        arrData.push({ base64: base64str, extension: ext });
      }

      return resolve(arrData);
    });
  }

  useEffect(() => {
    if (statDoMain) {
      if (dataDoMain?.data.success) {
        toast({ title: `Periyodik ${isSieOrNo("Kontrol", "Muayene")} işlemi başarıyla gerçekleşti!` });
        props.history.push("/");
      }
    }
  }, [statDoMain]);

  async function handleClickComplete() {
    setLoading(true);
    let base64List = await getBase64Photos(datas.photo || []);
    let base64ListForms = await getBase64Photos(datas.request || []);
    let base64ListFiles = await getBase64Photos(datas.filesRequests || []);
    await doMaintenance({
      answer: !!datas.quest,
      completedPersonelName: `${user.name} ${user.surname}`,
      completedUserID: user.id,
      documents: [
        ...(base64List as any[]).map((base64Str) => ({
          document: base64Str.base64,
          extension: base64Str.extension,
          type: "inventoryPhoto",
        })),
        ...(base64ListForms as any[]).map((base64Str) => ({
          document: base64Str.base64,
          extension: base64Str.extension,
          type: "formPhoto",
        })),
        ...(base64ListFiles as any[]).map((base64Str) => ({
          document: base64Str.base64,
          extension: base64Str.extension,
          type: "files",
        })),
      ],
      explain: datas.explain,
      secondDate: !!datas.lastDate
        ? datas.lastDate
        : Moment().format("YYYY-MM-DD"),
      id: props.location.state.timelineItem.id,
      maintenanceFirm: datas.firm,
    });
    setLoading(false);
  }

  return (
    <Main headerProps={{ title: `Periyodik ${isSieOrNo("Kontrol", "Muayene")}` }}>
      <Card mb="10">
        {true && (
          <Box>
            {[
              props.location.state.timelineItem.userGudiePathIsRequired,
              props.location.state.timelineItem.userInstructionsIsRequired,
              props.location.state.timelineItem.riskAnalysisIsRequired,
              props.location.state.timelineItem.maintenanceContractIsRequired,
            ].includes(true) && (
              <Fragment>
                <Heading fontSize="large">
                  Zorunlu Dokümanlar{" "}
                  <Text d="inline" color="red.500">
                    *
                  </Text>
                </Heading>
                <Divider my="2" />
                <SimpleGrid columns={[1, 2]} spacing="5">
                  <DocumentField
                    required={
                      props.location.state.timelineItem
                        .maintenanceContractIsRequired
                    }
                    title={`Periyodik ${isSieOrNo("Kontrol", "Muayene")} Sözleşmesi`}
                    value={datas.contract}
                    onOk={() => {
                      _onOk("contract");
                    }}
                    url={
                      props.location.state.timelineItem.maintenanceContractPath
                    }
                  />
                  <DocumentField
                    required={
                      props.location.state.timelineItem.riskAnalysisIsRequired
                    }
                    title="ISG Risk Analizi"
                    value={datas.isg}
                    onOk={() => {
                      _onOk("isg");
                    }}
                    url={props.location.state.timelineItem.riskAnalysisPath}
                  />
                  <DocumentField
                    required={
                      props.location.state.timelineItem
                        .userInstructionsIsRequired
                    }
                    title="Kullanma Talimatı"
                    value={datas.instruction}
                    onOk={() => {
                      _onOk("instruction");
                    }}
                    url={props.location.state.timelineItem.userInstructionsPath}
                  />
                  <DocumentField
                    required={
                      props.location.state.timelineItem.userGudiePathIsRequired
                    }
                    title="Kullanım Klavuzu"
                    value={datas.guide}
                    onOk={() => {
                      _onOk("guide");
                    }}
                    url={props.location.state.timelineItem.userGudiePath}
                  />
                </SimpleGrid>
              </Fragment>
            )}

            {props.location.state.timelineItem.isInventoryPhotoRequired && (
              <Fragment>
                <Heading fontSize="large" mt="8">
                  Ekipman Fotoğrafı{" "}
                  <Text d="inline" color="red.500">
                    *
                  </Text>
                </Heading>
                <Divider my="2" />
                <Box>
                  <Dropzone
                    multiple
                    onDrop={(files) => {
                      setDatas((_data) => ({ ..._data, photo: files }));
                    }}
                  />
                </Box>
              </Fragment>
            )}

            <Heading fontSize="large" mt="8">
              Periyodik {isSieOrNo("Kontrol", "Muayene")} Formu{" "}
              <Text d="inline" color="red.500">
                *
              </Text>
            </Heading>
            <Divider my="2" />
            <Box>
              <Dropzone
                hasAllType
                multiple
                onDrop={(files) => {
                  setDatas((_data) => ({ ..._data, request: files }));
                }}
              />
            </Box>

            <Heading fontSize="large" mt="8">
              Periyodik {isSieOrNo("Kontrol", "Muayene")} Sorusu
            </Heading>
            <Divider my="2" />
            <Box>
              <FormControl mb="2">
                <FormLabel>
                  {props.location.state.timelineItem.question}
                  <Text d="inline" color="red.500">
                    *
                  </Text>
                </FormLabel>
                <Flex>
                  <Button
                    onClick={() => {
                      setDatas((_datas) => ({ ..._datas, quest: true }));
                    }}
                    variant={datas.quest ? "solid" : "outline"}
                    colorScheme="green"
                  >
                    Evet
                  </Button>
                  <Button
                    onClick={() => {
                      setDatas((_datas) => ({ ..._datas, quest: false }));
                    }}
                    variant={datas.quest === false ? "solid" : "outline"}
                    colorScheme="red"
                    ml="1"
                  >
                    Hayır
                  </Button>
                </Flex>
              </FormControl>
              {datas.quest !== null && (
                <Fragment>
                  <FormControl mb="2">
                    <FormLabel>
                      {datas.quest
                        ? `Sonraki Periyodik ${isSieOrNo("Kontrol", "Muayene")} Tarihi`
                        : "İkinci Doküman Yükleme Tarihi"}
                      <Text d="inline" color="red.500">
                        *
                      </Text>
                    </FormLabel>
                    <Input
                      type="date"
                      value={datas.lastDate}
                      min={Moment().add(1, "d").format("YYYY-MM-DD")}
                      onChange={(e) => {
                        setDatas((_datas) => ({
                          ..._datas,
                          lastDate: e.currentTarget.value,
                        }));
                      }}
                    />
                  </FormControl>
                  {datas.quest === false && (
                    <Fragment>
                      <Heading fontSize="large" mt="8">
                        Fotoğraf / Belge{" "}
                      </Heading>
                      <Divider my="2" />
                      <Box>
                        <Dropzone
                          multiple
                          onDrop={(files) => {
                            setDatas((_data) => ({ ..._data, filesRequests: files }));
                          }}
                        />
                      </Box>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Box>

            <Heading fontSize="large" mt="8">
              Bilgiler
            </Heading>
            <Divider my="2" />
            <Box>
              <FormControl mb="2">
                <FormLabel>
                  Periyodik {isSieOrNo("Kontrol", "Muayene")} Firması{" "}
                  <Text d="inline" color="red.500">
                    *
                  </Text>
                </FormLabel>
                <Input
                  type="text"
                  value={datas.firm}
                  onChange={(e) => {
                   
                    setDatas((_datas) => ({
                      ..._datas,
                      firm: e.currentTarget.value,
                    }));
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Periyodik {isSieOrNo("Kontrol", "Muayene")} Notları</FormLabel>
                <Textarea
                  type="text"
                  value={datas.explain}
                  onChange={(e) =>
                    setDatas((_datas) => ({
                      ..._datas,
                      explain: e.currentTarget.value,
                    }))
                  }
                />
              </FormControl>
            </Box>

            <Flex justifyContent="flex-end" mt="10">
              <Button
                colorScheme="teal"
                onClick={handleClickComplete}
                isLoading={loading}
                isDisabled={[
                  props.location.state.timelineItem.isInventoryPhotoRequired
                    ? Array.isArray(datas.photo) && datas.photo?.length > 0
                    : true,
                  props.location.state.timelineItem.userGudiePathIsRequired
                    ? datas.guide
                    : true,
                  props.location.state.timelineItem.userInstructionsIsRequired
                    ? datas.instruction
                    : true,
                  props.location.state.timelineItem.riskAnalysisIsRequired
                    ? datas.isg
                    : true,
                  props.location.state.timelineItem
                    .maintenanceContractIsRequired
                    ? datas.contract
                    : true,
                  !!datas.firm,
                  !!datas.lastDate,
                ].includes(false)}
              >
                Periyodik {isSieOrNo("Kontrolü", "Muayenesi")} Tamamla
              </Button>
            </Flex>
          </Box>
        )}
      </Card>
    </Main>
  );
};

DoMaintenancePage.defaultProps = {};

export default DoMaintenancePage;
