import React, { FC, Fragment, useEffect, useState } from "react";
import {
  Box,
  Text,
  Spinner,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Tr,
  Divider,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import "react-image-lightbox/style.css";
import "./master.css";
import { useAuth, useRedux } from "hooks/redux-hooks";
import { Redirect, RouteComponentProps } from "react-router-dom";
import Moment from "moment";
import { Card } from "components/atoms";
import Lightbox from "react-image-lightbox";
import { FaCheck } from "react-icons/fa";
import { Question } from "models";
import { getApiUrl } from "services/urls";

interface DocReportProps {}

const configs = {
  dateString: "YYYY-MM-DD[T]HH:mm:ss",
};

const DocReport: FC<DocReportProps & RouteComponentProps> = (props) => {
  const { user, project } = useAuth();
  const [maintenance, setMaintenance] = useState<any>(null);
  const [lightImage, setLightImage] = useState<string | null>(null);

  useEffect(() => {
    const data = localStorage.getItem("doc-report");
    if (!data) {
      props.history.push("/");
    }

    setMaintenance(JSON.parse(data!));
  }, []);

  function photoUrlManipulation(url: string) {
    if (!url || url === "") return url;
    if (!url.includes("//")) return url;
    url.replace(/\/\//g, "/");
    const apiUrl = getApiUrl();

    url = url.split("/Content")[1];
    url = `${apiUrl}/Content${url}`;
    console.log({url});
    return url;
  }

  if (!maintenance) return <Spinner />;

  const questions: Question[] =
    JSON.parse(maintenance.maintenanceQuestion ?? "[]") ?? [];

  return (
    <Fragment>
      <div className="book">
        <div className="page">
          <Header />
          <DataTable
            title="Ekipman Bilgileri"
            data={[
              { title: "Tesis", value: maintenance.campusName },
              { title: "Bina", value: maintenance.buildName },
              { title: "Kat", value: maintenance.floorName },
              { title: "Oda", value: maintenance.roomName },
              { title: "Kod", value: maintenance.barcode },
              { title: "Envanter No", value: maintenance.inventoryNo },
              { title: "Ekipman Adı", value: maintenance.name },
              { title: "Grup", value: maintenance.groupName },
              { title: "Marka", value: maintenance.brandName },
              { title: "Model", value: maintenance.modelName },
              { title: "Seri No", value: maintenance.serialNumber },
              { title: "Üretim Yılı", value: maintenance.productionYear },
              {
                title: "Kapasite Birimi",
                value: maintenance.inventoryCapacityName,
              },
              { title: "Kapasite Değeri", value: maintenance.unit },
              { title: "Adet", value: maintenance.count },
            ]}
          />
          {!!maintenance.inventoryPhoto && (
            <Flex justifyContent="center">
              <img
                alt="Ekipman"
                style={{
                  cursor: "pointer",
                  height: "200px",
                  objectFit: "contain",
                }}
                src={photoUrlManipulation(maintenance.inventoryPhoto)}
                onClick={(e) =>
                  setLightImage(
                    photoUrlManipulation(maintenance.inventoryPhoto)
                  )
                }
                width="auto"
                height="150px"
              />
            </Flex>
          )}
          <div className="page-number">1</div>
        </div>
        <div className="page">
          <Header />
          <DataTable
            title="Bakım Bilgileri"
            data={[
              {
                title: "Planlı Bakım Tarihi",
                value: Moment(maintenance.endDate, configs.dateString).format(
                  "DD/MM/YYYY"
                ),
              },
              {
                title: "Gercekleşen Tarih",
                value: Moment(
                  maintenance.maintenanceDate,
                  configs.dateString
                ).format("DD/MM/YYYY HH:mm"),
              },
              {
                title: "Bakım Tipi",
                value: maintenance.maintenanceTypeName,
              },
              {
                title: "Bakım Periyodu",
                value: maintenance.maintenancePeriodName,
              },
              {
                title: "Kullanıcı",
                value: maintenance.personelName,
              },
              {
                title: "Bakım Firmasi",
                value: maintenance.maintenanceFirm,
              },
              {
                title: "Bakım Notları",
                value: maintenance.maintenanceExplain,
              },
            ]}
          />

          <DataTable
            title="Form Olayları"
            data={[
              {
                title: "ISG Risk Analizi",
                value: "Okudum, Anladım",
                stat: maintenance.riskAnalysisIsRequired,
              },
              {
                title: "Kullanma Klavuzu",
                value: "Okudum, Anladım",
                stat: maintenance.userGudiePathIsRequired,
              },
              {
                title: "Kullanıcı Talimatları",
                value: "Okudum, Anladım",
                stat: maintenance.userInstructionsIsRequired,
              },
              {
                title: "Bakım Sözleşmesi",
                value: "Okudum, Anladım",
                stat: maintenance.maintenanceContractIsRequired,
              },
            ].filter((item) => item.stat)}
          />
          <div className="page-number">2</div>
        </div>
        <div className="page">
          <Header />
          <DataTable
            title="Bakım Soruları"
            data={questions.map((question) => {
              return {
                title: question.Question,
                value:
                  question.SelectedItem == null
                    ? "Cevap Girilmemiş"
                    : question.SelectedItem === "Yes"
                    ? `Evet (${
                        question.trueFalseAnswer === true ||
                        question.trueFalseAnswer === undefined
                          ? "Olumlu"
                          : "Olumsuz"
                      })`
                    : question.SelectedItem === "No"
                    ? `Hayır (${
                        question.trueFalseAnswer === true ||
                        question.trueFalseAnswer === undefined
                          ? "Olumsuz"
                          : "Olumlu"
                      })`
                    : !!question.InitialValue
                    ? `${question.SelectedItem} (${
                        parseInt(question.SelectedItem) >=
                          parseInt(question.InitialValue) &&
                        parseInt(question.SelectedItem) <=
                          parseInt(question.EndValue)
                          ? "Olumlu Değer Aralığında"
                          : "Olumlu Değer Aralığı Dışında"
                      })`
                    : `${question.SelectedItem} (${
                        question.SelectedItem >= question.InitialValue &&
                        question.SelectedItem <= question.EndValue
                          ? "Olumlu Değer Aralığında"
                          : "Olumlu Değer Aralığı Dışında"
                      })`,
              };
            })}
          />
          <div className="page-number">3</div>
        </div>

        {maintenance &&
          maintenance.photos
            .filter((photo: any) => photo.type === "formPhoto")
            .map((photo: any, index: number) => (
              <div className="page">
                <Header />
                <DataCard title="İş İzin / Bakım Formu">
                  <img
                    alt={"Form" + index}
                    style={{
                      cursor: "pointer",
                      maxHeight: "100%",
                      maxWidth: "100%",
                      marginRight: 3,
                    }}
                    src={photoUrlManipulation(photo.photoPath)}
                    onClick={(e) => setLightImage(photoUrlManipulation(photo.photoPath))}
                    width="auto"
                    height="100%"
                  />
                </DataCard>
                <div className="page-number">4</div>
              </div>
            ))}

        <div className="page">
          <Header />
          <DataCard title="Ekipman Fotoğrafları">
            {maintenance &&
              maintenance.photos
                .filter((photo: any) => photo.type === "inventoryPhoto")
                .map((photo: any, index: number) => (
                  <Flex mb="2" justifyContent="center" key={index}>
                    <img
                      alt={"Form" + index}
                      style={{
                        cursor: "pointer",
                        maxHeight: "230px",
                        maxWidth: "100%",
                        marginRight: 3,
                      }}
                      src={photoUrlManipulation(photo.photoPath)}
                      onClick={(e) => setLightImage(photoUrlManipulation(photo.photoPath))}
                      width="auto"
                      height="auto"
                    />
                  </Flex>
                ))}
          </DataCard>
          <div className="page-number">
            {maintenance &&
              maintenance.photos.filter(
                (photo: any) => photo.type === "formPhoto"
              ).length + 4}
          </div>
        </div>
      </div>
      {!!lightImage && (
        <Lightbox
          mainSrc={lightImage!}
          onCloseRequest={() => setLightImage(null)}
        />
      )}
    </Fragment>
  );
};

function Header() {
  const { user, project } = useAuth();
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      borderBottom="1px"
      borderColor="gray.300"
      pb="1"
    >
      <Flex flex="1">
        <Image src={project.companyLogo} width="150px" />
      </Flex>
      <Flex flex="3" justifyContent="center">
        <Text textAlign="center" fontSize="lg">
          Ekipman Bakım Durum Bilgisi
        </Text>
      </Flex>
      <Flex flex="1" justifyContent="flex-end">
        <Text textAlign="center" fontSize="lg">
          {Moment().format("DD.MM.YYYY")}
        </Text>
      </Flex>
    </Flex>
  );
}

function DataTable({ data, title }: any) {
  return (
    <Box py="4">
      <Heading fontSize="md">{title}</Heading>
      <Divider my="2" />
      {data && (
        <Table variant="striped" size="sm">
          <Tbody>
            {data.map((item: any, index: number) => (
              <Tr key={index}>
                <Td>
                  <span>{item.title}: </span>
                </Td>
                <Td style={{ minWidth: 130 }}>
                  <strong>{item.value}</strong>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
}

function DataCard({ title, children }: any) {
  return (
    <Box py="4">
      <Heading fontSize="md" mb="5">
        {title}
      </Heading>
      <Flex w="100%" flexWrap="wrap" wrap="wrap">
        {children}
      </Flex>
    </Box>
  );
}

DocReport.defaultProps = {};

export default DocReport;
