import React, { PropsWithChildren, Ref } from "react";
import { Form, Formik, FormikProps } from "formik";

export type FormProps<T> = FormikProps<T>;

type MyFormProps<T> = { initialValues: T } & {
  onSubmit: (values: T) => void;
  innerRef?: Ref<FormikProps<T>>;
};

export function MyForm<T>({ children, innerRef, ...formikProps }: any) {
  return (
    <Formik innerRef={innerRef as any} {...formikProps}>
      <Form>{children}</Form>
    </Formik>
  );
}
