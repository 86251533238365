import React, { Fragment } from "react";
import {
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import { IoNotifications } from "react-icons/io5";

function Notifications() {
  return (
    <Popover placement="bottom-start">
      <PopoverTrigger>
        <IconButton
          mr="2"
          aria-label="notification"
          icon={<Icon as={IoNotifications} />}
          borderRadius="full"
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Bildirimler</PopoverHeader>
        <PopoverBody>Yeni bildirim bulunamadı</PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default Notifications;
