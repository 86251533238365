import React, { FC } from "react";
import { Box, Button, Text, Flex, useToast } from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import { Form, InputElement } from "components/atoms/HookForm";
import { Hooks } from "services";
import { ChangePasswordForm } from "models/forms";
import { useAuth } from "hooks/redux-hooks";
import { useHistory } from "react-router-dom";

interface ChangePasswordPageProps {}

const ChangePasswordPage: FC<ChangePasswordPageProps> = (props) => {
  const [updatePass, statusPass] = Hooks.UpdateUserPassword();
  const toast = useToast();
  const history = useHistory()
  const { user } = useAuth();
  async function onValid(values: ChangePasswordForm) {
   
    if (values["new-password"] !== values["new-password-confirm"]) {
      return toast({ status: "error", title: "Şifre tekrarı hatalı!" });
    }
    const _data = (await updatePass(
      user.id,
      values["current-password"],
      values["new-password"]
    )) as unknown as any;

    if (_data?.data?.success) {
      toast({ title: "Şifre başarıyla değiştirildi!", status: "success" });
      history.push("/");
    } else {
      toast({
        title: "Şifre değiştirilemedi!",
        description: _data.data.message,
        status: "error",
      });
    }
  }

  return (
    <Main headerProps={{ title: "Şifre Değiştir" }}>
      <Card>
        <Form onValid={onValid}>
          <InputElement
            name="current-password"
            label="Mevcut Şifre"
            rules={{
              required: {
                value: true,
                message: "Bu alanın doldurulması zorunludur!",
              },
            }}
          />
          <InputElement
            name="new-password"
            label="Yeni Şifre"
            containerProps={{ mt: "2" }}
            rules={{
              required: {
                value: true,
                message: "Bu alanın doldurulması zorunludur!",
              },
            }}
          />
          <InputElement
            name="new-password-confirm"
            label="Yeni Şifre Tekrar"
            containerProps={{ mt: "2" }}
            rules={{
              required: {
                value: true,
                message: "Bu alanın doldurulması zorunludur!",
              },
            }}
          />
          <Flex justifyContent="flex-end">
            <Button mt="3" type="submit" colorScheme="teal">
              Şifreyi Değiştir
            </Button>
          </Flex>
        </Form>
      </Card>
    </Main>
  );
};

ChangePasswordPage.defaultProps = {};

export default ChangePasswordPage;
