import React, { FC, useLayoutEffect } from "react";
import {
  Box,
  Collapse,
  Heading,
  HStack,
  IconButton,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import { Note } from "services/swagger";
import moment from "moment";
import DailyNoteItem from "./DailyNoteItem";
import DailyNoteForm from "../daily-note-form/DailyNoteForm";
import { IoChevronDown } from "react-icons/io5";

type DailyNoteListProps = {};

const DailyNoteList: FC<DailyNoteListProps> = (props) => {
  const { project } = useAuth();
  const disclosure = useDisclosure();
  const [getNotes, statusNotes, dataNotes] = Hooks.NoteList();

  useLayoutEffect(() => {
    if (project?.id) {
      getNotes(project.id);
    }
  }, [project]);

  function filterToday(item: Note) {
    const itemDate = moment(item.date).format("YYYY-MM-DD");
    const todayDate = moment().format("YYYY-MM-DD");

    return itemDate === todayDate;
  }

  return (
    <Box>
      <HStack justifyContent="space-between" bgColor="gray.50" px="3" my="2">
        <Heading size="md" pb="6" mt="6">
          {moment().format("DD MMMM YYYY dddd")} Notları
        </Heading>
      </HStack>
      <DailyNoteForm
        onCreate={() => {
          getNotes(project.id);
        }}
      />
      {statusNotes !== "fulfilled" && <Spinner />}
      {statusNotes === "fulfilled" &&
        dataNotes &&
        dataNotes?.data.data
          .filter(filterToday)
          .reverse()
          .map((item: any, index: number) => (
            <DailyNoteItem index={index} item={item} />
          ))}
    </Box>
  );
};

export default DailyNoteList;
