import {
  AuthApiFactory,
  Project,
  ProjectApiFactory,
  CampusApiFactory,
  Campus,
  BuildApiFactory,
  Build,
  FloorApiFactory,
  RoomApiFactory,
  Floor,
  Room,
  GroupApiFactory,
  Group,
  Brand,
  Model,
  InventoryApiFactory,
  UsersApiFactory,
  UserType,
  InventoryMaintenanceTypeApiFactory,
  MaintenanceCommonApiFactory,
  MaintenanceQuestionApiFactory,
  DocumentApiFactory,
  DemandApiFactory,
  InventoryCapacityApiFactory,
  MaintenanceReportApiFactory,
  InventoryMaintenanceDetailApiFactory,
  InventoryLegalInspectionApiFactory,
  ControlTaskApiFactory,
  CounterApiFactory,
  SearchApiFactory,
  NoteApiFactory,
} from "./swagger/api";
import { useFetchManager, useFetchManagerStore } from "hooks/fetch-manager";
import axios, { AxiosInstance } from "axios";
import { store } from "store";
import { getApiUrl } from "./urls";

const axiosInstance = (): AxiosInstance => {
  const axiosInstance = axios.create();
  // Add a request interceptor
  axiosInstance.interceptors.request.use((config) => {
    return new Promise((resolve) => {
      config.headers.Authorization = "Bearer " + store.getState().auth.jwt;
      resolve(config);
    });
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

const apiUrl = getApiUrl();
const founds = [undefined, apiUrl, axiosInstance()] as const;

const API = {
  AuthAPI: AuthApiFactory(...founds),
  ProjectAPI: ProjectApiFactory(...founds),
  CampusAPI: CampusApiFactory(...founds),
  BuildAPI: BuildApiFactory(...founds),
  FloorAPI: FloorApiFactory(...founds),
  RoomAPI: RoomApiFactory(...founds),
  GroupAPI: GroupApiFactory(...founds),
  InventoryAPI: InventoryApiFactory(...founds),
  UserAPI: UsersApiFactory(...founds),
  MaintenanceAPI: MaintenanceCommonApiFactory(...founds),
  InventoryMaintenance: InventoryMaintenanceTypeApiFactory(...founds),
  MaintenanceQuestion: MaintenanceQuestionApiFactory(...founds),
  Document: DocumentApiFactory(...founds),
  DemandAPI: DemandApiFactory(...founds),
  InventoryCapacityAPI: InventoryCapacityApiFactory(...founds),
  ReportAPI: MaintenanceReportApiFactory(...founds),
  MaintenanceDetailAPI: InventoryMaintenanceDetailApiFactory(...founds),
  InventoryLegalInspectionAPI: InventoryLegalInspectionApiFactory(...founds),
  ControlTaskAPI: ControlTaskApiFactory(...founds),
  CounterAPI: CounterApiFactory(...founds),
  SearchAPI: SearchApiFactory(...founds),
  NoteAPI: NoteApiFactory(...founds),
};

export const Hooks = {
  Login: () =>
    useFetchManager<typeof API.AuthAPI.apiAuthLoginGet>(
      API.AuthAPI.apiAuthLoginGet
    ),
  ProjectList: () =>
    useFetchManager<
      typeof API.UserAPI.apiUsersGetUserProjectByUserIDGet,
      Project[]
    >(API.UserAPI.apiUsersGetUserProjectByUserIDGet),
  CampusCreate: () =>
    useFetchManager<typeof API.CampusAPI.apiCampusAddCampusPost>(
      API.CampusAPI.apiCampusAddCampusPost
    ),
  CampusList: () =>
    useFetchManagerStore<
      typeof API.CampusAPI.apiCampusGetCampusListByProjectIDGet,
      Campus[]
    >(API.CampusAPI.apiCampusGetCampusListByProjectIDGet, "campus-list"),
  BuildCreate: () =>
    useFetchManager<typeof API.BuildAPI.apiBuildAddBuildPost>(
      API.BuildAPI.apiBuildAddBuildPost
    ),
  FloorCreate: () =>
    useFetchManager<typeof API.FloorAPI.apiFloorAddFloorPost>(
      API.FloorAPI.apiFloorAddFloorPost
    ),
  RoomCreate: () =>
    useFetchManager<typeof API.RoomAPI.apiRoomAddRoomPost>(
      API.RoomAPI.apiRoomAddRoomPost
    ),
  RoomList: () =>
    useFetchManager<typeof API.RoomAPI.apiRoomGetRoomListByFloorIDGet, Room[]>(
      API.RoomAPI.apiRoomGetRoomListByFloorIDGet
    ),
  FloorList: () =>
    useFetchManager<
      typeof API.FloorAPI.apiFloorGetFloorListByBuildIDGet,
      Floor[]
    >(API.FloorAPI.apiFloorGetFloorListByBuildIDGet),
  BuildList: () =>
    useFetchManager<
      typeof API.BuildAPI.apiBuildGetBuildListByCampusIDGet,
      Build[]
    >(API.BuildAPI.apiBuildGetBuildListByCampusIDGet),
  GroupList: () =>
    useFetchManager<
      typeof API.GroupAPI.apiGroupGetGroupListByProjectIDGet,
      Group[]
    >(API.GroupAPI.apiGroupGetGroupListByProjectIDGet),
  BrandList: () =>
    useFetchManager<
      typeof API.GroupAPI.apiGroupGetBrandListByGroupIDGet,
      Brand[]
    >(API.GroupAPI.apiGroupGetBrandListByGroupIDGet),
  ModelList: () =>
    useFetchManager<
      typeof API.GroupAPI.apiGroupGetModelListByBrandIDGet,
      Model[]
    >(API.GroupAPI.apiGroupGetModelListByBrandIDGet),
  AddInventory: () =>
    useFetchManager<typeof API.InventoryAPI.apiInventoryAddInventoryPost, any>(
      API.InventoryAPI.apiInventoryAddInventoryPost
    ),
  UpdateInvetory: () =>
    useFetchManager<typeof API.InventoryAPI.apiInventoryUpdateInventoryPost>(
      API.InventoryAPI.apiInventoryUpdateInventoryPost
    ),
  InventoryList: () =>
    useFetchManager<
      typeof API.InventoryAPI.apiInventoryGetInventoryListByProjectIDGet,
      any
    >(API.InventoryAPI.apiInventoryGetInventoryListByProjectIDGet),
  AddUser: () =>
    useFetchManager<typeof API.UserAPI.apiUsersAddUserPost, any>(
      API.UserAPI.apiUsersAddUserPost
    ),
  UserTypeList: () =>
    useFetchManager<typeof API.UserAPI.apiUsersGetUserTypeListGet, UserType[]>(
      API.UserAPI.apiUsersGetUserTypeListGet
    ),
  GetUser: () =>
    useFetchManager<typeof API.UserAPI.apiUsersGetUserByIdGet>(
      API.UserAPI.apiUsersGetUserByIdGet
    ),
  UserRoleList: () =>
    useFetchManager<
      typeof API.UserAPI.apiUsersGetUserRoleListGet,
      Array<{ id: number; name: string }>
    >(API.UserAPI.apiUsersGetUserRoleListGet),
  UserList: () =>
    useFetchManager<
      typeof API.UserAPI.apiUsersGetUserListByProjectIDGet,
      UserType[]
    >(API.UserAPI.apiUsersGetUserListByProjectIDGet),
  UpdateUserPassword: () =>
    useFetchManager<typeof API.UserAPI.apiUsersUpdateUserPasswordChangeGet>(
      API.UserAPI.apiUsersUpdateUserPasswordChangeGet
    ),
  UpdateUser: () =>
    useFetchManager<typeof API.UserAPI.apiUsersUpdateUserPost>(
      API.UserAPI.apiUsersUpdateUserPost
    ),
  UploadFile: () =>
    useFetchManager<
      typeof API.InventoryAPI.apiInventoryAddInventoryDocumentPost
    >(API.InventoryAPI.apiInventoryAddInventoryDocumentPost),
  AddUserType: () =>
    useFetchManager<typeof API.UserAPI.apiUsersAddUserTypePost>(
      API.UserAPI.apiUsersAddUserTypePost
    ),
  EditUserType: () =>
    useFetchManager<typeof API.UserAPI.apiUsersUpdateUserTypePost>(
      API.UserAPI.apiUsersUpdateUserTypePost
    ),
  EditCampus: () =>
    useFetchManager<typeof API.CampusAPI.apiCampusUpdateCampusPost>(
      API.CampusAPI.apiCampusUpdateCampusPost
    ),
  EditBuild: () =>
    useFetchManager<typeof API.BuildAPI.apiBuildUpdateBuildPost>(
      API.BuildAPI.apiBuildUpdateBuildPost
    ),
  EditFloor: () =>
    useFetchManager<typeof API.FloorAPI.apiFloorUpdateFloorPost>(
      API.FloorAPI.apiFloorUpdateFloorPost
    ),
  EditRoom: () =>
    useFetchManager<typeof API.RoomAPI.apiRoomUpdateRoomPost>(
      API.RoomAPI.apiRoomUpdateRoomPost
    ),
  AddMaintenance: () =>
    useFetchManager<
      typeof API.InventoryMaintenance.apiInventoryMaintenanceTypeAddInventoryMaintenanceTypePost
    >(
      API.InventoryMaintenance
        .apiInventoryMaintenanceTypeAddInventoryMaintenanceTypePost
    ),
  EditMaintenance: () =>
    useFetchManager<
      typeof API.InventoryMaintenance.apiInventoryMaintenanceTypeUpdateInventoryMaintenanceTypePost
    >(
      API.InventoryMaintenance
        .apiInventoryMaintenanceTypeUpdateInventoryMaintenanceTypePost
    ),
  ListMaintenance: () =>
    useFetchManager<
      typeof API.InventoryMaintenance.apiInventoryMaintenanceTypeGetInventoryMaintenanteTypeListGet
    >(
      API.InventoryMaintenance
        .apiInventoryMaintenanceTypeGetInventoryMaintenanteTypeListGet
    ),
  CancelMaintenance: () =>
    useFetchManager<
      typeof API.MaintenanceDetailAPI.apiInventoryMaintenanceDetailCancelMaintenanceProcessPost
    >(
      API.MaintenanceDetailAPI
        .apiInventoryMaintenanceDetailCancelMaintenanceProcessPost
    ),
  ListPeriod: () =>
    useFetchManager<
      typeof API.MaintenanceAPI.apiMaintenanceCommonGetMaintenancePeriodListByProjectIdGet
    >(
      API.MaintenanceAPI
        .apiMaintenanceCommonGetMaintenancePeriodListByProjectIdGet
    ),
  ListPeriodStore: () =>
    useFetchManagerStore<
      typeof API.MaintenanceAPI.apiMaintenanceCommonGetMaintenancePeriodListByProjectIdGet
    >(
      API.MaintenanceAPI
        .apiMaintenanceCommonGetMaintenancePeriodListByProjectIdGet,
      "list-period"
    ),
  AddQuestion: () =>
    useFetchManager<
      typeof API.MaintenanceQuestion.apiMaintenanceQuestionAddMaintenanceQuestionPost
    >(API.MaintenanceQuestion.apiMaintenanceQuestionAddMaintenanceQuestionPost),
  ListQuestion: () =>
    useFetchManager<
      typeof API.MaintenanceQuestion.apiMaintenanceQuestionGetInventoryQuestionListGet
    >(
      API.MaintenanceQuestion.apiMaintenanceQuestionGetInventoryQuestionListGet
    ),
  UpdateMaintenanceQuestion: () =>
    useFetchManager<
      typeof API.MaintenanceQuestion.apiMaintenanceQuestionUpdateMaintenanceQuestionPost
    >(
      API.MaintenanceQuestion
        .apiMaintenanceQuestionUpdateMaintenanceQuestionPost
    ),
  ListQuestionTypes: () =>
    useFetchManager<
      typeof API.MaintenanceQuestion.apiMaintenanceQuestionGetMaintenanceQuestionTypeListPost
    >(
      API.MaintenanceQuestion
        .apiMaintenanceQuestionGetMaintenanceQuestionTypeListPost
    ),
  DocumentList: () =>
    useFetchManager<typeof API.Document.apiDocumentGetDocumentListGet>(
      API.Document.apiDocumentGetDocumentListGet
    ),
  DocumentMaintenanceList: () =>
    useFetchManager<
      typeof API.Document.apiDocumentGetDocumentMaintenanceTypeGet
    >(API.Document.apiDocumentGetDocumentMaintenanceTypeGet),
  UpdateDocumentMaintenance: () =>
    useFetchManager<
      typeof API.Document.apiDocumentUpdateDocumentMaintenanceTypePost
    >(API.Document.apiDocumentUpdateDocumentMaintenanceTypePost),
  AddDocument: () =>
    useFetchManager<typeof API.Document.apiDocumentAddDocumentPost>(
      API.Document.apiDocumentAddDocumentPost
    ),
  EditDocument: () =>
    useFetchManager<typeof API.Document.apiDocumentUpdateDocumentPost>(
      API.Document.apiDocumentUpdateDocumentPost
    ),
  AddDocumentMaintenance: () =>
    useFetchManager<
      typeof API.Document.apiDocumentAddDocumentMaintenanceTypePost
    >(API.Document.apiDocumentAddDocumentMaintenanceTypePost),
  EditDocumentMaintenance: () =>
    useFetchManager<
      typeof API.Document.apiDocumentUpdateDocumentMaintenanceTypePost
    >(API.Document.apiDocumentUpdateDocumentMaintenanceTypePost),
  DocumentMaintenancePeriodList: () =>
    useFetchManager<typeof API.Document.apiDocumentGetDocumentPeriodListGet>(
      API.Document.apiDocumentGetDocumentPeriodListGet
    ),
  MaintenanceEdit: () =>
    useFetchManager<
      typeof API.InventoryMaintenance.apiInventoryMaintenanceTypeUpdateInventoryMaintenanceTypePost
    >(
      API.InventoryMaintenance
        .apiInventoryMaintenanceTypeUpdateInventoryMaintenanceTypePost
    ),
  AddGroup: () =>
    useFetchManager<typeof API.GroupAPI.apiGroupAddGroupPost>(
      API.GroupAPI.apiGroupAddGroupPost
    ),
  AddBrand: () =>
    useFetchManager<typeof API.GroupAPI.apiGroupAddBrandPost>(
      API.GroupAPI.apiGroupAddBrandPost
    ),
  AddModel: () =>
    useFetchManager<typeof API.GroupAPI.apiGroupAddModelPost>(
      API.GroupAPI.apiGroupAddModelPost
    ),
  DemandList: () =>
    useFetchManager<typeof API.DemandAPI.apiDemandGetDemandListByProjectIDGet>(
      API.DemandAPI.apiDemandGetDemandListByProjectIDGet
    ),
  AddDemand: () =>
    useFetchManager<typeof API.DemandAPI.apiDemandAddDemandPost>(
      API.DemandAPI.apiDemandAddDemandPost
    ),
  UpdateDemand: () =>
    useFetchManager<typeof API.DemandAPI.apiDemandUpdateDemandPost>(
      API.DemandAPI.apiDemandUpdateDemandPost
    ),
  DemandDocumentList: () =>
    useFetchManager<typeof API.DemandAPI.apiDemandGetDemandDocumentListGet>(
      API.DemandAPI.apiDemandGetDemandDocumentListGet
    ),
  AddDemandDocument: () =>
    useFetchManager<typeof API.DemandAPI.apiDemandAddDemandDocumentPost>(
      API.DemandAPI.apiDemandAddDemandDocumentPost
    ),
  UpdateDemandDocument: () =>
    useFetchManager<typeof API.DemandAPI.apiDemandUpdateDemandDocumentPost>(
      API.DemandAPI.apiDemandUpdateDemandDocumentPost
    ),
  AllDemandDocument: () =>
    useFetchManager<typeof API.DemandAPI.apiDemandGetDemandAndDocumentListGet>(
      API.DemandAPI.apiDemandGetDemandAndDocumentListGet
    ),
  DemandGroupList: () =>
    useFetchManager<typeof API.DemandAPI.apiDemandGetDemandGroupListGet>(
      API.DemandAPI.apiDemandGetDemandGroupListGet
    ),
  AddDemandGroup: () =>
    useFetchManager<typeof API.DemandAPI.apiDemandAddDemandGroupPost>(
      API.DemandAPI.apiDemandAddDemandGroupPost
    ),
  MaintenanceTimeline: () =>
    useFetchManagerStore<
      typeof API.MaintenanceAPI.apiMaintenanceCommonMaintenanceTimeLineGet
    >(
      API.MaintenanceAPI.apiMaintenanceCommonMaintenanceTimeLineGet,
      "maintenance-timeline"
    ),
  MaintenanceTimelineDetail: () =>
    useFetchManager<
      typeof API.MaintenanceAPI.apiMaintenanceCommonMaintenanceTimeLineDetailGet
    >(API.MaintenanceAPI.apiMaintenanceCommonMaintenanceTimeLineDetailGet),
  CapacityList: () =>
    useFetchManager<
      typeof API.InventoryCapacityAPI.apiInventoryCapacityGetInventoryCapacityListGet
    >(API.InventoryCapacityAPI.apiInventoryCapacityGetInventoryCapacityListGet),
  CompletedReport: () =>
    useFetchManager<
      typeof API.ReportAPI.apiMaintenanceReportGetCompletedMaintenanceGet
    >(API.ReportAPI.apiMaintenanceReportGetCompletedMaintenanceGet),
  InCompletedReport: () =>
    useFetchManager<
      typeof API.ReportAPI.apiMaintenanceReportGetNotCompletedMaintenanceGet
    >(API.ReportAPI.apiMaintenanceReportGetNotCompletedMaintenanceGet),
  WaitingFormReport: () =>
    useFetchManager<
      typeof API.ReportAPI.apiMaintenanceReportGetNegativeAnswerMaintenanceGet
    >(API.ReportAPI.apiMaintenanceReportGetNegativeAnswerMaintenanceGet),
  HomeStatistic: () =>
    useFetchManager<
      typeof API.ReportAPI.apiMaintenanceReportGetHomePageStatisticsGet
    >(API.ReportAPI.apiMaintenanceReportGetHomePageStatisticsGet),
  DoMaintenance: () =>
    useFetchManager<
      typeof API.MaintenanceDetailAPI.apiInventoryMaintenanceDetailDoMaintenanceProcessPost
    >(
      API.MaintenanceDetailAPI
        .apiInventoryMaintenanceDetailDoMaintenanceProcessPost
    ),
  UpdateDoMaintenance: () =>
    useFetchManager<
      typeof API.MaintenanceDetailAPI.apiInventoryMaintenanceDetailDoMaintenanceProcessPost
    >(
      API.MaintenanceDetailAPI
        .apiInventoryMaintenanceDetailDoMaintenanceProcessPost
    ),
  UpdateInventoryRiskAnalyzeDate: () =>
    useFetchManager<
      typeof API.InventoryAPI.apiInventoryUpdateRiskAnalysisExpireDateGet
    >(API.InventoryAPI.apiInventoryUpdateRiskAnalysisExpireDateGet),
  DemandTimeline: () =>
    useFetchManager<typeof API.DemandAPI.apiDemandGetDemandTimeLineGet>(
      API.DemandAPI.apiDemandGetDemandTimeLineGet
    ),
  UploadDemandDocument: () =>
    useFetchManager<typeof API.DemandAPI.apiDemandUploadDemandDocumentPost>(
      API.DemandAPI.apiDemandUploadDemandDocumentPost
    ),
  LegalListByInventory: () =>
    useFetchManager<
      typeof API.InventoryLegalInspectionAPI.apiInventoryLegalInspectionGetInventoryLegalInspectionTypeListGet
    >(
      API.InventoryLegalInspectionAPI
        .apiInventoryLegalInspectionGetInventoryLegalInspectionTypeListGet
    ),
  CreateLegal: () =>
    useFetchManager<
      typeof API.InventoryLegalInspectionAPI.apiInventoryLegalInspectionAddInventoryLegalInspectionTypePost
    >(
      API.InventoryLegalInspectionAPI
        .apiInventoryLegalInspectionAddInventoryLegalInspectionTypePost
    ),
  UpdateLegal: () =>
    useFetchManager<
      typeof API.InventoryLegalInspectionAPI.apiInventoryLegalInspectionUpdateInventoryLegalInspectionTypePost
    >(
      API.InventoryLegalInspectionAPI
        .apiInventoryLegalInspectionUpdateInventoryLegalInspectionTypePost
    ),
  TimelineLegal: () =>
    useFetchManager<
      typeof API.InventoryLegalInspectionAPI.apiInventoryLegalInspectionGetInventoryLegalInspectionTimelineGet
    >(
      API.InventoryLegalInspectionAPI
        .apiInventoryLegalInspectionGetInventoryLegalInspectionTimelineGet
    ),
  DoLegalMaintenance: () =>
    useFetchManager<
      typeof API.InventoryLegalInspectionAPI.apiInventoryLegalInspectionDoInventoryLegalInspectionMaintenancePost
    >(
      API.InventoryLegalInspectionAPI
        .apiInventoryLegalInspectionDoInventoryLegalInspectionMaintenancePost
    ),
  UpdateLegalMaintenance: () =>
    useFetchManager<
      typeof API.InventoryLegalInspectionAPI.apiInventoryLegalInspectionUpdateInventoryLegalInspectionTypePost
    >(
      API.InventoryLegalInspectionAPI
        .apiInventoryLegalInspectionUpdateInventoryLegalInspectionTypePost
    ),
  TimelineDocument: () =>
    useFetchManager<typeof API.Document.apiDocumentGetDocumentTimelineGet>(
      API.Document.apiDocumentGetDocumentTimelineGet
    ),
  CreateControlTask: () =>
    useFetchManager<typeof API.ControlTaskAPI.apiControlTaskAddControlPost>(
      API.ControlTaskAPI.apiControlTaskAddControlPost
    ),
  // EditControlTask: () =>
  //   useFetchManager<typeof API.ControlTaskAPI.apiControlTaskUpdateControlPost>(
  //     API.ControlTaskAPI.apiControlTaskUpdateControlPost
  //   ),
  ListControlTask: () =>
    useFetchManager<typeof API.ControlTaskAPI.apiControlTaskGetControlListGet>(
      API.ControlTaskAPI.apiControlTaskGetControlListGet
    ),
  DemandReport: () =>
    useFetchManager<typeof API.DemandAPI.apiDemandGetDemandReportListGet>(
      API.DemandAPI.apiDemandGetDemandReportListGet
    ),
  DoDocumentMaintenance: () =>
    useFetchManager<typeof API.Document.apiDocumentDoDocumentMaintenancePost>(
      API.Document.apiDocumentDoDocumentMaintenancePost
    ),
  DoUpdateDocument: () =>
    useFetchManager<
      typeof API.Document.apiDocumentDoUpdateDocumentMaintenancePost
    >(API.Document.apiDocumentDoUpdateDocumentMaintenancePost),
  AllDocumentControl: () =>
    useFetchManager<typeof API.Document.apiDocumentGetDocumentAndTypeListGet>(
      API.Document.apiDocumentGetDocumentAndTypeListGet
    ),
  ExpireRiskAnalysisInventory: () =>
    useFetchManager<
      typeof API.InventoryAPI.apiInventoryGetExpireRiskAnalysisInventoryListGet
    >(API.InventoryAPI.apiInventoryGetExpireRiskAnalysisInventoryListGet),
  NonRiskAnalysisInventory: () =>
    useFetchManager<
      typeof API.InventoryAPI.apiInventoryGetNonRiskAnalysisInventoryListGet
    >(API.InventoryAPI.apiInventoryGetNonRiskAnalysisInventoryListGet),
  NonUserInstructions: () =>
    useFetchManager<
      typeof API.InventoryAPI.apiInventoryGetNonUserInstructionsInventoryListGet
    >(API.InventoryAPI.apiInventoryGetNonUserInstructionsInventoryListGet),
  NonUserGuide: () =>
    useFetchManager<
      typeof API.InventoryAPI.apiInventoryGetNonUserGudieInventoryListGet
    >(API.InventoryAPI.apiInventoryGetNonUserGudieInventoryListGet),
  NonMaintenanceContract: () =>
    useFetchManager<
      typeof API.InventoryAPI.apiInventoryGetNonMaintenanceContractInventoryListGet
    >(API.InventoryAPI.apiInventoryGetNonMaintenanceContractInventoryListGet),
  GetMaintenanceNotes: () =>
    useFetchManager<
      typeof API.MaintenanceDetailAPI.apiInventoryMaintenanceDetailGetMaintenanceNotesGet
    >(
      API.MaintenanceDetailAPI
        .apiInventoryMaintenanceDetailGetMaintenanceNotesGet
    ),
  DocumentReports: () =>
    useFetchManager<typeof API.Document.apiDocumentGetDocumentReportListGet>(
      API.Document.apiDocumentGetDocumentReportListGet
    ),
  LegalDesc: () =>
    useFetchManager<
      typeof API.InventoryLegalInspectionAPI.apiInventoryLegalInspectionGetInventoryLegalInspectionTimelineStatusDescGet
    >(
      API.InventoryLegalInspectionAPI
        .apiInventoryLegalInspectionGetInventoryLegalInspectionTimelineStatusDescGet
    ),
  DocumentDesc: () =>
    useFetchManager<
      typeof API.Document.apiDocumentGetDocumentTimelineStatusDescGet
    >(API.Document.apiDocumentGetDocumentTimelineStatusDescGet),
  QuestionForms: () =>
    useFetchManager<
      typeof API.ControlTaskAPI.apiControlTaskGetControlQuestionGroupListGet
    >(API.ControlTaskAPI.apiControlTaskGetControlQuestionGroupListGet),
  UpdateQuestionForm: () =>
    useFetchManager<
      typeof API.ControlTaskAPI.apiControlTaskUpdateControlQuestionGroupGet
    >(API.ControlTaskAPI.apiControlTaskUpdateControlQuestionGroupGet),
  QuestionList: () =>
    useFetchManager<
      typeof API.ControlTaskAPI.apiControlTaskControlQuestionListGet
    >(API.ControlTaskAPI.apiControlTaskControlQuestionListGet),
  UpdateControlQuestion: () =>
    useFetchManager<
      typeof API.ControlTaskAPI.apiControlTaskUpdateControlQuestionGet
    >(API.ControlTaskAPI.apiControlTaskUpdateControlQuestionGet),
  QuestionTypes: () =>
    useFetchManager<
      typeof API.ControlTaskAPI.apiControlTaskGetControlQuestionTypeListGet
    >(API.ControlTaskAPI.apiControlTaskGetControlQuestionTypeListGet),
  AddQuestionToGroup: () =>
    useFetchManager<
      typeof API.ControlTaskAPI.apiControlTaskAddControlQuestionPost
    >(API.ControlTaskAPI.apiControlTaskAddControlQuestionPost),
  TaskList: () =>
    useFetchManager<
      typeof API.ControlTaskAPI.apiControlTaskGetControlTaskListGet
    >(API.ControlTaskAPI.apiControlTaskGetControlTaskListGet),
  AddTask: () =>
    useFetchManager<typeof API.ControlTaskAPI.apiControlTaskAddControlTaskPost>(
      API.ControlTaskAPI.apiControlTaskAddControlTaskPost
    ),
  UpdateTask: () =>
    useFetchManager<
      typeof API.ControlTaskAPI.apiControlTaskUpdateControlTaskPost
    >(API.ControlTaskAPI.apiControlTaskUpdateControlTaskPost),
  TaskPeriods: () =>
    useFetchManager<
      typeof API.ControlTaskAPI.apiControlTaskGetControlTaskPeriodListGet
    >(API.ControlTaskAPI.apiControlTaskGetControlTaskPeriodListGet),
  ControlTaskTimeline: () =>
    useFetchManager<
      typeof API.ControlTaskAPI.apiControlTaskGetControlTimelineGet
    >(API.ControlTaskAPI.apiControlTaskGetControlTimelineGet),
  ControlTaskDesc: () =>
    useFetchManager<
      typeof API.ControlTaskAPI.apiControlTaskGetControlTimelineStatusDescGet
    >(API.ControlTaskAPI.apiControlTaskGetControlTimelineStatusDescGet),
  DoControlTask: () =>
    useFetchManager<
      typeof API.ControlTaskAPI.apiControlTaskDoControlMaintenancePost
    >(API.ControlTaskAPI.apiControlTaskDoControlMaintenancePost),
  AddQuestionGroup: () =>
    useFetchManager<
      typeof API.ControlTaskAPI.apiControlTaskAddControlQuestionGroupPost
    >(API.ControlTaskAPI.apiControlTaskAddControlQuestionGroupPost),
  ControlTaskReport: () =>
    useFetchManager<
      typeof API.ControlTaskAPI.apiControlTaskGetControlReportGet
    >(API.ControlTaskAPI.apiControlTaskGetControlReportGet),
  AllMaintenanceList: () =>
    useFetchManager<
      typeof API.MaintenanceAPI.apiMaintenanceCommonGetMaintenanceTypeListByProjectIdGet
    >(
      API.MaintenanceAPI
        .apiMaintenanceCommonGetMaintenanceTypeListByProjectIdGet
    ),
  UpdateControlTask: () =>
    useFetchManager<
      typeof API.ControlTaskAPI.apiControlTaskUpdateControlTaskPost
    >(API.ControlTaskAPI.apiControlTaskUpdateControlTaskPost),
  UpdateControl: () =>
    useFetchManager<typeof API.ControlTaskAPI.apiControlTaskUpdateControlPost>(
      API.ControlTaskAPI.apiControlTaskUpdateControlPost
    ),
  AllTask: () =>
    useFetchManager<
      typeof API.ControlTaskAPI.apiControlTaskGetControlAndTypeListDGet
    >(API.ControlTaskAPI.apiControlTaskGetControlAndTypeListDGet),
  ReportControlTask: () =>
    useFetchManager<
      typeof API.ControlTaskAPI.apiControlTaskGetControlReportGet
    >(API.ControlTaskAPI.apiControlTaskGetControlReportGet),
  Inventory: () =>
    useFetchManager<typeof API.InventoryAPI.apiInventoryUpdateInventoryPost>(
      API.InventoryAPI.apiInventoryUpdateInventoryPost
    ),
  UpdateDocument: () =>
    useFetchManager<typeof API.Document.apiDocumentUpdateDocumentPost>(
      API.Document.apiDocumentUpdateDocumentPost
    ),
  ResetPassword: () =>
    useFetchManager<typeof API.UserAPI.apiUsersPasswordResetGet>(
      API.UserAPI.apiUsersPasswordResetGet
    ),
  UpdateLegalDate: () =>
    useFetchManager<
      typeof API.InventoryLegalInspectionAPI.apiInventoryLegalInspectionUpdateLegalInspectionMaintenanceEndDatePost
    >(
      API.InventoryLegalInspectionAPI
        .apiInventoryLegalInspectionUpdateLegalInspectionMaintenanceEndDatePost
    ),
  CounterTypeList: () =>
    useFetchManager<typeof API.CounterAPI.apiCounterGetCounterTypeListGet>(
      API.CounterAPI.apiCounterGetCounterTypeListGet
    ),
  CounterAdd: () =>
    useFetchManager<typeof API.CounterAPI.apiCounterAddCounterPost>(
      API.CounterAPI.apiCounterAddCounterPost
    ),
  CounterPeriodList: () =>
    useFetchManager<typeof API.CounterAPI.apiCounterGetCounterPeriodListGet>(
      API.CounterAPI.apiCounterGetCounterPeriodListGet
    ),
  CounterListByProject: () =>
    useFetchManagerStore<
      typeof API.CounterAPI.apiCounterCounterListByProjectIdGet
    >(API.CounterAPI.apiCounterCounterListByProjectIdGet, "counter-list"),
  CounterCreateMaintenance: () =>
    useFetchManager<
      typeof API.CounterAPI.apiCounterAddCounterMaintenanceTypePost
    >(API.CounterAPI.apiCounterAddCounterMaintenanceTypePost),
  CounterListMaintenance: () =>
    useFetchManager<
      typeof API.CounterAPI.apiCounterGetCounterMaintenanceTypeListGet
    >(API.CounterAPI.apiCounterGetCounterMaintenanceTypeListGet),
  CounterTimeline: () =>
    useFetchManagerStore<typeof API.CounterAPI.apiCounterCounterTimelineGet>(
      API.CounterAPI.apiCounterCounterTimelineGet,
      "counter-timeline"
    ),
  CounterTimelineStatus: () =>
    useFetchManager<typeof API.CounterAPI.apiCounterCounterTimelineStatusGet>(
      API.CounterAPI.apiCounterCounterTimelineStatusGet
    ),
  CounterDoMaintenance: () =>
    useFetchManager<typeof API.CounterAPI.apiCounterDoCounterMaintenancePost>(
      API.CounterAPI.apiCounterDoCounterMaintenancePost
    ),
  CancelControlTask: () =>
    useFetchManager<
      typeof API.ControlTaskAPI.apiControlTaskCancelControlMaintenancePost
    >(API.ControlTaskAPI.apiControlTaskCancelControlMaintenancePost),
  CancelDocument: () =>
    useFetchManager<
      typeof API.Document.apiDocumentCancelDocumentMaintenancePost
    >(API.Document.apiDocumentCancelDocumentMaintenancePost),
  CancelCounter: () =>
    useFetchManager<
      typeof API.CounterAPI.apiCounterCancelCounterMaintenancePost
    >(API.CounterAPI.apiCounterCancelCounterMaintenancePost),
  GlobalSearch: () =>
    useFetchManager<typeof API.SearchAPI.apiSearchTimelineGlobalSearchGet>(
      API.SearchAPI.apiSearchTimelineGlobalSearchGet
    ),
  CompletedMaintenanceById: () =>
    useFetchManager<
      typeof API.ReportAPI.apiMaintenanceReportGetCompletedMaintenanceByIdGet
    >(API.ReportAPI.apiMaintenanceReportGetCompletedMaintenanceByIdGet),
  InventoryDetail: () =>
    useFetchManager<
      typeof API.InventoryAPI.apiInventoryGetInventoryByBarcodeGet
    >(API.InventoryAPI.apiInventoryGetInventoryByBarcodeGet),
  DeleteInventoryFile: () =>
    useFetchManager<
      typeof API.InventoryAPI.apiInventoryDeleteInventoryDocumentDelete
    >(API.InventoryAPI.apiInventoryDeleteInventoryDocumentDelete),
  NoteCreate: () =>
    useFetchManager<typeof API.NoteAPI.apiNoteAddNotePost>(
      API.NoteAPI.apiNoteAddNotePost
    ),
  NoteList: () =>
    useFetchManager<typeof API.NoteAPI.apiNoteGetNoteListByProjectIDGet>(
      API.NoteAPI.apiNoteGetNoteListByProjectIDGet
    ),
  CompletedReportOData: (primaryDate: string = "EndDate") => {
    const service = (
      projectId: number | undefined,
      queryFilter: string,
      top: any,
      skipCount: any,
      count: string
    ) => {
      return axiosInstance()
        .get<any[]>(
          `${apiUrl}/odata/MaintenanceReportOdata?projectId=${projectId}&$top=${top}&$skip=${skipCount}&$count=${count}&${queryFilter}&$orderby=${primaryDate} desc`
        )
        .then((r) => r.data);
    };

    return useFetchManager<typeof service>(service);
  },
  MaintenanceCount: () => {
    const service = async (projectId: number | undefined) => {
      const r = await axiosInstance().get<any[]>(
        `${apiUrl}/api/MaintenanceReport/GetCompletedMaintenance?projectId=${projectId}`
      );
      return r.data;
    };

    return useFetchManager<typeof service>(service);
  },
  CompletedReportExcelOData: () => {
    const service = (projectId: number | undefined, queryFilter: string) => {
      return axiosInstance()
        .get<any[]>(
          `${apiUrl}/odata/MaintenanceReportOdata?projectId=${projectId}&${queryFilter}`
        )
        .then((r) => r.data);
    };

    return useFetchManager<typeof service>(service);
  },
  ShowDocsReports: () => {
    const service = async (projectId: number | undefined, query: string) => {
      const r = await axiosInstance().get<any[]>(
        `${apiUrl}/api/MaintenanceReport/GetCompletedMaintenanceById?projectId=${projectId}&maintenanceId=${query}`
      );
      return r.data;
    };

    return useFetchManager<typeof service>(service);
  },
  CancelLegalInspection: () => {
    const service = async (maintenanceId: number) => {
      const r = await axiosInstance().post(
        `${apiUrl}/api/InventoryLegalInspection/CancelInventoryLegalInspection/${maintenanceId}`
      );
      return r.data;
    };

    return useFetchManager<typeof service>(service);
  },
};
