import React, {
  FC,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Spinner,
  Text,
  Input,
  Select,
  Textarea,
  FormControl,
  FormLabel,
  FormErrorMessage,
  IconButton,
} from "@chakra-ui/react";
import { RouteComponentProps } from "react-router-dom";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import { Hooks } from "services";
import DataTable from "components/atoms/DataTable";
import Moment from "moment";
import Badge from "components/atoms/Badge";
import ModalAtom, { ModalProps } from "components/atoms/Modal";
import { useAuth } from "hooks/redux-hooks";
import { useForm } from "react-hook-form";
import { useToast } from "hooks/toast";
import { UserRoles } from "models/user";
import Confirm from "components/atoms/Confirm";
import { IoOptions } from "react-icons/io5";
import UpdateModal from "./UpdateModal";

interface PageStates {
  controlTask: any;
}

interface TasksProps {}

const Tasks: FC<TasksProps & RouteComponentProps<{}, {}, PageStates>> = (
  props
) => {
  const [getT, statT, dataT] = Hooks.TaskList();
  const [addAT, statAT, dataAT] = Hooks.AddTask();
  const [getUL, statUL, dataUL] = Hooks.UserList();
  const [getP, statP, dataP] = Hooks.TaskPeriods();
  const [getQF, statQF, dataQF] = Hooks.QuestionForms();
  const [updateC, statC, dataC] = Hooks.UpdateControlTask();

  const modalRef = useRef<ModalProps>(null);
  const editModalRef = useRef<ModalProps>(null);
  const toast = useToast();

  const { reset, handleSubmit, register, formState, watch } = useForm();

  const { role, user, project } = useAuth();

  const [editData, setEditData] = useState<any>(null);

  useLayoutEffect(() => {
    getT(props.location.state.controlTask.id);
    getUL(project.id);
    getP();
    getQF(project.id);
  }, []);

  function onValid(values: any) {
    addAT({
      beforeDay: parseInt(values.beforeDay || 0),
      controlID: parseInt(props.location.state.controlTask.id),
      controlPeriodID: parseInt(values.controlPeriodID),
      controlQuestionGroupID: parseInt(values.controlQuestionGroupID),
      userID: parseInt(values.userID),
      explain: values.explain,
      startDate: values.startDate || Moment().format("YYYY-MM-DD"),
    });
  }

  useEffect(() => {
    if (statAT === "fulfilled" && dataAT?.data.success) {
      toast({ title: "Görev Başarıyla Eklendi", status: "success" });
      getT(props.location.state.controlTask.id);
      reset();
      modalRef.current?.close();
    } else if (dataAT?.data.success === false) {
      toast({ title: dataAT?.data.message, status: "error" });
    }
  }, [statAT]);

  return (
    <Main headerProps={{ title: "Kontrol Görevleri" }}>
      <Heading size="md">
        Kontrol: {props.location.state.controlTask.name}
      </Heading>
      <Card mt="2">
        <Flex justifyContent="flex-end">
          {role !== UserRoles.view && role !== UserRoles.basic && (
            <Button
              size="sm"
              onClick={() => {
                modalRef.current?.open();
              }}
              colorScheme="blue"
            >
              Yeni Ekle
            </Button>
          )}
        </Flex>
      </Card>
      <Card mt="2">
        {statT !== "fulfilled" && (
          <Center h="300px">
            <Spinner />
          </Center>
        )}
        {statT === "fulfilled" && !!dataT?.data.data && (
          <DataTable
            data={dataT?.data.data}
            columns={[
              { name: "#ID", selector: "id", width: "200px" },
              {
                name: "Görev Atanan Kullanıcı",
                selector: "userName",
                width: "200px",
              },
              {
                name: "Periyot",
                selector: "controlPeriodName",
                width: "200px",
              },
              {
                name: "Soru Grubu",
                selector: "controlQuestionGroupName",
                width: "200px",
              },
              { name: "Açıklma", selector: "explain", width: "200px" },
              {
                name: "Denetim Başlangıç Tarihi",
                width: "200px",
                cell: (row: any) => Moment(row.startDate).format("DD.MM.YYYY"),
                selector: "startDate",
                columnType: "date",
              },
              {
                name: "Kaç Gün Önce Yapılabilir?",
                selector: "beforeDay",
                width: "200px",
              },
              {
                name: "Durum",
                cell: (row: any) => <Badge isActive={row.isActive} />,
                selector: "isActive",
                columnType: "isActiveBoolean",
              },
              {
                name: "Aktif / Pasif",
                cell: (row: any) => (
                  <Confirm
                    onConfirm={async () => {
                      await updateC({ ...row, isActive: !row.isActive });
                      getT(props.location.state.controlTask.id);
                    }}
                    text={`Görev ${
                      !row.isActive ? "Aktif" : "Pasif"
                    } yapılsın mı?`}
                  >
                    {({ onOpen }) => (
                      <IconButton
                        size="sm"
                        onClick={onOpen}
                        aria-label="activepassive"
                        icon={<IoOptions />}
                      />
                    )}
                  </Confirm>
                ),

                width: "100px",
              },
              {
                name: "Düzenle",
                cell: (row: any) => (
                  <Box>
                    <Button
                      size="sm"
                      colorScheme="blue"
                      onClick={() => {
                        setEditData(row);
                        editModalRef.current?.open();
                      }}
                    >
                      Düzenle
                    </Button>
                  </Box>
                ),
              },
            ]}
          />
        )}
      </Card>
      <ModalAtom
        ref={modalRef}
        onConfirmText="Oluştur"
        title="Görev Oluştur"
        onClose={() => {
          reset();
        }}
        isConfirmSpinner={statAT === "pending"}
        onConfirm={() => {
          handleSubmit(onValid)();
        }}
      >
        <FormControl isInvalid={formState.errors["userID"] as any}>
          <FormLabel>
            Kullanıcı{" "}
            <Text d="inline" color="red.500">
              *
            </Text>
          </FormLabel>
          <Select
            defaultValue="0"
            {...register("userID", {
              required: {
                value: true,
                message: "Bu Alanın Doldurulması Zorunludur!",
              },
              validate: (value: any) => value !== "0",
            })}
          >
            <option value="0">Seç</option>
            {!!dataUL?.data.data &&
              dataUL.data.data
                .filter((item: any) =>
                  role === UserRoles.admin ? true : item?.userRoleID >= role
                )
                .map((user: any, index: number) => (
                  <option key={index} value={user.id}>
                    {user.username}
                  </option>
                ))}
            <FormErrorMessage>Bu alan boş bırakılamaz!</FormErrorMessage>
          </Select>
        </FormControl>
        <FormControl mt="2" isInvalid={formState.errors["controlPeriodID"] as any}>
          <FormLabel>
            Periyot{" "}
            <Text d="inline" color="red.500">
              *
            </Text>
          </FormLabel>
          <Select
            defaultValue="0"
            {...register("controlPeriodID", {
              required: {
                value: true,
                message: "Bu Alanın Doldurulması Zorunludur!",
              },
              validate: (value: any) => value !== "0",
            })}
          >
            <option value="0">Seç</option>

            {!!dataP?.data.data &&
              dataP.data.data.map((item: any, index: number) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
          </Select>
          <FormErrorMessage>Bu alan boş bırakılamaz!</FormErrorMessage>
        </FormControl>
        <FormControl
          mt="2"
          isInvalid={formState.errors["controlQuestionGroupID"] as any}
        >
          <FormLabel>
            Soru Grubu{" "}
            <Text d="inline" color="red.500">
              *
            </Text>
          </FormLabel>
          <Select
            defaultValue="0"
            {...register("controlQuestionGroupID", {
              required: {
                value: true,
                message: "Bu Alanın Doldurulması Zorunludur!",
              },
              validate: (value: any) => value !== "0",
            })}
          >
            <option value="0">Seç</option>
            {!!dataQF?.data.data &&
              dataQF.data.data.map((item: any, index: number) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
          </Select>
          <FormErrorMessage>Bu alan boş bırakılamaz!</FormErrorMessage>
        </FormControl>
        {watch("controlPeriodID") !== "9" && (
          <Fragment>
            <FormControl mt="2" isInvalid={formState.errors["startDate"] as any}>
              <FormLabel>
                Lütfen Seçmiş Olduğunuz Periyodun Bir Önceki Periyot Tarihini
                Giriniz!{" "}
                <Text d="inline" color="red.500">
                  *
                </Text>
              </FormLabel>
              <Input
                {...register("startDate", { required: true })}
                type="date"
              />
              <FormErrorMessage>Bu alan boş bırakılamaz!</FormErrorMessage>
            </FormControl>
            <FormControl mt="2">
              <FormLabel>Kaç Gün Önce Yapılabilir?</FormLabel>
              <Input {...register("beforeDay", {})} type="number" />
            </FormControl>
          </Fragment>
        )}
        <FormControl mt="2">
          <FormLabel>Açıklama</FormLabel>
          <Textarea {...register("explain", {})} />
        </FormControl>
      </ModalAtom>
      <UpdateModal
        {...{ modalRef: editModalRef, task: editData, dataP, dataQF, dataUL }}
        onSuccess={() => {
          toast({ title: "Görev Başarıyla Düzenlendi", status: "success" });
          getT(props.location.state.controlTask.id);
          setEditData(null);
          editModalRef.current?.close();
        }}
      />
    </Main>
  );
};

Tasks.defaultProps = {};

export default Tasks;
