import React, { FC, useEffect, useLayoutEffect } from "react";
import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Text,
  Center,
  Spinner,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Inventory } from "models";
import { Card } from "components/atoms";
import { Hooks } from "services";
import DataTable from "components/atoms/DataTable";
import Moment from "moment";
import Confirm from "components/atoms/Confirm";
import { IoOptions } from "react-icons/io5";
import { useAuth } from "hooks/redux-hooks";
import { UserRoles } from "models/user";
import { isSieOrNo } from "helper/project-mode";

interface PageParams {
  id: string;
}

interface PageStates {
  inventory: Inventory;
}

interface PeriodControlsProps {}

type PageProps = RouteComponentProps<PageParams, {}, PageStates> &
  PeriodControlsProps;

const PeriodControls: FC<PageProps> = (props) => {
  const [getList, statList, dataList] = Hooks.LegalListByInventory();
  const [update, statUpdate, dataUpdate] = Hooks.UpdateLegal();
  const { role } = useAuth();
  const history = useHistory();

  function handleClickToggleActive(row: any) {
    update({
      ...row,
      isActive: !row.isActive,
    });
  }

  useLayoutEffect(() => {
    getList(parseInt(props.match.params.id));
  }, []);

  useEffect(() => {
    if (statUpdate === "fulfilled") {
      getList(parseInt(props.match.params.id));
    }
  }, [statUpdate]);

  return (
    <Main headerProps={{ title: `Periyodik ${isSieOrNo("Kontroller", "Muayeneler")}` }}>
      <Flex>
        <Heading size="md">
          Ekipman: {props.location.state.inventory.name}
        </Heading>
        <Heading size="md" ml="4">
          Barkod: {props.location.state.inventory.barcode}
        </Heading>
      </Flex>
      <Card mt="4" py="3">
        <Flex justifyContent="flex-end">
          {role !== UserRoles.basic && role !== UserRoles.view && (
            <Button
              colorScheme="blue"
              size="sm"
              onClick={() => {
                props.history.push(
                  `/periodic-controls-create/${props.match.params.id}`,
                  {
                    inventory: props.location.state.inventory,
                  }
                );
              }}
            >
              Yeni Ekle
            </Button>
          )}
        </Flex>
      </Card>
      <Card mt="3">
        {dataList?.data.data ? (
          <DataTable
            data={dataList?.data.data || []}
            columns={[
              { name: "#ID", selector: "id" },
              {
                name: "Başlangıç Tarihi",
                cell: (row: any) => Moment(row.startDate).format("DD.MM.YYYY"),
                width: "150px",
                selector: "startDate",
                columnType: "date",
              },
              {
                name: `Periyodik ${isSieOrNo("Kontrol", "Muayene")} Başlangıç Günü`,
                selector: "beforeDay",
              },
              {
                name: "Kontrol Formu",
                width: "150px",
                selector: "controlFormPhotoIdRequired",
                columnType: "boolean",
                cell: (row: any) => (
                  <Badge
                    colorScheme={
                      row.controlFormPhotoIdRequired ? "orange" : undefined
                    }
                  >
                    {row.controlFormPhotoIdRequired
                      ? "Zorunlu"
                      : "Zorunlu Değil"}
                  </Badge>
                ),
              },
              {
                name: "Ekipman Fotoğrafı",
                selector: "inventoryPhotoIdRequired",
                columnType: "boolean",
                width: "150px",
                cell: (row: any) => (
                  <Badge
                    colorScheme={
                      row.inventoryPhotoIdRequired ? "orange" : undefined
                    }
                  >
                    {row.inventoryPhotoIdRequired ? "Zorunlu" : "Zorunlu Değil"}
                  </Badge>
                ),
              },
              {
                name: "Bakım Sözleşmesi",
                width: "150px",
                selector: "maintenanceContractIsRequired",
                columnType: "boolean",
                cell: (row: any) => (
                  <Badge
                    colorScheme={
                      row.maintenanceContractIsRequired ? "orange" : undefined
                    }
                  >
                    {row.maintenanceContractIsRequired
                      ? "Zorunlu"
                      : "Zorunlu Değil"}
                  </Badge>
                ),
              },
              {
                name: "ISG Risk Analizi",
                width: "150px",
                selector: "riskAnalysisIsRequired",
                columnType: "boolean",
                cell: (row: any) => (
                  <Badge
                    colorScheme={
                      row.riskAnalysisIsRequired ? "orange" : undefined
                    }
                  >
                    {row.riskAnalysisIsRequired ? "Zorunlu" : "Zorunlu Değil"}
                  </Badge>
                ),
              },
              {
                name: "Kullanma Talimatı",
                selector: "userInstructionsIsRequired",
                columnType: "boolean",
                width: "150px",
                cell: (row: any) => (
                  <Badge
                    colorScheme={
                      row.userInstructionsIsRequired ? "orange" : undefined
                    }
                  >
                    {row.userInstructionsIsRequired
                      ? "Zorunlu"
                      : "Zorunlu Değil"}
                  </Badge>
                ),
              },
              {
                name: "Kullanım Klavuzu",
                width: "150px",
                selector: "userGudiePathIsRequired",
                columnType: "boolean",
                cell: (row: any) => (
                  <Badge
                    colorScheme={
                      row.userGudiePathIsRequired ? "orange" : undefined
                    }
                  >
                    {row.userGudiePathIsRequired ? "Zorunlu" : "Zorunlu Değil"}
                  </Badge>
                ),
              },
              {
                name: "Oluşturan Kullanıcı",
                width: "200px",
                selector: "createUserName",
              },
              {
                name: "Durum",
                width: "150px",
                selector: "isActive",
                columnType: "isActiveBoolean",
                cell: (row: any) => (
                  <Badge colorScheme={!!row.isActive ? "green" : undefined}>
                    {!!row.isActive ? "Aktif" : "Pasif"}
                  </Badge>
                ),
              },
              {
                name: "Aktif / Pasif",
                cell: (row: any) => (
                  <Box>
                    <Confirm
                      onConfirm={() => {
                        handleClickToggleActive(row);
                      }}
                      text={`Bu kayıt ${
                        !!row.isActive ? "aktif" : "pasif"
                      } yapılacak. İşleme devam edilsin mi?`}
                    >
                      {({ onOpen }) => (
                        <IconButton
                          disabled={role === UserRoles.basic}
                          size="sm"
                          onClick={onOpen}
                          aria-label="active"
                          icon={<IoOptions />}
                        />
                      )}
                    </Confirm>
                  </Box>
                ),
              },
              {
                name: "Düzenle",
                cell: (row: any) => (
                  <Box>
                    <Button
                      size="sm"
                      colorScheme="blue"
                      onClick={() => {
                       
                        history.push("/periodic-controls-edit", {
                          control: row,
                          inventory: props.location.state.inventory,
                        });
                      }}
                    >
                      Düzenle
                    </Button>
                  </Box>
                ),
              },
            ]}
          />
        ) : (
          <Center>
            <Spinner />
          </Center>
        )}
      </Card>
    </Main>
  );
};

PeriodControls.defaultProps = {};

export default PeriodControls;
