import {
  Box,
  Divider,
  Flex,
  Heading,
  Image,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import { useAuth } from "hooks/redux-hooks";
import Moment from "moment";
import { FC, Fragment, useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { RouteComponentProps } from "react-router-dom";
import "./master.css";
import { getApiUrl } from "services/urls";

function photoUrlManipulation(url: string) {
  if (!url || url === "") return url;
  if (!url.includes("//")) return url;
  url.replace(/\/\//g, "/");
  const apiUrl = getApiUrl();

  url = url.split("/Content")[1];
  url = `${apiUrl}/Content${url}`;
  console.log({ url });
  return url;
}

interface DocReportProps {}

const configs = {
  dateString: "YYYY-MM-DD[T]HH:mm:ss",
};

const DocReport: FC<DocReportProps & RouteComponentProps> = (props) => {
  const { user, project } = useAuth();
  const [counter, setMaintenance] = useState<any>(null);
  const [lightImage, setLightImage] = useState<string | null>(null);

  useEffect(() => {
    const data = localStorage.getItem("counter-report");
    if (!data) {
      props.history.push("/");
    }

    setMaintenance(JSON.parse(data!));
  }, []);

  if (!counter) return <Spinner />;

  return (
    <Fragment>
      <div className="book">
        <div className="page">
          <Header />
          <DataTable
            title="Sayaç Bilgileri"
            data={[
              { title: "Tüketim Bölgesi", value: counter.location },
              { title: "Kod", value: counter.barcode },
              { title: "Periyot", value: counter.periodName },
              { title: "Sayaç Türü", value: counter.counterTypeName },
              { title: "Çarpan Değeri", value: counter.multiplierValue },
              {
                title: "Ortalama Tüketim Farkı Yüzdesi",
                value: counter.multiplierValue,
              },
            ]}
          />
          <div className="page-number">1</div>
        </div>
        <div className="page">
          <Header />
          <DataTable
            title={`Sayaç Bilgileri`}
            data={[
              {
                title: "Başlangıç Tarihi",
                value: Moment(counter.startDate).format("DD/MM/YYYY"),
              },
              {
                title: `Gerçekleştirme Tarihi`,
                value: Moment(counter.completedDate).format("DD/MM/YYYY"),
              },
              {
                title: `T`,
                value: counter.t,
              },
              {
                title: `T1`,
                value: counter.t1,
              },
              {
                title: `T2`,
                value: counter.t2,
              },
              {
                title: `T3`,
                value: counter.t3,
              },
              {
                title: `Kapasitif`,
                value: counter.kapasitif,
              },
              {
                title: `Reaktif`,
                value: counter.reaktif,
              },
              {
                title: `Demant`,
                value: counter.demant,
              },
              {
                title: `Değer`,
                value: counter.value,
              },
              {
                title: `Açıklama`,
                value: counter.description,
              },
              {
                title: `Gerçekleştiren Kullanıcı`,
                value: counter.controlledUserName,
              },
            ]}
          />

          <div>
            <div style={{ marginTop: 10 }}>
              <Heading fontSize="md">Sayaç Fotoğrafı</Heading>
            </div>

            {!counter.photoPath && <div>Bu bakıma fotoğraf eklenmemiş.</div>}

            {counter.photoPath && (
              <img
                alt="photoPath"
                style={{
                  cursor: "pointer",
                  maxHeight: "230px",
                  maxWidth: "100%",
                  marginRight: 3,
                }}
                src={photoUrlManipulation(counter.photoPath)}
                onClick={(e) =>
                  setLightImage(photoUrlManipulation(counter.photoPath))
                }
                width="auto"
                height="auto"
              />
            )}
          </div>

          <div className="page-number">2</div>
        </div>
      </div>
      {!!lightImage && (
        <Lightbox
          mainSrc={lightImage!}
          onCloseRequest={() => setLightImage(null)}
        />
      )}
    </Fragment>
  );
};

function Header() {
  const { user, project } = useAuth();
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      borderBottom="1px"
      borderColor="gray.300"
      pb="1"
    >
      <Flex flex="1">
        <Image src={project.companyLogo} width="150px" />
      </Flex>
      <Flex flex="3" justifyContent="center">
        <Text textAlign="center" fontSize="lg">
          Sayaç Raporu
        </Text>
      </Flex>
      <Flex flex="1" justifyContent="flex-end">
        <Text textAlign="center" fontSize="lg">
          {Moment().format("DD.MM.YYYY")}
        </Text>
      </Flex>
    </Flex>
  );
}

function DataTable({ data, title }: any) {
  return (
    <Box py="4">
      <Heading fontSize="md">{title}</Heading>
      <Divider my="2" />
      {data && (
        <Table variant="striped" size="sm">
          <Tbody>
            {data.map((item: any, index: number) => (
              <Tr key={index}>
                <Td>
                  <span>{item.title}: </span>
                </Td>
                <Td style={{ minWidth: 130 }}>
                  <strong>{item.value}</strong>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
}

function DataCard({ title, children }: any) {
  return (
    <Box py="4">
      <Heading fontSize="md" mb="5">
        {title}
      </Heading>
      <Flex w="100%" flexWrap="wrap" wrap="wrap">
        {children}
      </Flex>
    </Box>
  );
}

DocReport.defaultProps = {};

export default DocReport;
