import React, {
  FC,
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  Badge,
  Box,
  Button,
  Center,
  Checkbox,
  Collapse,
  Divider,
  Flex,
  Heading,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  Spinner,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { Card, Modal, ModalProps } from "components/atoms";
import PerfectScrollbar from "components/atoms/PerfectScrollbar";
import { Hooks } from "services";
import {
  IoAlbumsOutline,
  IoArrowDown,
  IoBackspace,
  IoCheckmark,
  IoEllipsisHorizontal,
  IoFileTray,
  IoFilter,
  IoInformation,
  IoRemove,
  IoSearch,
  IoTrash,
} from "react-icons/io5";
import { useAuth } from "hooks/redux-hooks";
import Moment from "moment";
import { useHistory } from "react-router-dom";
import { TimelineItem } from "models";
import { colors } from "utils/maintenanceColors";
import { UserRoles } from "models/user";
import { IoIosClock } from "react-icons/io";
import Confirm from "components/atoms/Confirm";

export enum TimelineStatus {
  wait = 1,
  done = 2,
  ready = 3,
  late = 4,
  lateDone = 5,
}

interface TimelineControlProps {
  renderChangeDropdown: () => ReactElement;
  hidden: boolean;
  hiddenFuture: boolean;
  selectedCampus: number;
  onChangeCount: (count: number) => void;
}

const TimelineControl: FC<TimelineControlProps> = (props) => {
  const { project, user } = useAuth();
  const _ref = useRef<any>(null);
  const [getTime, statTime, dataTime] = Hooks.ControlTaskTimeline();
  const [getDesc, statDesc, dataDesc] = Hooks.ControlTaskDesc();
  const [getData, statusData, dataData] = Hooks.ControlTaskReport();
  const [getTypes, statType, dataTypes] = Hooks.UserTypeList();
  const [getUsers, statUsers, dataUsers] = Hooks.UserList();
  const filterModalRef = useRef<ModalProps>(null);
  const [query, setQuery] = useState<string>("");
  const [isSearch, setSearch] = useState(false);
  const [iStat, setIStat] = useState<boolean | null>(null);

  const [filter, setFilter] = useState({
    status: [] as string[],
    period: [] as number[],
    types: [] as number[],
    userTypes: [] as number[],
    users: [user.id] as number[],
  });

  const [currentFilter, setCurrentFilter] = useState({
    status: [] as string[],
    period: [] as number[],
    types: [] as number[],
    userTypes: [] as number[],
    users: [user.id] as number[],
  });

  function getDatas() {
    getDesc();
    getTime(project.id);
    getTypes(project.id);
    getUsers(project.id);
    getData(project.id);
  }

  useLayoutEffect(() => {
    getDatas();
  }, []);

  function handleFilter() {
    setCurrentFilter(filter);
    filterModalRef.current?.close();
  }

  function clearFilter() {
    setCurrentFilter({
      status: [] as string[],
      period: [] as number[],
      types: [] as number[],
      userTypes: [] as number[],
      users: [user.id] as number[],
    });
    setFilter({
      status: [] as string[],
      period: [] as number[],
      types: [] as number[],
      userTypes: [] as number[],
      users: [user.id] as number[],
    });
    filterModalRef.current?.close();
  }

  function onFilter(item: any) {
    let filterArr = [];
    if (currentFilter.status.length !== 0) {
      filterArr.push(currentFilter.status.includes((item as any).statusColor));
    }

    if (currentFilter.userTypes.length !== 0) {
      filterArr.push(
        currentFilter.userTypes.includes((item as any).userTypeID)
      );
    }

    if (currentFilter.users.length !== 0) {
      filterArr.push(currentFilter.users.includes((item as any).userID));
    }

    if (iStat !== null) {
      filterArr.push(item.isCompleted === iStat);
    }

    if (props.hidden) {
      if (
        [TimelineStatus.done, TimelineStatus.lateDone].includes(item.statusCode)
      ) {
        filterArr.push(false);
      }
    }
    if (props.hiddenFuture) {
      if ([TimelineStatus.wait].includes(item.statusCode)) {
        filterArr.push(false);
      }
    }

    if (props.selectedCampus !== 0) {
      filterArr.push(props.selectedCampus === item.campusID);
    }

    return (
      !filterArr.includes(false) &&
      item.controlName.toLowerCase().includes(query.toLowerCase())
    );
  }

  useEffect(() => {
    if (dataTime?.data.data) {
      const count = dataTime?.data.data.filter(onFilter).length;
      props.onChangeCount(count);
    }
  }, [filter, props.hiddenFuture, props.hidden, dataTime, currentFilter]);

  return (
    <Card w="100%" minH="calc(100vh - 200px)">
      <Flex mb="2" justifyContent="space-between">
        {props.renderChangeDropdown()}
        <Flex>
          <Tooltip label="Filtre" aria-label="A tooltip">
            <IconButton
              size="sm"
              mr="1"
              aria-label="Filtre"
              icon={<IoFilter />}
              onClick={() => {
                filterModalRef.current?.open();
              }}
            />
          </Tooltip>
          <Tooltip label="Geçmiş" aria-label="A tooltip">
            <IconButton
              size="sm"
              mr="2"
              icon={<IoIosClock />}
              onClick={() => {
                _ref.current?.goUndone();
              }}
              aria-label="Geçmiş"
            />
          </Tooltip>
          <Tooltip label="Bugüne Git" aria-label="A tooltip">
            <IconButton
              size="sm"
              mr="2"
              icon={<IoArrowDown />}
              onClick={() => {
                _ref.current?.goToday();
              }}
              aria-label="Bugün"
            />
          </Tooltip>
          <Tooltip label="Ara" aria-label="Search tooltip">
            <IconButton
              size="sm"
              mr="2"
              icon={<IoSearch />}
              onClick={() => {
                if (isSearch) {
                  setQuery("");
                  setSearch(false);
                } else {
                  setSearch(true);
                }
              }}
              aria-label="Ara"
            />
          </Tooltip>
          <Popover placement="bottom-end">
            <PopoverTrigger>
              <Box>
                <Tooltip label="Renk Açıklamaları" aria-label="Colors tooltip">
                  <IconButton
                    size="sm"
                    aria-label="colors"
                    icon={<IoInformation />}
                  />
                </Tooltip>
              </Box>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Doküman Renk Açıklamaları</PopoverHeader>
              <PopoverBody>
                {dataDesc?.data?.data.map((color: any, index: number) => (
                  <Flex alignItems="center" mb="1">
                    <Box
                      w="20px"
                      h="20px"
                      mr="2"
                      bgColor={color.statusColor}
                      rounded="sm"
                    ></Box>
                    <Text>{color.statusDesc}</Text>
                  </Flex>
                ))}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
      </Flex>
      <Box>
        <Collapse in={isSearch} animateOpacity>
          <Box>
            <Input
              size="sm"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              disabled={!isSearch}
              placeholder="Denetim Adına Göre Ara..."
            />
          </Box>
        </Collapse>
      </Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontWeight="semibold" mb="1" mt="2">
          Denetim İşlemleri
        </Text>
        <Flex>
          <Tooltip label="Gerçekleşen">
            <IconButton
              size="xs"
              colorScheme="green"
              variant={iStat === true ? "solid" : "outline"}
              aria-label="complete"
              onClick={() => {
                setIStat(true);
              }}
              icon={<IoCheckmark />}
            />
          </Tooltip>
          <Tooltip label="Hepsi">
            <IconButton
              mx="1"
              size="xs"
              aria-label="complete"
              variant={iStat === null ? "solid" : "outline"}
              onClick={() => {
                setIStat(null);
              }}
              icon={<IoAlbumsOutline />}
            />
          </Tooltip>
          <Tooltip label="Gerçekleşmeyen">
            <IconButton
              size="xs"
              aria-label="complete"
              variant={iStat === false ? "solid" : "outline"}
              onClick={() => {
                setIStat(false);
              }}
              colorScheme="red"
              icon={<IoRemove />}
            />
          </Tooltip>
        </Flex>
      </Flex>
      <Box>
        {statTime !== "fulfilled" && (
          <Center minH="400px">
            <Spinner />
          </Center>
        )}
        {statTime === "fulfilled" && dataTime?.data.data && (
          <TimelineItems
            ref={_ref}
            data={dataTime?.data.data.filter(onFilter)}
            reports={dataData}
            onNeedReload={getDatas}
          />
        )}
      </Box>
      <Modal
        ref={filterModalRef}
        noForm
        title="Filtre"
        onClose={() => {}}
        onOpen={() => {
          setFilter(currentFilter);
        }}
      >
        <Box>
          <Heading mt="-3" fontSize="md">
            Doküman Durumu
          </Heading>
          <Divider my="1" />
          <SimpleGrid columns={[2, 3]} gap="1">
            {dataDesc?.data?.data.map((_color: any) => (
              <Flex alignItems="center" borderWidth="1px" px="3" py="1">
                <Checkbox
                  borderColor={_color.statusColor}
                  defaultChecked={filter.status.includes(_color.statusColor)}
                  onChange={(e) => {
                    if (e.currentTarget.checked) {
                      if (!filter.status.includes(_color.statusColor)) {
                        setFilter((_state) => ({
                          ..._state,
                          status: [..._state.status, _color.statusColor],
                        }));
                      }
                    } else {
                      setFilter((_state) => ({
                        ..._state,
                        status: _state.status.filter(
                          (_statu) => _statu !== _color.statusColor
                        ),
                      }));
                    }
                  }}
                >
                  <Text fontSize="xs" lineHeight="4">
                    {_color.statusDesc}
                  </Text>
                </Checkbox>
              </Flex>
            ))}
          </SimpleGrid>
          <Heading mt="3" fontSize="md">
            Kullanıcı Tipi
          </Heading>
          <Divider my="1" />
          <SimpleGrid columns={[2, 3]} gap="1">
            {dataTypes?.data.data
              .filter((_period: any) =>
                user.userTypes.map((_: any) => _.id).includes(_period.id)
              )
              .map((_period: any) => (
                <Flex alignItems="center" borderWidth="1px" px="3" py="2">
                  <Checkbox
                    defaultChecked={filter.userTypes.includes(_period.id)}
                    onChange={(e) => {
                      if (e.currentTarget.checked) {
                        if (!filter.userTypes.includes(_period.id)) {
                          setFilter((_state) => ({
                            ..._state,
                            userTypes: [..._state.userTypes, _period.id],
                          }));
                        }
                      } else {
                        setFilter((_state) => ({
                          ..._state,
                          userTypes: _state.userTypes.filter(
                            (_periodId) => _periodId !== _period.id
                          ),
                        }));
                      }
                    }}
                  >
                    <Box>
                      <Text fontSize="xs">
                        {_period.name} {_period.surname}
                      </Text>
                    </Box>
                  </Checkbox>
                </Flex>
              ))}
          </SimpleGrid>
          <Heading mt="3" fontSize="md">
            Kullanıcı
          </Heading>
          <Divider my="1" />
          <SimpleGrid columns={[2, 3]} gap="1">
            {dataUsers?.data.data
              .filter((item) => item.id === user.id)
              .map((_period: any) => (
                <Flex alignItems="center" borderWidth="1px" px="3" py="2">
                  <Checkbox
                    defaultChecked={filter.users.includes(_period.id)}
                    onChange={(e) => {
                      if (e.currentTarget.checked) {
                        if (!filter.users.includes(_period.id)) {
                          setFilter((_state) => ({
                            ..._state,
                            users: [..._state.users, _period.id],
                          }));
                        }
                      } else {
                        setFilter((_state) => ({
                          ..._state,
                          users: _state.users.filter(
                            (_periodId) => _periodId !== _period.id
                          ),
                        }));
                      }
                    }}
                  >
                    <Text fontSize="xs" lineHeight="4">
                      Sadece Ben
                    </Text>
                  </Checkbox>
                </Flex>
              ))}
          </SimpleGrid>
          <Flex justifyContent="flex-end" mt="10">
            <Button
              leftIcon={<IoTrash />}
              onClick={clearFilter}
              mr="2"
              colorScheme="red"
              variant="outline"
              size="sm"
            >
              Temizle
            </Button>
            <Button
              leftIcon={<IoFilter />}
              colorScheme="blue"
              minW="200px"
              onClick={handleFilter}
              size="sm"
            >
              Filtre
            </Button>
          </Flex>
        </Box>
      </Modal>
    </Card>
  );
};

const TimelineItems = forwardRef<any, any>((props, ref) => {
  const { role } = useAuth();
  const history = useHistory();
  const scrollBarRef = useRef<any>(null);
  const [cancelControlTask] = Hooks.CancelControlTask();


  useImperativeHandle(ref, () => ({
    goToday: () => {
      getTodayTopPosition();
    },
    goUndone: () => {
      goUndone();
    },
  }));

  function getUndoneIndex(data: any[]) {
    let undoneIndex = 0;
    if (!!data) {
      data.forEach((item: any, currIndex: number) => {
        if (
          [TimelineStatus.ready, TimelineStatus.late].includes(item.statusCode)
        ) {
          var undoneItem: any = data[undoneIndex];
          if (undoneItem.isCompleted) {
            undoneIndex = currIndex;
          } else if (
            Moment(undoneItem.endDate).diff(item.endDate, "seconds") > 0
          ) {
            undoneIndex = currIndex;
          }
        }
      });
    }
    return undoneIndex;
  }

  function goUndone() {
    let today = getUndoneIndex(props.data);
    if (today || today === 0) {
      let el = document.getElementById(`item-${today}`);
      scrollBarRef.current?._container.scrollTo({
        top: el?.offsetTop,
        behavior: "smooth",
      });
    } else {
    }
  }

  function getTodayIndex() {
    if (!!props.data) {
      let index = 0;
      let closeIndex = 0;
      let close = 0;
      while (index < props.data.length) {
        const item = props.data[index];
        const day = Moment(item.endDate).diff(Moment(), "days") + 0;
        if (index === 0) {
          closeIndex = index;
          close = day;
        } else {
          if (0 - day < close) {
            closeIndex = index;
            close = day;
          }
        }
        index++;
      }
      return closeIndex;
    }
  }

  function getTodayTopPosition() {
    let today = getTodayIndex();
    if (today || today === 0) {
      let el = document.getElementById(`item-${today}`);
      scrollBarRef.current?._container.scrollTo({
        top: el?.offsetTop,
        behavior: "smooth",
      });
    } else {
    }
  }

  useEffect(() => {
    if (!!props.data && props.data.length > 0) {
      goUndone();
    }
  }, [props.data]);

  if (props.data.length === 0) {
    return <Center h="300px">Denetim Kontrolü Bulunamadı!</Center>;
  }
  return (
    <PerfectScrollbar
      maxHeight={"calc(100vh - 250px)"}
      minHeight={"calc(100vh - 250px)"}
      innerRef={scrollBarRef}
    >
      <Flex
        mb="2"
        border="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        p="1"
        justifyContent="space-between"
        alignItems="center"
        position="sticky"
        top="0px"
        backgroundColor={useColorModeValue("gray.100", "gray.700")}
        zIndex="2"
      >
        <Flex
          flexDir="column"
          alignItems="center"
          w="100px"
          pr="2"
          borderRight="1px"
          borderColor={useColorModeValue("gray.100", "gray.700")}
          mr="3"
        >
          <Text lineHeight="1" fontSize="xs">
            Tarih
          </Text>
        </Flex>
        <Flex alignItems="center" flex="1">
          <Box rounded="full" w="10px" h="10px" mr="4"></Box>
          <Box flex="1">
            <Text flex="1" lineHeight="1" fontSize="x-small">
              ID
            </Text>
          </Box>
          <Box ml="2" flex="1">
            <Text lineHeight="1" fontSize="x-small">
              Tesis
            </Text>
          </Box>
          <Box ml="2" flex="2">
            <Text lineHeight="1" fontSize="x-small">
              Kullanıcı
            </Text>
          </Box>
          <Box ml="2" flex="1">
            <Text lineHeight="1" fontSize="x-small">
              Periyot
            </Text>
          </Box>
          <Box ml="2" flex="2">
            <Text lineHeight="1" fontSize="x-small">
              Soru Grubu
            </Text>
          </Box>
          <Box ml="2" flex="2">
            <Text lineHeight="1" fontSize="x-small">
              Planlı Denetim Tarihi
            </Text>
          </Box>
          <Box ml="2" flex="2">
            <Text lineHeight="1" fontSize="x-small">
              Kullanıcı Tipi
            </Text>
          </Box>
        </Flex>
        <Flex minW="200px" justifyContent="flex-end"></Flex>
      </Flex>
      {props.data.map((item: any, index: number) => {
        return (
          <Flex
            mb="2"
            border="1px"
            borderColor={useColorModeValue("gray.100", "gray.700")}
            p="1"
            justifyContent="space-between"
            alignItems="center"
            id={`item-${index}`}
          >
            <Flex
              flexDir="column"
              alignItems="center"
              w="100px"
              pr="2"
              borderRight="1px"
              borderColor={useColorModeValue("gray.100", "gray.700")}
              mr="3"
            >
              <Text lineHeight="1" fontSize="xs">
                {Moment(item.endDate).format("DD")}
              </Text>
              <Text fontSize="xs" lineHeight="1" flex="1">
                {Moment(item.endDate).format("MMMM")}
              </Text>
            </Flex>
            <Flex alignItems="center" flex="1">
              <Box
                rounded="full"
                bgColor={item.statusColor}
                w="10px"
                h="10px"
                mr="4"
              ></Box>
              <Box flex="1">
                <Text lineHeight="1.5" fontSize="xs" fontWeight="bold">
                  {item.controlTaskMaintenanceID}
                </Text>
              </Box>
              <Box ml="2" flex="1">
                <Text lineHeight="1.5" fontSize="xs" fontWeight="bold">
                  {item.campusName}
                </Text>
              </Box>
              <Box ml="2" flex="2">
                <Text lineHeight="1.5" fontSize="xs" fontWeight="bold">
                  {item.userName}
                </Text>
              </Box>
              <Box ml="2" flex="1">
                <Text lineHeight="1.5" fontSize="xs" fontWeight="bold">
                  {item.controlPeriodName}
                </Text>
              </Box>
              <Box ml="2" flex="2">
                <Text lineHeight="1.5" fontSize="xs" fontWeight="bold">
                  {item.controlQuestionGroupName}
                </Text>
              </Box>
              <Box ml="2" flex="2">
                <Text lineHeight="1.5" fontSize="xs" fontWeight="bold">
                  {Moment(item.endDate).format("DD.MM.YYYY")}
                </Text>
              </Box>
              <Box ml="2" flex="2">
                <Text lineHeight="1.5" fontSize="xs" fontWeight="bold">
                  {(item as unknown as any)?.userTypeName}
                </Text>
              </Box>
            </Flex>
            <Flex minW="200px" justifyContent="flex-end">
              {[TimelineStatus.done, TimelineStatus.lateDone].includes(
                item.statusCode
              ) && (
                <>
                  <Badge size="sm" py="0.5" ml="2" colorScheme="green">
                    Tamamlandı
                  </Badge>
                </>
              )}
              {[TimelineStatus.done, TimelineStatus.lateDone].includes(
                item.statusCode
              ) && (
                <>
                  <Menu placement="bottom-end">
                    {({ isOpen }) => (
                      <>
                        <MenuButton
                          ml="2"
                          isActive={isOpen}
                          as={IconButton}
                          size="xs"
                          icon={<IoEllipsisHorizontal />}
                        />
                        <MenuList>
                          <MenuItem
                            icon={<IoFileTray />}
                            onClick={() => {
                              const row = props.reports.data.data.find(
                                (_item: any) =>
                                  _item.complatedDate === item.complatedDate
                              );
                              localStorage.removeItem("doc-report");
                              localStorage.setItem(
                                "doc-report",
                                JSON.stringify(row)
                              );
                              window.open("/doc-report-control-task", "_blank");
                            }}
                          >
                            <Text fontSize="xs">Raporu Gör</Text>
                          </MenuItem>
                          <Confirm
                            onConfirm={async () => {
                              await cancelControlTask({controlMaintenanceId: item.controlTaskMaintenanceID})
                              props.onNeedReload();
                            }}
                            colorScheme="orange"
                            confirmText="Geri Almayı Onayla"
                            text="Tamamlanan kontrol geri alınacak. Bu işlemi onaylıyor musunuz?"
                          >
                            {({ onOpen }) => (
                              <MenuItem icon={<IoBackspace />} onClick={onOpen}>
                                <Text fontSize="xs">Geri Al</Text>
                              </MenuItem>
                            )}
                          </Confirm>
                        </MenuList>
                      </>
                    )}
                  </Menu>
                </>
              )}
              {role !== UserRoles.view &&
                [TimelineStatus.ready, TimelineStatus.late].includes(
                  item.statusCode
                ) && (
                  <Button
                    size="xs"
                    colorScheme="teal"
                    ml="2"
                    onClick={() => {
                      history.push("/do-control-task", { data: item });
                    }}
                  >
                    İşlem Yap
                  </Button>
                )}
            </Flex>
          </Flex>
        );
      })}
    </PerfectScrollbar>
  );
});

TimelineControl.defaultProps = {};

export default TimelineControl;
