import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import {
  Flex,
  Box,
  Text,
  SimpleGrid,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  useColorMode,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import { useForm } from "react-hook-form";
import {
  CheckBoxElement,
  InputElement,
  SelectElement,
} from "components/atoms/FormElements";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { UserType as User } from "services/swagger";
import Confirm from "components/atoms/Confirm";
import ReactSelect from "react-select";
import { UserRoles } from "models/user";
import { useToast } from "hooks/toast";

interface UserEditPageProps {}

const UserEditPage: FC<
  UserEditPageProps & RouteComponentProps<{}, {}, { data: any }>
> = (props) => {
  const { colorMode } = useColorMode();
  const [updateUser, statUser] = Hooks.UpdateUser();
  const { project, user, role } = useAuth();
  const { control, handleSubmit } = useForm();
  const [getRoles, statRoles, dataRoles] = Hooks.UserRoleList();
  const [getTypes, statTypes, dataTypes] = Hooks.UserTypeList();
  const [resetPass, statResetPass] = Hooks.ResetPassword();
  const [userTypes, setUserTypes] = useState<any[]>([
    ...props.location.state.data.userTypes,
  ]);

  const toast = useToast();

  useLayoutEffect(() => {
    getRoles();
    getTypes();
  }, []);

  useEffect(() => {
    if (statUser === "rejected") {
      toast({ status: "error", title: "Kullanıcı düzenlenirken hata oluştu!" });
    }
  }, [statUser]);

  useEffect(() => {
    if (statResetPass === "fulfilled") {
      toast({
        title: "Parola sıfırlandı!",
        description: "Kullanıcının yeni parolası '00000'",
        duration: 10000,
        status: "success",
      });
    }
  }, [statResetPass]);

  async function onValid(values: any) {
    const _data = (await updateUser({
      ...props.location.state.data,
      ...values,
      userTypes: userTypes.map((item) => {
        return item.id;
      }),
      userRoleID: parseInt(values?.userRoleID!),
    })) as unknown as any;
    if (_data.data.success) {
      toast({ status: "success", title: "Kullanıcı Düzenlendi!" });
      props.history.push("/user");
    } else {
      toast({
        status: "error",
        title: "Kullanıcı Düzenlenemedi!",
        description: _data.data.messaqge,
      });
    }
  }

  const _user = props.location.state.data;

  if (!_user) {
    return <Redirect to="/user" />;
  }

  return (
    <Main
      headerProps={{
        title: "Kullanıcı Düzenle",
      }}
    >
      <Card>
        <form onSubmit={handleSubmit(onValid)}>
          <SimpleGrid columns={2} gap="5">
            <InputElement
              name="name"
              label="Ad"
              control={control}
              defaultValue={_user.name}
            />
            <InputElement
              name="surname"
              label="Soyad"
              control={control}
              defaultValue={_user.surname}
            />
            <InputElement
              name="username"
              label="Kullanıcı Adı"
              control={control}
              defaultValue={_user.username}
              disabled={role !== UserRoles.admin}
            />
            <InputElement
              name="email"
              label="E Posta"
              control={control}
              defaultValue={_user.email}
            />
            <InputElement
              name="phone"
              label="Telefon"
              control={control}
              defaultValue={_user.phone}
            />
            {/* <SelectElement
              name="userTypeID"
              label="Kullanıcı Tipi"
              control={control}
              data={dataTypes?.data.data.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              defaultValue={}
            /> */}
            <FormControl isRequired>
              <FormLabel>Kullanıcı Tipi</FormLabel>
              <ReactSelect
                placeholder="Kullanıcı Tipi seçiniz"
                isMulti
                theme={colorMode==="dark" ? theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: 'rgba(66, 153, 225, 0.6)',
                    primary: 'white',
                    neutral0: 'rgba(0, 0, 0, 0)',
                    neutral5: 'rgba(0, 0, 0, 0.2)',
                    neutral10: 'black',
                    neutral20: '#333',
                    neutral80: 'white',
                    
                  },
                }) : undefined}
                onChange={(values) => {
                  setUserTypes(
                    values.map((item: any) => ({
                      id: item.value,
                      name: item.label,
                    }))
                  );
                }}
                defaultValue={props.location.state.data.userTypes.map(
                  (item: any) => ({ value: item.id, label: item.name })
                )}
                options={dataTypes?.data.data
                  .filter((item) => !!item.isActive)
                  .map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
              />
              <FormHelperText>Birden fazla seçim yapılabilir</FormHelperText>
            </FormControl>
            <SelectElement
              name="userRoleID"
              label="Kullanıcı Rolü"
              control={control}
              data={dataRoles?.data.data
                .filter((item) => (role === 1 ? true : item.id > role))
                .map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              defaultValue={_user.userRoleID}
            />
            <CheckBoxElement
              name="isNotificationEnabled"
              label="Bildirimlere İzin Ver"
              control={control}
              defaultValue={_user.isNotificationEnabled}
            />
            <CheckBoxElement
              name="isEmailEnabled"
              label="E-Posta Almaya İzin Ver"
              control={control}
              defaultValue={_user.isEmailEnabled}
            />
          </SimpleGrid>
          <Flex justifyContent="flex-end" mt="5">
            <Confirm
              onConfirm={() => {
                resetPass(_user.id, "00000");
              }}
              text="Bu kullanıcın şifresi sıfırlanacak..."
            >
              {({ isOpen, onOpen }) => (
                <Button
                  mr="3"
                  colorScheme="teal"
                  onClick={onOpen}
                  isLoading={statResetPass === "pending"}
                >
                  Şifre Sıfırla
                </Button>
              )}
            </Confirm>
            <Button
              type="submit"
              colorScheme="teal"
              isLoading={statUser === "pending"}
            >
              Düzenle
            </Button>
          </Flex>
        </form>
      </Card>
    </Main>
  );
};

UserEditPage.defaultProps = {};

export default UserEditPage;
