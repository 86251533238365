import React, { FC, useEffect, useLayoutEffect } from "react";
import { Box, Button, Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import Main from "components/templates/Main";
import { RouteComponentProps } from "react-router-dom";
import { Inventory } from "models";
import { Card } from "components/atoms";
import { Hooks } from "services";
import {
  CheckBoxElement,
  Form,
  InputElement,
  SelectElement,
} from "components/atoms/HookForm";
import { useAuth } from "hooks/redux-hooks";
import { useToast } from "hooks/toast";
import { isSieOrNo } from "helper/project-mode";

interface PageParams {
  id: string;
}

interface PageStates {
  inventory: Inventory;
}

interface PeriodControlsProps {}

type PageProps = RouteComponentProps<PageParams, {}, PageStates> &
  PeriodControlsProps;

const CreatePeriodControl: FC<PageProps> = (props) => {
  const [add, stat, data] = Hooks.CreateLegal();
  const { project } = useAuth();
  const toast = useToast();

  async function handleValid(values: any) {
    add({
      ...values,
      controlFormPhotoIdRequired: true,
      beforeDay: parseInt(values.beforeDay),
      inventoryID: props.location.state.inventory.id,
      projectID: project.id,
    });
  }

  useEffect(() => {
    if (stat === "fulfilled") {
      if (data?.data.success) {
        toast({
          title: `Periyodik ${isSieOrNo("Kontrol", "Muayene")} Başarıyla Oluşturuldu!`,
          status: "success",
        });
        props.history.push(`/periodic-controls/${props.match.params.id}`, {inventory: props.location.state.inventory});
      }
    }
  }, [stat]);

  return (
    <Main headerProps={{ title: `Periyodik ${isSieOrNo("Kontrol", "Muayene")}` }}>
      <Flex>
        <Heading size="md">
          Ekipman: {props.location.state.inventory.name}
        </Heading>
        <Heading size="md" ml="4">
          Barkod: {props.location.state.inventory.barcode}
        </Heading>
      </Flex>
      <Card mt="3">
        <Form onValid={handleValid}>
          <SimpleGrid columns={[1, 2]} spacing="5" mt="3">
            <InputElement
              label={`Periyodik ${isSieOrNo("Kontrol", "Muayene")} Tarihi`}
              type="date"
              name="startDate"
              isRequired
            />
            <InputElement
              label="Başlangıç Tarihinden Kaç Gün Önce Bakım Yapılabilir?"
              name="beforeDay"
              isRequired
            />
            <CheckBoxElement
              label="Kullanma talimatı zorunlu mu?"
              name="userInstructionsIsRequired"
            />
            <CheckBoxElement
              label="Kullanım klavuzu zorunlu mu?"
              name="userGudiePathIsRequired"
            />
            <CheckBoxElement
              label="ISG risk analizi zorunlu mu?"
              name="riskAnalysisIsRequired"
            />
            <CheckBoxElement
              label="Ekipman fotoğrafı zorunlu mu?"
              name="inventoryPhotoIdRequired"
            />
            <CheckBoxElement
              label="Bakım sözleşmesi zorunlu mu?"
              name="maintenanceContractIsRequired"
            />
            <InputElement
              label="Soru"
              defaultValue="Uygun Mu?"
              name="question"
              isRequired
            />
          </SimpleGrid>
          <Flex mt="5" justifyContent="flex-end">
            <Button
              colorScheme="teal"
              type="submit"
              isLoading={stat === "pending"}
            >
              Periyodik {isSieOrNo("Kontrol", "Muayene")} Oluştur
            </Button>
          </Flex>
        </Form>
      </Card>
    </Main>
  );
};

CreatePeriodControl.defaultProps = {};

export default CreatePeriodControl;
