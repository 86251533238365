import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Spinner,
} from "@chakra-ui/react";
import { Card } from "components/atoms";
import Badge from "components/atoms/Badge";
import Confirm from "components/atoms/Confirm";
import DTable from "components/atoms/DataTable";
import Modal, { ModalProps } from "components/atoms/Modal";
import { FormProps, InputField, MyForm } from "components/molecules";
import LocationUpdater from "components/organisms/LocationUpdater";
import Main from "components/templates/Main";
import { useAuth } from "hooks/redux-hooks";
import { useToast } from "hooks/toast";
import Moment from "moment";
import Freeze from "pages/Freeze";
import { useLayoutEffect, useRef } from "react";
import { IoChevronForward, IoOptions } from "react-icons/io5";
import { useHistory, useParams } from "react-router";
import { Hooks } from "services";
import { Campus as ICampus } from "services/swagger";

function Build() {
  const modalRef = useRef<ModalProps>(null);
  const formRef = useRef<FormProps<any>>(null);
  const { project } = useAuth();
  const toast = useToast();
  const history = useHistory();
  const params = useParams<{ campusId: string; buildId: string }>();

  const [create] = Hooks.FloorCreate();
  const [onEdit] = Hooks.EditFloor();
  const [getCList, cStatus, Cdata] = Hooks.CampusList();
  const [getBList, bStatus, Bdata] = Hooks.BuildList();
  const [getFloors, statusFloors, data] = Hooks.FloorList();

  async function handleClickToggleActive(row: any) {
    const resp = (await onEdit({
      ...row,
      isActive: !row.isActive,
    })) as unknown as any;
    if (!resp.data.success) {
      return toast({
        status: "error",
        title: "Hata oluştu!",
        description: resp.data.message,
      });
    }

    getFloors(parseInt(params.buildId));
    toast({ status: "success", title: "Kat Düzenlendi!", duration: 1000 });
  }

  async function handleUpdateTitle(row: any, title: string) {
    const resp = (await onEdit({
      ...row,
      name: title,
    })) as unknown as any;
    if (!resp.data.success) {
      return toast({
        status: "error",
        title: "Hata oluştu!",
        description: resp.data.message,
      });
    }
    getFloors(parseInt(params.buildId));
    toast({ status: "success", title: "Kat Düzenlendi!" });
  }

  useLayoutEffect(() => {
    if (!!!Cdata) {
      getCList(project.id);
    }
    if (!!!Bdata) {
      getBList(parseInt(params.campusId));
    }

    getFloors(parseInt(params.buildId));
  }, []);

  if (
    cStatus !== "fulfilled" ||
    bStatus !== "fulfilled" ||
    statusFloors !== "fulfilled"
  )
    return <Freeze />;

  const campus = Cdata?.data.data.find(
    (item) => item.id?.toString() === params.campusId
  );
  const build = Bdata?.data.data.find(
    (item) => item.id?.toString() === params.buildId
  );

  return (
    <Main headerProps={{ title: "Kat" }}>
      <Card p="3" mb="3">
        <Flex justifyContent="space-between" alignItems="center">
          <Flex>
            <Button
              variant="link"
              size="sm"
              colorScheme="teal"
              mr="3"
              onClick={() => {
                history.push("/campus");
              }}
            >
              {campus?.name}
            </Button>
            <IoChevronForward />
            <Button
              variant="link"
              size="sm"
              ml="3"
              colorScheme="teal"
              onClick={() => {
                history.push(`/campus/${params.campusId}/build`);
              }}
            >
              {build?.name}
            </Button>
          </Flex>
          <Button
            size="sm"
            colorScheme="blue"
            onClick={() => modalRef.current?.open()}
          >
            Yeni Kat Ekle
          </Button>
        </Flex>
      </Card>

      {!!data?.data.data ? (
        <Card>
          <DTable
            columns={[
              {
                name: "ID",
                selector: "id",
                sortable: true,
                width: "100px",
              },
              {
                name: "Kat Adı",
                selector: "name",
                sortable: true,
              },
              {
                name: "Oluşturulma Tarihi",
                cell: (row: ICampus) => (
                  <span>{Moment(row.createDate).format("DD.MM.YYYY")}</span>
                ),
              },
              {
                name: "Durum",
                cell: (row: ICampus) => <Badge isActive={!!row.isActive} />,
              },
              {
                name: "Aktif / Pasif",
                cell: (row: any) => (
                  <Box>
                    <Confirm
                      onConfirm={() => {
                        handleClickToggleActive(row);
                      }}
                      text="Bu kayıt pasif yapılacak. İşleme devam edilsin mi?"
                    >
                      {({ onOpen }) => (
                        <IconButton
                          size="sm"
                          onClick={onOpen}
                          aria-label="active"
                          icon={<IoOptions />}
                        />
                      )}
                    </Confirm>
                  </Box>
                ),
              },
              {
                name: "Düzenle",
                cell: (row: ICampus) => (
                  <LocationUpdater
                    title="Kat Düzenle"
                    defaultData={{ title: row.name! }}
                    onSubmit={(title) => handleUpdateTitle(row, title)}
                  />
                ),
              },
              {
                name: "Odalar",
                cell: (row: ICampus) => (
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={() => {
                      history.push(
                        `/campus/${params.campusId}/build/${params.buildId}/floor/${row.id}/room`
                      );
                    }}
                  >
                    Odalar
                  </Button>
                ),
              },
            ]}
            data={data?.data.data}
          />
        </Card>
      ) : (
        <Card>
          <Center>
            <Spinner />
          </Center>
        </Card>
      )}

      <Modal
        ref={modalRef}
        title="Kat Ekle"
        onConfirm={() => {
          formRef.current?.submitForm();
        }}
      >
        <MyForm
          innerRef={formRef}
          initialValues={{ name: "" }}
          onSubmit={async ({ name }: any) => {
            const data = (await create({
              name,
              isActive: true,
              buildID: build?.id,
            })) as unknown as any;
            if (data?.data?.success) {
              toast({ title: "Kat Oluşturuldu", status: "success" });
              formRef.current?.resetForm();
              modalRef.current?.close();
              getFloors(parseInt(params.buildId));
            }
          }}
        >
          <InputField name="name" label="Kat Adı" />
        </MyForm>
      </Modal>
    </Main>
  );
}

export default Build;
