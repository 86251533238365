import { Box, BoxProps, useColorModeValue } from "@chakra-ui/react";


export const Card = (props: BoxProps) => {
  return <Box
    borderRadius="sm"
    padding="5"
    background={useColorModeValue("gray.50", "gray.900")}
    borderColor={useColorModeValue("gray.100", "gray.700")}
    {...props}
  >
    {props.children}
  </Box>;
};

export default Card;
