import React, { useLayoutEffect, useRef } from "react";
import {
  Flex,
  Box,
  Text,
  Button,
  Spinner,
  Center,
  IconButton,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import Modal, { ModalProps } from "components/atoms/Modal";
import {
  MyForm,
  InputField,
  FormProps,
  SelectField,
} from "components/molecules";
import { useAuth } from "hooks/redux-hooks";
import { TextareaField } from "components/molecules/Form/InputField";
import { Hooks } from "services";
import { useToast } from "hooks/toast";
import DTable from "components/atoms/DataTable";
import { Campus as ICampus } from "services/swagger";
import Badge from "components/atoms/Badge";
import Moment from "moment";
import { useHistory, useParams } from "react-router";
import Freeze from "pages/Freeze";
import Confirm from "components/atoms/Confirm";
import { IoOptions } from "react-icons/io5";
import { UserRoles } from "models/user";
import LocationUpdater from "components/organisms/LocationUpdater";

function Build() {
  const modalRef = useRef<ModalProps>(null);
  const formRef = useRef<FormProps<any>>(null);
  const { project, role } = useAuth();
  const toast = useToast();
  const history = useHistory();
  const params = useParams<{ campusId: string }>();

  const [create] = Hooks.BuildCreate();
  const [onEdit] = Hooks.EditBuild();
  const [getList, statusList, data] = Hooks.CampusList();
  const [getBuilds, statusBuilds, dataBuilds] = Hooks.BuildList();

  async function handleClickToggleActive(row: any) {
    const resp = (await onEdit({
      ...row,
      isActive: !row.isActive,
    })) as unknown as any;
    if (!resp.data.success) {
      return toast({
        status: "error",
        title: "Hata oluştu!",
        description: resp.data.message,
      });
    }

    getBuilds(parseInt(params.campusId));
    toast({ status: "success", title: "Bina Düzenlendi!", duration: 500 });
  }

  async function handleUpdateTitle(row: any, title: string) {
    const resp = (await onEdit({
      ...row,
      name: title,
    })) as unknown as any;
    if (!resp.data.success) {
      return toast({
        status: "error",
        title: "Hata oluştu!",
        description: resp.data.message,
      });
    }
    getBuilds(parseInt(params.campusId));
    toast({ status: "success", title: "Bina Düzenlendi!" });
  }

  useLayoutEffect(() => {
    if (!!!data) {
      getList(project.id);
    }

    getBuilds(parseInt(params.campusId));
  }, []);

  if (statusList !== "fulfilled" || statusBuilds !== "fulfilled")
    return <Freeze />;

  const campus = data?.data.data.find(
    (item) => item.id?.toString() === params.campusId
  );

  return (
    <Main headerProps={{ title: "Bina" }}>
      <Card p="3" mb="3">
        <Flex justifyContent="space-between" alignItems="center">
          <Flex>
            <Button
              variant="link"
              size="sm"
              colorScheme="teal"
              onClick={() => {
                history.push("/campus");
              }}
            >
              {campus?.name}
            </Button>
          </Flex>
          {role !== UserRoles.view && (
            <Button
              size="sm"
              colorScheme="blue"
              onClick={() => modalRef.current?.open()}
            >
              Yeni Bina Ekle
            </Button>
          )}
        </Flex>
      </Card>

      {!!data?.data.data ? (
        <Card>
          <DTable
            columns={[
              {
                name: "ID",
                selector: "id",
                sortable: true,
                width: "100px",
              },
              {
                name: "Bina Adı",
                selector: "name",
                sortable: true,
              },
              {
                name: "Oluşturulma Tarihi",
                cell: (row: ICampus) => (
                  <span>{Moment(row.createDate).format("DD.MM.YYYY")}</span>
                ),
              },
              {
                name: "Durum",
                cell: (row: ICampus) => <Badge isActive={!!row.isActive} />,
              },
              {
                name: "Aktif / Pasif",
                cell: (row: any) => (
                  <Box>
                    <Confirm
                      onConfirm={() => {
                        handleClickToggleActive(row);
                      }}
                      text="Bu kayıt pasif yapılacak. İşleme devam edilsin mi?"
                    >
                      {({ onOpen }) => (
                        <IconButton
                          size="sm"
                          onClick={onOpen}
                          aria-label="active"
                          icon={<IoOptions />}
                        />
                      )}
                    </Confirm>
                  </Box>
                ),
              },
              {
                name: "Düzenle",
                cell: (row: ICampus) => (
                  <LocationUpdater
                    title="Bina Düzenle"
                    defaultData={{ title: row.name! }}
                    onSubmit={(title) => handleUpdateTitle(row, title)}
                  />
                ),
              },
              {
                name: "Katlar",
                cell: (row: ICampus) => (
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={() => {
                      history.push(
                        `/campus/${params.campusId}/build/${row.id}/floor`
                      );
                    }}
                  >
                    Katlar
                  </Button>
                ),
              },
            ]}
            data={dataBuilds?.data.data}
          />
        </Card>
      ) : (
        <Card>
          <Center>
            <Spinner />
          </Center>
        </Card>
      )}

      <Modal
        ref={modalRef}
        title="Bina Ekle"
        onConfirm={() => {
          formRef.current?.submitForm();
        }}
      >
        <MyForm
          innerRef={formRef}
          initialValues={{ name: "", address: "" }}
          onSubmit={async ({ name }: any) => {
            const data = (await create({
              name,
              isActive: true,
              campusID: campus?.id!,
            })) as unknown as any;
            if (data?.data?.success) {
              toast({ title: "Bina Oluşturuldu", status: "success" });
              formRef.current?.resetForm();
              modalRef.current?.close();
              getBuilds(campus!.id!);
            }
          }}
        >
          <SelectField
            disabled
            name="campus"
            label="Tesis"
            data={[{ label: campus?.name!, value: campus!.id!.toString() }]}
            containerProps={{ mb: "3" }}
          />
          <InputField name="name" label="Bina Adı" />
        </MyForm>
      </Modal>
    </Main>
  );
}

export default Build;
