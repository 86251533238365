import React, {
  FC,
  forwardRef,
  Fragment,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Flex,
  Text,
  Center,
  Spinner,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Textarea,
  Checkbox,
  Divider,
  SimpleGrid,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Badge,
  Tooltip,
  Collapse,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import { Card, Modal, ModalProps } from "components/atoms";
import { TimelineLegal as ITimelineLegal, User } from "models";
import PerfectScrollbar from "components/atoms/PerfectScrollbar";
import { CheckBoxElement, Form, InputElement } from "components/atoms/HookForm";
import Moment from "moment";
import { useHistory } from "react-router-dom";
import Dropzone from "components/atoms/Dropzone";
import {
  IoTrash,
  IoFilter,
  IoInformation,
  IoArrowDown,
  IoSearch,
  IoAlbumsOutline,
  IoCheckmark,
  IoRemove,
  IoBackspace,
  IoEllipsisHorizontal,
  IoFileTray,
} from "react-icons/io5";
import { colors } from "utils/maintenanceColors";
import { useToast } from "hooks/toast";
import { UserRoles } from "models/user";
import { IoIosClock } from "react-icons/io";
import { isSieOrNo } from "helper/project-mode";
import Confirm from "components/atoms/Confirm";

export enum TimelineStatus {
  completed = 2,
  notTime = 1,
  late = 4,
  ready = 3,
  needDocument = 5,
  lateDone = 6,
}

interface TimelineLegalProps {
  renderChangeDropdown?: () => ReactElement;
  hidden: boolean;
  hiddenFuture: boolean;
  selectedCampus: number;
  onChangeCount: (count: number) => void;
}

const TimelineLegal: FC<TimelineLegalProps> = (props) => {
  const { project, user } = useAuth();
  const _ref = useRef<any>(null);
  const [getDesc, statDesc, dataDesc] = Hooks.LegalDesc();
  const [getTypes, statType, dataTypes] = Hooks.UserTypeList();
  const [getTimeline, statTime, dataTime] = Hooks.TimelineLegal();
  const filterModalRef = useRef<ModalProps>(null);
  const [query, setQuery] = useState<string>("");
  const [isSearch, setSearch] = useState(false);
  const [iStat, setIStat] = useState<boolean | null>(null);

  const [filter, setFilter] = useState({
    status: [] as string[],
    period: [] as number[],
    types: [] as number[],
    userTypes: [] as number[],
  });

  const [currentFilter, setCurrentFilter] = useState({
    status: [] as string[],
    period: [] as number[],
    types: [] as number[],
    userTypes: [] as number[],
  });

  useLayoutEffect(() => {
    getTimeline(project.id);
    getDesc(project.id);
    getTypes(project.id);
  }, []);

  function handleFilter() {
    setCurrentFilter(filter);
    filterModalRef.current?.close();
  }

  function clearFilter() {
    setCurrentFilter({
      status: [] as string[],
      period: [] as number[],
      types: [] as number[],
      userTypes: [] as number[],
    });
    setFilter({
      status: [] as string[],
      period: [] as number[],
      types: [] as number[],
      userTypes: [] as number[],
    });
    filterModalRef.current?.close();
  }

  function onFilter(item: ITimelineLegal) {
    let filterArr = [];
    if (currentFilter.status.length !== 0) {
      filterArr.push(currentFilter.status.includes(item.statusColor));
    }

    if (currentFilter.userTypes.length !== 0) {
      filterArr.push(
        currentFilter.userTypes.includes((item as any).userTypeId)
      );
    }

    if (iStat !== null) {
      filterArr.push(item.isCompleted === iStat);
    }

    if (props.hidden) {
      if (
        [TimelineStatus.completed, TimelineStatus.lateDone].includes(
          item.status
        )
      ) {
        filterArr.push(false);
      }
    }
    if (props.hiddenFuture) {
      if ([TimelineStatus.notTime].includes(item.status)) {
        filterArr.push(false);
      }
    }

    if (props.selectedCampus !== 0) {
      filterArr.push(props.selectedCampus === (item as any).campusID);
    }

    return (
      !filterArr.includes(false) &&
      item.inventoryName.toLowerCase().includes(query.toLowerCase())
    );
  }

  useEffect(() => {
    if (dataTime?.data.data) {
      const count = dataTime?.data.data.filter(onFilter).length;
      props.onChangeCount(count);
    }
  }, [filter, props.hiddenFuture, props.hidden, dataTime, currentFilter]);

  return (
    <Card w="100%" minH="calc(100vh - 250px)">
      <Flex mb="2" justifyContent="space-between">
        <Box>{props.renderChangeDropdown && props.renderChangeDropdown()}</Box>
        <Flex>
          <Tooltip label="Filtre" aria-label="A tooltip">
            <IconButton
              size="sm"
              mr="1"
              aria-label="Filtre"
              icon={<IoFilter />}
              onClick={() => {
                filterModalRef.current?.open();
              }}
            />
          </Tooltip>
          <Tooltip label="Geçmiş" aria-label="A tooltip">
            <IconButton
              size="sm"
              mr="2"
              icon={<IoArrowDown />}
              onClick={() => {
                _ref.current?.goUndone();
              }}
              aria-label="Geçmiş"
            />
          </Tooltip>
          <Tooltip label="Bugüne Git" aria-label="A tooltip">
            <IconButton
              size="sm"
              mr="2"
              icon={<IoIosClock />}
              onClick={() => {
                _ref.current?.goToday();
              }}
              aria-label="Bugün"
            />
          </Tooltip>
          <Tooltip label="Ara" aria-label="Search tooltip">
            <IconButton
              size="sm"
              mr="2"
              icon={<IoSearch />}
              onClick={() => {
                if (isSearch) {
                  setQuery("");
                  setSearch(false);
                } else {
                  setSearch(true);
                }
              }}
              aria-label="Ara"
            />
          </Tooltip>
          <Popover placement="bottom-end">
            <PopoverTrigger>
              <Box>
                <Tooltip label="Renk Açıklamaları" aria-label="Colors tooltip">
                  <IconButton
                    size="sm"
                    aria-label="colors"
                    icon={<IoInformation />}
                  />
                </Tooltip>
              </Box>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>
                Periyodik {isSieOrNo("Kontrol", "Muayene")} Renk Açıklamaları
              </PopoverHeader>
              <PopoverBody>
                {dataDesc?.data?.data.map((color: any, index: number) => (
                  <Flex alignItems="center" mb="1">
                    <Box
                      w="20px"
                      h="20px"
                      mr="2"
                      bgColor={color.statusColor}
                      rounded="sm"
                    ></Box>
                    <Text>{color.statusDesc}</Text>
                  </Flex>
                ))}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
      </Flex>
      <Box>
        <Collapse in={isSearch} animateOpacity>
          <Box>
            <Input
              size="sm"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              disabled={!isSearch}
              placeholder="Ekipman Adına Göre Ara..."
            />
          </Box>
        </Collapse>
      </Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontWeight="semibold" mb="1" mt="2">
          Periyodik {isSieOrNo("Kontrol", "Muayene")} İşlemleri
        </Text>
        <Flex>
          <Tooltip label="Gerçekleşen">
            <IconButton
              size="xs"
              colorScheme="green"
              variant={iStat === true ? "solid" : "outline"}
              aria-label="complete"
              onClick={() => {
                setIStat(true);
              }}
              icon={<IoCheckmark />}
            />
          </Tooltip>
          <Tooltip label="Hepsi">
            <IconButton
              mx="1"
              size="xs"
              aria-label="complete"
              variant={iStat === null ? "solid" : "outline"}
              onClick={() => {
                setIStat(null);
              }}
              icon={<IoAlbumsOutline />}
            />
          </Tooltip>
          <Tooltip label="Gerçekleşmeyen">
            <IconButton
              size="xs"
              aria-label="complete"
              variant={iStat === false ? "solid" : "outline"}
              onClick={() => {
                setIStat(false);
              }}
              colorScheme="red"
              icon={<IoRemove />}
            />
          </Tooltip>
        </Flex>
      </Flex>
      {statTime !== "fulfilled" && (
        <Center minH="400px">
          <Spinner />
        </Center>
      )}
      {statTime === "fulfilled" && dataTime?.data.data && (
        <TimelineItems
          ref={_ref}
          data={dataTime?.data?.data.filter(onFilter)}
          onNeedReload={() => {
            getTimeline(project.id);
          }}
        />
      )}
      <Modal
        ref={filterModalRef}
        noForm
        title="Filtre"
        onClose={() => {}}
        onOpen={() => {
          setFilter(currentFilter);
        }}
      >
        <Box>
          <Heading mt="-3" fontSize="md">
            Periyodik {isSieOrNo("Kontrol", "Muayene")} Durumu
          </Heading>
          <Divider my="1" />
          <SimpleGrid columns={[2, 3]} gap="1">
            {dataDesc?.data?.data.map((_color: any) => (
              <Flex alignItems="center" borderWidth="1px" px="3" py="1">
                <Checkbox
                  borderColor={_color.statusColor}
                  defaultChecked={filter.status.includes(_color.statusColor)}
                  onChange={(e) => {
                    if (e.currentTarget.checked) {
                      if (!filter.status.includes(_color.statusColor)) {
                        setFilter((_state) => ({
                          ..._state,
                          status: [..._state.status, _color.statusColor],
                        }));
                      }
                    } else {
                      setFilter((_state) => ({
                        ..._state,
                        status: _state.status.filter(
                          (_statu) => _statu !== _color.statusColor
                        ),
                      }));
                    }
                  }}
                >
                  <Text fontSize="xs" lineHeight="4">
                    {_color.statusDesc}
                  </Text>
                </Checkbox>
              </Flex>
            ))}
          </SimpleGrid>
          <Heading mt="3" fontSize="md">
            Kullanıcı Tipi
          </Heading>
          <Divider my="1" />
          <SimpleGrid columns={[2, 3]} gap="1">
            {dataTypes?.data.data
              .filter((_period: any) =>
                user.userTypes.map((_: any) => _.id).includes(_period.id)
              )
              .map((_period: any) => (
                <Flex alignItems="center" borderWidth="1px" px="3" py="2">
                  <Checkbox
                    defaultChecked={filter.userTypes.includes(_period.id)}
                    onChange={(e) => {
                      if (e.currentTarget.checked) {
                        if (!filter.userTypes.includes(_period.id)) {
                          setFilter((_state) => ({
                            ..._state,
                            userTypes: [..._state.userTypes, _period.id],
                          }));
                        }
                      } else {
                        setFilter((_state) => ({
                          ..._state,
                          userTypes: _state.userTypes.filter(
                            (_periodId) => _periodId !== _period.id
                          ),
                        }));
                      }
                    }}
                  >
                    <Text fontSize="xs" lineHeight="4">
                      {_period.name}
                    </Text>
                  </Checkbox>
                </Flex>
              ))}
          </SimpleGrid>
          <Flex justifyContent="flex-end" mt="10">
            <Button
              leftIcon={<IoTrash />}
              onClick={clearFilter}
              mr="2"
              colorScheme="red"
              variant="outline"
              size="sm"
            >
              Temizle
            </Button>
            <Button
              leftIcon={<IoFilter />}
              colorScheme="blue"
              minW="200px"
              onClick={handleFilter}
              size="sm"
            >
              Filtre
            </Button>
          </Flex>
        </Box>
      </Modal>
    </Card>
  );
};

interface TimelineItemsProps {
  data: ITimelineLegal[];
  onNeedReload: () => void;
}
const TimelineItems = forwardRef<any, any>((props, ref) => {
  const scrollBarRef = useRef<any>(null);
  const { user } = useAuth();

  useImperativeHandle(ref, () => ({
    goToday: () => {
      getTodayTopPosition();
    },
    goUndone: () => {
      goUndone();
    },
  }));

  function getTodayIndex() {
    if (!!props.data) {
      let index = 0;
      let closeIndex = 0;
      let close = 0;
      while (index < props.data.length) {
        const item = props.data[index];
        const day = Moment(item.endDate).diff(Moment(), "days") + 0;
        if (index === 0) {
          closeIndex = index;
          close = day;
        } else {
          if (0 - day < close) {
            closeIndex = index;
            close = day;
          }
        }
        index++;
      }
      return closeIndex;
    }
  }

  function getIndex(data: any[]) {
    let undoneIndex = 0;
    if (!!data) {
      data.forEach((item: any, currIndex: number) => {
        if (
          [
            TimelineStatus.late,
            TimelineStatus.ready,
            TimelineStatus.needDocument,
          ].includes(item.status)
        ) {
          var undoneItem: any = data[undoneIndex];
          if (undoneItem.isCompleted) {
            undoneIndex = currIndex;
          } else if (
            Moment(undoneItem.endDate).diff(item.endDate, "seconds") > 0
          ) {
            undoneIndex = currIndex;
          }
        }
      });
    }
    return undoneIndex;
  }

  function goUndone() {
    let unDoneIndex = getIndex(props.data);
    if (!!unDoneIndex || unDoneIndex === 0) {
      let el = document.getElementById(`item-${unDoneIndex}`);
      scrollBarRef.current?._container.scrollTo({
        top: el?.offsetTop,
        behavior: "smooth",
      });
    } else {
    }
  }

  function getTodayTopPosition() {
    let today = getTodayIndex();
    if (today || today === 0) {
      let el = document.getElementById(`item-${today}`);
      scrollBarRef.current?._container.scrollTo({
        top: el?.offsetTop,
        behavior: "smooth",
      });
    } else {
    }
  }

  useEffect(() => {
    if (!!props.data && props.data.length > 0) {
      goUndone();
    }
  }, [props.data]);

  if (props.data.length === 0) {
    return (
      <Center minH="300px">
        <Text>
          Periyodik {isSieOrNo("Kontrol", "Muayene")} İşlemi Bulunamadı
        </Text>
      </Center>
    );
  }

  return (
    <PerfectScrollbar maxHeight={"calc(100vh - 250px)"} innerRef={scrollBarRef}>
      <TimelineItemTitle />

      {props.data.map((item: any, index: number) => (
        <TimelineItem
          id={`item-${index}`}
          key={index}
          data={item}
          onNeedReload={() => {
            props.onNeedReload();
          }}
        />
      ))}
    </PerfectScrollbar>
  );
});

const TimelineItemTitle = (props: {}) => {
  return (
    <Flex
      mb="2"
      border="1px"
      borderColor={useColorModeValue("gray.100", "gray.700")}
      p="2"
      justifyContent="space-between"
      alignItems="center"
      position="sticky"
      top="0px"
      backgroundColor={useColorModeValue("gray.100", "gray.700")}
      zIndex="2"
    >
      <Flex
        flexDir="column"
        alignItems="center"
        w="100px"
        pr="0"
        borderRight="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        mr="3"
      >
        <Text lineHeight="1" fontSize="xs">
          Tarih
        </Text>
      </Flex>
      <Flex alignItems="center" flex="1">
        <Box flex="1" flexBasis="25px">
          <Text lineHeight="1" fontSize="x-small">
            ID
          </Text>
        </Box>
        <Box ml="2" flex="1">
          <Text lineHeight="1" fontSize="x-small">
            Ekipman
          </Text>
        </Box>
        <Box ml="2" flex="1">
          <Text lineHeight="1" fontSize="x-small">
            Tesis
          </Text>
        </Box>
        <Box ml="2" flex="1">
          <Text lineHeight="1" fontSize="x-small">
            Barkod
          </Text>
        </Box>
        <Box ml="2" flex="1">
          <Text lineHeight="1" fontSize="x-small">
            Periyodik {isSieOrNo("Kontrol", "Muayene")} Tarihi
          </Text>
        </Box>
        <Box ml="2" flex="1">
          <Text lineHeight="1" fontSize="x-small">
            Kullanıcı Tipi
          </Text>
        </Box>
      </Flex>
      <Flex minW="290px" justifyContent="flex-end" alignItems="center"></Flex>
    </Flex>
  );
};

const TimelineItem = (props: {
  data: ITimelineLegal;
  onNeedReload: () => void;
  id: string;
}) => {
  const history = useHistory();
  const modalRef = useRef<ModalProps>(null);
  const docsModalRef = useRef<ModalProps>(null);
  const detailRef = useRef<ModalProps>(null);
  const dateRef = useRef<ModalProps>(null);
  const { user, role } = useAuth();
  const [datas, setDatas] = useState({ firm: "", note: "" });

  const [files, setFiles] = useState<File[]>([]);
  const [date, setDate] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [update, status, dataUpdate] = Hooks.DoLegalMaintenance();
  const [updateDate, statusDate] = Hooks.UpdateLegalDate();
  const [cancelLegal] = Hooks.CancelLegalInspection();
  const toast = useToast();

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  function getBase64Photos(arr: any[]) {
    return new Promise(async (resolve, reject) => {
      let arrData: any[] = [];
      for (var index = 0; index < arr.length; index++) {
        let base64str = await toBase64(arr[index]);
        let ext = arr[index].name.split(".").pop();
        arrData.push({ base64: base64str, extension: ext });
      }

      return resolve(arrData);
    });
  }

  function handleOnDrop(dropFiles: File[]) {
    setFiles(dropFiles);
  }

  async function handleConfirm() {
    setLoading(true);
    let base64ListForms = await getBase64Photos(files || []);

    await update({
      id: props.data.id,
      completedUserID: (user as User).id,
      completedPersonelName: (user as User).name,
      explain: datas.note,
      maintenanceFirm: datas.firm,
      secondDate: date,
      answer: true,
      documents: [
        ...(base64ListForms as any[]).map((base64Str) => ({
          document: base64Str.base64,
          extension: base64Str.extension,
          type: "formPhoto",
        })),
      ],
    });
  }

  useEffect(() => {
    if (dataUpdate && dataUpdate.data.success) {
      modalRef.current?.close();
      toast({
        title: `Periyodik ${isSieOrNo("Kontrol", "Muayene")} İşlemi Başarılı!`,
        status: "success",
      });
      props.onNeedReload();
    }
  }, [dataUpdate]);

  useEffect(() => {
    if (statusDate === "fulfilled") {
      modalRef.current?.close();
      toast({ title: "Tarih Değiştirildi!", status: "success" });
      props.onNeedReload();
    }
  }, [statusDate]);

  async function onCancel() {
    await cancelLegal(props.data.id);
    props.onNeedReload();
  }

  return (
    <Flex
      mb="2"
      border="1px"
      borderColor={useColorModeValue("gray.100", "gray.700")}
      p="2"
      justifyContent="space-between"
      alignItems="center"
      id={`item-${props.id}`}
    >
      <Flex
        flexDir="column"
        alignItems="center"
        w="100px"
        pr="0"
        borderRight="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        mr="3"
      >
        <Text lineHeight="1" fontSize="xs">
          {Moment(props.data.endDate).format("DD")}
        </Text>
        <Text fontSize="xs">{Moment(props.data.endDate).format("MMMM")}</Text>
      </Flex>
      <Flex alignItems="center" flex="1">
        <Box flex="1" flexBasis="25px">
          <Text lineHeight="1" fontSize="xs" fontWeight="bold">
            {props.data.id}
          </Text>
        </Box>
        <Box ml="2" flex="1">
          <Text lineHeight="1" fontSize="xs" fontWeight="bold">
            {props.data.inventoryName}
          </Text>
        </Box>
        <Box ml="2" flex="1">
          <Text lineHeight="1" fontSize="xs" fontWeight="bold">
            {props.data.campusName}
          </Text>
        </Box>
        {/* <Box ml="2" flex="1">
          <Text lineHeight="1" fontSize="x-small">
            Bina
          </Text>
          <Text lineHeight="1" fontSize="xs" fontWeight="bold">
            {props.data.buildName}
          </Text>
        </Box>
        <Box ml="2" flex="1">
          <Text lineHeight="1" fontSize="x-small">
            Kat
          </Text>
          <Text lineHeight="1" fontSize="xs" fontWeight="bold">
            {props.data.floorName}
          </Text>
        </Box>
        <Box ml="2" flex="1">
          <Text lineHeight="1" fontSize="x-small">
            Oda
          </Text>
          <Text lineHeight="1" fontSize="xs" fontWeight="bold">
            {props.data.roomName}
          </Text>
        </Box> */}
        <Box ml="2" flex="1">
          <Text lineHeight="1" fontSize="xs" fontWeight="bold">
            {props.data.barcode}
          </Text>
        </Box>
        <Box ml="2" flex="1">
          <Text lineHeight="1" fontSize="xs" fontWeight="bold">
            {Moment(props.data.endDate).format("DD.MM.YYYY")}
          </Text>
        </Box>
        <Box ml="2" flex="1">
          <Text lineHeight="1" fontSize="xs" fontWeight="bold">
            {props.data.userTypeName}
          </Text>
        </Box>
      </Flex>
      <Flex minW="290px" justifyContent="flex-end" alignItems="center">
        <Button
          size="xs"
          colorScheme="blue"
          ml="2"
          onClick={() => {
            detailRef.current?.open();
          }}
        >
          Detay
        </Button>
        {[UserRoles.admin, UserRoles.manager].includes(role) &&
          ![TimelineStatus.completed, TimelineStatus.lateDone].includes(
            props.data.status
          ) && (
            <Button
              ml="2"
              onClick={() => {
                dateRef.current?.open();
              }}
              colorScheme="pink"
              size="xs"
            >
              Tarih Güncelle
            </Button>
          )}
        {role !== UserRoles.view &&
          [TimelineStatus.late, TimelineStatus.ready].includes(
            props.data.status
          ) && (
            <Flex flexDir="column">
              <Button
                onClick={() => {
                  history.push(
                    `/maintenance/do-period-maintenance/${props.data.id}`,
                    {
                      timelineItem: props.data,
                    }
                  );
                }}
                colorScheme="teal"
                size="xs"
                ml="2"
              >
                İşlem Yap
              </Button>
            </Flex>
          )}
        {role !== UserRoles.view &&
          [TimelineStatus.needDocument].includes(props.data.status) && (
            <Flex flexDir="column" ml="2">
              <Button
                colorScheme="orange"
                size="xs"
                onClick={() => {
                  modalRef.current?.open();
                }}
              >
                Form Yükle
              </Button>
              <Text fontSize="xs">
                Form Yüklenmesi
                <br />
                Gereken Tarih
              </Text>
              <Text fontSize="xs">
                {Moment(props.data.secondDate).format("DD.MM.YYYY")}
              </Text>
            </Flex>
          )}
        {[TimelineStatus.completed, TimelineStatus.lateDone].includes(
          props.data.status
        ) && (
          <>
            <Badge colorScheme="green" ml="2" py="1" size="sm">
              Gerçekleşti
            </Badge>
            <Menu placement="bottom-end">
              {({ isOpen }) => (
                <>
                  <MenuButton
                    ml="2"
                    isActive={isOpen}
                    as={IconButton}
                    size="sm"
                    icon={<IoEllipsisHorizontal />}
                  />
                  <MenuList>
                    <MenuItem
                      icon={<IoFileTray />}
                      onClick={() => {
                        docsModalRef.current?.open();
                      }}
                    >
                      <Text fontSize="xs">Dokümanları Gör</Text>
                    </MenuItem>
                    <Confirm
                      onConfirm={() => {
                        onCancel();
                      }}
                      colorScheme="orange"
                      confirmText="Geri Almayı Onayla"
                      text="Gerçekleşen bakım geri alınacak. Bu işlemi onaylıyor musunuz?"
                    >
                      {({ onOpen }) => (
                        <MenuItem icon={<IoBackspace />} onClick={onOpen}>
                          <Text fontSize="xs">Geri Al</Text>
                        </MenuItem>
                      )}
                    </Confirm>
                  </MenuList>
                </>
              )}
            </Menu>
          </>
        )}
      </Flex>

      <Modal
        ref={docsModalRef}
        title={`Periyodik ${isSieOrNo("Kontrol", "Muayene")} Dokümanları`}
      >
        <Box mb="4">
          <Heading color="gray.700" size="md" mb="2">
            {props.data.inventoryName} Dokümanları
          </Heading>
          <Divider />
          {props.data?.documents?.map((document, index) => (
            <Box
              mt="2"
              borderBottom="1px"
              paddingBottom="2"
              borderColor="gray.200"
            >
              <Text fontWeight="semibold" color="gray.700">
                Doküman {index + 1}
              </Text>
              <Button
                size="sm"
                mt="0.5"
                onClick={() => {
                  window.open(document.path, "_blank");
                }}
              >
                Dosyayı Gör
              </Button>
              <Flex fontSize="xs" mt="2">
                <Text>Yüklenme Tarihi: </Text>
                <Text>
                  {Moment(document.createDate).format("DD.MM.YYYY HH:mm")}
                </Text>
              </Flex>
            </Box>
          ))}
        </Box>
      </Modal>

      <Modal noForm ref={dateRef} title={props.data?.inventoryName}>
        <Heading size="md" mb="4">
          Son Tarihi Güncelle
        </Heading>
        <Form
          onValid={(values: any) => {
            updateDate({
              endDate: values.endDate,
              legalInspectionID: props.data.id,
            });
          }}
        >
          <InputElement
            label={`Periyodik ${isSieOrNo("Kontrol", "Muayene")} Tarihi`}
            type="date"
            name="endDate"
            defaultValue={Moment(props.data?.endDate).format("YYYY-MM-DD")}
            isRequired
          />
          <Button mb="10" mt="5" type="submit">
            Güncelle
          </Button>
        </Form>
      </Modal>

      <Modal ref={detailRef} noForm title={props.data?.inventoryName}>
        <Box>
          <Heading fontSize="md">Ekipman</Heading>
          <Divider my="2" />
          <Flex justifyContent="space-between" fontSize="xs" mb="2">
            <Box>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  #ID:
                </Text>{" "}
                {props.data?.inventoryID}
              </Text>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Ekipman Adı:
                </Text>{" "}
                {props.data.inventoryName}
              </Text>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Barkod:
                </Text>{" "}
                {props.data.barcode}
              </Text>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Asset No:
                </Text>{" "}
                {props.data?.assetNo}
              </Text>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Birim:
                </Text>{" "}
                {props.data?.unit}
              </Text>
            </Box>
            <Box>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Tesis:
                </Text>{" "}
                {props.data?.campusName}
              </Text>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Bina:
                </Text>{" "}
                {props.data?.buildName}
              </Text>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Kat:
                </Text>{" "}
                {props.data?.floorName}
              </Text>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Oda:
                </Text>{" "}
                {props.data?.roomName}
              </Text>
            </Box>
            <Box>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Grup:
                </Text>{" "}
                {props.data?.groupName}
              </Text>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Marka:
                </Text>{" "}
                {props.data?.brandName}
              </Text>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Model:
                </Text>{" "}
                {props.data?.modelName}
              </Text>
            </Box>
          </Flex>

          <Heading fontSize="md" mt="5">
            Bakım
          </Heading>
          <Divider my="2" />
          <Flex justifyContent="space-between" fontSize="xs" mb="5">
            <Box>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Bakım Başlangıç Tarihi:
                </Text>{" "}
                {Moment(props.data?.startDate).format("DD.MM.YYYY")}
              </Text>
              <Text>
                <Text d="inline" fontWeight="semibold">
                  Planlı Bakım Tarihi:
                </Text>{" "}
                {Moment(props.data?.endDate).format("DD.MM.YYYY")}
              </Text>
            </Box>
          </Flex>
          {/* <Flex justifyContent="flex-end" mt="5" mb="3">
            <Button colorScheme="teal" size="sm">
              Bakım Yap
            </Button>
          </Flex> */}
        </Box>
      </Modal>
      <Modal
        ref={modalRef}
        title={`Periyodik ${isSieOrNo(
          "Kontrol",
          "Muayene"
        )} İkinci Formu Yükle`}
        onConfirm={handleConfirm}
        onConfirmText="Kaydet"
        isConfirmSpinner={status === "pending"}
      >
        <Box mb="4">
          <Heading size="sm" mb="3">
            {props.data.inventoryName} Periyodik{" "}
            {isSieOrNo("Kontrolü", "Muayenesi")}
          </Heading>
          <FormControl mt="2">
            <FormLabel>
              Periyodik {isSieOrNo("Kontrol", "Muayene")} İkinci Formu
            </FormLabel>
            <Dropzone hasAllType onDrop={handleOnDrop} />
          </FormControl>
          <FormControl mt="4">
            <FormLabel>
              Periyodik {isSieOrNo("Kontrol", "Muayene")} İkinci Form Notu
            </FormLabel>
            <Textarea
              resize="none"
              value={datas.note}
              onChange={(e) =>
                setDatas((_datas) => ({
                  ..._datas,
                  note: e.target.value,
                }))
              }
            ></Textarea>
          </FormControl>
          <FormControl mt="2">
            <FormLabel>
              Sonraki Periyodik {isSieOrNo("Kontrol", "Muayene")} Tarihi
            </FormLabel>
            <Input
              type="date"
              value={date}
              min={Moment().add(1, "d").format("YYYY-MM-DD")}
              onChange={(e) => {
                setDate(e.currentTarget?.value);
              }}
            />
          </FormControl>
        </Box>
      </Modal>
    </Flex>
  );

  return (
    <Flex
      borderWidth="1px"
      borderColor={useColorModeValue("gray.100", "gray.700")}
      p="2"
      px="3"
      mb="1"
      justifyContent="space-between"
      id={props.id}
      alignItems="flex-start"
    >
      <Box>
        <Flex alignItems="center">
          <Box
            rounded="full"
            bgColor={props.data.statusColor}
            w="20px"
            h="20px"
            mr="2"
          ></Box>
          <Text fontSize="xs">{props.data.inventoryName}</Text>
        </Flex>
        <Text mt="2" fontSize="xs">
          ID: {props.data.id}
        </Text>
        <Text fontSize="xs">Ekipman: {props.data.inventoryName}</Text>
        <Text fontSize="xs">Tesis: {props.data.campusName}</Text>
        <Text fontSize="xs">Bina: {props.data.buildName}</Text>
        <Text fontSize="xs">Kat: {props.data.floorName}</Text>
        <Text fontSize="xs">Oda: {props.data.roomName}</Text>
        <Text fontSize="xs">Barkod: {props.data.barcode}</Text>
        <Text fontSize="xs">
          Periyodik {isSieOrNo("Kontrol", "Muayene")} Tarihi:{" "}
          {Moment(props.data.endDate).format("DD.MM.YYYY")}
        </Text>
        <Text fontSize="xs">
          Kullanıcı Tipi:{" "}
          <Text d="inline" color="blue.500">
            {props.data.userTypeName}
          </Text>
        </Text>
        <Text fontSize="x-small">Durum: {props.data.statusDesc}</Text>
      </Box>
      <Flex flexDir="column" alignItems="flex-end">
        <Button
          size="sm"
          colorScheme="blue"
          mb="2"
          onClick={() => {
            detailRef.current?.open();
          }}
        >
          Detay
        </Button>
        {[UserRoles.admin, UserRoles.manager].includes(role) &&
          ![TimelineStatus.completed, TimelineStatus.lateDone].includes(
            props.data.status
          ) && (
            <Button
              mb="2"
              onClick={() => {
                dateRef.current?.open();
              }}
              colorScheme="pink"
              size="sm"
            >
              Tarih Güncelle
            </Button>
          )}
        {role !== UserRoles.view &&
          [TimelineStatus.late, TimelineStatus.ready].includes(
            props.data.status
          ) && (
            <Flex flexDir="column">
              <Button
                onClick={() => {
                  history.push(
                    `/maintenance/do-period-maintenance/${props.data.id}`,
                    {
                      timelineItem: props.data,
                    }
                  );
                }}
                colorScheme="teal"
                size="sm"
              >
                İşlem Yap
              </Button>
            </Flex>
          )}
        {role !== UserRoles.view &&
          [TimelineStatus.needDocument].includes(props.data.status) && (
            <Flex flexDir="column">
              <Button
                colorScheme="orange"
                size="sm"
                onClick={() => {
                  modalRef.current?.open();
                }}
              >
                Form Yükle
              </Button>
              <Text fontSize="xs">
                Form Yüklenmesi
                <br />
                Gereken Tarih
              </Text>
              <Text fontSize="xs">
                {Moment(props.data.secondDate).format("DD.MM.YYYY")}
              </Text>
            </Flex>
          )}
        {[TimelineStatus.completed, TimelineStatus.lateDone].includes(
          props.data.status
        ) && (
          <Flex flexDir="column" alignItems="flex-end">
            <Badge colorScheme="green">Gerçekleştİ</Badge>
            <Button
              colorScheme="teal"
              size="sm"
              mt="2"
              onClick={() => {
                docsModalRef.current?.open();
              }}
            >
              Dokümanları Gör
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

TimelineLegal.defaultProps = {};

export default TimelineLegal;
