import React, { FC, useLayoutEffect } from "react";
import { Box, Divider, Heading, Spinner, Text } from "@chakra-ui/react";
import { useAuth } from "hooks/redux-hooks";
import { Hooks } from "services";
import DailyNoteItem from "./DailyNoteItem";
import { Note } from "services/swagger";
import moment from "moment";

type NoteListProps = {};

const NoteList: FC<NoteListProps> = (props) => {
  const { project } = useAuth();

  const [getNotes, statusNotes, dataNotes] = Hooks.NoteList();
  useLayoutEffect(() => {
    if (project?.id) {
      getNotes(project.id);
    }
  }, [project]);
  return (
    <Box>
      {statusNotes !== "fulfilled" && <Spinner />}
      {statusNotes === "fulfilled" &&
        dataNotes &&
        dataNotes?.data.data.map((item: Note, index: number, array: Note[]) => (
          <>
            {(index === 0 || item.date !== array[index - 1].date) && (
              <>
                <Heading size="lg" mt="5">
                  {moment(item.date).format("DD MMMM YYYY dddd")} Notları
                </Heading>
              </>
            )}
            <DailyNoteItem index={index} item={item} />
          </>
        ))}
    </Box>
  );
};

export default NoteList;
