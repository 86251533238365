import {
  Badge,
  Box,
  Button,
  Center, Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Select,
  Spinner,
  Text,
  Textarea,
  useToast
} from "@chakra-ui/react";
import { Card } from "components/atoms";
import Confirm from "components/atoms/Confirm";
import DataTable from "components/atoms/DataTable";
import Modal, { ModalProps } from "components/atoms/Modal";
import SortableList from "components/atoms/SortableList";
import Main from "components/templates/Main";
import { useAuth } from "hooks/redux-hooks";
import { UserRoles } from "models/user";
import Moment from "moment";
import { FC, useLayoutEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { IoArrowForward, IoOptions } from "react-icons/io5";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Hooks } from "services";

interface MaintenanceListProps {}

type PageProps = RouteComponentProps<{ id: string }, {}, { inventory: any }>;

const MaintenanceList: FC<MaintenanceListProps & PageProps> = (props) => {
  const [getM, statusM, dataM] = Hooks.ListMaintenance();
  const [addQuest, statusAQ, dataAQ] = Hooks.AddQuestion();
  const [onEdit] = Hooks.MaintenanceEdit();
  const [getQuestionTypes] = Hooks.ListQuestionTypes();
  const addModalRef = useRef<ModalProps>(null);
  const listModalRef = useRef<ModalProps>(null);
  const [selectedMain, setSelectedMain] = useState<any | null>(null);
  const { register, handleSubmit, watch, reset } = useForm();
  const history = useHistory();
  const toast = useToast();
  const { role } = useAuth();

  function onValidAddQuestion(values: any) {
    addQuest({
      ...values,
      trueFalseAnswer: values.positive === "1",
      maintenanceQuestionTypeID: parseInt(values.maintenanceQuestionTypeID),
      inventoryMaintenanceTypeID: selectedMain?.id,
      orders: 10,
    });
    toast({ status: "success", title: "Soru başarıyla eklendi!" });
    reset();
    addModalRef.current?.close();
  }

  async function handleClickToggleActive(row: any) {
    const resp = (await onEdit({
      ...row,
      isActive: !row.isActive,
    })) as unknown as any;
    if (!resp.data.success) {
      return toast({
        status: "error",
        title: "Hata oluştu!",
        description: resp.data.message,
      });
    }

    getM(parseInt(props.match.params.id));
    toast({
      status: "success",
      title: "Bakım Düzenlendi!",
      duration: 500,
    });
  }

  useLayoutEffect(() => {
    getM(parseInt(props.match.params.id));
    getQuestionTypes();
  }, []);

  if(!props?.location?.state?.inventory) {
    return (
      <div>No Inventory</div>
    )
  }

  return (
    <Main headerProps={{ title: "Bakımlar" }}>
      <Flex>
        <Heading size="md" mb="3">
          Ekipman Adı: {props.location.state?.inventory?.name}
        </Heading>
        <Heading size="md" mb="3" ml="3">
          Barkod: {props.location.state?.inventory?.barcode}
        </Heading>
      </Flex>
      <Card p="3">
        <Flex justifyContent="flex-end">
          {role !== UserRoles.basic && role !== UserRoles.view && (
            <Button
              colorScheme="blue"
              size="sm"
              onClick={() => {
                history.push(`/maintenance-create/${props.match.params.id}`, {
                  inventory: props.location.state.inventory,
                });
              }}
            >
              Yeni Bakım Ekle
            </Button>
          )}
        </Flex>
      </Card>
      {statusM !== "fulfilled" && (
        <Center mt="5">
          <Spinner size="lg" colorScheme="blue" />
        </Center>
      )}
      {statusM === "fulfilled" && !!dataM?.data.data && (
        <Card mt="3">
          <DataTable
            data={dataM.data.data}
            columns={[
              { name: "#ID", selector: "id" },
              { name: "Bakım Tipi", selector: "maintenanceTypeName" },
              { name: "Periyot", selector: "maintenancePeriodName" },
              {
                name: "Periyot Başlangıç Tarihi",
                cell: (row: any) => Moment(row.startDate).format("DD.MM.YYYY"),
                width: "150px",
                selector: "startDate",
                columnType: "date",
              },
              {
                name: "Bakım Başlangıç Günü",
                selector: "beforeDay",
              },
              {
                name: "Kontrol Formu",
                width: "150px",
                selector: "controlFormPhotoIdRequired",
                columnType: "boolean",
                cell: (row: any) => (
                  <Badge
                    colorScheme={
                      row.controlFormPhotoIdRequired ? "orange" : undefined
                    }
                  >
                    {row.controlFormPhotoIdRequired
                      ? "Zorunlu"
                      : "Zorunlu Değil"}
                  </Badge>
                ),
              },
              {
                name: "Ekipman Fotoğrafı",
                selector: "inventoryPhotoIdRequired",
                columnType: "boolean",
                width: "150px",
                cell: (row: any) => (
                  <Badge
                    colorScheme={
                      row.inventoryPhotoIdRequired ? "orange" : undefined
                    }
                  >
                    {row.inventoryPhotoIdRequired ? "Zorunlu" : "Zorunlu Değil"}
                  </Badge>
                ),
              },
              {
                name: "Bakım Sözleşmesi",
                selector: "maintenanceContractIsRequired",
                columnType: "boolean",
                width: "150px",
                cell: (row: any) => (
                  <Badge
                    colorScheme={
                      row.maintenanceContractIsRequired ? "orange" : undefined
                    }
                  >
                    {row.maintenanceContractIsRequired
                      ? "Zorunlu"
                      : "Zorunlu Değil"}
                  </Badge>
                ),
              },
              {
                name: "ISG Risk Analizi",
                width: "150px",
                selector: "riskAnalysisIsRequired",
                columnType: "boolean",
                cell: (row: any) => (
                  <Badge
                    colorScheme={
                      row.riskAnalysisIsRequired ? "orange" : undefined
                    }
                  >
                    {row.riskAnalysisIsRequired ? "Zorunlu" : "Zorunlu Değil"}
                  </Badge>
                ),
              },
              {
                name: "Kullanma Talimatı",
                selector: "userInstructionsIsRequired",
                columnType: "boolean",
                width: "150px",
                cell: (row: any) => (
                  <Badge
                    colorScheme={
                      row.userInstructionsIsRequired ? "orange" : undefined
                    }
                  >
                    {row.userInstructionsIsRequired
                      ? "Zorunlu"
                      : "Zorunlu Değil"}
                  </Badge>
                ),
              },
              {
                name: "Kullanım Klavuzu",
                selector: "userGudiePathIsRequired",
                columnType: "boolean",
                width: "150px",
                cell: (row: any) => (
                  <Badge
                    colorScheme={
                      row.userGudiePathIsRequired ? "orange" : undefined
                    }
                  >
                    {row.userGudiePathIsRequired ? "Zorunlu" : "Zorunlu Değil"}
                  </Badge>
                ),
              },
              {
                name: "Oluşturan Kullanıcı",
                width: "150px",
                selector: "createUserName",
              },
              {
                name: "Durum",
                width: "150px",
                selector: "isActive",
                columnType: "isActiveBoolean",
                cell: (row: any) => (
                  <Badge colorScheme={!!row.isActive ? "green" : undefined}>
                    {!!row.isActive ? "Aktif" : "Pasif"}
                  </Badge>
                ),
              },
              {
                name: "Aktif / Pasif",
                cell: (row: any) => (
                  <Box>
                    <Confirm
                      onConfirm={() => {
                        handleClickToggleActive(row);
                      }}
                      text="Bu kayıt pasif yapılacak. İşleme devam edilsin mi?"
                    >
                      {({ onOpen }) => (
                        <IconButton
                          disabled={role === UserRoles.basic}
                          size="sm"
                          onClick={onOpen}
                          aria-label="active"
                          icon={<IoOptions />}
                        />
                      )}
                    </Confirm>
                  </Box>
                ),
              },
              {
                name: "Düzenle",
                cell: (row: any) => (
                  <Flex>
                    <Button
                      colorScheme="blue"
                      size="sm"
                      onClick={() => {
                       
                        history.push("/maintenance-edit", {
                          maintenance: row,
                          inventory: props.location.state?.inventory,
                        });
                      }}
                    >
                      Düzenle
                    </Button>
                  </Flex>
                ),
              },
              {
                name: "Soru İşlemleri",
                width: "250px",
                cell: (row: any) =>
                  row.maintenanceTypeID === 1 ? (
                    <Flex>
                      <Button
                        size="sm"
                        disabled={role === UserRoles.basic}
                        onClick={() => {
                          setSelectedMain(row);
                          addModalRef.current?.open();
                        }}
                        colorScheme="teal"
                      >
                        Soru Ekle
                      </Button>
                      <Button
                        size="sm"
                        disabled={role === UserRoles.basic}
                        ml="2"
                        onClick={() => {
                          // setSelectedMain(row);
                          // listModalRef.current?.open();
                          history.push(`/maintenance-questions/${row.id}`, {
                            maintenance: row,
                            inventory: props.location.state?.inventory
                          });
                        }}
                        colorScheme="purple"
                      >
                        Soru Listesi
                      </Button>
                    </Flex>
                  ) : null,
              },
            ]}
          />
        </Card>
      )}

      <Modal ref={addModalRef} title="Soru Ekle">
        <form onSubmit={handleSubmit(onValidAddQuestion)}>
          <Flex flexDir="column">
            <Box>
              <FormControl>
                <Select
                  placeholder="Soru Tipi"
                  {...register("maintenanceQuestionTypeID")}
                >
                  <option value="1">DEĞER GİRME ALANI</option>
                  <option value="2">EVET HAYIR</option>
                </Select>
              </FormControl>
              <FormControl>
                <Textarea mt="1" placeholder="Soru" {...register("question")} />
              </FormControl>
              {watch("maintenanceQuestionTypeID") === "1" && (
                <>
                  <FormControl mt="2">
                    <Input
                      {...register("initialValue")}
                      placeholder="Başlangıç Değeri"
                    />
                  </FormControl>
                  <FormControl mt="2">
                    <Input
                      {...register("endValue")}
                      placeholder="Bitiş Değeri"
                    />
                  </FormControl>
                </>
              )}
              {watch("maintenanceQuestionTypeID") === "2" && (
                <FormControl mt="2">
                  <FormLabel>Olumlu Olan Yanıt</FormLabel>
                  <Select {...register("positive")}>
                    <option value="1">EVET</option>
                    <option value="2">HAYIR</option>
                  </Select>
                </FormControl>
              )}

              <Flex justifyContent="flex-end" mt="2">
                <Button size="sm" rightIcon={<IoArrowForward />} type="submit">
                  Soru Ekle
                </Button>
              </Flex>
            </Box>
          </Flex>
        </form>
      </Modal>

      <Modal ref={listModalRef} title="Soru Listesi">
        <QuestionList maintenanceID={selectedMain?.id} />
      </Modal>
    </Main>
  );
};

MaintenanceList.defaultProps = {};

export default MaintenanceList;

function QuestionList(props: any) {
  const [getQuestions, status, data] = Hooks.ListQuestion();

  useLayoutEffect(() => {
    getQuestions(props.maintenanceID);
  }, []);

  return (
    <Flex flexDir="column">
      {status !== "fulfilled" && (
        <Center>
          <Spinner />
        </Center>
      )}
      {status === "fulfilled" && (
        <SortableList
          data={data?.data.data}
          keyExtractor={(item) => Math.random().toString()}
          renderItem={(item) => (
            <Box
              borderWidth="thin"
              p="2"
              bgColor="white"
              borderRadius="md"
              mb="1"
              position="relative"
              paddingTop="7"
            >
              <Badge
                position="absolute"
                top="5px"
                size="sm"
                right="5px"
                colorScheme={
                  item.maintenanceQuestionTypeID === 1 ? "purple" : "teal"
                }
              >
                {item.maintenanceQuestionTypeID === 1 ? (
                  "Değer Girişli"
                ) : (
                  <Flex>
                    <Text
                      borderBottomWidth={
                        item.trueFalseAnswer ? "1px" : undefined
                      }
                      d="inline"
                    >
                      Evet
                    </Text>{" "}
                    /{" "}
                    <Text
                      borderBottomWidth={
                        !item.trueFalseAnswer ? "1px" : undefined
                      }
                    >
                      Hayır
                    </Text>
                  </Flex>
                )}
              </Badge>
              <Flex flexDir="column">
                {item.question}
                {item.maintenanceQuestionTypeID === 2 ? (
                  <Box></Box>
                ) : (
                  <Box>
                    Başlangıç Değeri: {item.initialValue} - Bitiş Değeri:{" "}
                    {item.endValue}
                  </Box>
                )}
              </Flex>
            </Box>
          )}
          onChange={(newData: any) => {
           
          }}
        />
      )}
    </Flex>
  );
}
