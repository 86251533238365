import React, { useLayoutEffect, useRef } from "react";
import {
  Flex,
  Box,
  Text,
  Button,
  Spinner,
  Center,
  IconButton,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import Modal, { ModalProps } from "components/atoms/Modal";
import {
  MyForm,
  InputField,
  FormProps,
  SelectField,
} from "components/molecules";
import { useAuth } from "hooks/redux-hooks";
import { TextareaField } from "components/molecules/Form/InputField";
import { Hooks } from "services";
import { useToast } from "hooks/toast";
import DTable from "components/atoms/DataTable";
import { Campus as ICampus } from "services/swagger";
import Badge from "components/atoms/Badge";
import Moment from "moment";
import { useHistory, useParams } from "react-router";
import Freeze from "pages/Freeze";
import { IoChevronForward, IoOptions } from "react-icons/io5";
import Confirm from "components/atoms/Confirm";
import LocationUpdater from "components/organisms/LocationUpdater";

function Build() {
  const modalRef = useRef<ModalProps>(null);
  const formRef = useRef<FormProps<any>>(null);
  const { project } = useAuth();
  const toast = useToast();
  const history = useHistory();
  const params = useParams<{
    campusId: string;
    buildId: string;
    floorId: string;
  }>();

  const [create] = Hooks.RoomCreate();
  const [onEdit] = Hooks.EditRoom();
  const [getCList, cStatus, Cdata] = Hooks.CampusList();
  const [getBList, bStatus, Bdata] = Hooks.BuildList();
  const [getFList, fStatus, Fdata] = Hooks.FloorList();
  const [getRooms, status, data] = Hooks.RoomList();

  async function handleClickToggleActive(row: any) {
    const resp = (await onEdit({
      ...row,
      isActive: !row.isActive,
    })) as unknown as any;
    if (!resp.data.success) {
      return toast({
        status: "error",
        title: "Hata oluştu!",
        description: resp.data.message,
      });
    }

    getRooms(parseInt(params.floorId));
    toast({ status: "success", title: "Oda Düzenlendi!", duration: 1000 });
  }

  async function handleUpdateTitle(row: any, title: string) {
    const resp = (await onEdit({
      ...row,
      name: title,
    })) as unknown as any;
    if (!resp.data.success) {
      return toast({
        status: "error",
        title: "Hata oluştu!",
        description: resp.data.message,
      });
    }
    getRooms(parseInt(params.floorId));
    toast({ status: "success", title: "Oda Düzenlendi!" });
  }

  useLayoutEffect(() => {
    if (!!!Cdata) {
      getCList(project.id);
    }
    if (!!!Bdata) {
      getBList(parseInt(params.campusId));
    }
    if (!!!Fdata) {
      getFList(parseInt(params.buildId));
    }

    getRooms(parseInt(params.floorId));
  }, []);

  if (
    cStatus !== "fulfilled" ||
    bStatus !== "fulfilled" ||
    fStatus !== "fulfilled" ||
    status !== "fulfilled"
  )
    return <Freeze />;

  const campus = Cdata?.data.data.find(
    (item) => item.id?.toString() === params.campusId
  );
  const build = Bdata?.data.data.find(
    (item) => item.id?.toString() === params.buildId
  );
  const floor = Fdata?.data.data.find(
    (item) => item.id?.toString() === params.floorId
  );

  return (
    <Main headerProps={{ title: "Oda" }}>
      <Card p="3" mb="3">
        <Flex justifyContent="space-between" alignItems="center">
          <Flex>
            <Button
              variant="link"
              size="sm"
              colorScheme="teal"
              mr="3"
              onClick={() => {
                history.push("/campus");
              }}
            >
              {campus?.name}
            </Button>
            <IoChevronForward />
            <Button
              variant="link"
              size="sm"
              ml="3"
              colorScheme="teal"
              onClick={() => {
                history.push(`/campus/${params.campusId}/build`);
              }}
            >
              {build?.name}
            </Button>
            <IoChevronForward />
            <Button
              variant="link"
              size="sm"
              ml="3"
              colorScheme="teal"
              onClick={() => {
                history.push(
                  `/campus/${params.campusId}/build/${params.buildId}/floor`
                );
              }}
            >
              {floor?.name}
            </Button>
          </Flex>
          <Button
            size="sm"
            colorScheme="blue"
            onClick={() => modalRef.current?.open()}
          >
            Yeni Oda Ekle
          </Button>
        </Flex>
      </Card>

      {!!data?.data.data ? (
        <Card>
          <DTable
            columns={[
              {
                name: "ID",
                selector: "id",
                sortable: true,
                width: "100px",
              },
              {
                name: "Oda Adı",
                selector: "name",
                sortable: true,
              },
              {
                name: "Oluşturulma Tarihi",
                cell: (row: ICampus) => (
                  <span>{Moment(row.createDate).format("DD.MM.YYYY")}</span>
                ),
              },
              {
                name: "Durum",
                cell: (row: ICampus) => <Badge isActive={!!row.isActive} />,
              },
              {
                name: "Aktif / Pasif",
                cell: (row: any) => (
                  <Box>
                    <Confirm
                      onConfirm={() => {
                        handleClickToggleActive(row);
                      }}
                      text="Bu kayıt pasif yapılacak. İşleme devam edilsin mi?"
                    >
                      {({ onOpen }) => (
                        <IconButton
                          size="sm"
                          onClick={onOpen}
                          aria-label="active"
                          icon={<IoOptions />}
                        />
                      )}
                    </Confirm>
                  </Box>
                ),
              },
              {
                name: "Düzenle",
                cell: (row: ICampus) => (
                  <LocationUpdater
                    title="Oda Düzenle"
                    defaultData={{ title: row.name! }}
                    onSubmit={(title) => handleUpdateTitle(row, title)}
                  />
                ),
              },
            ]}
            data={data?.data.data}
          />
        </Card>
      ) : (
        <Card>
          <Center>
            <Spinner />
          </Center>
        </Card>
      )}

      <Modal
        ref={modalRef}
        title="Oda Ekle"
        onConfirm={() => {
          formRef.current?.submitForm();
        }}
      >
        <MyForm
          innerRef={formRef}
          initialValues={{ name: "" }}
          onSubmit={async ({ name }: any) => {
            const data = (await create({
              name,
              isActive: true,
              floorID: floor?.id,
            })) as unknown as any;
            if (data?.data?.success) {
              toast({ title: "Oda Oluşturuldu", status: "success" });
              formRef.current?.resetForm();
              modalRef.current?.close();
              getRooms(parseInt(params.floorId));
            }
          }}
        >
          <InputField name="name" label="Oda Adı" />
        </MyForm>
      </Modal>
    </Main>
  );
}

export default Build;
