import React, { FC } from "react";
import { Box, Text, SimpleGrid, Button, Center } from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";

interface ReportsPageProps {}

const ReportsPage: FC<ReportsPageProps> = (props) => {
  return (
    <Main headerProps={{ title: "Bakım Raporları" }}>
      <Card>
        <SimpleGrid columns={[1, 2]} gap="5">
          <Link to="/report/completed">
            <Center
              bgColor="gray.50"
              h="250px"
              rounded="md"
              _hover={{ bgColor: "blue.50" }}
            >
              <Box textAlign="center">
                <Text>Gerçekleşen Bakımlar</Text>
              </Box>
            </Center>
          </Link>
          <Link to="/report/incompleted">
            <Center
              bgColor="gray.50"
              h="250px"
              rounded="md"
              _hover={{ bgColor: "blue.50" }}
            >
              <Box textAlign="center">
                <Text>
                  Planlanan Zamanda
                  <br />
                  Gerçekleşmeyen Bakımlar
                </Text>
              </Box>
            </Center>
          </Link>
        </SimpleGrid>
      </Card>
    </Main>
  );
};

ReportsPage.defaultProps = {};

export default ReportsPage;
