import React, {
  FC,
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
  Fragment,
} from "react";
import {
  Box,
  Flex,
  Text,
  Center,
  Spinner,
  Button,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Checkbox,
  Divider,
  SimpleGrid,
  Badge,
  Tooltip,
  Collapse,
  useDisclosure,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import { Card, Modal, ModalProps } from "components/atoms";
import {
  TimelineDocument as ITimelineDocument,
  TimelineLegal as ITimelineLegal,
} from "models";
import PerfectScrollbar from "components/atoms/PerfectScrollbar";
import Moment from "moment";
import { useHistory } from "react-router-dom";
import Dropzone from "components/atoms/Dropzone";
import {
  IoAlbumsOutline,
  IoArrowDown,
  IoBackspace,
  IoCheckmark,
  IoEllipsisHorizontal,
  IoFileTray,
  IoFilter,
  IoInformation,
  IoRemove,
  IoSearch,
  IoTrash,
} from "react-icons/io5";
import { useToast } from "hooks/toast";
import { UserRoles } from "models/user";
import { IoIosClock } from "react-icons/io";
import Confirm from "components/atoms/Confirm";

export enum TimelineStatus {
  completed = 2,
  notTime = 1,
  late = 4,
  ready = 3,
  needDocument = 5,
  lateDone = 6,
}

interface TimelineLegalProps {
  renderChangeDropdown?: () => ReactElement;
  hidden: boolean;
  selectedCampus: number;
  hiddenFuture: boolean;
  onChangeCount: (count: number) => void;
}

const TimelineDocument: FC<TimelineLegalProps> = (props) => {
  const { project, user } = useAuth();
  const [getTimeline, statTime, dataTime] = Hooks.TimelineDocument();
  const [getDesc, statDesc, dataDesc] = Hooks.DocumentDesc();
  const [getTypes, statType, dataTypes] = Hooks.UserTypeList();
  const _ref = useRef<any>(null);
  const [query, setQuery] = useState<string>("");
  const [isSearch, setSearch] = useState(false);
  const [iStat, setIStat] = useState<boolean | null>(null);

  const filterModalRef = useRef<ModalProps>(null);

  const [filter, setFilter] = useState({
    status: [] as string[],
    period: [] as number[],
    types: [] as number[],
    userTypes: [] as number[],
    onlyMe: true as boolean,
  });

  const [currentFilter, setCurrentFilter] = useState({
    status: [] as string[],
    period: [] as number[],
    types: [] as number[],
    userTypes: [] as number[],
    onlyMe: true as boolean,
  });

  function handleFilter() {
    setCurrentFilter(filter);
    filterModalRef.current?.close();
  }

  function clearFilter() {
    setCurrentFilter({
      status: [] as string[],
      period: [] as number[],
      types: [] as number[],
      userTypes: [] as number[],
      onlyMe: true as boolean,
    });
    setFilter({
      status: [] as string[],
      period: [] as number[],
      types: [] as number[],
      userTypes: [] as number[],
      onlyMe: true as boolean,
    });
    filterModalRef.current?.close();
  }

  function onFilter(item: any) {
    let filterArr = [];
    if (currentFilter.status.length !== 0) {
      filterArr.push(currentFilter.status.includes(item.statusColor));
    }

    if (currentFilter.userTypes.length !== 0) {
      filterArr.push(
        currentFilter.userTypes.includes((item as any).userTypeID)
      );
    }

    if (!!currentFilter.onlyMe) {
      filterArr.push(item.assignUserID === user.id);
    }

    if (iStat !== null) {
      filterArr.push(item.isCompleted === iStat);
    }

    if (props.hidden) {
      if (
        [TimelineStatus.completed, TimelineStatus.lateDone].includes(
          item.status
        )
      ) {
        filterArr.push(false);
      }
    }

    if (props.hiddenFuture) {
      if ([TimelineStatus.notTime].includes(item.status)) {
        filterArr.push(false);
      }
    }

    if (props.selectedCampus !== 0) {
      filterArr.push((item as any).campusID === props.selectedCampus);
    }

    return (
      !filterArr.includes(false) &&
      item.documentName.toLowerCase().includes(query.toLowerCase())
    );
  }

  useLayoutEffect(() => {
    getTimeline(project.id);
    getDesc(project.id);
    getTypes(project.id);
  }, []);

  useEffect(() => {
    if (dataTime?.data.data) {
      const count = dataTime?.data.data.filter(onFilter).length;
      props.onChangeCount(count);
    }
  }, [filter, props.hiddenFuture, props.hidden, dataTime, currentFilter]);

  return (
    <Card w="100%" minH="calc(100vh - 200px)">
      <Flex mb="2" justifyContent="space-between">
        <Box>{props.renderChangeDropdown && props.renderChangeDropdown()}</Box>
        <Flex>
          <Tooltip label="Filtre" aria-label="A tooltip">
            <IconButton
              size="sm"
              mr="1"
              aria-label="Filtre"
              icon={<IoFilter />}
              onClick={() => {
                filterModalRef.current?.open();
              }}
            />
          </Tooltip>
          <Tooltip label="Geçmiş" aria-label="A tooltip">
            <IconButton
              size="sm"
              mr="2"
              icon={<IoArrowDown />}
              onClick={() => {
                _ref.current?.goUndone();
              }}
              aria-label="Geçmiş"
            />
          </Tooltip>
          <Tooltip label="Bugüne Git" aria-label="A tooltip">
            <IconButton
              size="sm"
              mr="2"
              icon={<IoIosClock />}
              onClick={() => {
                _ref.current?.goToday();
              }}
              aria-label="Bugün"
            />
          </Tooltip>
          <Tooltip label="Ara" aria-label="Search tooltip">
            <IconButton
              size="sm"
              mr="2"
              icon={<IoSearch />}
              onClick={() => {
                if (isSearch) {
                  setQuery("");
                  setSearch(false);
                } else {
                  setSearch(true);
                }
              }}
              aria-label="Ara"
            />
          </Tooltip>
          <Popover placement="bottom-end">
            <PopoverTrigger>
              <Box>
                <Tooltip label="Renk Açıklamaları" aria-label="Colors tooltip">
                  <IconButton
                    size="sm"
                    aria-label="colors"
                    icon={<IoInformation />}
                  />
                </Tooltip>
              </Box>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Doküman Renk Açıklamaları</PopoverHeader>
              <PopoverBody>
                {dataDesc?.data?.data.map((color: any, index: number) => (
                  <Flex alignItems="center" mb="1">
                    <Box
                      w="20px"
                      h="20px"
                      mr="2"
                      bgColor={color.statusColor}
                      rounded="sm"
                    ></Box>
                    <Text>{color.statusDesc}</Text>
                  </Flex>
                ))}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
      </Flex>
      <Box>
        <Collapse in={isSearch} animateOpacity>
          <Box>
            <Input
              size="sm"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              disabled={!isSearch}
              placeholder="Belge Adına Göre Ara..."
            />
          </Box>
        </Collapse>
      </Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontWeight="semibold" mb="1" mt="2">
          Belge Takip İşlemleri
        </Text>
        <Flex>
          <Tooltip label="Gerçekleşen">
            <IconButton
              size="xs"
              colorScheme="green"
              variant={iStat === true ? "solid" : "outline"}
              aria-label="complete"
              onClick={() => {
                setIStat(true);
              }}
              icon={<IoCheckmark />}
            />
          </Tooltip>
          <Tooltip label="Hepsi">
            <IconButton
              mx="1"
              size="xs"
              aria-label="complete"
              variant={iStat === null ? "solid" : "outline"}
              onClick={() => {
                setIStat(null);
              }}
              icon={<IoAlbumsOutline />}
            />
          </Tooltip>
          <Tooltip label="Gerçekleşmeyen">
            <IconButton
              size="xs"
              aria-label="complete"
              variant={iStat === false ? "solid" : "outline"}
              onClick={() => {
                setIStat(false);
              }}
              colorScheme="red"
              icon={<IoRemove />}
            />
          </Tooltip>
        </Flex>
      </Flex>
      {statTime !== "fulfilled" && (
        <Center minH="400px">
          <Spinner />
        </Center>
      )}
      {statTime === "fulfilled" && dataTime?.data.data && (
        <TimelineItems
          onNeedReload={() => {
            getTimeline(project.id);
          }}
          onlyMe={currentFilter.onlyMe}
          ref={_ref}
          data={dataTime?.data.data.filter(onFilter).sort((a: any, b: any) => {
            var dateA = new Date(a.endDate),
              dateB = new Date(b.endDate);
            return (dateB as any) - (dateA as any);
          })}
        />
      )}
      <Modal
        ref={filterModalRef}
        noForm
        title="Filtre"
        onClose={() => {}}
        onOpen={() => {
          setFilter(currentFilter);
        }}
      >
        <Box>
          <Heading mt="-3" fontSize="md">
            Doküman Durumu
          </Heading>
          <Divider my="1" />
          <SimpleGrid columns={[2, 3]} gap="1">
            {dataDesc?.data?.data.map((_color: any) => (
              <Flex alignItems="center" borderWidth="1px" px="3" py="1">
                <Checkbox
                  borderColor={_color.statusColor}
                  defaultChecked={filter.status.includes(_color.statusColor)}
                  onChange={(e) => {
                    if (e.currentTarget.checked) {
                      if (!filter.status.includes(_color.statusColor)) {
                        setFilter((_state) => ({
                          ..._state,
                          status: [..._state.status, _color.statusColor],
                        }));
                      }
                    } else {
                      setFilter((_state) => ({
                        ..._state,
                        status: _state.status.filter(
                          (_statu) => _statu !== _color.statusColor
                        ),
                      }));
                    }
                  }}
                >
                  <Text fontSize="xs" lineHeight="4">
                    {_color.statusDesc}
                  </Text>
                </Checkbox>
              </Flex>
            ))}
          </SimpleGrid>
          <Heading mt="3" fontSize="md">
            Kullanıcı Tipi
          </Heading>
          <Divider my="1" />
          <SimpleGrid columns={[2, 3]} gap="1">
            {dataTypes?.data.data
              .filter((_period: any) =>
                user.userTypes.map((_: any) => _.id).includes(_period.id)
              )
              .map((_period: any) => (
                <Flex alignItems="center" borderWidth="1px" px="3" py="2">
                  <Checkbox
                    defaultChecked={filter.userTypes.includes(_period.id)}
                    onChange={(e) => {
                      if (e.currentTarget.checked) {
                        if (!filter.userTypes.includes(_period.id)) {
                          setFilter((_state) => ({
                            ..._state,
                            userTypes: [..._state.userTypes, _period.id],
                          }));
                        }
                      } else {
                        setFilter((_state) => ({
                          ..._state,
                          userTypes: _state.userTypes.filter(
                            (_periodId) => _periodId !== _period.id
                          ),
                        }));
                      }
                    }}
                  >
                    <Text fontSize="xs" lineHeight="4">
                      {_period.name}
                    </Text>
                  </Checkbox>
                </Flex>
              ))}
          </SimpleGrid>
          <Heading mt="3" fontSize="md">
            Doküman Atanan Kullanıcı
          </Heading>
          <Divider my="2" />
          <SimpleGrid columns={[2, 3]}>
            <Flex alignItems="center" borderWidth="1px" px="3" py="2">
              <Checkbox
                defaultChecked={filter.onlyMe}
                onChange={(e) => {
                  setFilter((prev) => ({ ...prev, onlyMe: !prev.onlyMe }));
                }}
              >
                <Text fontSize="xs" lineHeight="4">
                  {"Sadece Ben"}
                </Text>
              </Checkbox>
            </Flex>
          </SimpleGrid>
          <Flex justifyContent="flex-end" mt="10">
            <Button
              leftIcon={<IoTrash />}
              onClick={clearFilter}
              mr="2"
              colorScheme="red"
              variant="outline"
              size="sm"
            >
              Temizle
            </Button>
            <Button
              leftIcon={<IoFilter />}
              colorScheme="blue"
              minW="200px"
              onClick={handleFilter}
              size="sm"
            >
              Filtre
            </Button>
          </Flex>
        </Box>
      </Modal>
    </Card>
  );
};

interface TimelineItemsProps {
  data: ITimelineDocument[];
  onNeedReload: () => void;
  onlyMe: boolean;
}
const TimelineItems = forwardRef((props: TimelineItemsProps, ref) => {
  const scrollBarRef = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    goToday: () => {
      getTodayTopPosition();
    },
    goUndone: () => {
      goUndone();
    },
  }));

  function getTodayIndex() {
    if (!!props.data) {
      let index = 0;
      let closeIndex = 0;
      let close = 0;
      while (index < props.data.length) {
        const item = props.data[index];
        const day = Moment(item.endDate).diff(Moment(), "days") + 0;
        if (index === 0) {
          closeIndex = index;
          close = day;
        } else {
          if (0 - day < close) {
            closeIndex = index;
            close = day;
          }
        }
        index++;
      }
      return closeIndex;
    }
  }

  function getUndoneIndex(data: any[]) {
    let undoneIndex = 0;
    if (!!data) {
      data.forEach((item: any, currIndex: number) => {
        if (
          [
            TimelineStatus.late,
            TimelineStatus.ready,
            TimelineStatus.needDocument,
          ].includes(item.status)
        ) {
          var undoneItem: any = data[undoneIndex];
          if (undoneItem.isCompleted) {
            undoneIndex = currIndex;
          } else if (
            Moment(undoneItem.endDate).diff(item.endDate, "seconds") > 0
          ) {
            undoneIndex = currIndex;
          }
        }
      });
    }
    return undoneIndex;
  }

  function goUndone() {
    let today = getUndoneIndex(props.data);
    if (today || today === 0) {
      let el = document.getElementById(`item-${today}`);
      scrollBarRef.current?._container.scrollTo({
        top: el?.offsetTop,
        behavior: "smooth",
      });
    } else {
    }
  }
  function getTodayTopPosition() {
    let today = getTodayIndex();
    if (today || today === 0) {
      let el = document.getElementById(`item-${today}`);
      scrollBarRef.current?._container.scrollTo({
        top: el?.offsetTop,
        behavior: "smooth",
      });
    } else {
    }
  }

  useEffect(() => {
    if (!!props.data && props.data.length > 0) {
      goUndone();
    }
  }, [props.data]);

  if (props.data.length === 0) {
    return (
      <Center minH="300px">
        <Text>Belge Takip İşlemi Bulunamadı</Text>
      </Center>
    );
  }
  return (
    <PerfectScrollbar maxHeight={"calc(100vh - 250px)"} innerRef={scrollBarRef}>
      <TimelineItemTitle />
      {props.data.map((item, index) => (
        <TimelineItem
          key={index}
          data={item}
          id={`item-${index}`}
          onNeedReload={() => {
            props.onNeedReload();
          }}
        />
      ))}
    </PerfectScrollbar>
  );
});

const TimelineItemTitle = () => {
  return (
    <Fragment>
      <Flex
        mb="2"
        border="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        p="2"
        justifyContent="space-between"
        alignItems="center"
        position="sticky"
        top="0px"
        backgroundColor={useColorModeValue("gray.100", "gray.700")}
        zIndex="2"
      >
        <Flex alignItems="center" flex="1">
          <Box flex="1" flexBasis="25px">
            <Text lineHeight="1" fontSize="x-small">
              ID
            </Text>
          </Box>
          <Box flex="1" flexBasis="25px">
            <Text lineHeight="1" fontSize="x-small">
              Belge
            </Text>
          </Box>
          <Box ml="2" flex="1">
            <Text lineHeight="1" fontSize="x-small">
              Tesis
            </Text>
          </Box>

          <Box ml="2" flex="1">
            <Text lineHeight="1" fontSize="x-small">
              Periyot
            </Text>
          </Box>
          <Box ml="2" flex="1">
            <Text lineHeight="1" fontSize="x-small">
              Kontrol Tarihi
            </Text>
          </Box>
          <Box ml="2" flex="1">
            <Text lineHeight="1" fontSize="x-small">
              Kullanıcı Tipi
            </Text>
          </Box>
          <Box ml="2" flex="1">
            <Text lineHeight="1" fontSize="x-small">
              Atanan Kullacı
            </Text>
          </Box>
        </Flex>
        <Flex minW="290px" justifyContent="flex-end" alignItems="center"></Flex>
      </Flex>
    </Fragment>
  );
};

const TimelineItem = (props: {
  data: ITimelineDocument;
  id: string;
  onNeedReload: () => void;
}) => {
  const history = useHistory();
  const modalRef = useRef<ModalProps>(null);
  const docsModalRef = useRef<ModalProps>(null);
  const { user, role } = useAuth();
  const [datas, setDatas] = useState({ firm: "", note: "" });
  const toast = useToast();
  const documentsDisc = useDisclosure();

  const [files, setFiles] = useState<File[]>([]);
  const [date, setDate] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [update, status, dataUpdate] = Hooks.DoUpdateDocument();
  const [cancel] = Hooks.CancelDocument();

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  function getBase64Photos(arr: any[]) {
    return new Promise(async (resolve, reject) => {
      let arrData: any[] = [];
      for (var index = 0; index < arr.length; index++) {
        let base64str = await toBase64(arr[index]);
        let ext = arr[index].name.split(".").pop();
        arrData.push({ base64: base64str, extension: ext });
      }

      return resolve(arrData);
    });
  }

  function handleOnDrop(dropFiles: File[]) {
    setFiles(dropFiles);
  }

  async function handleConfirm() {
    setLoading(true);
    let base64ListForms = await getBase64Photos(files || []);
    await update({
      documents: [
        ...(base64ListForms as any[]).map((base64Str) => ({
          document: base64Str.base64,
          extension: base64Str.extension,
          type: "inventoryPhoto",
        })),
      ],
      explain: datas.note,
      documentMaintenanceID: props.data.documentMaintenanceID,
      documentMaintenanceDetailID: props.data.documentMaintenanceDetailID,
      secondControlUserID: user.id,
      secondControlUserName: user.username,
    });
  }

  useEffect(() => {
    if (!!dataUpdate && dataUpdate?.data.success) {
      modalRef.current?.close();
      toast({ title: "İşlem başarıyla gerçekleşti!", status: "success" });
      props.onNeedReload();
    } else if (!!dataUpdate && dataUpdate?.data.success === false) {
      toast({ title: dataUpdate?.data.message, status: "error" });
    }
  }, [status]);

  return (
    <Fragment>
      <Flex
        mb="2"
        border="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        p="2"
        justifyContent="space-between"
        alignItems="center"
        id={`item-${props.id}`}
      >
        <Flex alignItems="center" flex="1">
          <Box flex="1" flexBasis="25px">
            <Text lineHeight="1" fontSize="xs" fontWeight="bold">
              {props.data?.documentMaintenanceID}
            </Text>
          </Box>
          <Box flex="1" flexBasis="25px">
            <Text lineHeight="1" fontSize="xs" fontWeight="bold">
              {props.data?.documentName}
            </Text>
          </Box>
          <Box ml="2" flex="1">
            <Text lineHeight="1" fontSize="xs" fontWeight="bold">
              {props.data.campusName}
            </Text>
          </Box>

          <Box ml="2" flex="1">
            <Text lineHeight="1" fontSize="xs" fontWeight="bold">
              {props.data.documentPeriodName}
            </Text>
          </Box>
          <Box ml="2" flex="1">
            <Text lineHeight="1" fontSize="xs" fontWeight="bold">
              {Moment(props.data.endDate).format("DD.MM.YYYY")}
            </Text>
          </Box>
          <Box ml="2" flex="1">
            <Text lineHeight="1" fontSize="xs" fontWeight="bold">
              {props.data.userTypeName}
            </Text>
          </Box>
          <Box ml="2" flex="1">
            <Text lineHeight="1" fontSize="xs" fontWeight="bold">
              {(props.data as any).assignUserName}
            </Text>
          </Box>
        </Flex>
        <Flex minW="290px" justifyContent="flex-end" alignItems="center">
          {role !== UserRoles.view &&
            [TimelineStatus.late, TimelineStatus.ready].includes(
              props.data.status
            ) && (
              <Button
                onClick={() => {
                  history.push(
                    `/maintenance/do-document-maintenance/${props.data.documentMaintenanceID}`,
                    {
                      timelineItem: props.data,
                    }
                  );
                }}
                colorScheme="blue"
                size="xs"
                ml="2"
              >
                İşlem Yap
              </Button>
            )}
          {role !== UserRoles.view &&
            [TimelineStatus.needDocument].includes(props.data.status) && (
              <Button
                colorScheme="pink"
                size="xs"
                ml="2"
                onClick={() => {
                  modalRef.current?.open();
                }}
              >
                Uygun Doküman Yükle
              </Button>
            )}
          {[TimelineStatus.completed, TimelineStatus.lateDone].includes(
            props.data.status
          ) && (
            <>
              <Menu placement="bottom-end">
                {({ isOpen }) => (
                  <>
                    <Badge colorScheme="green">Tamamlandı</Badge>
                    <MenuButton
                      ml="2"
                      isActive={isOpen}
                      as={IconButton}
                      size="xs"
                      icon={<IoEllipsisHorizontal />}
                    />
                    <MenuList>
                      <Confirm
                        onConfirm={async () => {
                          await cancel({
                            documentMaintenanceId:
                              props.data.documentMaintenanceID,
                          });
                          props.onNeedReload();
                        }}
                        colorScheme="orange"
                        confirmText="Geri Almayı Onayla"
                        text="Tamamlanan işlem geri alınacak. Bu işlemi onaylıyor musunuz?"
                      >
                        {({ onOpen }) => (
                          <MenuItem icon={<IoBackspace />} onClick={onOpen}>
                            <Text fontSize="xs">Geri Al</Text>
                          </MenuItem>
                        )}
                      </Confirm>
                    </MenuList>
                  </>
                )}
              </Menu>
            </>
          )}
        </Flex>
      </Flex>
      <Modal ref={docsModalRef} title="Dokümanlar">
        <Box mb="4">
          <Heading color="gray.700" size="md" mb="2">
            {props.data.documentName} Dokümanları
          </Heading>
          <Divider />
          {props.data?.documents?.map((document, index) => (
            <Box
              mt="2"
              borderBottom="1px"
              paddingBottom="2"
              borderColor="gray.200"
            >
              <Text fontWeight="semibold" color="gray.700">
                Doküman {index + 1}
              </Text>
              <Button
                size="sm"
                mt="0.5"
                onClick={() => {
                  window.open(document.path, "_blank");
                }}
              >
                Dosyayı Gör
              </Button>
              <Flex fontSize="xs" mt="2">
                <Text>Yüklenme Tarihi: </Text>
                <Text>
                  {Moment(document.createDate).format("DD.MM.YYYY HH:mm")}
                </Text>
              </Flex>
            </Box>
          ))}
        </Box>
      </Modal>
      <Modal
        ref={modalRef}
        title="Doküman Yükle"
        onConfirm={handleConfirm}
        onConfirmText="Kaydet"
      >
        <Box mb="4">
          <Heading size="sm" mb="3">
            {props.data.documentMaintenanceID} Belge Takip İşlemi
          </Heading>
          <FormControl mt="2">
            <FormLabel>Bakım Notu</FormLabel>
            <Textarea
              resize="none"
              value={datas.note}
              onChange={(e) =>
                setDatas((_datas) => ({
                  ..._datas,
                  note: e.target?.value,
                }))
              }
            ></Textarea>
          </FormControl>
          <FormControl mt="2">
            <FormLabel>Doküman</FormLabel>
            <Dropzone onDrop={handleOnDrop} hasAllType />
          </FormControl>
        </Box>
      </Modal>
    </Fragment>
  );

  return (
    <Flex
      borderWidth="1px"
      borderColor={useColorModeValue("gray.100", "gray.700")}
      p="2"
      px="3"
      mb="1"
      justifyContent="space-between"
      id={props.id}
    >
      <Box>
        <Flex alignItems="center">
          <Box
            rounded="full"
            bgColor={props.data.statusColor}
            w="20px"
            h="20px"
            mr="2"
          ></Box>
          <Text fontSize="xs">{props.data.documentName}</Text>
        </Flex>
        <Text mt="2" fontSize="xs">
          ID: {props.data.documentMaintenanceID}
        </Text>
        <Text fontSize="xs">Tesis: {props.data.campusName}</Text>
        <Text fontSize="xs">Bina: {props.data.buildName}</Text>
        <Text fontSize="xs">Kat: {props.data.floorName}</Text>
        <Text fontSize="xs">Oda: {props.data.roomName}</Text>
        <Text fontSize="xs">Periyot: {props.data.documentPeriodName}</Text>
        <Text fontSize="xs">
          Kontrol Tarihi: {Moment(props.data.endDate).format("DD.MM.YYYY")}
        </Text>
        <Text fontSize="xs">
          Kullanıcı Tipi:{" "}
          <Text d="inline" color="blue.500">
            {props.data.userTypeName}
          </Text>
        </Text>
        <Text fontSize="xs">
          Atanan Kullacı:{" "}
          <Text d="inline" color="blue.500">
            {(props.data as any).assignUserName}
          </Text>
        </Text>
        <Text fontSize="x-small">Durum: {props.data.statusDesc}</Text>
      </Box>
      <Flex flexDir="column" alignItems="flex-end">
        {[
          TimelineStatus.completed,
          TimelineStatus.lateDone,
          TimelineStatus.needDocument,
        ].includes(props.data.status) && (
          <Flex flexDirection="column" alignItems="flex-end">
            <Badge colorScheme="green">Gerçekleşti</Badge>
            <Button
              colorScheme="teal"
              size="sm"
              mt="2"
              mb="2"
              onClick={() => {
                docsModalRef.current?.open();
              }}
            >
              Dokümanları Gör
            </Button>
          </Flex>
        )}
        {role !== UserRoles.view &&
          [TimelineStatus.late, TimelineStatus.ready].includes(
            props.data.status
          ) && (
            <Button
              onClick={() => {
                history.push(
                  `/maintenance/do-document-maintenance/${props.data.documentMaintenanceID}`,
                  {
                    timelineItem: props.data,
                  }
                );
              }}
              colorScheme="blue"
              size="sm"
            >
              İşlem Yap
            </Button>
          )}
        {role !== UserRoles.view &&
          [TimelineStatus.needDocument].includes(props.data.status) && (
            <Button
              colorScheme="pink"
              size="sm"
              onClick={() => {
                modalRef.current?.open();
              }}
            >
              Uygun Doküman Yükle
            </Button>
          )}
      </Flex>
    </Flex>
  );
};

TimelineDocument.defaultProps = {};

export default TimelineDocument;
