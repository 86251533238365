import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Center,
  Spinner,
  Heading,
  Textarea,
  Input,
  FormControl,
  FormLabel,
  Select,
  IconButton,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import { UserRoles } from "models/user";
import DataTable from "components/atoms/DataTable";
import Moment from "moment";
import Badge from "components/atoms/Badge";
import { RouteComponentProps } from "react-router-dom";
import ModalAtom, { ModalProps } from "components/atoms/Modal";
import { useToast } from "hooks/toast";
import Confirm from "components/atoms/Confirm";
import { IoOptions } from "react-icons/io5";

interface QuestionListProps {}

interface PageState {
  group: any;
}

const QuestionList: FC<
  QuestionListProps & RouteComponentProps<{}, {}, PageState>
> = (props) => {
  const { role, project } = useAuth();
  const modalRef = useRef<ModalProps>(null);
  const updateModalRef = useRef<ModalProps>(null);
  const toast = useToast();
  const [getQF, statQF, dataQF] = Hooks.QuestionList();
  const [getQT, statQT, dataQT] = Hooks.QuestionTypes();
  const [getAQ, statAQ, dataAQ] = Hooks.AddQuestionToGroup();
  const [updateCQ, statCQ, dataCQ] = Hooks.UpdateControlQuestion();
  const [updateItem, setUpdateItem] = useState<any | null>(null);
  const [questionQuery, setQuestionQuery] = useState("");

  const [questionInfo, setQuestionInfo] = useState({
    questionType: 0,
    question: "",
  });

  useLayoutEffect(() => {
    getQF(props.location.state.group.id);
    getQT();
  }, []);

  useEffect(() => {
    if (dataAQ?.data.success) {
      toast({ title: "Soru başarıyla eklendi!" });
      getQF(props.location.state.group.id);
      modalRef.current?.close();
      setQuestionInfo({
        questionType: 0,
        question: "",
      });
    }
  }, [dataAQ]);

  useEffect(() => {
    if (statCQ === "fulfilled") {
      updateModalRef.current?.close();
      getQF(props.location.state.group.id);
    }
  }, [statCQ]);

  return (
    <Main headerProps={{ title: "Soru Grubu Soruları" }}>
      <Heading size="md" mb="3">
        Soru Grubu: {props.location.state.group.name}
      </Heading>
      <Card py="2">
        <Flex justifyContent="flex-end">
          {role !== UserRoles.basic && (
            <Button
              size="sm"
              colorScheme="blue"
              onClick={() => {
                modalRef.current?.open();
              }}
            >
              Yeni Oluştur
            </Button>
          )}
        </Flex>
      </Card>

      <Card mt="3">
        {statQF !== "fulfilled" && (
          <Center minH="300px">
            <Spinner />
          </Center>
        )}
        {statQF === "fulfilled" && !!dataQF?.data.data && (
          <DataTable
            columns={[
              { name: "#ID", selector: "id" },
              { name: "Soru", selector: "question" },
              {
                name: "Oluşturulma Tarihi",
                cell: (row: any) => Moment(row.createDate).format("DD.MM.YYYY"),
                selector: "createDate",
                columnType: "date",
              },
              {
                name: "Durum",
                selector: "isActive",
                columnType: "isActiveBoolean",
                cell: (row: any) => <Badge isActive={row.isActive} />,
              },
              {
                name: "Aktif / Pasif",
                cell: (row: any) => (
                  <Center w="90%">
                    <Confirm
                      onConfirm={() => {
                        updateCQ(row.id, row.question, !row.isActive);
                      }}
                      text={`Soru Grubu ${
                        !row.isActive ? "Aktif" : "Pasif"
                      } yapılsın mı?`}
                    >
                      {({ onOpen }) => (
                        <IconButton
                          size="sm"
                          onClick={onOpen}
                          aria-label="activepassive"
                          icon={<IoOptions />}
                        />
                      )}
                    </Confirm>
                  </Center>
                ),
                width: "100px",
              },
              {
                name: "Soru Düzenle",
                cell: (row: any) => (
                  <Button
                    colorScheme="purple"
                    size="sm"
                    onClick={() => {
                      setQuestionQuery(row.question);
                      setUpdateItem(row);
                      updateModalRef.current?.open();
                    }}
                  >
                    Soru Düzenle
                  </Button>
                ),
              },
            ]}
            data={dataQF.data.data}
          />
        )}
      </Card>
      <ModalAtom
        ref={modalRef}
        onConfirm={() => {
          getAQ({
            // controlQuestionID: props.location.state.group.id,
            controlQuestionGroupID: props.location.state.group.id,
            controlQuestionTypeID: questionInfo.questionType,
            projectID: project.id,
            question: questionInfo.question,
          });
        }}
        onClose={() => {
          setQuestionInfo({
            questionType: 0,
            question: "",
          });
        }}
        onConfirmText="Oluştur"
        title="Soru Ekle"
      >
        <FormControl>
          <FormLabel>
            Soru Tipi{" "}
            <Text color="red.500" d="inline">
              *
            </Text>
          </FormLabel>
          <Select
            value={questionInfo.questionType.toString()}
            onChange={(e) =>
              setQuestionInfo((_datas) => ({
                ..._datas,
                questionType: parseInt(e.target.value),
              }))
            }
          >
            <option value="0">Seç</option>
            {!!dataQT &&
              dataQT?.data.data.map((item: any, index: number) => (
                <option key={index} value={item.id}>
                  {item.id === 1 || item.id === 3
                    ? "DURUM DEĞERLENDİRME"
                    : item.name}
                </option>
              ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel mt="4">
            Soru{" "}
            <Text color="red.500" d="inline">
              *
            </Text>
          </FormLabel>
          <Textarea
            value={questionInfo.question}
            onChange={(e) =>
              setQuestionInfo((_datas) => ({
                ..._datas,
                question: e.target.value,
              }))
            }
          ></Textarea>
        </FormControl>
      </ModalAtom>
      <ModalAtom
        ref={updateModalRef}
        onConfirm={() => {
          updateCQ(updateItem.id, questionQuery, updateItem.isActive);
        }}
        onClose={() => {
          setQuestionQuery("");
          setUpdateItem(null);
        }}
        onConfirmText="Düzenle"
        title="Soru Düzenle"
      >
        <FormControl>
          <FormLabel mt="4">
            Soru{" "}
            <Text color="red.500" d="inline">
              *
            </Text>
          </FormLabel>
          <Textarea
            value={questionQuery}
            onChange={(e) => setQuestionQuery(e.target.value)}
          ></Textarea>
        </FormControl>
      </ModalAtom>
    </Main>
  );
};

QuestionList.defaultProps = {};

export default QuestionList;
