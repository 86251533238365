import React, { FC, Fragment, useEffect, useState } from "react";
import {
  Box,
  Text,
  Spinner,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Tr,
  Divider,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import "react-image-lightbox/style.css";
import "./master.css";
import { useAuth, useRedux } from "hooks/redux-hooks";
import { Redirect, RouteComponentProps } from "react-router-dom";
import Moment from "moment";
import { Card } from "components/atoms";
import Lightbox from "react-image-lightbox";
import { FaCheck } from "react-icons/fa";
import { Question } from "models";
import { getApiUrl } from "services/urls";

interface DocReportProps {}

const configs = {
  dateString: "YYYY-MM-DD[T]HH:mm:ss",
};

const DocReport: FC<DocReportProps & RouteComponentProps> = (props) => {
  const { user, project } = useAuth();
  const [maintenance, setMaintenance] = useState<any>(null);
  const [lightImage, setLightImage] = useState<string | null>(null);

  useEffect(() => {
    const data = localStorage.getItem("doc-report");
    if (!data) {
      props.history.push("/");
    }

    setMaintenance(JSON.parse(data!));
  }, []);

  function photoUrlManipulation(url: string) {
    if (!url || url === "") return url;
    if (!url.includes("//")) return url;
    url.replace(/\/\//g, "/");
    const apiUrl = getApiUrl();

    url = url.split("/Content")[1];
    url = `${apiUrl}/Content${url}`;
    console.log({ url });
    return url;
  }

  if (!maintenance) return <Spinner />;

  return (
    <Fragment>
      <div className="book">
        <div className="page">
          <Header />
          <DataTable
            title="Doküman Bilgileri"
            data={[
              { title: "Doküman Adı", value: maintenance.documentName },
              { title: "Tesis", value: maintenance.campusName },
              { title: "Bina", value: maintenance.buildName },
              { title: "Kat", value: maintenance.floorName },
              { title: "Oda", value: maintenance.roomName },
              { title: "Grup", value: maintenance.groupName },
              { title: "Marka", value: maintenance.brandName },
              { title: "Model", value: maintenance.modelName },
              { title: "Seri No", value: maintenance.serialNumber },
              { title: "Üretim Yılı", value: maintenance.productionYear },
              {
                title: "Kapasite Birimi",
                value: maintenance.inventoryCapacityName,
              },
              { title: "Kapasite Değeri", value: maintenance.unit },
            ]}
          />
          {!!maintenance.inventoryPhoto && (
            <Flex justifyContent="center">
              <img
                alt="Ekipman"
                style={{
                  cursor: "pointer",
                }}
                src={photoUrlManipulation(maintenance.inventoryPhoto)}
                onClick={(e) =>
                  setLightImage(
                    photoUrlManipulation(maintenance.inventoryPhoto)
                  )
                }
                width="auto"
                height="150px"
              />
            </Flex>
          )}
          <div className="page-number">1</div>
        </div>
        <div className="page">
          <Header />
          <DataTable
            title="Doküman Bilgileri"
            data={[
              {
                title: "Planlı Doküman Tarihi",
                value: Moment(maintenance.endDate, configs.dateString).format(
                  "DD/MM/YYYY"
                ),
              },
              {
                title: "Gercekleşen Tarih",
                value: Moment(maintenance.completedDate).format(
                  "DD/MM/YYYY HH:mm"
                ),
              },
              {
                title: "Kullanıcı",
                value: maintenance.completedUserName,
              },
              {
                title: "Doküman Sorusu",
                value: maintenance.question,
              },
              {
                title: "Doküman Sorusu Cevabı",
                value: maintenance.questionAnswer,
              },
              {
                title: "Doküman Sorusu Cevab Açıklaması",
                value: maintenance.answerExplain,
              },
            ]}
          />
          <div className="page-number">2</div>
        </div>
      </div>
      {!!lightImage && (
        <Lightbox
          mainSrc={lightImage!}
          onCloseRequest={() => setLightImage(null)}
        />
      )}
    </Fragment>
  );
};

function Header() {
  const { user, project } = useAuth();
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      borderBottom="1px"
      borderColor="gray.300"
      pb="1"
    >
      <Flex flex="1">
        <Image src={project.companyLogo} width="150px" />
      </Flex>
      <Flex flex="3" justifyContent="center">
        <Text textAlign="center" fontSize="lg">
          Belge Takip Raporu
        </Text>
      </Flex>
      <Flex flex="1" justifyContent="flex-end">
        <Text textAlign="center" fontSize="lg">
          {Moment().format("DD.MM.YYYY")}
        </Text>
      </Flex>
    </Flex>
  );
}

function DataTable({ data, title }: any) {
  return (
    <Box py="4">
      <Heading fontSize="md">{title}</Heading>
      <Divider my="2" />
      {data && (
        <Table variant="striped" size="sm">
          <Tbody>
            {data.map((item: any, index: number) => (
              <Tr key={index}>
                <Td>
                  <span>{item.title}: </span>
                </Td>
                <Td style={{ minWidth: 130 }}>
                  <strong>{item.value}</strong>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
}

function DataCard({ title, children }: any) {
  return (
    <Box py="4">
      <Heading fontSize="md" mb="5">
        {title}
      </Heading>
      <Flex w="100%" flexWrap="wrap" wrap="wrap">
        {children}
      </Flex>
    </Box>
  );
}

DocReport.defaultProps = {};

export default DocReport;
