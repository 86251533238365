import React, { FC } from "react";
import { Box, HStack, Text } from "@chakra-ui/react";
import { Note } from "services/swagger";
import { Card } from "components/atoms";
import moment from "moment";

type DailyNoteItemProps = {
  item: Note;
  index: number;
};

const DailyNoteItem: FC<DailyNoteItemProps> = ({ item, index }) => {
  return (
    <Box my="2">
      <Card bgColor="white">
        <Text fontSize="xs" color="gray.500">
          {(item as any).userName} ({moment(item.createDate).format("HH:mm")}):
        </Text>
        <Text>{item.body}</Text>
      </Card>
    </Box>
  );
};

export default DailyNoteItem;
